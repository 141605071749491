import editorLayout from './editor-layout';

import {conditionallyAddConfig} from '~/utils';

const dropdownIconConfig = [{
	name: 'dropdownIconOpen',
	inputType: 'icon',
	label: 'Dropdown icon open',
	default: '{"prefix": "fas", "x": {"name": "caret-up"}}'
}, {
	name: 'dropdownIconOpenColor',
	inputType: 'color',
	label: 'Set color on icon',
	default: '#000'
}, {
	name: 'dropdownIconOpenSize',
	inputType: 'text',
	label: 'Set size on icon',
	default: '1em'
}, {
	name: 'dropdownIconClosed',
	inputType: 'icon',
	label: 'Dropdown icon closed',
	default: '{"prefix": "fas", "x": {"name": "caret-down"}}'
}, {
	name: 'dropdownIconClosedColor',
	inputType: 'color',
	label: 'Set color on icon',
	default: '#000'
}, {
	name: 'dropdownIconClosedSize',
	inputType: 'text',
	label: 'Set size on icon',
	default: '1em'
}];

const dropdownImageConfig = [{
	name: 'dropdownImageOpen',
	inputType: 'media-v2',
	label: 'Dropdown image open',
	default: false
}, {
	name: 'dropdownImageClosed',
	inputType: 'media-v2',
	label: 'Dropdown image open',
	default: false
}];

export default node => ({
	type: 'ARTICLE_SUBSCRIPTIONS',
	name: 'Subscriptions',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-box-usd',
	editorLayout,
	styleClasses: {
		Wrapper: '',
		Subscriptions: '',
		Subscriptions_Dropdown_Active: '',
		Subscriptions_Single_Purchase: '',
		Subscription: 'Preset-Primary-Font-Family',
		Subscription_Active: '',
		Subscription_Heading: 'Preset-Heading-Font-Family, Preset-Heading-Color',
		Subscription_Text: 'Preset-Primary-Font-Family',
		Single_Purchase_Heading: 'Preset-Heading-Font-Family, Preset-Heading-Color',
		Single_Purchase_Text: 'Preset-Primary-Font-Family',
		Descriptions: '',
		Description1: 'Preset-Primary-Text',
		Description2: 'Preset-Primary-Text',
		Description3: 'Preset-Primary-Text',
		Price: '',
		Price_Org: '',
		Price_Prefix: '',
		Price_Suffix: '',
		Prices: '',
		Saved_Amount: '',
		Saved_Amount_Prefix: '',
		Saved_Amount_Suffix: '',
		Saved_Amount_Value: '',
		Button_Wrapper: '',
		Button: 'Preset-Secondary-Button',
		Select: '',
		Options: '',
		Option: ''
	},
	props: [{
		name: 'layout',
		inputType: 'select',
		label: 'Purchase layout',
		default: 'list',
		values: [{
			label: 'One purchase button for each subscription',
			value: 'list'
		}, {
			label: 'One purchase button for selected subscription',
			value: 'radio'
		}, {
			label: 'Use buy button component',
			value: 'buyButton'
		}]
	}, {
		name: 'singlePurchase',
		inputType: 'checkbox',
		label: 'Add single purchase as option',
		default: false
	}, node && node.props.singlePurchase && {
		name: 'singlePurchaseDesc1',
		inputType: 'text',
		label: 'Single purchase description 1',
		default: ''
	}, node && node.props.singlePurchase && {
		name: 'singlePurchaseDesc2',
		inputType: 'textarea',
		label: 'Single purchase description 2',
		default: ''
	}, node && node.props.singlePurchase === false && {
		name: 'singlePurchaseRelatedArticles',
		inputType: 'checkbox',
		label: 'Add single purchase options from related articles',
		description: 'List related articles with type Purchase options',
		default: false
	}, node && node.props.singlePurchase === false && {
		name: 'useDropdown',
		inputType: 'checkbox',
		label: 'Display options in dropdown',
		description: 'Can be used together with the purchase layout options "One purchase button for selected subscription" and "Use buy button component"',
		default: false
	}, node && node.props.useDropdown && {
		name: 'dropdownPlaceholderSubscription',
		inputType: 'text',
		label: 'Dropdown placeholder text for subscriptions',
		default: 'Choose option'
	}, node && node.props.useDropdown && {
		name: 'dropdownPlaceholderSinglePurchase',
		inputType: 'text',
		label: 'Dropdown placeholder text for single purchase',
		default: 'Choose option'
	}, node && node.props.useDropdown && {
		name: 'dropdownIconType',
		inputType: 'select',
		label: 'Choose dropdown icon type',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	},
	...conditionallyAddConfig(node, 'dropdownIconType', 'icon', 'icon', dropdownIconConfig),
	...conditionallyAddConfig(node, 'dropdownIconType', 'image', 'icon', dropdownImageConfig),
	node && !node.props.useDropdown && node.props.layout !== 'list' && {
		name: 'useRadioButtons',
		inputType: 'checkbox',
		label: 'Use radio buttons',
		description: 'Can be used together with the purchase layout options "One purchase button for each subscription" and "Use buy button component"',
		default: false
	}, node && !node.props.useDropdown && {
		name: 'showSavedAmount',
		inputType: 'select',
		label: 'Show saved amount',
		description: 'Shows the difference between original price and sales price. Does not work with dropdown layout.',
		default: '',
		values: [{
			label: 'Do not show',
			value: ''
		}, {
			label: 'Show in percent',
			value: 'showPercent'
		}, {
			label: 'Show a sum',
			value: 'showSum'
		}]
	}, node && (node.props.showSavedAmount === 'showPercent' || node.props.showSavedAmount === 'showSum') && {
		name: 'savedAmountPrefix',
		inputType: 'text',
		label: 'Saved amount prefix',
		default: ''
	}, node && (node.props.showSavedAmount === 'showPercent' || node.props.showSavedAmount === 'showSum') && {
		name: 'savedAmountSuffix',
		inputType: 'text',
		label: 'Saved amount suffix',
		default: ''
	}, {
		name: 'goToCheckout',
		inputType: 'checkbox',
		label: 'Go to checkout after add',
		default: false
	}, {
		name: 'subscriptionHeading',
		inputType: 'text',
		label: 'Subscription heading',
		default: ''
	}, {
		name: 'singlePurchaseHeading',
		inputType: 'text',
		label: 'Single purchase heading',
		default: ''
	}, {
		name: 'subscriptionText',
		inputType: 'textarea',
		label: 'Subscription additional text',
		default: ''
	}, {
		name: 'singlePurchaseText',
		inputType: 'textarea',
		label: 'Single purchase additional text',
		default: ''
	}, {
		name: 'showDescription1',
		inputType: 'checkbox',
		label: 'Show description 1',
		default: true
	}, {
		name: 'showDescription2',
		inputType: 'checkbox',
		label: 'Show description 2',
		default: true
	}, {
		name: 'showDescription3',
		inputType: 'checkbox',
		label: 'Show description 3',
		default: true
	}, {
		name: 'showPrice',
		inputType: 'checkbox',
		label: 'Show price',
		default: true
	}, node && node.props.showPrice && {
		name: 'pricePrefix',
		inputType: 'text',
		label: 'Subscription price prefix',
		default: ''
	}, node && node.props.showPrice && {
		name: 'priceSuffix',
		inputType: 'text',
		label: 'Subscription price suffix',
		default: ''
	}, node && node.props.showPrice && {
		name: 'showPriceDifference',
		inputType: 'checkbox',
		label: 'Show both sales price and original price',
		description: 'Shows both sales price and original price for subscriptions and single purchase articles if there is a discount',
		default: false
	}, {
		name: 'buttonText',
		inputType: 'text',
		label: 'Button text',
		default: 'Add to cart'
	}, {
		name: 'disableBuyButton',
		inputType: 'checkbox',
		label: 'Disable buy button after subscription has been added to cart',
		default: false
	}, {
		name: 'advancedHtmlSubscription',
		inputType: 'htmladvanced',
		label: 'Subscription additional content',
		default: ''
	}, node && (node.props.singlePurchase || (!node.props.singlePurchase && node.props.singlePurchaseRelatedArticles)) && {
		name: 'advancedHtmlSinglePurchase',
		inputType: 'htmladvanced',
		label: 'Single purchase additional content',
		default: ''
	}].filter(Boolean)
});
