import articleAddons from './cms-components/customer-unique/article-addons/reducer';
import checkoutIncoming from './cms-components/customer-unique/checkout-incoming-consent/reducer';
import checkoutShipping from './cms-components/customer-unique/checkout-shipping/reducer';
import myPagesOrders from './cms-components/customer-unique/my-pages-orders/reducer';

export default {
	articleAddons,
	checkoutIncoming,
	checkoutShipping,
	myPagesOrders
};
