/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/no-array-push-push */
/* eslint-disable fp/no-mutating-methods */
/* global _paq */
import estoreAnalytics from '@viskan/analytics';
import Timeout from 'await-timeout';
import {generateMediaURL} from '@viskan/streamline-utilities';
import {setGoogleAnalyticsId} from '../site/actions';

const analytics = estoreAnalytics({
	enhanced: true,
	params: {
		id: window._streamline.siteContext.thirdParty.googleTagManagerId,
		gaId: window._streamline.siteContext.thirdParty.googleAnalyticsId,
		useGaUa: window._streamline.siteContext.thirdParty.googleAnalyticsUa,
		useGa4: window._streamline.siteContext.thirdParty.googleAnalytics4
	}
});

const usePiwik = () =>
	Boolean(window._streamline.siteContext.thirdParty.piwikId) &&
	Boolean(window._streamline.siteContext.thirdParty.piwikInstanceAddress) &&
	typeof _paq !== 'undefined' &&
	Boolean(_paq);

const noop = () => {};

const getCampaign = (data, attr1_id) => {
	let ret = '';

	data.forEach(x => {
		if (x.attr1_id === attr1_id) {
			ret = x.camp_name || x.camp_desc;
		}
	});

	return ret;
};

/**
 * Generates a "variant string" containing `color name/size name/attr3`.
 * Will return only one of them if only one of them has an attribute identifier > 0.
 *
 * @param {number} attribute1Id the attribute 1 identifier
 * @param {string} attribute1Code the attribute 1 code
 * @param {number} attribute2Id the attribute 2 identifier
 * @param {string} attribute2Code the attribute 2 code
 * @returns {string} a "variant string" containing `color/size`
 */
const getVariant = sku => {
	const attributes = [];

	if (sku.attribute1 && sku.attribute1.attr1_id && sku.attribute1.attr1_code) {
		attributes.push(sku.attribute1.attr1_code);
	}

	if (sku.attribute2 && sku.attribute2.attr2_id && sku.attribute2.attr2_code) {
		attributes.push(sku.attribute2.attr2_code);
	}

	if (sku.attribute3 && sku.attribute3.attr3_id && sku.attribute3.attr3_code) {
		attributes.push(sku.attribute3.attr3_code);
	}

	return attributes.length > 0 ? attributes.join('/') : sku.attribute1 ? sku.attribute1.attr1_code : '';
};

/**
 * Finds a article category name from article categories and returns it.
 *
 * @param {array} articleCategories the article categories to search in
 * @returns {string} the article category name
 */
const getArticleCategoryName = articleCategories => {
	if (!articleCategories || articleCategories.length === 0) {
		return undefined;
	}

	const defaultCategory = articleCategories.find(category => category.default_flg === true);

	if (defaultCategory && defaultCategory.category) {
		return defaultCategory.category.catname;
	}

	return articleCategories[0] && articleCategories[0].category ? articleCategories[0].category.catname : undefined;
};

const getCategoryTree = articleCategories => {
	if (!articleCategories || articleCategories.length === 0) {
		return false;
	}

	const defaultCategory = articleCategories.find(category => category.default_flg === true);

	if (defaultCategory && defaultCategory.category) {
		const x = defaultCategory.category.link_friendly_name.replace(/\//g, ' ');
		const categoryArray = x.split(' ');
		const reverseCategory = categoryArray.slice().reverse();

		return reverseCategory;
	}

	return false;
};

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
	ecommerce: {
		currencyCode: window._streamline.siteContext.currency_code
	}
});

export const setOrigin = () => window.performance.navigation.type !== 1 && analytics.track({originalLocation: `${window.location.origin}${window.location.pathname}${window.location.search}`});

export const getClientId = async app => window.ga && Promise.race([
	analytics.getClientId(),
	new Timeout().set(2000, 'Timeout while fetching Google analytics client ID')
]).then(id => app.sources.store.dispatch(setGoogleAnalyticsId(id))).catch(error => console.warn(error.message));

export const trackAddToCart = obj => {
	if (analytics.opts.useGaUa) {
		window.dataLayer.push({ecommerce: null});
		analytics.enhanced.addToCart({
			name: obj.body.artname,
			category: getArticleCategoryName(obj.body.articleCategories),
			brand: obj.body.brand && obj.body.brand.name,
			id: obj.body.art_no,
			identifier: `${obj.body.art_no}-${obj.sku.attr1_id}`,
			priceLookupUnitNumber: obj.sku.pluno,
			price: obj.sku.articlePrice.price_sales,
			quantity: obj.qty,
			variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku) : undefined
		});
	}

	if (analytics.opts.useGa4) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'add_to_cart',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				items: [{
					item_name: obj.body.artname,
					item_id: obj.body.art_no,
					identifier: `${obj.body.art_no}-${obj.sku.attr1_id}`,
					price: obj.sku.articlePrice.price_sales,
					pluno: obj.sku.pluno,
					item_brand: obj.body.brand && obj.body.brand.name,
					item_category: getArticleCategoryName(obj.body.articleCategories),
					item_category2: getCategoryTree(obj.body.articleCategories)[1],
					item_category3: getCategoryTree(obj.body.articleCategories)[2],
					item_category4: getCategoryTree(obj.body.articleCategories)[3],
					item_variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku) : undefined,
					quantity: obj.qty,
				}]
			}
		});
	}

	if (usePiwik()) {
		_paq.push([
			'ecommerceAddToCart',
			[{
				sku: obj.sku.sku_id.toString(),
				name: obj.body.artname,
				category: getArticleCategoryName(obj.body.articleCategories),
				price: obj.sku.articlePrice.price_sales,
				quantity: obj.qty,
				brand: obj.body.brand && obj.body.brand.name,
				variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku) : undefined
			}]
		]);
	}
};

export const trackArticle = (obj, index) => {
	if (analytics.opts.useGaUa) {
		window.dataLayer.push({ecommerce: null});
		analytics.enhanced.detail({
			name: obj.body.artname,
			category: getArticleCategoryName(obj.body.articleCategories),
			brand: obj.body.brand && obj.body.brand.name,
			id: obj.body.art_no,
			identifier: `${obj.body.art_no}-${obj.sku.attr1_id}`,
			priceLookupUnitNumber: obj.sku.pluno,
			price: obj.sku.articlePrice.price_sales,
			variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku) : undefined,
			attr1_id: obj.sku.attr1_id,	// CUSTOMER UNIQUE
			attr2_id: obj.sku.attr2_id,	// CUSTOMER UNIQUE
			pluno: obj.sku.pluno, // CUSTOMER UNIQUE
			media: obj.sku.media // CUSTOMER UNIQUE
		});
	}

	if (analytics.opts.useGa4) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'view_item',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				items: [{
					attr1_id: obj.sku.attr1_id,
					attr2_id: obj.sku.attr2_id,
					media: obj.sku.media,
					item_name: obj.body.artname,
					item_id: obj.body.art_no,
					identifier: `${obj.body.art_no}-${obj.sku.attr1_id}`,
					pluno: obj.sku.pluno,
					price: obj.sku.articlePrice.price_sales,
					item_brand: obj.body.brand && obj.body.brand.name,
					item_category: getArticleCategoryName(obj.body.articleCategories),
					item_category2: getCategoryTree(obj.body.articleCategories)[1],
					item_category3: getCategoryTree(obj.body.articleCategories)[2],
					item_category4: getCategoryTree(obj.body.articleCategories)[3],
					item_variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku) : undefined,
					index
				}]
			}
		});
	}

	if (usePiwik()) {
		_paq.push([
			'ecommerceProductDetailView',
			[{
				sku: obj.sku.sku_id.toString(),
				name: obj.body.artname,
				category: getArticleCategoryName(obj.body.articleCategories),
				price: obj.sku.articlePrice.price_sales,
				quantity: 1,
				brand: obj.body.brand && obj.body.brand.name,
				variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku) : undefined
			}]
		]);
	}
};

export const trackImpression = (articles, {from} = {from: 0}) => {
	if (analytics.opts.useGaUa) {
		let index = from;

		window.dataLayer.push({ecommerce: null});
		analytics.enhanced.impression(articles.map(x => {
			const sku = x.articleAttributes[0];

			if (!sku) {
				return;
			}

			index++;

			return {
				name: x.artname,
				brand: x.brand && x.brand.name,
				id: x.art_no,
				identifier: `${x.art_no}-${sku ? sku.attr1_id : -1}`,
				priceLookupUnitNumber: sku && sku.pluno,
				price: sku && sku.articlePrice.price_sales,
				list: window.location.pathname,
				campaign: sku && getCampaign(x.articleCampaigns, sku.attr1_id),
				position: index,
				variant: sku && sku.attribute1 ? getVariant(sku) : undefined
			};
		}).filter(Boolean));
	}

	if (analytics.opts.useGa4) {
		let index = from;

		index++;

		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'view_item_list',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				item_list_name: window.location.pathname,
				items: articles.map((item, i) => ({
					item_id: item.art_no,
					item_name: item.artname,
					index: i,
					item_brand: item.brand && item.brand.name,
					item_list_name: window.location.pathname,
					item_variant: getVariant(item.articleAttributes[0]),
					price: item.articleAttributes[0].articlePrice.price_sales,
					pluno: item.articleAttributes[0].pluno,
					identifier: `${item.art_no}-${item ? item.attr1_id : -1}`
				}))
			}
		});
	}
};

export const trackPageView = url => {
	if (analytics.opts.useGaUa) {
		analytics.enhanced.pageView(url);
	}

	setTimeout(() => {
		// Wait to ensure piwik is loaded and the page title is updated before sending a pageview (same approach as Piwik's automatic pageview tracking)
		if (usePiwik()) {
			_paq.push(['setCustomUrl', url]);
			_paq.push(['trackPageView', document.title]);
		}
	}, 1000);
};

export const trackProductClick = (obj, index) => {
	if (analytics.opts.useGaUa) {
		const sku = obj.articleAttributes[0];

		window.dataLayer.push({ecommerce: null});
		analytics.enhanced.productClick({
			callback: noop,
			name: obj.artname,
			brand: obj.brand && obj.brand.name,
			id: obj.art_no,
			identifier: `${obj.art_no}-${sku ? sku.attr1_id : -1}`,
			priceLookupUnitNumber: sku && sku.pluno,
			price: sku && sku.articlePrice.price_sales,
			variant: sku && sku.attribute1 ? getVariant(sku) : undefined
		}, {
			list: window.location.pathname
		});
	}

	if (analytics.opts.useGa4) {
		const sku = obj.articleAttributes[0];
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'select_item',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				items: [{
					item_name: obj.artname,
					item_id: obj.art_no,
					identifier: `${obj.art_no}-${sku ? sku.attr1_id : -1}`,
					item_brand: obj.brand && obj.brand.name,
					item_variant: sku && sku.attribute1 ? getVariant(sku) : undefined,
					item_list_name: window.location.pathname,
					pluno: sku && sku.pluno,
					price: sku && sku.articlePrice.price_sales,
					index
				}]
			}
		});
	}

	// Skip this event for Piwik
};

export const trackPurchase = receipt => {
	if (analytics.opts.useGaUa) {
		const rows = receipt.rows.map(row => ({
			coupon: row.offerDescription,
			category: row.meta.defaultCategory ? row.meta.defaultCategory : '',
			id: row.meta.articleNumber,
			identifier: `${row.meta.articleNumber}-${row.meta.attribute1Id}`,
			name: row.meta.articleName,
			priceLookupUnitNumber: row.meta.priceLookupUnitNumber,
			price: row.value.total / row.quantity,
			quantity: row.quantity,
			variant: getVariant({
				attribute1: {
					attr1_id: row.meta.attribute1Id,
					attr1_code: row.meta.attribute1Name,
				},
				attribute2: {
					attr2_id: row.meta.attribute2Id,
					attr2_code: row.meta.attribute2Name,
				},
				attribute3: {
					attr3_id: row.meta.attribute3Id,
					attr3_code: row.meta.attribute3Name,
				}
			}),
			url: `${window.location.origin}/${window._streamline.siteContext.locale.toLowerCase()}/${window._streamline.siteContext.articleUrlPrefix}/${row.meta.linkFriendlyName}` // CUSTOMER UNIQUE
		}))
			.concat(receipt.subscriptions.map(subscription => ({
				coupon: subscription.offerDescription,
				id: subscription.subscriptionTypeId,
				identifier: subscription.subscriptionTypeId,
				name: subscription.meta.subscriptionTypeName,
				price: subscription.value.total,
				quantity: subscription.quantity
			})));
		window.dataLayer.push({ecommerce: null});
		analytics.enhanced.purchase(rows, {
			affiliation: window.location.host,
			coupon: receipt.offerCodes.join(', '),
			currencyCode: receipt.currencyCode || window._streamline.siteContext.currency_code, // CUSTOMER UNIQUE
			id: receipt.orderNumber || receipt.identifier,
			revenue: receipt.value.total,
			shipping: receipt.deliveryMethod && receipt.deliveryMethod.methodName,
			email: receipt.customer.email, // CUSTOMER UNIQUE
			revenueExcludingFees: receipt.value.total - receipt.value.shippingFee - receipt.value.paymentFee, // CUSTOMER UNIQUE
			value: receipt.value // CUSTOMER UNIQUE
		});
	}

	if (analytics.opts.useGa4) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'ga4_purchase',
			ecommerce: {
				transaction_id: receipt.orderNumber || receipt.identifier,
				affiliation: window.location.host,
				value: receipt.value.total,
				values: receipt.value,
				tax: receipt.value.total - receipt.value.totalExcludingTax,
				shipping: receipt.value.shippingFee,
				shippingMethod: receipt.deliveryMethod && receipt.deliveryMethod.methodName,
				currency: window._streamline.siteContext.currency_code,
				coupon: receipt.offerCodes.join(', '),
				email: receipt.customer.email,
				items: receipt.rows.map(row => ({
					coupon: row.offerDescription,
					item_name: row.meta.articleName,
					item_id: row.meta.articleNumber,
					identifier: `${row.meta.articleNumber}-${row.meta.attribute1Id}`,
					pluno: row.meta.priceLookupUnitNumber,
					price: row.value.total / row.quantity,
					item_brand: row.meta.brandName,
					item_category: row.meta.defaultCategory ? row.meta.defaultCategory : '',
					item_variant: getVariant({
						attribute1: {
							attr1_id: row.meta.attribute1Id,
							attr1_code: row.meta.attribute1Name,
						},
						attribute2: {
							attr2_id: row.meta.attribute2Id,
							attr2_code: row.meta.attribute2Name,
						},
						attribute3: {
							attr3_id: row.meta.attribute3Id,
							attr3_code: row.meta.attribute3Name,
						}
					}),
					quantity: row.quantity,
					url: `${window.location.origin}/${window._streamline.siteContext.locale.toLowerCase()}/${window._streamline.siteContext.articleUrlPrefix}/${row.meta.linkFriendlyName}` // CUSTOMER UNIQUE
				}))
					.concat(receipt.subscriptions.map(subscription => ({
						item_name: subscription.meta.subscriptionTypeName,
						item_id: subscription.subscriptionTypeId,
						price: subscription.value.total,
						quantity: subscription.quantity
					})))
			}
		});
	}

	if (usePiwik()) {
		_paq.push([
			'ecommerceOrder',
			receipt.rows.map(row => ({
				sku: row.meta.articleNumber,
				name: row.meta.articleName,
				price: row.value.total / row.quantity,
				quantity: row.quantity,
				variant: getVariant({
					attribute1: {
						attr1_id: row.meta.attribute1Id,
						attr1_code: row.meta.attribute1Name,
					},
					attribute2: {
						attr2_id: row.meta.attribute2Id,
						attr2_code: row.meta.attribute2Name,
					},
					attribute3: {
						attr3_id: row.meta.attribute3Id,
						attr3_code: row.meta.attribute3Name,
					}
				})
			}))
				.concat(receipt.subscriptions.map(subscription => ({
					sku: subscription.subscriptionTypeId.toString(),
					name: subscription.meta.subscriptionTypeName,
					price: subscription.value.total,
					quantity: subscription.quantity
				}))),
			{
				orderId: receipt.orderNumber || receipt.identifier,
				grandTotal: receipt.value.total,
				subTotal: receipt.value.totalExcludingTax,
				tax: receipt.value.total - receipt.value.totalExcludingTax,
				shipping: receipt.value.shippingFee,
				discount: receipt.value.discountTotal
			}
		]);
	}
};

export const trackRemoveFromCart = obj => {
	if (analytics.opts.useGaUa) {
		window.dataLayer.push({ecommerce: null});
		analytics.enhanced.removeFromCart({
			name: obj.body.artname,
			id: obj.body.art_no,
			priceLookupUnitNumber: obj.sku.pluno,
			price: obj.sku.articlePrice.price_sales,
			quantity: obj.qty,
			variant: getVariant(obj.body)
		});
	}

	if (analytics.opts.useGa4) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'remove_from_cart',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				items: [{
					item_name: obj.body.artname,
					item_id: obj.body.art_no,
					pluno: obj.sku.pluno,
					price: obj.sku.articlePrice.price_sales,
					item_variant: getVariant(obj.body),
					quantity: obj.qty
				}]
			}
		});
	}

	if (usePiwik()) {
		_paq.push([
			'ecommerceRemoveFromCart',
			[{
				sku: obj.sku.sku_id.toString(),
				name: obj.body.artname,
				category: getArticleCategoryName(obj.body.articleCategories),
				price: obj.sku.articlePrice.price_sales,
				quantity: obj.qty,
				brand: obj.body.brand && obj.body.brand.name,
				variant: getVariant(obj.body)
			}]
		]);
	}
};

export const trackShopcart = (shopcart, site) => {
	if (!shopcart.head.shopcart_id_str) {
		// Don't bother to track an non-existing shopcart
		return;
	}

	if (analytics.opts.useGaUa) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'shopcartChange',
			shopcart: {
				id: shopcart.head.shopcart_id_str,
				locale: site.locale,
				total: shopcart.head.tot_rowsum_to_pay,
				items: shopcart.rows.map(item => ({
					itemId: item.art_no,
					name: item.artname, // CUSTOMER UNIQUE
					priceLookupUnitNumber: item.pluno,
					price: item.price_incvat,
					quantity: item.qty,
					linkFriendlyName: item.link_friendly_name,
					attr1Id: item.attr1_id,
					variant: getVariant(item),
					total: item.rowsum_to_pay, // CUSTOMER UNIQUE
					media: generateMediaURL(item.media_filename, {scale: 'thumbnail'}, window._streamline.siteContext.mediaConfig) // CUSTOMER UNIQUE
				}))
			}
		});
	}

	if (analytics.opts.useGa4) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'view_cart',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				value: shopcart.head.tot_to_pay,
				items: shopcart.rows.map((item, i) => ({
					attr1Id: item.attr1_id,
					item_name: item.artname,
					item_id: item.art_no,
					item_link: item.link_friendly_name,
					media: generateMediaURL(item.media_filename, {scale: 'thumbnail'}, window._streamline.siteContext.mediaConfig),
					pluno: item.pluno,
					price: item.price_incvat,
					row_total: item.rowsum_to_pay,
					item_variant: getVariant(item),
					index: i,
					quantity: item.qty
				}))
			}
		});
	}
};

export const trackCheckout = obj => {
	if (analytics.opts.useGaUa) {
		const arr = [];

		if (obj.rows && obj.rows.length !== 0) {
			obj.rows.forEach(x => {
				window.dataLayer.push({ecommerce: null});
				arr.push({
					coupon: obj.head.camp_code || '',
					id: x.art_no,
					identifier: `${x.art_no}-${x.attr1_id}`,
					name: x.artname,
					priceLookupUnitNumber: x.pluno,
					price: x.price_incvat,
					variant: getVariant(x),
					quantity: x.qty
				});
			});
		}

		analytics.enhanced.checkout(arr, {
			callback: noop
		});
	}

	if (analytics.opts.useGa4) {
		window.dataLayer.push({ecommerce: null});
		window.dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				currency: window._streamline.siteContext.currency_code,
				items: obj.rows.map((row, i) => ({
					item_name: row.artname,
					item_id: row.art_no,
					identifier: `${row.art_no}-${row.attr1_id}`,
					price: row.price_incvat,
					pluno: row.pluno,
					item_variant: getVariant(row),
					index: i,
					quantity: row.qty
				}))
			}
		});
	}

	if (usePiwik()) {
		const totalNumberOfItems = obj.rows.reduce((accumulator, row) => {
			return accumulator + row.qty;
		}, 0);

		_paq.push([
			'trackEvent',
			'Ecommerce',
			'Begin Checkout',
			`Initializing checkout with ${totalNumberOfItems} item/s`,
			obj.head.tot_to_pay
		]);
	}
};

// CUSTOMER UNIQUE
export const trackShopcartOnDemand = shopcart => {
	if (!shopcart.head) {
		return;
	}

	const productNumbers = [];

	if (shopcart.rows && shopcart.rows.length !== 0) {
		shopcart.rows.forEach(x => {
			productNumbers.push(x.pluno);
		});
	}

	const obj = {
		total: productNumbers.length > 0 ? shopcart.head.tot_to_pay : 0,
		productNumbers: productNumbers.toString(),
		shopcartIdStr: shopcart.head.shopcart_id_str,
		shopcartRows: shopcart.rows
	};

	if (window.ADDWISH_PARTNER_NS) {
		analytics.enhanced.checkout(obj, {
			callback: noop
		});
	}
};

// CUSTOMER UNIQUE
export const trackImBoxInsertCard = (customer, site, myPagesOrders) => {
	if (customer && customer.logged_in && myPagesOrders.orders) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'myPages',
			customer: {
				id: customer.dynamic.customerNumber,
				name: {
					firstName: customer.dynamic.firstName,
					lastName: customer.dynamic.lastName
				},
				address: {
					address: customer.dynamic.invoiceAddress.addressLine1,
					zipcode: customer.dynamic.invoiceAddress.zipcode,
					city: customer.dynamic.invoiceAddress.city,
					countrycode: customer.dynamic.invoiceAddress.countryCode
				},
				email: customer.dynamic.emailAddress,
				phone: customer.dynamic.cellphone,
				bonusBalance: customer.dynamic.providerData.lindbakMemberBalance.bonusBalance,
				orderDetails: myPagesOrders.orders.map(order => ({
					orderDate: order.orderDate,
					ordernr: order.orderNumber
				}))
			}
		});
	}
};

export default async app => {
	analytics.start().then(() => getClientId(app));

	app.set('trackAddToCart', trackAddToCart);
	app.set('trackPageView', trackPageView);
	app.set('trackProductClick', trackProductClick);
	app.set('trackPurchase', trackPurchase);
	app.set('trackRemoveFromCart', trackRemoveFromCart);
	app.set('trackCheckout', trackCheckout);
	app.set('trackShopcart', trackShopcart);
	app.set('trackImBoxInsertCard', trackImBoxInsertCard);
};
