export default {
	type: 'SHOW_MORE',
	name: 'Show more',
	allowedSections: ['header', 'main', 'footer'],
	disallowedChildren: [],
	category: 'Layout',
	icon: 'fad fa-chevron-down',
	styleClasses: {
		Wrapper: 'Preset-ShowMore-Wrapper',
		Closed: 'Preset-ShowMore-Closed',
		Open: 'Preset-ShowMore-Open',
		Icon_Wrapper: 'Preset-ShowMore-IconWrapper',
		Icon: 'Preset-ShowMore-Icon'
	},
	props: [{
		name: 'heightClosed',
		inputType: 'text',
		label: 'Maximum height when closed',
		default: '200px'
	}, {
		name: 'iconClosed',
		inputType: 'icon',
		label: 'Choose icon when closed',
		default: '{"prefix":"fal","x":{"name":"chevron-down"}}'
	}, {
		name: 'iconOpen',
		inputType: 'icon',
		label: 'Choose icon when opened',
		default: '{"prefix":"fal","x":{"name":"chevron-up"}}'
	}, {
		name: 'iconSize',
		inputType: 'text',
		label: 'Set size on icon',
		default: '1em'
	}, {
		name: 'iconColor',
		inputType: 'color',
		label: 'Set color on icon',
		default: '#000'
	}]
};
