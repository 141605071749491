export default node => ({
	type: 'ARTICLE_FIELD',
	name: 'Field',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		default: 'artname',
		values: [{
			label: 'Article name',
			value: 'artname'
		}, {
			label: 'Article number',
			value: 'art_no'
		}, {
			label: 'Short description',
			value: 'art_desc'
		}, {
			label: 'Long description',
			value: 'art_desc2'
		}, {
			label: 'Color number',
			value: 'attr1_no'
		}, {
			label: 'EAN-13',
			value: 'ean13'
		}, {
			label: 'Pluno',
			value: 'pluno'
		}, {
			label: 'Brand Name',
			value: 'brand.name'
		}, {
			label: 'Brand Image',
			value: 'brand.mediaFileName'
		}, {
			label: 'Brand Linkfriendlyname',
			value: 'brand.linkFriendlyName'
		}, {
			label: 'Property Key Name',
			value: 'propertykey_name'
		}, {
			label: 'Property Key Description',
			value: 'propertykey_description'
		}, {
			label: 'Property Key Value',
			value: 'propertykey_value'
		}, {
			label: 'Property Key Value Description',
			value: 'propertykey_value_description'
		}, {
			label: 'Height',
			value: 'height'
		}, {
			label: 'Length',
			value: 'length'
		}, {
			label: 'Weight',
			value: 'weight'
		}, {
			label: 'Width',
			value: 'width'
		}, {
			label: 'Volume',
			value: 'volume'
		}]
	}, node && node.props.field === 'brand.mediaFileName' && {
		name: 'mediaWrapWithLink',
		inputType: 'checkbox',
		label: 'Media should be link to brand',
		default: false
	}, {
		name: 'showValueFromSku',
		inputType: 'checkbox',
		label: 'Show value from sku instead of article',
		default: false
	}, node && node.props.field.startsWith('propertykey') && {
		name: 'showAllValues',
		inputType: 'checkbox',
		label: 'Show all values from property',
		default: ''
	}, node && (node.props.field === 'propertykey_value' || node.props.field === 'propertykey_description') && {
		name: 'propertyKey',
		inputType: 'text',
		label: 'Internal property key name to show value from',
		default: ''
	}, {
		name: 'elementType',
		inputType: 'select',
		label: 'Select element type',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Paragraph',
			value: 'p'
		}, {
			label: 'Span',
			value: 'span'
		}, {
			label: 'H1',
			value: 'h1'
		}, {
			label: 'H2',
			value: 'h2'
		}, {
			label: 'H3',
			value: 'h3'
		}, {
			label: 'H4',
			value: 'h4'
		}, {
			label: 'H5',
			value: 'h5'
		}, {
			label: 'H6',
			value: 'h6'
		}, node && node.props.field === 'propertykey_value_description' && {
			label: 'A',
			value: 'a'
		}, node && ['propertykey_value_description', 'propertykey_value'].includes(node.props.field) && {
			label: 'Image',
			value: 'img'
		}].filter(Boolean)
	}, node && node.props.elementType === 'img' && {
		name: 'scale',
		inputType: 'select',
		label: 'Image scale',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}].filter(Boolean)
	}, node && node.props.elementType === 'img' && {
		name: 'wrapImgWithLink',
		inputType: 'checkbox',
		label: 'Make image clickable',
		default: false
	}, node && node.props.wrapImgWithLink && {
		name: 'clickableImgScale',
		inputType: 'select',
		label: 'Image scale for clickable image',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}].filter(Boolean)
	}, {
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before',
		default: ''
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after',
		default: ''
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
