export default node => `
	<Tabs>
		<Tab heading="General">
			<Grid>
				<Cell size="full">
					<Field name="label"/>
				</Cell>
				<Cell size="full">
					<Field name="interactionType"/>
				</Cell>
				<Cell size="full" hidden="${node && node.props.interactionType === 'select'}">
					<Field name="inputStepValue"/>
				</Cell>
				<Cell size="full" hidden="${node && (node.props.interactionType === 'select')}">
					<Field name="useDefaultValue"/>
				</Cell>
				<Cell size="full" hidden="${node && (node.props.interactionType === 'input')}">
					<Field name="selectDefaultValue"/>
				</Cell>
				<Cell size="full" hidden="${node && (node.props.interactionType === 'select' || !node.props.useDefaultValue)}">
					<Field name="inputDefaultValue"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Select list Values" hidden="${node && node.props.interactionType === 'input'}">
			<Grid>
			<Cell size="full">
					<Field name="days0"/>
				</Cell>
				<Cell size="full">
					<Field name="days14"/>
				</Cell>
				<Cell size="full">
					<Field name="days30"/>
				</Cell>
				<Cell size="full">
					<Field name="days42"/>
				</Cell>
				<Cell size="full">
					<Field name="days61"/>
				</Cell>
				<Cell size="full">
					<Field name="days70"/>
				</Cell>
				<Cell size="full">
					<Field name="days91"/>
				</Cell>
				<Cell size="full">
					<Field name="days122"/>
				</Cell>
				<Cell size="full">
					<Field name="days152"/>
				</Cell>
				<Cell size="full">
					<Field name="days183"/>
				</Cell>
				<Cell size="full">
					<Field name="days213"/>
				</Cell>
				<Cell size="full">
					<Field name="days244"/>
				</Cell>
				<Cell size="full">
					<Field name="days274"/>
				</Cell>
				<Cell size="full">
					<Field name="days304"/>
				</Cell>
				<Cell size="full">
					<Field name="days335"/>
				</Cell>
				<Cell size="full">
					<Field name="days365"/>
				</Cell>
			</Grid>
		</Tab>
	</Tabs>
`;
