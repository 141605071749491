const removeMetaTags = tagSelector => {
	const tags = document.head.querySelectorAll(tagSelector);
	tags.forEach(tag => {
		tag.remove();
	});
};

export const setCanonical = () => {
	const canonical = document.querySelector('link[rel="canonical"]');
	const element = document.querySelector('meta[property="og:url"]');

	if (canonical) {
		canonical.setAttribute('href', window.location.href);
	}

	if (element) {
		element.setAttribute('content', window.location.href.replace(window.location.search, ''));
	}
};

export const setDescription = (string = '') => {
	const element = document.querySelector('meta[name="description"]');
	const ogDescElement = document.querySelector('meta[property="og:description"]');

	if (element) {
		element.setAttribute('content', string);
	}

	if (ogDescElement) {
		ogDescElement.setAttribute('content', string);
	}
};

export const setContentImages = content => {
	removeMetaTags('[property="og:image"]');

	if (content.length === 0) {
		return;
	}

	const regex = /"(?:path|imgSrc)":"([^"]+)"/g;
	const images = content.match(regex);

	if (images) {
		images.forEach(image => {
			const img = JSON.parse(`{${image}}`);
			const mediaUrl = img.path ? `${window._streamline.siteContext.mediaConfig.mediaServiceBaseURL}/original/${img.path}` : `${window._streamline.siteContext.mediaConfig.mediaServiceBaseURL}/original/${img.imgSrc}`;
			const element = document.createElement('meta');
			element.setAttribute('property', 'og:image');
			element.setAttribute('content', mediaUrl);
			document.querySelectorAll('head')[0].append(element);
		});
	}
};

export const setProductImages = images => {
	removeMetaTags('[property="og:image"]');

	if (images.length === 0) {
		return;
	}

	images.forEach(image => {
		const mediaUrl = `${window._streamline.siteContext.mediaConfig.mediaServiceBaseURL}/original/${image.media.filename}`;
		const element = document.createElement('meta');
		element.setAttribute('property', 'og:image');
		element.setAttribute('content', mediaUrl);
		document.querySelectorAll('head')[0].append(element);
	});
};

export const setTitle = (str, addDash = true, addCompanyName = true, props) => {
	const element = document.querySelector('meta[property="og:title"]');

	if (!str) {
		return;
	}

	if (element && !addDash && addCompanyName) {
		element.setAttribute('content', `${str} ${window._streamline.siteContext.companyName}`);
		document.title = `${str} ${window._streamline.siteContext.companyName}`;

		return;
	}

	if (element && (!addCompanyName || !window._streamline.siteContext.companyName)) {
		const lastIndex = str.includes('|') ? str.lastIndexOf('|') : str.lastIndexOf('-');
		const lastToken = str.includes('|') ? ' |' : ' -';

		if (lastIndex !== -1 && props.router.query.page) {
			const firstPart = str.substring(0, lastIndex);
			const secondPart = str.substring(lastIndex + 1);
			const translation = props.getTranslation('articles.page_prefix');

			const resultString = firstPart + '- ' + translation.toLowerCase() + ' ' + props.router.query.page + lastToken + secondPart;

			element.setAttribute('content', resultString);
			document.title = resultString;

			return;
		}

		if (lastIndex === -1 && props.router.query.page) {
			const translation = props.getTranslation('articles.page_prefix');
			const newStrWithPage = str + ' - ' + translation.toLowerCase() + ' ' + props.router.query.page;

			element.setAttribute('content', newStrWithPage);
			document.title = newStrWithPage;

			return;
		}

		element.setAttribute('content', str);
		document.title = str;

		return;
	}

	if (element) {
		element.setAttribute('content', `${str} - ${window._streamline.siteContext.companyName}`);
	}
	document.title = `${str} - ${window._streamline.siteContext.companyName}`;
};

export const setType = ({pageType}) => {
	const element = document.querySelector('meta[property="og:type"]');

	if (pageType) {
		element.setAttribute('content', pageType);
		return;
	}

	element.setAttribute('content', 'website');
};
