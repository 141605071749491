import {deepEqual} from 'fast-equals';
import Slide from '@viskan/deku-slide';
import {getIcon} from '../cms-components/utils/actions';
import {Image} from '~/media';

const shouldUpdate = ({props}, nextProps) => !deepEqual(props.colorListOptions, nextProps.colorListOptions) || !deepEqual(props.styleClasses, nextProps.styleClasses) || props.breakpoint !== nextProps.breakpoint;

const onChangeSelect = props => e => props.handleSetListColor(parseInt(e.target.value, 10));

const getSlidesPerViewForBreakpoint = (breakpoint, colorListOptions) => {
	if (breakpoint === 'sm' || breakpoint === 'xsm') {
		return parseInt(colorListOptions.colorListSlidesPerViewMobile, 10) || 3;
	}
	if (breakpoint === 'md') {
		return parseInt(colorListOptions.colorListSlidesPerViewTablet, 10) || 3;
	}

	return parseInt(colorListOptions.colorListSlidesPerView, 10) || 3;
};

const handleSliderChange = (props, attr1_id, currentActiveIndex, id) => () => {
	props.handleSetListColor(attr1_id);
	const ColorListEl = document.querySelector(`.ColorList-${id}`);

	if (ColorListEl) {
		Array.from(ColorListEl.querySelectorAll('.ColorList-item')).map((item, index) => {
			if (index === currentActiveIndex) {
				item.classList.add('ColorList-item--active');
			} else {
				item.classList.remove('ColorList-item--active');
			}

			return item;
		});
	}
};

const checkIfColorIsOutOfStock = (article, props) => {
	const colorIsOutOfStock = props.article.articleAttributes.filter(sku => sku.attr1_id === article.attr1_id).every(sku => !sku.inStock);
	return colorIsOutOfStock;
};

const renderColorList = (props, id) => {
	const skusNonDuplicateAttr1Ids = Array.from(new Set(props.article.articleAttributes
		.map(sku => sku.attr1_id)))
		.map(id => props.article.articleAttributes.find(sku => props.colorListOptions.colorListOnlyShowInStock ? sku.attr1_id === id && sku.inStock && sku.attr1_id !== 0 : sku.attr1_id === id && sku.attr1_id !== 0))
		.filter(Boolean);
	const colorListChangeColorOnHover = props.colorListOptions.colorListChangeColorOnHover;
	const displayedAttr1Ids = skusNonDuplicateAttr1Ids.map(sku => sku.attr1_id);
	const nonDuplicateAttr1Ids1Medias = Array.from(new Set(props.article.articleAttributeMedias.map(sku => sku.attr1_id)))
		.map(id => props.article.articleAttributeMedias.find(sku => sku.attr1_id === id))
		.filter(sku => displayedAttr1Ids.includes(sku.attr1_id));

	if (displayedAttr1Ids.length <= 1) {
		return <noscript/>;
	}

	if (
		((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && props.colorListOptions.colorListDisplayTypeMobile === 'select') ||
		(props.breakpoint === 'md' && props.colorListOptions.colorListDisplayTypeTablet === 'select') ||
		((props.breakpoint === 'lg' || props.breakpoint === 'xlg') && props.colorListOptions.colorListDisplayType === 'select')
	) {
		const placeholder = props.colorListOptions.colorListSelectPlaceholder;

		return (
			<select name='colorListSelect' class={['ColorList-select', props.styleClasses.ColorListSelect]} data-style-attr='ColorListSelect' onChange={onChangeSelect(props)}>
				{placeholder && placeholder.length > 0 && <option disabled selected>{placeholder}</option>}
				{skusNonDuplicateAttr1Ids.map(article => {
					return (
						<option key={article.attribute1.attr1_code} value={article.attribute1.attr1_id} selected={(!placeholder || placeholder.length === 0) && article.attr1_id === props.article.attr1_id}>{article.attribute1.attr1_code}</option>
					);
				})}
			</select>
		);
	}

	let items;
	const settings = {
		arrows: displayedAttr1Ids.length > (getSlidesPerViewForBreakpoint(props.breakpoint, props.colorListOptions)),
		iconNext: getIcon({icon: props.colorListOptions.colorListSliderNextIcon, iconColor: props.colorListOptions.colorListSliderNextIconColor, iconSize: props.colorListOptions.colorListSliderNextIconSize}),
		iconPrev: getIcon({icon: props.colorListOptions.colorListSliderPrevIcon, iconColor: props.colorListOptions.colorListSliderPrevIconColor, iconSize: props.colorListOptions.colorListSliderPrevIconSize}),
		duration: parseInt(props.colorListOptions.colorListDuration, 10) || 3000,
		options: {
			slidesPerView: getSlidesPerViewForBreakpoint(props.breakpoint, props.colorListOptions),
			slidesPerColumn: parseInt(props.colorListOptions.colorListSlidesPerColumn, 10) || 1,
			spaceBetween: parseInt(props.colorListOptions.colorListSpaceBetween, 10) || 0,
			centeredSlides: Boolean(props.colorListOptions.colorListCenterSlides)
		},
		effect: 'slide',
		pagination: false,
		speed: parseInt(props.colorListOptions.colorListSliderSpeed, 10) || 500,
		timing: 'ease'
	};

	if (
		((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && props.colorListOptions.colorListDisplayTypeMobile === 'fixedNumberOfColors') ||
		(props.breakpoint === 'md' && props.colorListOptions.colorListDisplayTypeTablet === 'fixedNumberOfColors') ||
		((props.breakpoint === 'lg' || props.breakpoint === 'xlg') && props.colorListOptions.colorListDisplayType === 'fixedNumberOfColors')
	) {
		const numberOfColorsToDisplay = ((props.breakpoint === 'xsm' || props.breakpoint === 'sm') ? props.colorListOptions.colorListNumberOfColorsMobile : props.breakpoint === 'md' ? props.colorListOptions.colorListNumberOfColorsTablet : props.colorListOptions.colorListNumberOfColorsDesktop);

		items = skusNonDuplicateAttr1Ids.slice(0, numberOfColorsToDisplay).map((article, index) => {
			const colorIsOutOfStock = checkIfColorIsOutOfStock(article, props);
			return (
				<div key={`${article.art_id}-${article.attr1_id}-${article.attr2_id}`} class={['ColorList-item', colorIsOutOfStock && 'ColorList-item--outOfStock', props.styleClasses.ColorListItem]} onMouseOver={colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} onClick={!colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} data-style-attr='ColorListItem'>
					<div class={['ColorList-item-color-code', props.styleClasses['ColorListItem Rgb Code']]} data-style-attr='ColorListItem Rgb Code' style={`height:100%;background:#${article.attribute1.rgb_code || '000'}`}/>
				</div>
			);
		});

		const numberOfAdditionalColors = skusNonDuplicateAttr1Ids.length > numberOfColorsToDisplay ? skusNonDuplicateAttr1Ids.length - numberOfColorsToDisplay : 0;

		if (numberOfAdditionalColors > 0) {
			items.push(<div key={numberOfColorsToDisplay} class={['Colorlist-additionalColors', props.styleClasses['ColorList Additional Colors']]} data-style-attr='ColorList Additional Colors'>+{numberOfAdditionalColors}</div>);
		}

		return items;
	}

	if (
		((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && (props.colorListOptions.colorListDisplayTypeMobile === 'imagesSlider' || props.colorListOptions.colorListDisplayTypeMobile.length === 0)) ||
		(props.breakpoint === 'md' && (props.colorListOptions.colorListDisplayTypeTablet === 'imagesSlider' || props.colorListOptions.colorListDisplayTypeTablet.length === 0)) ||
		((props.breakpoint === 'lg' || props.breakpoint === 'xlg') && (props.colorListOptions.colorListDisplayType === 'imagesSlider' || props.colorListOptions.colorListDisplayType.length === 0))
	) {
		items = nonDuplicateAttr1Ids1Medias.map((article, index) => {
			const colorIsOutOfStock = checkIfColorIsOutOfStock(article, props);
			return (
				<div key={`${article.art_id}-${article.attr1_id}-${article.attr2_id}`} class={['ColorList-item', colorIsOutOfStock && 'ColorList-item--outOfStock', props.styleClasses.ColorListItem]} onMouseOver={colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} onClick={!colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} data-style-attr='ColorListItem'>
					<Image source={props.sanitizeMediaFilename(article.media.filename)} scale='list'/>
				</div>
			);
		});
	}

	if (
		((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && props.colorListOptions.colorListDisplayTypeMobile === 'colorSlider') ||
		(props.breakpoint === 'md' && props.colorListOptions.colorListDisplayTypeTablet === 'colorSlider') ||
		((props.breakpoint === 'lg' || props.breakpoint === 'xlg') && props.colorListOptions.colorListDisplayType === 'colorSlider')
	) {
		items = skusNonDuplicateAttr1Ids.map((article, index) => {
			const colorIsOutOfStock = checkIfColorIsOutOfStock(article, props);
			return (
				<div key={`${article.art_id}-${article.attr1_id}-${article.attr2_id}`} class={['ColorList-item', colorIsOutOfStock && 'ColorList-item--outOfStock', props.styleClasses.ColorListItem]} onMouseOver={colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} onClick={!colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} data-style-attr='ColorListItem'>
					<div style={`height:100%;background:#${article.attribute1.rgb_code || '000'}`}/>
				</div>
			);
		});
	}

	if (
		((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && (props.colorListOptions.colorListDisplayTypeMobile === 'imagesSlider' || props.colorListOptions.colorListDisplayTypeMobile.length === 0 || props.colorListOptions.colorListDisplayTypeMobile === 'colorSlider')) ||
		(props.breakpoint === 'md' && (props.colorListOptions.colorListDisplayTypeTablet === 'imagesSlider' || props.colorListOptions.colorListDisplayTypeTablet.length === 0 || props.colorListOptions.colorListDisplayTypeTablet === 'colorSlider')) ||
		((props.breakpoint === 'lg' || props.breakpoint === 'xlg') && (props.colorListOptions.colorListDisplayType === 'imagesSlider' || props.colorListOptions.colorListDisplayType.length === 0 || props.colorListOptions.colorListDisplayType === 'colorSlider'))
	) {
		return <Slide {...settings} styleClasses={props.styleClasses}>{items}</Slide>;
	}

	if (
		((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && (props.colorListOptions.colorListDisplayTypeMobile === 'imageAndColorSlider' || props.colorListOptions.colorListDisplayTypeMobile.length === 0)) ||
		(props.breakpoint === 'md' && (props.colorListOptions.colorListDisplayTypeTablet === 'imageAndColorSlider' || props.colorListOptions.colorListDisplayTypeTablet.length === 0)) ||
		((props.breakpoint === 'lg' || props.breakpoint === 'xlg') && (props.colorListOptions.colorListDisplayType === 'imageAndColorSlider' || props.colorListOptions.colorListDisplayType.length === 0))
	) {
		const imageArray = nonDuplicateAttr1Ids1Medias.map((article, index) => {
			const colorIsOutOfStock = checkIfColorIsOutOfStock(article, props);
			return (
				<div key={`${article.art_id}-${article.attr1_id}-${article.attr2_id}`} class={['ColorList-item', colorIsOutOfStock && 'ColorList-item--outOfStock', props.styleClasses.ColorListItem]} onMouseOver={colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} onClick={!colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} data-style-attr='ColorListItem'>
					<Image source={props.sanitizeMediaFilename(article.media.filename)} scale='list'/>
				</div>
			);
		});

		const colorListArray = skusNonDuplicateAttr1Ids.map((article, index) => {
			const colorIsOutOfStock = checkIfColorIsOutOfStock(article, props);
			return (
				<div key={`${article.art_id}-${article.attr1_id}-${article.attr2_id}`} class={['ColorList-item', colorIsOutOfStock && 'ColorList-item--outOfStock', props.styleClasses.ColorListItem]} onMouseOver={colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} onClick={!colorListChangeColorOnHover && handleSliderChange(props, article.attr1_id, index, id)} data-style-attr='ColorListItem'>
					<div style={`height:100%;background:#${article.attribute1.rgb_code || '000'}`}/>
				</div>
			);
		});

		return (
			<div class={['ImageAndColorSlider', props.styleClasses.ColorList_Image_And_Color_Slider]} data-style-attr='ColorList_Image_And_Color_Slider'>
				<div class={['ImageArray', props.styleClasses.ColorList_Image_Slider]} data-style-attr='ColorList_Image_Slider'>
					<Slide {...settings} styleClasses={props.styleClasses}>{imageArray}</Slide>
				</div>
				<div class={['ColorListArray', props.styleClasses.ColorList_Color_Slider]} data-style-attr='ColorList_Color_Slider'>
					<Slide {...settings} styleClasses={props.styleClasses}>{colorListArray}</Slide>
				</div>
			</div>
		);
	}

	return <noscript/>;
};

const render = ({id, props}) => {
	return (
		<div class={['ColorList', `ColorList-${id}`, props.styleClasses.ColorList]} data-style-attr='ColorList'>
			{renderColorList(props, id)}
		</div>
	);
};

export default {render, shouldUpdate};
