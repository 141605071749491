import bus from '@viskan/bus';
import concatObject from 'concat-object';
import {createAction} from 'redux-actions';
import {openPopup} from '../actions';
import {SHOPCART_ADD, SHOPCART_EDIT, SHOPCART_FETCH, SHOPCART_REMOVE, SHOPCART_UPDATE, SHOPCART_SET, SHOPCART_SET_TEMP_QTY, SHOPCART_STORE_KLARNA_SHIPPING_DATA} from '../action-types';
import {trackAddToCart, trackRemoveFromCart} from '../analytics';

const getErrorMessage = error => {
	const errorCode = error && error.response && error.response.body && error.response.body.errors[0] && error.response.body.errors[0].errorCode
		? error.response.body.errors[0].errorCode
		: (
			error && error.message
				? error.message
				: ''
		);

	switch (errorCode) {
		case 'shopcart.add.variant_not_in_stock': {
			return 'shopcart.error.not_in_stock';
		}
		case 'shopcart.add-voucher.voucher-already-added': {
			return 'shopcart.error.voucher_already_added';
		}
		case 'shopcart.carriage.no_way_of_delivery_found': {
			return 'shopcart.error.no_way_of_delivery_found';
		}
		case 'shopcart.carriage.no_country_found': {
			return 'shopcart.error.no_country_found';
		}
		default: {
			return 'shopcart.error.default';
		}
	}
};

const addToCart = (data = {}, getTranslation) => v12.shopcart.add(data)
	.then(data => {
		bus.emit('shopcart:update', data);
		bus.emit('loading:close');

		return {
			data
		};
	})
	.catch(error => {
		const message = getTranslation ? getTranslation(getErrorMessage(error)) : 'Something went wrong, please try again or contact customer service';
		openPopup(message, {class: 'AddToCartPopup'});
		bus.emit('loading:close');
		throw error;
	});

export const addItem = createAction(SHOPCART_ADD, (data, sku, addons = [], qty = 1, interval, getTranslation) => {
	const {art_id} = data;
	const {attr1_id, attr2_id, attr3_id} = sku;

	bus.emit('loading:open');

	const addOnRows = addons.length > 0 ? addons.map(addon => {
		return {art_id: addon.art_id, attr1_id: addon.attr1_id, attr2_id: addon.attr2_id, attr3_id: addon.attr3_id, sort: addon.sort, personalization_text: addon.personalization_text, qty: 1};
	}).sort((a, b) => a.sort - b.sort) : [];

	const body = {
		art_id,
		attr1_id: attr1_id || 0,
		attr2_id: attr2_id || 0,
		attr3_id: attr3_id || 0,
		personalization_text: '',
		qty,
		addOnRows: JSON.stringify(addOnRows)
	};

	if (interval) {
		body.subscriptionSettings = JSON.stringify({interval});
	}

	trackAddToCart({
		body: data,
		qty: body.qty,
		sku
	});

	return addToCart(body, getTranslation);
}, data => ({data}));

export const addSubscription = createAction(SHOPCART_ADD, (subscription, getTranslation) => {
	bus.emit('loading:open');

	trackAddToCart({
		body: {
			art_no: subscription.prentype_id,
			artname: subscription.prentype_desc
		},
		qty: 1,
		sku: {
			articlePrice: {
				price_sales: subscription.prognoses[0].price
			},
			sku_id: subscription.prentype_id
		}
	});

	return addToCart({prentype_id: subscription.prentype_id}, getTranslation);
}, subscription => ({subscription}));

export const addAddons = createAction(SHOPCART_ADD, (addons = [], subscription = {}, getTranslation) => {
	bus.emit('loading:open');

	if (Object.keys(addons).length === 0) {
		return addToCart({prentype_id: subscription.prentype_id}, getTranslation);
	}

	return addToCart(concatObject({prentype_id: subscription.prentype_id}, ...addons), getTranslation);
}, subscription => ({subscription}));

export const edit = createAction(SHOPCART_EDIT, (id, qty, dontOpen, getTranslation) => {
	bus.emit('loading:open');

	return v12.shopcart.edit({
		qty,
		row_id: id
	})
		.then(data => {
			bus.emit('shopcart:update', data, dontOpen);
			bus.emit('loading:close');

			return {
				data,
				dontOpen
			};
		})
		.catch(error => {
			const message = getTranslation ? getTranslation(getErrorMessage(error)) : 'Something went wrong, please try again or contact customer service';
			openPopup(message, {class: 'EditCartPopup'});
			bus.emit('loading:close');
			throw error;
		});
});

export const fetch = createAction(SHOPCART_FETCH, dontOpen => v12.shopcart.get().then(data => {
	bus.emit('shopcart:update', data, dontOpen);

	return {
		data,
		dontOpen
	};
}).catch(error => {
	openPopup(error.message, {class: 'FetchCartPopup'});
	throw error;
}));

export const remove = createAction(SHOPCART_REMOVE, () => v12.util.http.get('/api/shopcart/clear', {json: false}));

export const removeItem = createAction(SHOPCART_REMOVE, (data, qty, dontOpen, getTranslation) => {
	const {art_no, artname, attr1_id, attr1_code, attr2_id, attr2_code, attr3_id, attr3_code, price_incvat, price_exvat, row_id} = data;
	const sku_id = data.prentype_id ? data.prentype_id : data.sku_id;

	bus.emit('loading:open');

	trackRemoveFromCart({
		sku: {articlePrice: {price_sales: price_incvat, price_sales_exvat: price_exvat}, sku_id},
		body: {
			artname,
			art_no,
			attr1_id,
			attr1_code,
			attr2_id,
			attr2_code,
			attr3_id,
			attr3_code
		},
		qty
	});

	/* When removing an addonrow, parameter `addon_row_id` must also be provided.
	*  So having this row structure:
	*  "rows": [
	*    {
	*      "row_id": 1,
	*      ......
	*      "addons": [
	*        {
	*          "row_id": 1,
	*          ....
	*        },
	*        {
	*          "row_id": 2,
	*          ......
	*        }
	*      ]
	*    }
	*  ]
	*
	*  ... and removal of the second addon is wanted then
	*  parameters should be:
	*    row_id: 1,
	*    addon_row_id: 2
	*/
	return v12.shopcart.remove({row_id})
		.then(data => {
			bus.emit('shopcart:update', data, dontOpen);
			bus.emit('loading:close');

			return {
				data,
				dontOpen
			};
		})
		.catch(error => {
			const message = getTranslation ? getTranslation(getErrorMessage(error)) : 'Something went wrong, please try again or contact customer service';
			openPopup(message, {class: 'RemoveFromCartPopup'});
			v12.util.error(error);
			bus.emit('loading:close');
			throw error;
		});
});

export const set = createAction(SHOPCART_SET, (data, dontOpen) => {
	bus.emit('shopcart:update', data, dontOpen);

	return {
		data,
		dontOpen
	};
});

export const update = createAction(SHOPCART_UPDATE, (data, dontOpen) => v12.shopcart.update(data)
	.then(() => fetch(dontOpen))
	.then(({payload}) => payload)
);

export const setTempQty = createAction(SHOPCART_SET_TEMP_QTY, (id, qty) => {
	return {qty, row_id: id};
});

export const storeKlarnaShippingData = createAction(SHOPCART_STORE_KLARNA_SHIPPING_DATA);
