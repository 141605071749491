import editorLayout from './editor-layout';
import {conditionallyAddConfig} from '~/utils';

const conditionallyAddToggleIcons = (node, key, conditionStr, configArray) => node && node.props.showToggler && node.props[key] === conditionStr ? configArray : [];

const activeIconConfig = [{
	name: 'iconActive',
	inputType: 'icon',
	label: 'Choose icon when addon is active',
	default: false
}, {
	name: 'iconActiveSize',
	inputType: 'text',
	label: 'Choose size on active icon',
	default: '2em'
}, {
	name: 'iconActiveColor',
	inputType: 'color',
	label: 'Choose color on active icon',
	default: '#000'
}, {
	name: 'iconInactive',
	inputType: 'icon',
	label: 'Choose icon when addon is inactive',
	default: false
}, {
	name: 'iconInactiveSize',
	inputType: 'text',
	label: 'Choose size on inactive icon',
	default: '2em'
}, {
	name: 'iconInactiveColor',
	inputType: 'color',
	label: 'Choose color on inactive icon',
	default: '#000'
}];

const activeImageConfig = [{
	name: 'imageActive',
	inputType: 'media-v2',
	label: 'Choose image when addon is active',
	default: ''
}, {
	name: 'imageInactive',
	inputType: 'media-v2',
	label: 'Choose image when addon is inactive',
	default: ''
}];

const selectIconConfig = [{
	name: 'iconSelectOpen',
	inputType: 'icon',
	label: 'Choose icon when select list is open',
	default: false
}, {
	name: 'iconSelectOpenSize',
	inputType: 'text',
	label: 'Choose size on open icon',
	default: '2em'
}, {
	name: 'iconSelectOpenColor',
	inputType: 'color',
	label: 'Choose color on open icon',
	default: '#000'
}, {
	name: 'iconSelectClosed',
	inputType: 'icon',
	label: 'Choose icon when select list is closed',
	default: false
}, {
	name: 'iconSelectClosedSize',
	inputType: 'text',
	label: 'Choose size on closed icon',
	default: '2em'
}, {
	name: 'iconSelectClosedColor',
	inputType: 'color',
	label: 'Choose color on closed icon',
	default: '#000'
}];

const selectImageConfig = [{
	name: 'imageSelectOpen',
	inputType: 'media-v2',
	label: 'Choose image when select list is open',
	default: ''
}, {
	name: 'imageSelectClosed',
	inputType: 'media-v2',
	label: 'Choose image when select list is closed',
	default: ''
}];

const resetIconConfig = [{
	name: 'iconReset',
	inputType: 'icon',
	label: 'Choose icon for resetting select list option',
	default: false
}, {
	name: 'iconResetSize',
	inputType: 'text',
	label: 'Choose size on reset icon',
	default: '2em'
}, {
	name: 'iconResetColor',
	inputType: 'color',
	label: 'Choose color on reset icon',
	default: '#000'
}];

const resetImageConfig = [{
	name: 'imageReset',
	inputType: 'media-v2',
	label: 'Choose image for resetting select list option',
	default: ''
}];

const toggleIconConfig = [{
	name: 'iconToggleOpen',
	inputType: 'icon',
	label: 'Choose icon when toggle is open',
	default: false
}, {
	name: 'iconToggleOpenSize',
	inputType: 'text',
	label: 'Choose size on open icon',
	default: '2em'
}, {
	name: 'iconToggleOpenColor',
	inputType: 'color',
	label: 'Choose color on open icon',
	default: '#000'
}, {
	name: 'iconToggleClosed',
	inputType: 'icon',
	label: 'Choose icon when toggle is closed',
	default: false
}, {
	name: 'iconToggleClosedSize',
	inputType: 'text',
	label: 'Choose size on closed icon',
	default: '2em'
}, {
	name: 'iconToggleClosedColor',
	inputType: 'color',
	label: 'Choose color closed icon',
	default: '#000'
}];

const toggleImageConfig = [{
	name: 'imageToggleOpen',
	inputType: 'media-v2',
	label: 'Choose image when toggle is open',
	default: ''
}, {
	name: 'imageToggleClosed',
	inputType: 'media-v2',
	label: 'Choose image when toggle is closed',
	default: ''
}];

const columnSizeConfig = [{
	name: 'xsm',
	inputType: 'slider',
	label: 'Number of columns on mobile (small)',
	default: '1'
}, {
	name: 'sm',
	inputType: 'slider',
	label: 'Number of columns on mobile',
	default: '1'
}, {
	name: 'md',
	inputType: 'slider',
	label: 'Number of columns on tablet',
	default: '1'
}, {
	name: 'lg',
	inputType: 'slider',
	label: 'Number of columns on desktop',
	default: '2'
}, {
	name: 'xlg',
	inputType: 'slider',
	label: 'Number of columns on desktop (large)',
	default: '2'
}];

export default node => ({
	type: 'ARTICLE_ADDONS_NEW',
	name: 'Addons',
	allowedSections: ['article'],
	disallowedChildren: [],
	category: 'Article',
	editorLayout: editorLayout(node),
	icon: 'fal fa-plus-square',
	styleClasses: {
		Article_Info: 'Preset-Addons-ArticleInfo',
		Attribute_3: '',
		Article_Image: '',
		Article_Link: '',
		Article_Name: '',
		Button_Active: '',
		Button_Inactive: '',
		Button_Wrapper: '',
		Color: '',
		Group_Name: 'Preset-Heading-Font-Family, Preset-Heading-Color',
		Icon: '',
		Image: '',
		Image_Wrapper: '',
		Inner_Wrapper: 'Preset-Addons-InnerWrapper',
		Input_Field: '',
		Is_Required: '',
		Label: '',
		Multi_Select_Wrapper: '',
		Personalization_Text: '',
		Prices: '',
		Price_Original: '',
		Price_Sales: '',
		Select_Icon: '',
		Select_Image: '',
		Single_Multi_Select: 'Preset-Addons-SingleMultiSelect',
		Single_Multi_Select_Wrapper: 'Preset-Addons-SingleMultiSelectWrapper',
		Single_Select_Wrapper: '',
		Size: '',
		Summary_Wrapper: '',
		Summary_Row: 'Preset-Addons-SummaryRow',
		Text_Input_Wrapper: '',
		Text_Wrapper: '',
		Toggler_Button: '',
		Toggler_Icon: '',
		Toggler_Wrapper: '',
		Wrapper: 'Preset-Addons-Wrapper'
	},
	props: [{
		name: 'layout',
		label: 'Layout',
		inputType: 'select',
		default: 'text',
		values: [{
			label: 'Text',
			value: 'text'
		}, {
			label: 'Single select',
			value: 'singleSelect'
		}, {
			label: 'Multi select',
			value: 'multiSelect'
		}, {
			label: 'Summary',
			value: 'summary'
		}]
	}, node && node.props.layout === 'summary' && {
		name: 'summaryHeading',
		label: 'Summary Heading',
		inputType: 'text',
		default: ''
	}, node && node.props.layout === 'summary' && {
		name: 'showSize',
		label: 'Show size',
		inputType: 'checkbox',
		default: false
	}, node && node.props.layout === 'summary' && {
		name: 'showColor',
		label: 'Show color',
		inputType: 'checkbox',
		default: false
	}, node && node.props.layout === 'summary' && {
		name: 'showAttribute3',
		label: 'Show attribute 3',
		inputType: 'checkbox',
		default: false
	}, node && node.props.layout === 'summary' && {
		name: 'showPersonalizationText',
		label: 'Show personalization text',
		inputType: 'checkbox',
		default: false
	}, node && node.props.layout !== 'summary' && {
		name: 'groupNumber',
		label: 'Group',
		inputType: 'text',
		description: 'Specify which group of addons should be rendered.',
		default: ''
	}, node && node.props.layout === 'text' && {
		name: 'isRequired',
		label: 'Is required',
		inputType: 'checkbox',
		default: false
	}, node && node.props.layout === 'text' && node.props.isRequired && {
		name: 'isRequiredText',
		label: 'Is required text',
		inputType: 'text',
		default: ''
	}, node && node.props.layout !== 'text' && {
		name: 'showImage',
		label: 'Show product image',
		inputType: 'checkbox',
		default: false
	}, node && node.props.showImage && {
		name: 'imageScale',
		inputType: 'select',
		label: 'Product image scale',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	},
	...conditionallyAddConfig(node, 'layout', 'text', 'text', columnSizeConfig),
	{
		name: 'showSalesPrice',
		label: 'Show sales price',
		inputType: 'checkbox',
		default: false
	}, {
		name: 'showOrgPrice',
		label: 'Show original price',
		inputType: 'checkbox',
		default: false
	}, node && (node.props.layout === 'singleSelect' || node.props.layout === 'multiSelect') && {
		name: 'showAttr1',
		label: 'Show attribute 1 (color)',
		inputType: 'checkbox',
		default: false
	}, {
		name: 'iconType',
		inputType: 'select',
		label: 'Choose icon type',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	},
	...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', activeIconConfig),
	...conditionallyAddConfig(node, 'iconType', 'image', 'icon', activeImageConfig),
	...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', resetIconConfig),
	...conditionallyAddConfig(node, 'iconType', 'image', 'icon', resetImageConfig),
	...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', selectIconConfig),
	...conditionallyAddConfig(node, 'iconType', 'image', 'icon', selectImageConfig),
	...conditionallyAddToggleIcons(node, 'iconType', 'icon', toggleIconConfig),
	...conditionallyAddToggleIcons(node, 'iconType', 'image', toggleImageConfig),
	{
		name: 'showToggler',
		label: 'Show toggler button',
		inputType: 'checkbox',
		default: false
	}, node && node.props.showToggler && {
		name: 'initialNumberOfAddons',
		label: 'Initial number of addons to show',
		description: 'If set to 0 it will work as a toggler which hides and shows all addons',
		inputType: 'number',
		default: 0
	}, node && node.props.showToggler && {
		name: 'startWithOpenToggler',
		label: 'Start with an open toggler',
		inputType: 'checkbox',
		default: true
	}, node && node.props.showToggler && {
		name: 'showMoreButtonText',
		label: 'Show more button text',
		inputType: 'text',
		default: ''
	}, node && node.props.showToggler && {
		name: 'showLessButtonText',
		label: 'Show less button text',
		inputType: 'text',
		default: ''
	}, node && (node.props.layout === 'multiSelect' || node.props.layout === 'singleSelect') && {
		name: 'activeButtonText',
		label: 'Active button text',
		inputType: 'text',
		default: ''
	}, node && (node.props.layout === 'multiSelect' || node.props.layout === 'singleSelect') && {
		name: 'inactiveButtonText',
		label: 'Inactive button text',
		inputType: 'text',
		default: ''
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
