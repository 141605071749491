export default node => ({
	type: 'RECEIPT_FIELD',
	name: 'Receipt Field',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Article',
	styleClasses: {
		Field_Container: '',
		Field_Label: '',
		Field_Row: '',
		Field_Row_Artname_Link: '',
		Field_Row_ArtNumber: '',
		Field_Row_Attribute1: '',
		Field_Row_Attribute2: '',
		Field_Row_Attribute3: '',
		Field_Row_Details: '',
		Field_Row_Desc: '',
		Field_Row_Header: '',
		Field_Row_Image: '',
		Field_Row_Offer: '',
		Field_Row_Price: '',
		Field_Row_Price_Sale: '',
		Field_Row_Price_Org: '',
		Field_Row_Total_Price: '',
		Field_Row_Voucher_Number: '',
		Field_Row_Voucher_Payment_Method_Name: '',
		Field_Row_Quantity: '',
		Field_Value: '',
		Link: ''
	},
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		default: 'customerName',
		values: [{
			label: 'Address',
			value: 'addressLine1'
		}, {
			label: 'Phone number',
			value: 'cellphone'
		}, {
			label: 'City',
			value: 'city'
		}, {
			label: 'Customer company name',
			value: 'companyName'
		}, {
			label: 'Country Code',
			value: 'countryCode'
		}, {
			label: 'Delivery address',
			value: 'deliveryAddressLine1'
		}, {
			label: 'Delivery city',
			value: 'deliveryCity'
		}, {
			label: 'Delivery method',
			value: 'deliveryMethod'
		}, {
			label: 'Delivery zipcode',
			value: 'deliveryZipcode'
		}, {
			label: 'Discount total',
			value: 'discountTotal'
		}, {
			label: 'Email',
			value: 'email'
		}, {
			label: 'First name',
			value: 'firstName'
		}, {
			label: 'Last name',
			value: 'lastName'
		}, {
			label: 'Payment fee',
			value: 'paymentFee'
		}, {
			label: 'Payment method',
			value: 'paymentMethod'
		}, {
			label: 'Product rows',
			value: 'productRows'
		}, {
			label: 'Purchase number',
			value: 'purchaseNumber'
		}, {
			label: 'Shipping fee',
			value: 'shippingFee'
		}, {
			label: 'Social security number',
			value: 'socialSecurityNumber'
		}, {
			label: 'Subscription rows',
			value: 'subscriptionRows'
		}, {
			label: 'Total cost',
			value: 'totalCost'
		}, {
			label: 'Total VAT',
			value: 'totalVAT'
		}, {
			label: 'Vouchers',
			value: 'voucherRows'
		}, {
			label: 'Vouchers total',
			value: 'vouchersTotal'
		}, {
			label: 'Total without vouchers',
			value: 'totalWithoutVouchers'
		}, {
			label: 'Zipcode',
			value: 'zipcode'
		}]
	}, {
		name: 'addLabel',
		inputType: 'checkbox',
		label: 'Add a custom label for field',
		default: false
	}, node && node.props.addLabel && {
		name: 'label',
		inputType: 'text',
		label: 'Label for field',
		default: 'Exampel label'
	}, node && node.props.field === 'totalWithoutVouchers' && {
		name: 'showPaymentMethodNameAsLabel',
		inputType: 'checkbox',
		label: 'Show payment method name as label',
		default: true
	}, node && node.props.field === 'voucherRows' && {
		name: 'showVoucherNumber',
		inputType: 'checkbox',
		label: 'Show voucher number',
		default: true
	}, node && node.props.field === 'voucherRows' && {
		name: 'showVoucherPaymentMethodName',
		inputType: 'checkbox',
		label: 'Show voucher payment method name',
		default: true
	}, node && node.props.field === 'productRows' && {
		name: 'showArtName',
		inputType: 'checkbox',
		label: 'Show article name of each row',
		default: true
	}, node && node.props.field === 'productRows' && {
		name: 'showArtNumber',
		inputType: 'checkbox',
		label: 'Show article number of each row',
		default: true
	}, node && node.props.field === 'productRows' && {
		name: 'showAttribute1',
		inputType: 'checkbox',
		label: 'Show attribute1 of each row',
		default: false
	}, node && node.props.field === 'productRows' && {
		name: 'showAttribute2',
		inputType: 'checkbox',
		label: 'Show attribute1 of each row',
		default: true
	}, node && node.props.field === 'productRows' && {
		name: 'showAttribute3',
		inputType: 'checkbox',
		label: 'Show attribute1 of each row',
		default: true
	}, node && node.props.field === 'subscriptionRows' && {
		name: 'showSubDesc',
		inputType: 'checkbox',
		label: 'Show description of each item row',
		default: true
	}, node && (node.props.field === 'productRows' || node.props.field === 'subscriptionRows') && {
		name: 'showImage',
		inputType: 'checkbox',
		label: 'Show image of each item row',
		default: true
	}, node && (node.props.field === 'productRows' || node.props.field === 'subscriptionRows' || node.props.field === 'voucherRows') && {
		name: 'showPrice',
		inputType: 'checkbox',
		label: 'Show unit price of each item row',
		default: true
	}, node && (node.props.field === 'productRows' || node.props.field === 'subscriptionRows' || node.props.field === 'voucherRows') && {
		name: 'showTotalPrice',
		inputType: 'checkbox',
		label: 'Show total price of each item row',
		description: 'Shows the unit price x quantity',
		default: false
	}, node && (node.props.field === 'productRows' || node.props.field === 'subscriptionRows' || node.props.field === 'voucherRows') && {
		name: 'showQuantity',
		inputType: 'checkbox',
		label: 'Show quantity of each item row',
		default: true
	}, node && (node.props.field === 'productRows' || node.props.field === 'subscriptionRows') && {
		name: 'showOfferDesc',
		inputType: 'checkbox',
		label: 'Show offer description if exist on each item row',
		default: true
	}].filter(Boolean)
});
