import {persistedState} from '~/utils';

import {
	STORE_BALANCE_FETCH_FULFILLED,
	STORE_BALANCE_SEARCH,
	STORE_BALANCE_STORE_SET,
	STORES_FETCH_FULFILLED
} from '../../../action-types';

export const initialState = {
	storeBalance: [],
	stores: [],
	selectedStore: persistedState.articleStoreBalancedSelectedStore || null,
	searchQuery: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case STORE_BALANCE_FETCH_FULFILLED: {
			const stockKeepingUnitsForSelectedStore = state.selectedStore && typeof state.selectedStore === 'object' ? action.payload.find(store => store.store.storeId === state.selectedStore.storeId).stockKeepingUnits : [];
			return {
				...state,
				storeBalance: action.payload,
				stores: action.payload.map(stores => stores.store),
				...(state.selectedStore && {
					selectedStore: {
						...state.selectedStore,
						stockKeepingUnits: stockKeepingUnitsForSelectedStore
					}
				})
			};
		}
		case STORE_BALANCE_SEARCH: {
			return {
				...state,
				searchQuery: action.payload.query
			};
		}
		case STORE_BALANCE_STORE_SET: {
			const stockKeepingUnitsForSelectedStore = typeof action.payload === 'object' ? state.storeBalance.find(store => store.store.storeId === action.payload.storeId).stockKeepingUnits : [];
			return {
				...state,
				selectedStore: {
					...action.payload,
					stockKeepingUnits: stockKeepingUnitsForSelectedStore
				}
			};
		}
		case 'STORE_BALANCE_STORE_RESET': {
			return {
				...state,
				selectedStore: null
			};
		}
		case STORES_FETCH_FULFILLED: {
			return {
				...state,
				stores: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
