import {connect} from '@viskan/deku-redux';
import {close} from './actions';
import Slideout from './components';

export default connect(
	({slideout}) => ({
		...slideout
	}),
	dispatch => ({
		close: () => dispatch(close())
	})
)(Slideout);
