export default node => ({
	type: 'ARTICLE_THUMBNAILS',
	name: 'Thumbnails',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fas fa-camera-retro',
	styleClasses: {
		PlayIcon: 'Preset-Thumbnail-Video-Icon',
		Thumbnail: 'Preset-Thumbnail',
		Thumbnails: '',
		'Thumbnail Image': '',
		'Thumbnail Video': '',
		Wrapper: ''
	},
	props: [{
		name: 'orientation',
		inputType: 'select',
		label: 'Orientation',
		default: 'horizontal',
		values: ['horizontal', 'vertical']
	}, {
		name: 'thresHoldToShowThumbnails',
		inputType: 'number',
		label: 'Minimum images required to show thumbnails',
		default: '2'
	}, {
		name: 'disableLazyLoadOnMobile',
		inputType: 'checkbox',
		label: 'Disable lazy load on mobile',
		description: 'Will turn off lazy load on mobile. Recommended for media that are visible on the page without scrolling (i.e. above the fold) for better pagespeed.',
		default: false
	}, {
		name: 'disableLazyLoadOnTablet',
		inputType: 'checkbox',
		label: 'Disable lazy load on tablet',
		description: 'Will turn off lazy load on tablet. Recommended for media that are visible on the page without scrolling (i.e. above the fold) for better pagespeed.',
		default: false
	}, {
		name: 'disableLazyLoadOnDesktop',
		inputType: 'checkbox',
		label: 'Disable lazy load on desktop',
		description: 'Will turn off lazy load on desktop. Recommended for media that are visible on the page without scrolling (i.e. above the fold) for better pagespeed.',
		default: false
	}, {
		name: 'mobileScale',
		inputType: 'select',
		label: 'Image scale on mobile',
		description: 'Select the smallest scale possible to increase page speed. Does not apply for video thumbnails.',
		default: 'thumbnail',
		values: [{
			label: 'Thumbnail',
			value: 'thumbnail'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Original',
			value: 'original'
		}]
	}, {
		name: 'tabletScale',
		inputType: 'select',
		label: 'Image scale on tablet',
		description: 'Select the smallest scale possible to increase page speed. Does not apply for video thumbnails.',
		default: 'thumbnail',
		values: [{
			label: 'Thumbnail',
			value: 'thumbnail'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Original',
			value: 'original'
		}]
	}, {
		name: 'desktopScale',
		inputType: 'select',
		label: 'Image scale on desktop',
		description: 'Select the smallest scale possible to increase page speed. Does not apply for video thumbnails.',
		default: 'thumbnail',
		values: [{
			label: 'Thumbnail',
			value: 'thumbnail'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Original',
			value: 'original'
		}]
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'useGlobalSettings',
			inputType: 'checkbox',
			label: 'Use global settings for size and attribute 3 match',
			description: 'Change global settings under the site settings menu',
			default: false
		}, node && !node.props.useGlobalSettings && {
			name: 'isSizeMatchRequired',
			inputType: 'checkbox',
			label: 'Show images for selected size only',
			default: ''
		}, node && !node.props.useGlobalSettings && {
			name: 'isAttr3MatchRequired',
			inputType: 'checkbox',
			label: 'Show images for selected attribute 3 only',
			default: ''
		}, node && !node.props.useGlobalSettings && {
			name: 'useFallbackImage',
			inputType: 'checkbox',
			label: 'Use fallback images if article variant has no images',
			default: ''
		}, {
			name: 'useWith360Image',
			inputType: 'checkbox',
			label: 'Use with 360 image (shows every sixth thumbnail)',
			default: false
		}, {
			name: 'useWithVerticalGrid',
			inputType: 'checkbox',
			label: 'Use with vertical grid',
			description: 'Scrolls to the selected image when clicked, only works with grid layout.',
			default: false
		}, {
			name: 'excludeImages',
			inputType: 'text',
			label: 'Exclude images (eg. 1011, 1040)',
			default: ''
		}, {
			name: 'imagesOrderList',
			inputType: 'text',
			label: 'Order images by media row id',
			description: 'Comma separated list of media row ids to order images by (eg. 1011, 1040)',
			default: ''
		}, {
			name: 'videoThumbnailPath',
			inputType: 'media',
			label: 'Thumbnail to use for videos',
			default: ''
		}, {
			name: 'showFirstFrameVideoThumbnail',
			inputType: 'checkbox',
			label: 'Show the first frame as image for video thumbnail',
			description: 'Does not support media type VIDEO/VIMEO. For VIDEO/YOUTUBE the hqdefault image is used.',
			default: false
		}, {
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}].filter(Boolean)
	}]
});
