import clickOutside from 'click-outside';
import Slideout from '@viskan/deku-slideout';
import {deepEqual} from 'fast-equals';

let unbindOutsideClick;

const initialState = () => ({
	open: false
});

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	if (props.open && !state.open) {
		setTimeout(() => {
			setState({open: true});
		}, 200);
	}

	if (!props.open && state.open) {
		setTimeout(() => {
			setState({open: false});
		}, 100);
	}
};

const afterRender = ({props}, el) => {
	if (unbindOutsideClick) {
		unbindOutsideClick();

		unbindOutsideClick = undefined;
	}

	if (!props.open) {
		return;
	}

	unbindOutsideClick = clickOutside(el, () => props.close());
};

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);

const render = ({props, state}, setState) => {
	const {content, open, options} = props;
	const combinedOpen = open && state.open;

	if (!open && !state.open) {
		return <noscript/>;
	}

	return (
		<Slideout class={['QuickBuySlideout', combinedOpen && 'QuickBuySlideout--isOpen']} align={options.side ? options.side.toLowerCase() : 'bottom'} open={combinedOpen} top='auto' bottom='0' showOverlay={options.showOverlay ? options.showOverlay : false}>
			{open && content}
		</Slideout>
	);
};

export default {initialState, afterUpdate, shouldUpdate, render, afterRender};
