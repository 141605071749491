export default node => ({
	type: 'LINDBAK',
	name: 'Lindbak',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*'],
	category: 'My Pages',
	styleClasses: {
		Wrapper: '',
		Container: 'Preset-Lindbak-Circle-Container',
		Circle: 'Preset-Lindbak-Circle',
		CurrentBonus: 'Preset-Lindbak-Circle-CurrentBonus',
		CurrentBonusTextPrefix: '',
		CurrentBonusText: '',
		CurrentBonusTextSuffix: '',
		NextBonus: 'Preset-Lindbak-Circle-NextBonus',
		NextBonusTextPrefix: '',
		NextBonusText: '',
		NextBonusTextSuffix: '',
		About: '',
		Vouchers: '',
		VouchersLabel: '',
		VoucherActive: 'Preset-Lindbak-VoucherActive',
		Voucher: 'Preset-Lindbak-Voucher',
		VoucherLabel: '',
		VoucherAmount: '',
		VoucherDisclaimer: '',
		NoVouchersText: ''
	},
	icon: 'fas fa-gift',
	props: [{
		name: 'showBonus',
		inputType: 'checkbox',
		label: 'Show bonus details',
		default: true
	}, {
		name: 'showVouchers',
		inputType: 'checkbox',
		label: 'Show vouchers',
		default: false
	}, node && node.props.showBonus && {
		name: 'showCurrentBonus',
		inputType: 'checkbox',
		label: 'Show current bonus details',
		default: true
	}, node && node.props.showBonus && {
		name: 'prefixCurrentBonus',
		inputType: 'text',
		label: 'Prefix current bonus',
		default: 'Your bonus:'
	}, node && node.props.showBonus && {
		name: 'suffixCurrentBonus',
		inputType: 'text',
		label: 'Suffix current bonus',
		default: ''
	}, node && node.props.showBonus && {
		name: 'showNextBonus',
		inputType: 'checkbox',
		label: 'Show next bonus details',
		default: true
	}, node && node.props.showBonus && {
		name: 'prefixNextBonus',
		inputType: 'text',
		label: 'Prefix next bonus',
		default: ''
	}, node && node.props.showBonus && {
		name: 'suffixNextBonus',
		inputType: 'text',
		label: 'Suffix next bonus',
		default: ''
	}, node && node.props.showBonus && {
		name: 'about',
		inputType: 'text',
		label: 'About text',
		default: ''
	}, node && node.props.showVouchers && {
		name: 'voucherLabel',
		inputType: 'text',
		label: 'Vouchers label',
		default: ''
	}, node && node.props.showVouchers && {
		name: 'noVouchersText',
		inputType: 'text',
		label: 'No vouchers text',
		default: 'You currently dont have any vouchers.'
	}, node && node.props.showVouchers && {
		name: 'voucherItemLabel',
		inputType: 'text',
		label: 'Voucher label',
		default: 'Voucher'
	}, node && node.props.showVouchers && {
		name: 'voucherItemDisclaimer',
		inputType: 'text',
		label: 'Voucher Disclaimer prefix (date)',
		default: 'This voucher will expire (YYYY-MM-DD)'
	}, node && node.props.showVouchers && {
		name: 'prefixVoucherAmount',
		inputType: 'text',
		label: 'Prefix voucher amount',
		default: ''
	}, node && node.props.showVouchers && {
		name: 'suffixVoucherAmount',
		inputType: 'text',
		label: 'Suffix voucher amount',
		default: ''
	}].filter(Boolean)
});
