const iconConfig = [{
	name: 'iconNext',
	inputType: 'icon',
	label: 'Choose next icon',
	default: false
}, {
	name: 'iconNextSize',
	inputType: 'text',
	label: 'Set size on next icon',
	default: '2em'
}, {
	name: 'iconNextColor',
	inputType: 'color',
	label: 'Set color on next icon',
	default: '#000'
}, {
	name: 'iconPrev',
	inputType: 'icon',
	label: 'Choose previous icon',
	default: false
}, {
	name: 'iconPrevSize',
	inputType: 'text',
	label: 'Set size on previous icon',
	default: '2em'
}, {
	name: 'iconPrevColor',
	inputType: 'color',
	label: 'Set color on previous icon',
	default: '#000'
}];

const imageConfig = [{
	name: 'imageNext',
	inputType: 'media',
	label: 'Choose next image',
	default: ''
}, {
	name: 'imagePrev',
	inputType: 'media',
	label: 'Choose previous image',
	default: ''
}];

const conditionallyAddConfig = (node, conditionStr, configArray) => (node && node.props.type === conditionStr) ? configArray : [];

export default node => ({
	type: 'HELLO_RETAIL_RECOMMENDATIONS',
	name: 'Hello Retail Recommendations',
	allowedSections: ['footer', 'main', 'header', 'article'],
	deprecated: true,
	deprecatedDescription: 'This component is no longer maintained. Please use the Articles Slider component with Hello Retail Recommendations as source instead.',
	disallowedChildren: ['*'],
	category: 'Plugins',
	icon: 'fal fa-store',
	props: [{
		name: 'title',
		inputType: 'text',
		label: 'Heading',
		description: 'Only shown if there is products to render',
		default: 'Lorem ipsum'
	}, {
		name: 'recommendationId',
		inputType: 'text',
		label: 'Recommendation ID',
		default: ''
	}, {
		name: 'showAs',
		inputType: 'select',
		label: 'Show as',
		default: 'articleSlider',
		values: [{
			label: 'Article Slider',
			value: 'articleSlider'
		}, {
			label: 'Article List',
			value: 'articleList'
		}]
	}, {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}, {
		name: 'showColor',
		inputType: 'checkbox',
		label: 'Show Color',
		default: false
	}, {
		name: 'showArticleDescription',
		inputType: 'checkbox',
		label: 'Show Article description',
		default: false
	}, {
		name: 'quickbuy',
		inputType: 'checkbox',
		label: 'Quick buy',
		default: false
	}, {
		name: 'favorite',
		inputType: 'checkbox',
		label: 'Toggle favorite',
		default: false
	}, node && node.props.quickbuy && {
		name: 'popupForSingleSku',
		inputType: 'checkbox',
		label: 'Show popup for single SKU articles',
		default: false
	}, {
		name: 'image',
		inputType: 'select',
		label: 'Image',
		default: '1010',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}]
	}, {
		name: 'imageOnHover',
		inputType: 'select',
		label: 'Image on hover',
		default: '-1',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}, {
			label: 'None',
			value: '-1'
		}]
	}].filter(Boolean),
	hiddenProps: [node && node.props.showAs === 'articleList' && {
		label: 'Columns settings',
		props: [{
			name: 'xsm',
			inputType: 'slider',
			label: 'Columns mobile (small)',
			default: '2'
		}, {
			name: 'sm',
			inputType: 'slider',
			label: 'Columns mobile',
			default: '2'
		}, {
			name: 'md',
			inputType: 'slider',
			label: 'Columns tablet',
			default: '3'
		}, {
			name: 'lg',
			inputType: 'slider',
			label: 'Columns desktop',
			default: '4'
		}, {
			name: 'xlg',
			inputType: 'slider',
			label: 'Columns desktop (large)',
			default: '5'
		}]
	}, {
		label: 'Badge settings',
		props: [{
			name: 'showSaleOrNew',
			inputType: 'checkbox',
			label: 'Show sale or new icon',
			default: false
		}, {
			name: 'showCampaigns',
			inputType: 'checkbox',
			label: 'Show campaign icon',
			default: true
		}, {
			name: 'campaignPopup',
			inputType: 'checkbox',
			label: 'Popup on campaign click',
			default: false
		}, {
			name: 'campaignHeader',
			inputType: 'text',
			label: 'Campaign popup header',
			default: ''
		}]
	}, {
		label: 'Favorite icon',
		props: [{
			name: 'favoriteIcon',
			inputType: 'icon',
			label: 'Choose icon',
			default: '{"prefix":"fal","x":{"name":"heart"}}'
		}, {
			name: 'favoriteIconSelected',
			inputType: 'icon',
			label: 'Choose icon for selected',
			default: '{"prefix":"fas","x":{"name":"heart"}}'
		}, {
			name: 'favoriteIconSize',
			inputType: 'text',
			label: 'Set size on icon',
			default: '1em'
		}, {
			name: 'favoriteIconColor',
			inputType: 'color',
			label: 'Set color on icon',
			default: '#000'
		}]
	}, node && node.props.showAs === 'articleSlider' && {
		label: 'Slider settings',
		props: [{
			name: 'play',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'loop',
			inputType: 'checkbox',
			label: 'Loop',
			default: false
		}, {
			name: 'speed',
			inputType: 'text',
			label: 'Animation speed',
			default: '300'
		}, {
			name: 'duration',
			inputType: 'text',
			label: 'Animation duration',
			default: '3000'
		}, {
			name: 'effect',
			inputType: 'select',
			label: 'Effect',
			default: 'slide',
			values: [{
				label: 'Slide',
				value: 'slide'
			}, {
				label: 'Fade',
				value: 'fade'
			}, {
				label: 'cube',
				value: 'cube'
			}, {
				label: 'Coverflow (Needs fixed height)',
				value: 'coverflow'
			}, {
				label: 'Flip',
				value: 'flip'
			}]
		}, {
			name: 'timing',
			inputType: 'select',
			label: 'Transition',
			default: 'ease',
			values: [{
				label: 'Linear',
				value: 'linear'
			}, {
				label: 'Ease',
				value: 'ease'
			}, {
				label: 'Ease-in',
				value: 'ease-in'
			}, {
				label: 'Ease-out',
				value: 'ease-out'
			}, {
				label: 'Ease-in-out',
				value: 'ease-in-out'
			}]
		}]
	}, node && node.props.showAs === 'articleSlider' && {
		label: 'Navigation',
		props: [{
			name: 'arrows',
			inputType: 'checkbox',
			label: 'Show Arrows',
			default: true
		}, {
			name: 'type',
			inputType: 'select',
			label: 'Choose arrow type',
			default: 'none',
			values: [{
				label: 'Native',
				value: 'none'
			}, {
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
		...conditionallyAddConfig(node, 'icon', iconConfig),
		...conditionallyAddConfig(node, 'image', imageConfig),
		{
			name: 'pagination',
			inputType: 'checkbox',
			label: 'Show Pagination',
			default: true
		}, {
			name: 'paginationType',
			inputType: 'select',
			label: 'Choose pagination type',
			default: 'bullets',
			values: [{
				label: 'Bullets',
				value: 'bullets'
			}, {
				label: 'Fraction',
				value: 'fraction'
			}]
		}]
	}, node && node.props.showAs === 'articleSlider' && {
		label: 'Slide options mobile',
		props: [{
			name: 'slidesPerViewMobile',
			inputType: 'select',
			label: 'Slides per view',
			default: '1',
			values: ['1', '2', '3', '4', '5', '6', '7', '8']
		}, {
			name: 'slidesPerColumnMobile',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenMobile',
			inputType: 'select',
			label: 'Space between',
			default: '0',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesMobile',
			inputType: 'checkbox',
			label: 'Center slides',
			default: false
		}]
	}, node && node.props.showAs === 'articleSlider' && {
		'label': 'Slide options tablet',
		'props': [{
			'name': 'slidesPerViewTablet',
			'inputType': 'select',
			'label': 'Slides per view',
			'default': '3',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, {
			'name': 'slidesPerColumnTablet',
			'inputType': 'select',
			'label': 'Slides per column',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, {
			'name': 'spaceBetweenTablet',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, {
			'name': 'centerSlidesTablet',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}]
	}, node && node.props.showAs === 'articleSlider' && {
		'label': 'Slide options desktop',
		'props': [{
			'name': 'slidesPerViewDesktop',
			'inputType': 'select',
			'label': 'Slides per view',
			'default': '5',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, {
			'name': 'slidesPerColumnDesktop',
			'inputType': 'select',
			'label': 'Slides per column',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, {
			'name': 'spaceBetweenDesktop',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, {
			'name': 'centerSlidesDesktop',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}]
	}].filter(Boolean)
});
