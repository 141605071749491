export default {
	type: 'STICKY_OUT_OF_VIEWPORT',
	name: 'Sticky out of viewport',
	description: 'Will become a sticky element if it\'s out of viewport.',
	allowedSections: ['header', 'main', 'footer'],
	disallowedChildren: [],
	category: 'Layout',
	icon: 'fal fa-tape',
	styleClasses: {
		Wrapper: '',
		Wrapper_Sticky: '',
		Content: '',
		Content_Sticky: ''
	},
	props: [{
		name: 'stickToBottom',
		label: 'Stick to bottom',
		inputType: 'checkbox',
		default: false
	}]
};
