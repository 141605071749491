import bus from '@viskan/bus';
import store from 'store2';
import cookie from 'component-cookie';
import page from 'page';
import {patch as customerPatch} from '../customer/actions';
import {url as urlUtils} from '@viskan/streamline-utilities';

export const openPopup = (data, opts) => bus.emit('popup:open', data, opts);
export const closePopup = () => bus.emit('popup:close');
export const isPerson = () => store('vs-person_flg') !== null ? store('vs-person_flg') : true;

export const validationError = (str, getTranslation) => (validity, el, rule) => {
	const errors = [];
	const rules = [
		'matches',
		'differs',
		'exactLength',
		'greaterThan',
		'greaterThanEqual',
		'lowerThan',
		'lowerThanEqual'
	];

	for (const x in el.validity) {
		if (el.validity[x] && x !== 'valid') {
			if (x === 'valueMissing') {
				errors.unshift(getTranslation(`validation.${x}`, {field: str}));
				break;
			}
			if (rule && rules.includes(rule.rule)) {
				errors.push(getTranslation(`validation.${rule.rule}`, {field: str, msg: rule.msg}));
				break;
			}

			if (rule) {
				errors.push(getTranslation(`validation.${rule.rule}`, {field: str}));
				break;
			}

			errors.push(getTranslation(`validation.${x}`, {field: str}));
		}
	}

	return errors[0];
};

const getUrlBasedOnLocaleMode = site => urlUtils.getLocaleFriendlyPathPrefix(site.lang_code_web, site.country_code, site.localeMode);

/**
 * Check if a locale exists in a webshop by checking the available countries' languages
 */
const localeExistsInCountries = (site, locale) => site.countries.some(country => country.language === locale);

const removeDoubleSlashes = url => url.replace(/\/{2,}/g, '/');

const removeLeadingSlash = url => url.startsWith('/') ? url.slice(1) : url;

export const languageRedirect = {
	handleBadResponse: ({pathPrefix, articleRedirectNotFoundUrl}) => {
		store.remove('vs-languageRedirect');

		if (pathPrefix && articleRedirectNotFoundUrl) {
			page(removeDoubleSlashes(`/${pathPrefix}/${articleRedirectNotFoundUrl}`));
		} else if (pathPrefix && !window.location.href.includes(pathPrefix)) {
			page(removeDoubleSlashes(`/${pathPrefix}/404`));
		} else {
			page('/');
		}
	},
	handleRedirect: async ({pathname, querystring, site, pathPrefix = undefined}) => {
		/**
		 * PathPrefix is only passed from locale component,
		 * because we don't set locale cookie before after we enter the site
		 */
		const locale = pathPrefix || cookie('locale');
		const fixedPathname = pathname.startsWith('/') ? pathname : `/${pathname}`;
		const {articleRedirectNotFoundUrl} = site.settings;
		const invalidLocale = !locale || (!pathPrefix && !localeExistsInCountries(site, site.lang_code_web));

		/**
		 * Site.lang_code_web can sometimes be "en" even if the webshop doesn't have "en" enabled
		 * If that happens we should force the user to select one of the valid countries/locales
		 */
		if (invalidLocale) {
			store('vs-languageRedirect', {pathname, querystring});
			page('/');

			return;
		}

		try {
			let artno;
			let attr1_code;

			if (fixedPathname.includes('/artno/')) {
				const splitFixedPathname = fixedPathname.split('/');

				artno = splitFixedPathname[2];
				attr1_code = splitFixedPathname[4];
			}

			const query = artno ? `?productNumber=${artno}` : fixedPathname.startsWith('/') ? `?path=${fixedPathname.slice(1)}` : `?path=${fixedPathname}`;
			const response = await v12.util.http(`/api/navigation/path-info${query}`);
			const {body} = response;
			const localePrefix = pathPrefix ? `/${pathPrefix}/` : getUrlBasedOnLocaleMode(site);

			if (!body) {
				languageRedirect.handleBadResponse({pathPrefix: localePrefix, articleRedirectNotFoundUrl});
			}

			if (body && body.languageAlternatives) {
				const {targetType} = body;
				const currentLanguageCode = pathPrefix && site.localeMode === 'COUNTRY_IN_PATH' ? site.countries.find(country => country.iso.toLowerCase() === pathPrefix.slice(0, 2)).language : locale.slice(0, 2).toLowerCase();
				const redirectTarget = body.languageAlternatives.find(alternative => alternative.languageCode === currentLanguageCode);

				store.remove('vs-languageRedirect');

				if (!redirectTarget && !articleRedirectNotFoundUrl) {
					// There's no article-404 page setup, show regular 404 page
					page(removeDoubleSlashes(`/${localePrefix}/404`));

					return;
				}

				if (!redirectTarget) {
					page(removeDoubleSlashes(`/${localePrefix}/${articleRedirectNotFoundUrl}`));

					return;
				}

				if (targetType === 'CATEGORY') {
					const urlParameters = querystring ? `?${querystring}` : '';
					const url = `/${localePrefix}/${redirectTarget.path}${urlParameters}`.replace(/\/{2,}/g, '/'); // Create url and remove double slashes

					page(url);
				}

				if (targetType === 'ARTICLE') {
					const urlParameters = attr1_code ? `?attr1_code=${attr1_code}` : querystring ? `?${querystring}` : '';
					const url = removeDoubleSlashes(`/${localePrefix}/${site.articleUrlPrefix}/${redirectTarget.path}${urlParameters}`);

					// We already know if the article exists from the path-info response, now check if the attr1_code exists
					const article = await v12.article.get(removeLeadingSlash(redirectTarget.path));
					const attr1Exists = article.articleAttributes.some(attribute => attribute.attribute1.attr1_code === attr1_code);

					if (attr1Exists) {
						page(url);
					} else if (articleRedirectNotFoundUrl) {
						page(removeDoubleSlashes(`/${localePrefix}/${articleRedirectNotFoundUrl}`));
					} else {
						page(removeDoubleSlashes(`/${localePrefix}/404`));
					}
				}
			}
		} catch (error) {
			languageRedirect.handleBadResponse({pathPrefix: locale, articleRedirectNotFoundUrl});
		}
	}
};

export default app => {
	app.set('isPerson', isPerson);
	app.set('validationError', validationError);
	app.set('openPopup', openPopup);
	app.set('closePopup', closePopup);
	app.set('customerPatch', customerPatch);
	app.set('languageRedirect', languageRedirect);
};
