import {conditionallyAddConfig} from '~/utils';

const iconConfig = [{
	name: 'iconClosed',
	inputType: 'icon',
	label: 'Choose icon when select list is closed',
	default: '{"prefix": "fas", "x": {"name": "caret-down"}}'
}, {
	name: 'iconClosedSize',
	inputType: 'text',
	label: 'Set size on closed icon',
	default: '1em'
}, {
	name: 'iconClosedColor',
	inputType: 'color',
	label: 'Set color on closed icon',
	default: '#000'
}, {
	name: 'iconOpen',
	inputType: 'icon',
	label: 'Choose icon when select list is open',
	default: '{"prefix": "fas", "x": {"name": "caret-up"}}'
}, {
	name: 'iconOpenSize',
	inputType: 'text',
	label: 'Set size on open icon',
	default: '1em'
}, {
	name: 'iconOpenColor',
	inputType: 'color',
	label: 'Set color on open icon',
	default: '#000'
}];

const imageConfig = [{
	name: 'imageClosed',
	inputType: 'media-v2',
	label: 'Choose image when select list is closed',
	default: ''
}, {
	name: 'imageOpen',
	inputType: 'media-v2',
	label: 'Choose image when select list is open',
	default: ''
}];

export default node => ({
	type: 'ARTICLE_ADDONS',
	name: 'Legacy Addons',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-plus-square',
	styleClasses: {
		AddonCheckbox: '',
		AddonCheckbox_Checkbox: '',
		AddonCheckbox_Label: '',
		AddonCheckbox_Name: '',
		AddonCheckbox_Price: '',
		AddonCheckboxText: '',
		AddonCheckboxText_Checkbox: '',
		AddonCheckboxText_Input: '',
		AddonCheckboxText_Label: '',
		AddonCheckboxText_Name: '',
		AddonCheckboxText_Price: '',
		AddonImage: '',
		AddonImage_Name: '',
		AddonImage_Price: '',
		Image: '',
		Option: '',
		Options: '',
		Select: ''
	},
	props: [],
	hiddenProps: [{
		label: 'Rendering settings',
		props: [{
			name: 'renderCheckboxText',
			inputType: 'text',
			label: 'Render as checkbox text',
			description: 'Specify which groups of addons (relation_no) that should be rendered as checkbox texts. Separate numbers by comma.'
		}, {
			name: 'renderCheckbox',
			inputType: 'text',
			label: 'Render as checkbox',
			description: 'Specify which groups of addons (relation_no) that should be rendered as checkboxes. Separate numbers by comma.'
		}, {
			name: 'renderSelect',
			inputType: 'text',
			label: 'Render as select list',
			description: 'Specify which groups of addons (relation_no) that should be rendered as select lists. Separate numbers by comma.'
		}, {
			name: 'renderImageSingle',
			inputType: 'text',
			label: 'Render as image (one selectable image per group)',
			description: 'Specify which groups of addons (relation_no) that should be rendered as images. Separate numbers by comma.'
		}, {
			name: 'renderImageMultiple',
			inputType: 'text',
			label: 'Render as image (multiple selectable images per group)',
			description: 'Specify which groups of addons (relation_no) that should be rendered as images. Separate numbers by comma.'
		}, {
			name: 'renderNoscript',
			inputType: 'text',
			label: 'Do not render',
			description: 'Specify which groups of addons (relation_no) that should not be rendered. Separate numbers by comma.'
		}]
	}, {
		label: 'Select list settings',
		props: [{
			name: 'relation4Placeholder',
			inputType: 'text',
			label: 'Placeholder',
			default: ''
		}, {
			name: 'iconType',
			inputType: 'select',
			label: 'Choose icon type',
			default: 'icon',
			values: [{
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
		...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', iconConfig),
		...conditionallyAddConfig(node, 'iconType', 'image', 'icon', imageConfig)]
	}, {
		label: 'Image settings',
		props: [{
			name: 'scale',
			inputType: 'select',
			label: 'Image scale',
			description: 'Select the smallest scale possible to increase page speed',
			default: 'thumbnail',
			values: [{
				label: 'Original',
				value: 'original'
			}, {
				label: 'Large',
				value: 'large'
			}, {
				label: 'Normal',
				value: 'normal'
			}, {
				label: 'List',
				value: 'list'
			}, {
				label: 'Thumbnail',
				value: 'thumbnail'
			}].filter(Boolean)
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
