export default [{
	label: 'Store',
	settings: [{
		description: 'Presented as a page title suffix',
		label: 'Company Name*',
		settingKey: 'companyName',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Select which media type you use as favicon',
		label: 'Favicon type*',
		settingKey: 'favIconMediaType',
		type: 'select',
		defaultValue: 'png',
		languageSpecific: false,
		options: [
			{
				label: 'PNG',
				value: 'png'
			}, {
				label: 'SVG',
				value: 'svg'
			}
		]
	}, {
		description: 'Remember to change path of the search page in "Pages"',
		label: 'Search Url Prefix',
		settingKey: 'searchUrlPrefix',
		type: 'text',
		defaultValue: 'search',
		languageSpecific: true
	}, {
		description: 'Insert your custom favicon name. If you have selected favicon type PNG you have to upload three favicon images that end with the file name `-16x16`, `-24x24` and `-32x32`.',
		label: 'Favicon name*',
		removeOnEmpty: true,
		settingKey: 'favIconName',
		type: 'text',
		defaultValue: 'favicon',
		languageSpecific: false
	}, {
		label: 'Allow persons to shop*',
		settingKey: 'isPersonAllowed',
		type: 'checkbox',
		defaultValue: true
	}, {
		label: 'Allow businesses to shop*',
		settingKey: 'isBusinessAllowed',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Enable remarketing*',
		settingKey: 'remarketing',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Empty shopping cart when logging in on my pages*',
		settingKey: 'emptyCartOnAuth',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Empty shopping cart when logging out of my pages*',
		settingKey: 'emptyCartOnLogout',
		type: 'checkbox',
		defaultValue: ''
	}, {
		label: 'Enable Font Awesome',
		description: 'If Font Awesome icons are not used anywhere on the site, disabling the setting can improve page speed',
		settingKey: 'enableFontAwesome',
		type: 'checkbox',
		defaultValue: true
	}, {
		label: 'Enable Anime.js',
		description: 'If Anime.js animations are not used anywhere on the site, disabling the setting can improve page speed',
		settingKey: 'enableAnimeJs',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Enable Dotlottie*',
		description: 'If Dotlottie animations are not used anywhere on the site, disabling the setting can improve page speed',
		settingKey: 'enableDotlottie',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Customer category id',
		description: 'The customer category id to add to the customer when customer registration on my pages.',
		settingKey: 'customerCategoryId',
		type: 'text',
		defaultValue: ''
	}, {
		label: 'Disable ex VAT prices for B2B customers*',
		description: 'By default prices are shown including VAT for B2C and excluding VAT for B2B. Enable this setting to always show prices including VAT.',
		settingKey: 'disableExVATForB2B',
		type: 'checkbox',
		defaultValue: false
	}]
}, {
	label: 'Third party',
	settings: [{
		description: 'Used for Google Tag Manager tracking',
		label: 'Google Tag Manager ID*',
		settingKey: 'googleTagManagerId',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Used for Google Analytics backend tracking',
		label: 'Google Analytics ID',
		settingKey: 'googleAnalyticsId',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Use Events for Google Analytics UA',
		label: 'Use Events for Google Analytics UA',
		settingKey: 'googleAnalyticsUa',
		type: 'checkbox',
		defaultValue: true
	}, {
		description: 'Use Events for Google Analytics GA4',
		label: 'Use Events for Google Analytics GA4',
		settingKey: 'googleAnalytics4',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Insert your meta tag verification string (id only) to verify your site',
		label: 'Google Site Verification*',
		removeOnEmpty: true,
		settingKey: 'googleSiteVerification',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Insert your meta tag verification string (id only) to verify your site',
		label: 'Facebook Site Verification*',
		removeOnEmpty: true,
		settingKey: 'facebookSiteVerification',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Apsis newsletter API authentication',
		label: 'Apsis API key',
		settingKey: 'hidden-apsisApiKey',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Mailchimp newsletter API authentication',
		label: 'Mailchimp API key',
		settingKey: 'hidden-mailchimpApiKey',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Voyado newsletter API authentication',
		label: 'Voyado API key',
		settingKey: 'hidden-voyadoApiKey',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Klarna On-Site Messaging',
		label: 'Klarna On-Site Messaging ID*',
		settingKey: 'klarnaOnSiteMessaging',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Lipscore API authentication',
		label: 'Lipscore API key',
		settingKey: 'lipscoreApiKey',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Requires a signed agreement. Talk to your customer manager for more information. If disabled, a hidden purchase widget on the receipt page is used to send in order data to Lipscore.',
		label: 'Get Lipscore order data from API*',
		settingKey: 'getLipscoreOrderDataFromAPI',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Used for Marketing Platform newsletter API authentication',
		label: 'Marketing Platform API key',
		settingKey: 'hidden-marketingplatformApiKey',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Used for Marketing Platform newsletter API authentication',
		label: 'Marketing Platform API username',
		settingKey: 'hidden-marketingplatformApiusername',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'E.g. efcd98a5-335b-48b0-ab17-bf43f1c542be',
		label: 'Piwik Tag Manager ID*',
		settingKey: 'piwikId',
		type: 'text',
		defaultValue: '',
		countrySpecific: true
	}, {
		description: 'E.g. https://your-instance-name.piwik.pro/',
		label: 'Piwik Tag Manager Instance Address*',
		settingKey: 'piwikInstanceAddress',
		type: 'text',
		defaultValue: '',
		countrySpecific: true
	}, {
		description: 'Used for Ingrid Tracking Widget. Set the site id for Ingrid on Orders component',
		label: 'Activate Ingrid Tracking Widget*',
		settingKey: 'ingridTrackingWidget',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Used for the Cevoid Instagram integration',
		label: 'Activate Cevoid',
		settingKey: 'cevoid',
		type: 'checkbox',
		defaultValue: false
	}]
}, {
	label: 'Articles',
	settings: [{
		description: 'Sets the prefix for article detail page',
		label: 'Article URL Prefix',
		settingKey: 'articleUrlPrefix',
		type: 'text',
		languageSpecific: true,
		defaultValue: 'article'
	}, {
		description: 'Will enable to show a unique category section on article',
		label: 'Load category on article page',
		settingKey: 'loadCategoryOnArticle',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Color filter on product listings will be grouped by similar colors',
		label: 'Load product filter colors as a group',
		settingKey: 'aggregateAttribute1Group',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Size filter on product listings will be grouped by similar sizes',
		label: 'Load product filter sizes as a group',
		settingKey: 'aggregateAttribute2Group',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Attribute 3 filter on product listings will be grouped by similar attribute 3',
		label: 'Load product filter attribute 3 as a group',
		settingKey: 'aggregateAttribute3Group',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Makes the visitor to make an active choice of the attribute 1 value',
		label: 'Don\'t preselect attribute 1',
		settingKey: 'noPreselectAttribute1',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Makes the visitor to make an active choice of the attribute 2 value',
		label: 'Don\'t preselect attribute 2',
		settingKey: 'noPreselectAttribute2',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Makes the visitor to make an active choice of the attribute 3 value',
		label: 'Don\'t preselect attribute 3',
		settingKey: 'noPreselectAttribute3',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'The number of days that an article is considered new since it\'s activation date',
		label: 'Days as brand new',
		settingKey: 'daysAsBrandNew',
		type: 'number',
		min: '0',
		removeOnEmpty: true,
		defaultValue: 30
	}, {
		description: 'Use different article layout for Quickbuy view',
		label: 'Separate Quickbuy layout',
		settingKey: 'useArticleQuickBuyLayout',
		type: 'checkbox',
		languageSpecific: false,
		defaultValue: false
	}, {
		description: 'Exclude out of stock articles from listings and sliders',
		label: 'Hide articles out of stock',
		settingKey: 'excludeOutOfStockListArticles',
		type: 'checkbox',
		defaultValue: true
	}, {
		description: 'Adds current picked filter to URL for easy sharing. Does not support multiple articles components on the same page.',
		label: 'Add filter to URL*',
		settingKey: 'addArticleFilterToUrl',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Article has to have media to be visible on site',
		label: 'Enable checkMedia*',
		settingKey: 'checkMediaEnabled',
		type: 'checkbox',
		defaultValue: true
	}, {
		description: 'Applies to the price shown in list article, articles slider and search',
		label: 'Hide price if the price is 0',
		settingKey: 'hideZeroPrice',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Show lowest price (when applicable) everywhere. Can be overriden on component level',
		label: 'Show lowest price',
		settingKey: 'showLowestPrice',
		type: 'checkbox',
		defaultValue: false,
		languageSpecific: true
	}, {
		description: 'Don\'t show the lowest price for articles that has one of these price campaigns. Separate with comma. (For example "2,3,10")',
		label: 'Hide lowest price for specific offer types',
		settingKey: 'hideLowestPriceForSpecificOfferTypes',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Global setting for lowest price prefix text. Can be overridden on component level',
		label: 'Lowest price prefix text',
		settingKey: 'lowestPricePrefixText',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Global setting for lowest price suffix text. Can be overridden on component level',
		label: 'Lowest price suffix text',
		settingKey: 'lowestPriceSuffixText',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Calculate discount percentage from lowest price when applicable.',
		label: 'Calculate discount percent from lowest price',
		settingKey: 'calculateDiscountPercentFromLowestPrice',
		type: 'checkbox',
		defaultValue: false,
		languageSpecific: true
	}, {
		description: 'Applies to the media, thumbnails and attribute component as well as structured data markup',
		label: 'Show images for selected size only',
		settingKey: 'isSizeMatchRequired',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Applies to the media, thumbnails and attribute component as well as structured data markup',
		label: 'Show images for selected attribute 3 only',
		settingKey: 'isAttr3MatchRequired',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Show a general image matching all sizes if there is no image for the selected size',
		label: 'Show fallback image for selected size',
		settingKey: 'useFallbackImageForSize',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Use fallback image if image for current variant is not available. (Applies to the media, thumbnails and attribute component as well as structured data markup)',
		label: 'Use fallback image',
		settingKey: 'useFallbackImageGlobal',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Show customer category offers',
		label: 'Include Customer Category Offers*',
		settingKey: 'includeCustomerCategoryOffers',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'URL to redirect to if a language-neutral link leads to a non-existing article. Remember to add this page for all countries.',
		label: 'Article Redirect Not Found URL',
		settingKey: 'articleRedirectNotFoundUrl',
		type: 'link',
		defaultValue: ''
	}]
}, {
	label: 'Checkout',
	settings: [{
		description: 'Sets the URL path for checkout page',
		label: 'Checkout URL Path',
		settingKey: 'checkoutUrl',
		type: 'text',
		languageSpecific: true,
		defaultValue: 'checkout'
	}, {
		description: 'Remember to change path of the receipt page in "Pages"',
		label: 'Receipt Url path',
		settingKey: 'receiptUrlPath',
		type: 'text',
		defaultValue: 'receipt',
		languageSpecific: true
	}, {
		description: 'Remember to change path of the terms page in "Pages"',
		label: 'Terms Url path',
		settingKey: 'termsUrlPath',
		type: 'text',
		defaultValue: 'terms-and-conditions',
		languageSpecific: true
	}, {
		description: 'Blocks ZIP codes in Klarna matching the regex (for example "200.*")',
		label: 'Forbidden ZIP code pattern for Klarna Checkout*',
		settingKey: 'klarnaForbiddenZipCodePattern',
		type: 'text',
		languageSpecific: true,
		defaultValue: ''
	}, {
		description: 'Will enable "Klarna Shipping Assistant" and disable Viskan\'s delivery method selection on all countries',
		label: 'Enable Klarna Shipping Assistant (Global)*',
		settingKey: 'klarnaShippingAssistantEnabled',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Will enable "Klarna Shipping Assistant" and disable Viskan\'s delivery method selection on current country. (Does not work with Global enabled)',
		label: 'Enable Klarna Shipping Assistant (Country)*',
		settingKey: 'klarnaShippingAssistantEnabled_country',
		type: 'checkbox',
		defaultValue: false,
		countrySpecific: true
	}, {
		description: 'Will enable Ingrid Shipping Assistant on all countries',
		label: 'Enable Ingrid Shipping Assistant (Global)*',
		settingKey: 'ingridShippingAssistantEnabled',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Will enable Ingrid Shipping Assistant on current country (Does not work with Global enabled)',
		label: 'Enable Ingrid Shipping Assistant (Country)*',
		settingKey: 'ingridShippingAssistantEnabled_country',
		type: 'checkbox',
		defaultValue: false,
		countrySpecific: true
	}, {
		description: 'Will enable possibilty to have separate shipping address in Klarna. (Does not work with Ingrid or klarna Shipping Assistant enabled)',
		label: 'Enable Klarna Separate Shipping Address*',
		settingKey: 'klarnaSeparateShippingAddressEnabled',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Select layout for receipt page',
		label: 'Receipt layout*',
		settingKey: 'receiptLayout',
		type: 'select',
		defaultValue: 'default',
		languageSpecific: true,
		options: [
			{
				label: 'Default receipt',
				value: 'default'
			}, {
				label: 'Custom receipt',
				value: 'custom'
			}, {
				label: 'Both custom and default',
				value: 'both'
			}
		]
	}]
}, {
	label: 'Advanced',
	settings: [{
		label: 'Enable ArticleStore*',
		settingKey: 'articleStoreEnabled',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Will fetch shopcart from last time customer was logged in',
		label: 'Use persistent shopcarts',
		settingKey: 'persistentShopcart',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'You should only enable if you need to see if article has subscriptions in article lists. This can affect performance*',
		label: 'Enable subscription templates in articles',
		settingKey: 'articleListWithPrentypes',
		type: 'checkbox',
		defaultValue: false,
		languageSpecific: true
	}]
}];
