import {connect} from '@viskan/deku-redux';
import {getTranslator} from '~/sources/translator';
import Component from './components';
import {inlineQuickbuyClose} from '../cms-components/articles/actions';
import {clickArticle} from '../article/actions';

export default connect(
	state => ({
		breakpoint: state.breakpoint.breakpointOverride || state.breakpoint.breakpoint,
		cmsActive: state.streamlineCms.loaded && !state.cms.base.hidden,
		getTranslation: getTranslator(state),
		site: state.site,
		locale: state.router.pathPrefix,
		isPerson: state.customer.person_flg,
		hideZeroPrice: state.site.settings.hideZeroPrice,
		showLowestPriceStoreSetting: state.site.settings.showLowestPrice,
		inlineQuickbuy: state.articles.inlineQuickbuy,
		open: state.articles.inlineQuickbuy.open,
		content: state.articles.inlineQuickbuy.content,
		hideLowestPriceForSpecificOfferTypes: state.site.settings.hideLowestPriceForSpecificOfferTypes
	}),
	{
		inlineQuickbuyClose,
		clickArticle
	}
)(Component);
