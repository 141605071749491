import {createCmsComponent} from '@viskan/cms-component-utilities';
import {Grid, Cell} from 'viskan-deku-grid';
import {deepEqual} from 'fast-equals';
import Slide from '@viskan/deku-slide';
import {Image} from '~/media';
import ListArticle from '~/list-article';
import {getIcon} from '~/cms-components/utils/actions';
import {getSkuBasedOnAttribute1Id} from '~/utils';

const name = 'CMS-HelloRetail';
const getSize = size => size === 1 ? 'full' : `1of${size}`;
const parseNumber = (num, def) => parseInt(num, 10) || def;
const getImage = (stateString, props) => <Image class={props.styleClasses.Image} source={props[`image${stateString}`]} data-style-attr='Image'/>;
const getPresentation = (stateString, props) => {
	switch (props.type) {
		case 'icon': {
			return getIcon({icon: props[`icon${stateString}`], iconColor: props[`icon${stateString}Color`], iconSize: props[`icon${stateString}Size`], label: stateString});
		}
		case 'image': {
			return getImage(stateString, props);
		}
		case 'text':
		default: {
			return stateString;
		}
	}
};

const getOptions = props => {
	const options = {
		roundLengths: false,
		simulateTouch: props.cmsLoaded
	};

	switch (props.breakpoint) {
		case 'xsm':
		case 'sm':
			options.centeredSlides = props.centerSlidesMobile;
			options.slidesPerColumn = parseNumber(props.slidesPerColumnMobile, 1);
			options.slidesPerGroup = parseNumber(props.slidesPerGroupMobile, 1);
			options.slidesPerView = parseNumber(props.slidesPerViewMobile, 1);
			options.spaceBetween = parseNumber(props.spaceBetweenMobile, 0);
			break;
		case 'md':
			options.centeredSlides = props.centerSlidesTablet;
			options.slidesPerColumn = parseNumber(props.slidesPerColumnTablet, 1);
			options.slidesPerGroup = parseNumber(props.slidesPerGroupTablet, 1);
			options.slidesPerView = parseNumber(props.slidesPerViewTablet, 1);
			options.spaceBetween = parseNumber(props.spaceBetweenTablet, 0);
			break;
		default:
			options.centeredSlides = props.centerSlidesDesktop;
			options.slidesPerColumn = parseNumber(props.slidesPerColumnDesktop, 1);
			options.slidesPerGroup = parseNumber(props.slidesPerGroupDesktop, 1);
			options.slidesPerView = parseNumber(props.slidesPerViewDesktop, 1);
			options.spaceBetween = parseNumber(props.spaceBetweenDesktop, 0);
	}

	return options;
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

export const renderArticles = (articles, props) => {
	const meta = props.recommendationIds[props.recommendationId] ? props.recommendationIds[props.recommendationId].meta : undefined;
	const {image, imageOnHover, styleClasses, showArticleDescription, favorite, favoritesArtNosAndAttr1Ids, popupForSingleSku, showColor, quickbuy, splitByAttribute1, viewActive, viewActiveImage, viewActiveImageOnHover, xsm, sm, md, lg, xlg} = props;
	const columns = {xsm, sm, md, lg, xlg};
	const badgeOptions = {
		campaignPopup: props.campaignPopup,
		campaignHeader: props.campaignHeader,
		showCampaigns: props.showCampaigns,
		showSaleOrNew: props.showSaleOrNew
	};

	const favoriteOptions = {
		favorite,
		favoriteIcon: props.favoriteIcon,
		favoriteIconSelected: props.favoriteIconSelected,
		favoriteIconColor: props.favoriteIconColor,
		favoriteIconSize: props.favoriteIconSize
	};

	return articles.map((article, index) => {
		try {
			const {art_id} = article;
			const sku = getSkuBasedOnAttribute1Id(article);
			const listArticleProps = {
				article,
				attr1_id: sku.attr1_id,
				back: viewActive ? viewActiveImageOnHover : imageOnHover,
				badgeOptions,
				front: viewActive ? viewActiveImage : image,
				favorite,
				favoritesArtNosAndAttr1Ids,
				favoriteOptions,
				showArticleDescription,
				showColor,
				splitByAttribute1,
				styleClasses,
				meta,
				popupForSingleSku,
				quickbuy,
				index
			};

			const listArticleElement = <ListArticle {...listArticleProps}/>;

			if (props.showAs === 'articleSlider') {
				return (
					<div key={`${article.art_id}-${sku.attr1_id}-${sku.attr2_id}`}>
						{listArticleElement}
					</div>
				);
			}

			return (
				<Cell key={`${art_id}-${sku.attr1_id}-${sku.attr2_id}`} size={getSize(columns.xsm)} sm-size={getSize(columns.sm)} md-size={getSize(columns.md)} lg-size={getSize(columns.lg)} xlg-size={getSize(columns.xlg)}>
					{listArticleElement}
				</Cell>
			);
		} catch (error) {
			v12.util.error(error);

			return console.error(error);
		}
	}).filter(Boolean);
};

const renderSlider = (items, props) => {
	const iconNext = (props.iconNext || props.imageNext) && props.type !== 'none' ? getPresentation('Next', props) : '';
	const iconPrev = (props.iconPrev || props.imagePrev) && props.type !== 'none' ? getPresentation('Prev', props) : '';
	const options = getOptions(props);
	const key = `${props.recommendationId}-${props.id}`;

	const settings = {
		arrows: props.arrows,
		iconNext,
		iconPrev,
		direction: 'horizontal',
		disableOnInteraction: true,
		duration: parseNumber(props.duration, 3000),
		effect: props.effect,
		loop: props.loop,
		options,
		pagination: props.pagination,
		paginationType: props.paginationType,
		play: props.play,
		speed: parseNumber(props.speed, 500),
		timing: props.timing
	};

	return <Slide key={key} {...settings} styleClasses={props.styleClasses}>{items}</Slide>;
};

const render = ({props}) => {
	const hasArticles = props.recommendationIds[props.recommendationId] && props.recommendationIds[props.recommendationId].articles && props.recommendationIds[props.recommendationId].articles.length !== 0;
	const articles = hasArticles ? props.recommendationIds[props.recommendationId].articles : [];

	const items = renderArticles(articles, props);

	if (items.length === 0) {
		return <noscript class={name} data-recommendation-id={props.recommendationId}/>;
	}

	return createCmsComponent((
		<div class={[name, props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper' data-recommendation-id={props.recommendationId}>
			{props.title && <div class={['CMS-HelloRetail-heading', props.styleClasses.Heading]} data-style-attr='Heading'>{props.title}</div>}
			{props.showAs === 'articleSlider' ? renderSlider(items, props) : <Grid>{items}</Grid>}
		</div>
	), props);
};

export default {name, render, shouldUpdate};
