import {deepEqual} from 'fast-equals';

const propTypes = {
	getEmail: {
		source: 'getEmail'
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	const {shopcart} = props;
	const {opened} = state;
	const {rows} = shopcart;

	if (props.getEmail() || opened) {
		return;
	}

	document.documentElement.addEventListener('mouseleave', e => {
		document.documentElement.addEventListener('mouseleave', () => {});

		if (props.getEmail() || opened || rows.length === 0 || e.clientY >= 0) {
			return;
		}

		props.leaveWindowAction();
		setState({opened: true});
	});
};

const render = () => <noscript/>;

export default {afterUpdate, render, shouldUpdate, propTypes};
