const conditionallyAddConfig = (node, key, conditionStr, configArray) => (node && node.props[key] === conditionStr) ? configArray : [];
const displayAsShowMore = {
	name: 'showAsShowMore',
	inputType: 'checkbox',
	label: 'Display show more button',
	default: false
};

const showMoreSetting = [{
	label: 'Show more setting',
	props: [{
		name: 'imagesFirstViewDesktop',
		inputType: 'text',
		label: 'Initial number of images to show in desktop'
	}, {
		name: 'imagesFirstViewTablet',
		inputType: 'text',
		label: 'Initial number of images to show in tablet'
	}, {
		name: 'imagesFirstViewMobile',
		inputType: 'text',
		label: 'Initial number of images to show in mobile'
	}, {
		name: 'showLessText',
		inputType: 'text',
		label: 'Show less text',
		default: 'Show less'
	}]
}];

const displayAsSlider = {
	name: 'showAsSlider',
	inputType: 'checkbox',
	label: 'Display attributes as a slider',
	default: false
};

const checkboxConfig = [
	displayAsSlider
];

const imageConfig = [
	displayAsSlider,
	displayAsShowMore
];

const sliderIconConfig = [{
	name: 'iconNext',
	inputType: 'icon',
	label: 'Choose next icon',
	default: false
}, {
	name: 'iconNextSize',
	inputType: 'text',
	label: 'Set size on next icon',
	default: '2em'
}, {
	name: 'iconNextColor',
	inputType: 'color',
	label: 'Set color on next icon',
	default: '#000'
}, {
	name: 'iconPrev',
	inputType: 'icon',
	label: 'Choose previous icon',
	default: false
}, {
	name: 'iconPrevSize',
	inputType: 'text',
	label: 'Set size on previous icon',
	default: '2em'
}, {
	name: 'iconPrevColor',
	inputType: 'color',
	label: 'Set color on previous icon',
	default: '#000'
}];

const sliderImageConfig = [{
	name: 'imageNext',
	inputType: 'media',
	label: 'Choose next image',
	default: ''
}, {
	name: 'imagePrev',
	inputType: 'media',
	label: 'Choose previous image',
	default: ''
}];

const sliderArrowConfig = node => [{
	label: 'Slider arrow configuraton',
	props: [{
		name: 'sliderArrowsType',
		inputType: 'select',
		label: 'Choose arrow type',
		default: 'none',
		values: [{
			label: 'Native',
			value: 'none'
		}, {
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	},
	...conditionallyAddConfig(node, 'sliderArrowsType', 'icon', sliderIconConfig),
	...conditionallyAddConfig(node, 'sliderArrowsType', 'image', sliderImageConfig)]
}];

const sliderConfig = [{
	label: 'Slider configuraton',
	props: [{
		name: 'sliderSlidesPerview',
		inputType: 'select',
		label: 'Slides per view',
		default: 4,
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	}, {
		name: 'sliderSlidesPerColumn',
		inputType: 'select',
		label: 'Slides per column',
		default: 1,
		values: [1, 2, 3, 4]
	}, {
		name: 'slidesPerGroup',
		inputType: 'select',
		label: 'Slides per group',
		default: 1,
		values: [1, 2, 3, 4]
	}, {
		name: 'sliderSpaceBetween',
		inputType: 'select',
		label: 'Space between',
		default: 10,
		values: [0, 10, 20, 30, 40, 50]
	}, {
		name: 'sliderCenter',
		inputType: 'checkbox',
		label: 'Center slides',
		default: true
	}, {
		name: 'sliderSpeed',
		inputType: 'text',
		label: 'Animation speed',
		default: '300'
	}, {
		name: 'sliderTiming',
		inputType: 'select',
		label: 'Transition',
		default: 'ease',
		values: [{
			label: 'Linear',
			value: 'linear'
		}, {
			label: 'Ease',
			value: 'ease'
		}, {
			label: 'Ease-in',
			value: 'ease-in'
		}, {
			label: 'Ease-out',
			value: 'ease-out'
		}, {
			label: 'Ease-in-out',
			value: 'ease-in-out'
		}]
	}, {
		name: 'sliderArrows',
		inputType: 'checkbox',
		label: 'Show Arrows',
		default: true
	}, {
		name: 'sliderPagination',
		inputType: 'checkbox',
		label: 'Show Pagination',
		default: false
	}, {
		name: 'sliderPaginationType',
		inputType: 'select',
		label: 'Pagination type',
		default: 'bullets',
		values: [{
			label: 'Bullets',
			value: 'bullets'
		}, {
			label: 'Progress',
			value: 'progress'
		}, {
			label: 'Fraction',
			value: 'fraction'
		}]
	}, {
		name: 'sliderNavigationColor',
		inputType: 'color',
		label: 'Navigation color',
		default: '#000000'
	}]
}];

export default node => ({
	type: 'ARTICLE_ATTRIBUTE',
	name: 'Attribute',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	styleClasses: {
		Active: '',
		Attribute: '',
		Out_Of_Stock_Message: '',
		Out_Of_Stock_Wrapper: '',
		Price_Difference: '',
		Store_Balance: ''
	},
	props: [{
		name: 'attribute',
		inputType: 'select',
		label: 'Select attribute',
		default: 'attr1',
		values: [{
			label: 'Attribute 1 (Color)',
			value: 'attr1'
		}, {
			label: 'Attribute 2 (Size)',
			value: 'attr2'
		}, {
			label: 'Attribute 3',
			value: 'attr3'
		}]
	}, {
		name: 'label',
		inputType: 'text',
		label: 'Label',
		default: 'Attribute'
	}, {
		name: 'showAs',
		inputType: 'select',
		label: 'Display as a',
		default: 'dropdown',
		values: [{
			label: 'Colorbox',
			value: 'checkbox'
		}, {
			label: 'Dropdown',
			value: 'dropdown'
		}, {
			label: 'Image',
			value: 'image'
		}, {
			label: 'Name',
			value: 'name'
		}, node && node.props.attribute === 'attr2' && {
			label: 'Size Range (Only works with attribute size)',
			value: 'sizeRange'
		}].filter(Boolean)
	}, node && node.props.showAs === 'image' && {
		name: 'mediaRowId',
		inputType: 'text',
		label: 'Media row id for image',
		description: 'E.g. 1010, 1011. Media row id 1010 (front image) will be used if the other row ids do not match any image.',
		default: '1010'
	}, node && node.props.attribute === 'attr2' && node.props.showAs === 'sizeRange' && {
		name: 'regexToRemoveFromSize',
		inputType: 'text',
		label: 'Regex pattern which will be removed from size range',
		default: ''
	}, node && node.props.showAs === 'dropdown' && {
		name: 'fewInStockMessage',
		inputType: 'text',
		label: 'Few in stock message',
		default: ''
	}, node && node.props.showAs === 'dropdown' && {
		name: 'fewInStockThreshold',
		inputType: 'text',
		label: 'Few in stock threshold',
		description: 'If the stock balance is less than the entered value the few in stock message is displayed',
		default: ''
	}, {
		name: 'outOfStockMessage',
		inputType: 'text',
		label: 'Out of stock message',
		default: ''
	}, ...conditionallyAddConfig(node, 'showAs', 'checkbox', checkboxConfig), ...conditionallyAddConfig(node, 'showAs', 'image', imageConfig), {
		name: 'disableOutOfStock',
		inputType: 'checkbox',
		label: 'Disable out of stock options',
		default: false
	}, {
		name: 'checkBalance',
		inputType: 'checkbox',
		label: 'Remove out of stock options',
		default: false
	}, {
		name: 'showStoreBalance',
		inputType: 'checkbox',
		label: 'Show store balance if out of stock',
		default: false
	}, node && node.props.showStoreBalance && {
		name: 'showStoreBalanceText',
		inputType: 'text',
		label: 'Store balance text',
		description: 'Text that will be shown in the attribute picker if it\'s only available in store',
		default: 'Only in store'
	}, {
		name: 'hideIfOne',
		inputType: 'checkbox',
		label: 'Hide attribute if there is only one option',
		default: false
	}, {
		name: 'priceDifference',
		inputType: 'checkbox',
		label: 'Show difference in price',
		default: false
	}, {
		name: 'labelAttribute',
		inputType: 'checkbox',
		label: 'Show current SKU name in label',
		default: false
	}, {
		name: 'attributeBelow',
		inputType: 'checkbox',
		label: 'Show current SKU name below the option',
		default: true
	}, {
		name: 'showAllSkuName',
		inputType: 'checkbox',
		label: 'Show all SKU names below the option',
		default: false
	}, node && node.props.attribute === 'attr2' && {
		name: 'disableIncomingText',
		inputType: 'checkbox',
		label: 'Hide incoming text for attribute',
		description: 'Incoming text is showing when an article has attribute incomingInStock',
		default: false
	}, {
		name: 'scrollToTopOnAttributeChange',
		inputType: 'checkbox',
		label: 'Scroll to top on change',
		description: 'When enabled, scroll to top of page when you click to change attribute',
		default: false
	}, {
		name: 'addToCartOnClick',
		inputType: 'checkbox',
		label: 'Add to cart on click',
		description: 'When enabled, the article will be added to cart when you click on an attribute',
		default: false
	}].filter(Boolean),
	hiddenProps: [
		...conditionallyAddConfig(node, 'showAsShowMore', true, showMoreSetting), ...conditionallyAddConfig(node, 'showAsSlider', true, sliderConfig), ...conditionallyAddConfig(node, 'sliderArrows', true, sliderArrowConfig(node)), {
			label: 'Advanced settings',
			props: [{
				name: 'useGlobalSettings',
				inputType: 'checkbox',
				label: 'Use global settings for size and attribute 3 match',
				description: 'Change global settings under the site settings menu',
				default: false
			}, node && !node.props.useGlobalSettings && {
				name: 'isSizeMatchRequired',
				inputType: 'checkbox',
				label: 'Show images for selected size only',
				default: ''
			}, node && !node.props.useGlobalSettings && {
				name: 'isAttr3MatchRequired',
				inputType: 'checkbox',
				label: 'Show images for selected attribute 3 only',
				default: ''
			}, {
				name: 'class',
				inputType: 'text',
				label: 'CSS-class',
				default: ''
			}].filter(Boolean)
		}
	]
});
