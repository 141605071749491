import {createCmsComponent} from '@viskan/cms-component-utilities';
import {deepEqual} from 'fast-equals';

let isFetching;
let hasFetched = [];
const isEmptyObject = data => !Object.keys(data).length;
const containsCurrentCategory = (data, key) => Object.prototype.hasOwnProperty.call(data, key);

const getUrl = (router, articleUrlPrefix) => {
	if (articleUrlPrefix && router.pathname.includes(articleUrlPrefix)) {
		const attr1Id = router.query.attr1_id ? `attr1_id=${router.query.attr1_id}` : 'attr1_id=0';

		return `${window.location.origin}${window.location.pathname}?${attr1Id}`;
	}

	return `${window.location.origin}${window.location.pathname}`;
};

const getTrackingUserId = (setState, retries = 0) => {
	if (window.ADDWISH_PARTNER_NS) {
		window.ADDWISH_PARTNER_NS.api.user.get_tracking_id(trackingUserId => {
			setState({trackingUserId});
		});

		return;
	}

	if (retries === 15) {
		return;
	}

	setTimeout(() => getTrackingUserId(setState, retries + 1), 100);
};

const createRecommendationIdsString = recommendationIds => Object.entries(recommendationIds).map(([, value]) => value.recommendationId).join('');

const getRecommendationIds = (setRecommendationIds, retries = 0) => {
	const elements = document.querySelectorAll('.CMS-HelloRetail');

	if (elements && elements.length !== 0) {
		setRecommendationIds(Array.prototype.map.call(elements, element => element && element.dataset && element.dataset.recommendationId));
		return;
	}

	if (retries === 4) {
		return;
	}

	setTimeout(() => getRecommendationIds(setRecommendationIds, retries + 1), 200);
};

const afterMount = ({props}, el, setState) => {
	requestAnimationFrame(() => {
		getTrackingUserId(setState);
	});
};

const afterRender = ({props}) => {
	requestAnimationFrame(() => {
		getRecommendationIds(props.setRecommendationIds);
	});
};

const afterUpdate = ({props, state}, prevProps) => {
	if (props.router.pathname !== prevProps.router.pathname || !deepEqual(props.shopcartRows, prevProps.shopcartRows)) {
		hasFetched = [];

		requestAnimationFrame(() => getRecommendationIds(props.setRecommendationIds));
		return;
	}

	const recommendationIdsString = createRecommendationIdsString(props.recommendationIds);
	const validHostNames = ['localhost', 'stage.viskan.com', 'prodtest.viskan.com'];

	if ((state.trackingUserId || validHostNames.some(name => window.location.host.includes(name))) && ((isFetching !== recommendationIdsString && !hasFetched.includes(recommendationIdsString)) || prevProps.categoryId !== props.categoryId || prevProps.breadcrumbs.data[props.categoryId] !== props.breadcrumbs.data[props.categoryId]) && !isEmptyObject(props.recommendationIds)) {
		const hasShopcartRows = props.shopcartRows && props.shopcartRows.length !== 0;
		const shopcartUrls = hasShopcartRows ? props.shopcartRows.map(x => `${window.location.origin}/${props.articleUrlPrefix}/${x.link_friendly_name}?attr1_id=${x.attr1_id}`) : [];

		if (props.router.pathname !== '/' && props.router.pathname !== '/404/' && !isEmptyObject(props.breadcrumbs.data)) {
			const url = getUrl(props.router, props.articleUrlPrefix);
			const hierarchies = containsCurrentCategory(props.breadcrumbs.data, props.categoryId) ? props.breadcrumbs.data[props.categoryId] : [];
			isFetching = recommendationIdsString;

			return props.recommendationsFetch(props.recommendationIds, {
				url,
				userId: state.trackingUserId,
				hierarchies,
				shopcartUrls
			})
				.then(() => {
					isFetching = '';
					hasFetched.push(recommendationIdsString);
				});
		}

		if (props.router.pathname === '/' || props.router.pathname === '/404/') {
			const url = getUrl(props.router);
			const hierarchies = [];
			isFetching = recommendationIdsString;

			return props.recommendationsFetch(props.recommendationIds, {
				url,
				userId: state.trackingUserId,
				hierarchies,
				shopcartUrls
			})
				.then(() => {
					isFetching = '';
					hasFetched.push(recommendationIdsString);
				});
		}
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);
const render = ({props}) => createCmsComponent(<noscript class='CMS-HelloRetailFetcher'/>, props);

export default {afterMount, afterUpdate, render, shouldUpdate, afterRender};
