import {connect} from '@viskan/deku-redux';
import Component from './components';
import config from './config';

const component = connect(
	state => ({
		breakpoint: state.breakpoint.breakpointOverride || state.breakpoint.breakpoint,
		cmsLoaded: state.streamlineCms.loaded,
		favoritesArtNosAndAttr1Ids: state.articles.favorites.artNosAndAttr1Ids,
		recommendationIds: state.helloRetail.recommendationIds
	})
)(Component);

export default {component, config};
