export default node => `
	<Tabs>
		<Tab heading="General">
			<Grid>
				<Cell size="full">
					<Field name="layout"/>
				</Cell>
				<Cell size="full">
					<Field name="summaryHeading"/>
				</Cell>
				<Cell size="full">
					<Field name="groupNumber"/>
				</Cell>
				<Cell size="full">
					<Field name="showImage"/>
				</Cell>
				<Cell size="full">
					<Field name="imageScale"/>
				</Cell>
				<Cell size="full">
					<Field name="showSalesPrice"/>
				</Cell>
				<Cell size="full">
					<Field name="showOrgPrice"/>
				</Cell>
				<Cell size="full">
					<Field name="showColor"/>
				</Cell>
				<Cell size="full">
					<Field name="showSize"/>
				</Cell>
				<Cell size="full">
					<Field name="showAttribute3"/>
				</Cell>
				<Cell size="full">
					<Field name="showPersonalizationText"/>
				</Cell>
				<Cell size="full">
					<Field name="iconType"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Single and multi select" hidden="${node && (node.props.layout !== 'singleSelect' && node.props.layout !== 'multiSelect')}">
			<Grid>
				<Cell size="full">
					<Field name="activeButtonText"/>
				</Cell>
				<Cell size="full">
					<Field name="inactiveButtonText"/>
				</Cell>
				<Cell size="full">
					<Field name="iconActive"/>
				</Cell>
				<Cell size="full">
					<Field name="iconActiveSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconActiveColor"/>
				</Cell>
				<Cell size="full">
					<Field name="iconInactive"/>
				</Cell>
				<Cell size="full">
					<Field name="iconInactiveSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconInactiveColor"/>
				</Cell>
				<Cell size="full">
					<Field name="imageActive"/>
				</Cell>
				<Cell size="full">
					<Field name="imageInactive"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectOpen"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectOpenSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectOpenColor"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectClosed"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectClosedSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectClosedColor"/>
				</Cell>
				<Cell size="full">
					<Field name="imageSelectOpen"/>
				</Cell>
				<Cell size="full">
					<Field name="imageSelectClosed"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Text layout" hidden="${node && node.props.layout !== 'text'}">
			<Grid>
				<Cell size="full">
					<Field name="isRequired"/>
				</Cell>
				<Cell size="full">
					<Field name="isRequiredText"/>
				</Cell>
				<Cell size="full">
					<Field name="xsm"/>
				</Cell>
				<Cell size="full">
					<Field name="sm"/>
				</Cell>
				<Cell size="full">
					<Field name="md"/>
				</Cell>
				<Cell size="full">
					<Field name="lg"/>
				</Cell>
				<Cell size="full">
					<Field name="xlg"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectOpen"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectOpenSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectOpenColor"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectClosed"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectClosedSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconSelectClosedColor"/>
				</Cell>
				<Cell size="full">
					<Field name="imageSelectOpen"/>
				</Cell>
				<Cell size="full">
					<Field name="imageSelectClosed"/>
				</Cell>
				<Cell size="full">
					<Field name="iconReset"/>
				</Cell>
				<Cell size="full">
					<Field name="iconResetSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconResetColor"/>
				</Cell>
				<Cell size="full">
					<Field name="imageReset"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Toggler">
			<Grid>
				<Cell size="full">
					<Field name="showToggler"/>
				</Cell>
				<Cell size="full">
					<Field name="initialNumberOfAddons"/>
				</Cell>
				<Cell size="full">
					<Field name="startWithOpenToggler"/>
				</Cell>
				<Cell size="full">
					<Field name="showMoreButtonText"/>
				</Cell>
				<Cell size="full">
					<Field name="showLessButtonText"/>
				</Cell>
				<Cell size="full">
					<Field name="iconToggleOpen"/>
				</Cell>
				<Cell size="full">
					<Field name="iconToggleOpenSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconToggleOpenColor"/>
				</Cell>
				<Cell size="full">
					<Field name="iconToggleClosed"/>
				</Cell>
				<Cell size="full">
					<Field name="iconToggleClosedSize"/>
				</Cell>
				<Cell size="full">
					<Field name="iconToggleClosedColor"/>
				</Cell>
				<Cell size="full">
					<Field name="imageToggleOpen"/>
				</Cell>
				<Cell size="full">
					<Field name="imageToggleClosed"/>
				</Cell>
			</Grid>
		</Tab>
	</Tabs>
`;
