export default node => ({
	type: 'ARTICLE_BUY_BUTTON',
	name: 'Buy button',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'buttonText',
		inputType: 'text',
		label: 'Text',
		default: 'Add to cart'
	}, {
		/* START CUSTOMER OVERRIDE */
		name: 'haveNotAddedAllRequiredAddonsText',
		inputType: 'text',
		label: 'Text when user has not added all required addons',
		default: 'You have to add all addons to be able to add to cart'
		/* END CUSTOMER OVERRIDE */
	}, {
		name: 'goToCheckout',
		inputType: 'checkbox',
		label: 'Go to checkout after add',
		default: false
	}, {
		name: 'addSubscription',
		inputType: 'checkbox',
		label: 'Add selected subscription',
		description: 'Requires that the purchase layout setting in the Subscription component is set to "Use buy button component"',
		default: false
	}, {
		name: 'disableBuyButton',
		inputType: 'checkbox',
		label: 'Disable buy button after subscription has been added to cart',
		default: false
	}, {
		name: 'requireAttributeSelectionMessage',
		description: 'Message to display when the user tries to add an article to the cart without selecting all required attributes',
		inputType: 'text',
		label: 'Require attribute selection message',
		default: 'Please select all required attributes'
	}, {
		name: 'showBuyButtonIfNoAttribute',
		label: 'Show buy button if required attribute has not been selected',
		inputType: 'checkbox',
		description: 'Will show a disabled buy button if enabled',
		default: false
	}, node && node.props.showBuyButtonIfNoAttribute && {
		name: 'requireAttributeButtonText',
		inputType: 'text',
		label: 'Button text if attribute has not been selected',
		default: ''
	}, node && node.props.showBuyButtonIfNoAttribute && {
		name: 'requireAttributeErrorMessage',
		inputType: 'text',
		label: 'Error message if attribute has not been selected',
		default: ''
	}, {
		name: 'closeQuickcartAfterAdd',
		inputType: 'checkbox',
		label: 'Close quickcart after add.',
		description: 'Will close the quickcart after the user has added an article to the cart',
		default: false
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Out of stock settings',
		props: [{
			name: 'outOfStockButtonText',
			inputType: 'text',
			label: 'Text',
			default: 'Out of stock'
		}, {
			name: 'outOfStockBackgroundColor',
			inputType: 'color',
			label: 'Button background color',
			default: '#777'
		}, {
			name: 'outOfStockColor',
			inputType: 'color',
			label: 'Text color',
			default: '#fff'
		}, {
			name: 'hideOutOfStockButton',
			inputType: 'checkbox',
			label: 'Hide button',
			default: false
		}, {
			name: 'outOfStockMessage',
			inputType: 'text',
			label: 'Out of stock message',
			default: ''
		}, {
			name: 'enableIncomingInStock',
			inputType: 'checkbox',
			label: 'Enable incoming in stock',
			description: 'When article have attribute incoming the buy button will be disabled and display the text selected in next option',
			default: false
		}, {
			name: 'incomingInStockText',
			inputType: 'text',
			label: 'Text for incoming in stock',
			default: 'Coming soon'
		}, {
			name: 'showNotifyButton',
			inputType: 'checkbox',
			label: 'Show notify button when out of stock',
			description: 'The notify button will be shown instead of the buy button. It will trigger a slideout that has the On Action setting "Article out of stock".',
			default: false
		}, node && node.props.showNotifyButton && {
			name: 'notifyButtonText',
			inputType: 'text',
			label: 'Notify button text',
			default: ''
		}].filter(Boolean),
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
