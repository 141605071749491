import {
	ARTICLES_CLEAR_COMPONENT,
	ARTICLES_FAVORITES_ADD_FULFILLED,
	ARTICLES_FAVORITES_ADD_PENDING,
	ARTICLES_FAVORITES_ADD_REJECTED,
	ARTICLES_FAVORITES_FETCH_FULFILLED,
	ARTICLES_FAVORITES_FETCH_PENDING,
	ARTICLES_FAVORITES_FETCH_REJECTED,
	ARTICLES_FAVORITES_REMOVE_FULFILLED,
	ARTICLES_FAVORITES_REMOVE_PENDING,
	ARTICLES_FAVORITES_REMOVE_REJECTED,
	ARTICLES_FETCH_FULFILLED,
	ARTICLES_FETCH_PENDING,
	ARTICLES_FETCH_REJECTED,
	ARTICLES_FILTERS_RESET,
	ARTICLES_GO_TO_PAGE_FULFILLED,
	ARTICLES_INLINE_QUICKBUY_CLOSE,
	ARTICLES_INLINE_QUICKBUY_OPEN,
	ARTICLES_PAGINATION_NEXT,
	ARTICLES_PAGINATION_PREVIOUS,
	ARTICLES_SET_ACTIVE_FILTERS,
	ARTICLES_SET_COLUMN_SIZE,
	ARTICLES_SET_VIEW,
	ARTICLES_SORT,
	ARTICLES_RESET_COMPONENT,
	PATH_CHANGE,
	ARTICLES_IN_VIEW
} from '~/action-types';
import {sanitizeAttr1IdKeyValue, omit, persistedState} from '~/utils';
import {saveScrollMemoryData} from '~/scroll-memory/actions';
import {articleIsFavorite} from '../article/favorite/actions';

export const initialState = {
	activeFilters: {
		apiInput: {},
		curr: {},
		prev: {}
	},
	category: {
		categoryBlurbs: []
	},
	clearedComponents: [],
	components: {},
	currentFilters: {},
	favorites: {
		saved: persistedState.saved || false,
		articles: [],
		artNosAndAttr1Ids: persistedState.artNosAndAttr1Ids || [],
		numberOfSavedFavorites: persistedState.numberOfSavedFavorites || 0
	},
	fetchedCategoryId: -1,
	fetchedSearchQuery: '',
	filters: {},
	initialPreviousPage: null,
	inlineQuickbuy: {
		open: false,
		content: {}
	},
	componentsLoading: [],
	nextPageQuery: null,
	key: 'link_friendly_name',
	loadPrevious: false,
	selectedColumnSize: undefined,
	selectedFilters: [],
	sort: [],
	total: 0,
	viewActive: false,
	viewActiveImage: '1010',
	viewActiveImageOnHover: '-1',
	inView: {}
};

const sortFavorites = (favorites, artNosAndAttr1Ids) => {
	const arrSortBy = artNosAndAttr1Ids && artNosAndAttr1Ids.map(favorite => favorite.artNo + String(favorite.attr1Id));

	return [...favorites.hits].sort((a, b) => {
		return arrSortBy.indexOf(a.art_no + String(sanitizeAttr1IdKeyValue(a))) - arrSortBy.indexOf(b.art_no + String(sanitizeAttr1IdKeyValue(b)));
	});
};

const getNumberOfFavorites = (favoritesArtNosAndAttr1Ids, articles) => {
	return articles.filter(article => {
		return articleIsFavorite(favoritesArtNosAndAttr1Ids, article.art_no, article.attr1_id);
	}).length;
};

const removeArticlesDuplicates = articles => {
	return articles.filter((value, index, self) => index === self.findIndex(item => (item.art_no === value.art_no && item.attr1_id === value.attr1_id)));
};

export default (state = initialState, action) => {
	const {type, meta = {}, payload, sort} = action;

	switch (type) {
		case ARTICLES_RESET_COMPONENT: {
			const {key, component} = payload;

			return {
				...state,
				components: {
					...state.components,
					[key]: {
						...component
					}
				},
				clearedComponents: state.clearedComponents.filter(id => id !== key)
			};
		}
		case ARTICLES_CLEAR_COMPONENT: {
			delete state.components[payload];
			delete state.inView[payload];

			return {
				...state,
				componentsLoading: state.componentsLoading.filter(id => id !== payload),
				clearedComponents: !state.clearedComponents.includes(payload) ? [
					...state.clearedComponents,
					payload
				] : state.clearedComponents
			};
		}
		case ARTICLES_FAVORITES_ADD_PENDING:
		case ARTICLES_FAVORITES_FETCH_PENDING:
		case ARTICLES_FAVORITES_REMOVE_PENDING:
		case ARTICLES_FETCH_PENDING: {
			if (meta.options && meta.options.id) {
				return {
					...state,
					componentsLoading: [
						...state.componentsLoading,
						meta.options.id
					]
				};
			}

			return state;
		}
		case ARTICLES_FETCH_FULFILLED: {
			if (!state.componentsLoading.includes(payload.id)) {
				return state;
			}

			const component = state.components[payload.id] || [];
			const hasFetchedCategoryId = component.fetchedCategoryId === payload.categoryId;

			const mergeFiltersForMultipleComponents = () => {
				const filters = {};
				const components = {
					...state.components,
					[payload.id]: {
						...component,
						isFiltered: payload.isFiltered,
						aggregations: payload.aggregations
					}
				};

				Object.values(components).forEach(component => {
					Object.entries(component.aggregations).forEach(([key, value]) => {
						if (key in filters) {
							if (key === 'lowest_price_sales') {
								if (filters[key].min > value.min) {
									filters[key].min = value.min;
								}

								if (filters[key].max < value.max) {
									filters[key].max = value.max;
								}

								filters[key].avg = (filters[key].min + filters[key].max) / 2;

								return;
							}

							if (value.buckets) {
								value.buckets.forEach(bucket => {
									const existingBuckets = filters[key].buckets;
									const existingBucketIndex = existingBuckets ? existingBuckets.findIndex(existingBucket => existingBucket.key === bucket.key) : -1;

									if (existingBucketIndex !== -1 && !component.isFiltered && !hasFetchedCategoryId) {
										existingBuckets[existingBucketIndex].count += bucket.count;

										return;
									}

									if (existingBucketIndex === -1) {
										filters[key].buckets.push(bucket);
									}
								});
							}
						} else {
							filters[key] = value;
						}
					});
				});

				return filters;
			};

			const getTotalAmountOfArticles = () => {
				let totalAmountOfArticles = 0;

				const components = {
					...state.components,
					[payload.id]: {
						total: payload.total
					}
				};

				Object.values(components).forEach(component => {
					totalAmountOfArticles += parseInt(component.total, 10);
				});

				return totalAmountOfArticles;
			};

			const mergedFilters = mergeFiltersForMultipleComponents();
			const totalAmountOfArticles = getTotalAmountOfArticles();
			const componentsLoading = state.componentsLoading.filter(id => id !== payload.id);

			if (state.fetchedSearchQuery !== payload.searchQuery || !hasFetchedCategoryId) {
				const newState = {
					...initialState,
					components: {
						...state.components,
						[payload.id]: {
							aggregations: payload.aggregations,
							articles: payload.articles,
							fetchedCategoryId: payload.categoryId,
							from: payload.from,
							isFiltered: payload.isFiltered,
							meta: payload.meta,
							total: payload.total,
						}
					},
					currentFilters: mergedFilters,
					clearedComponents: state.clearedComponents.filter(id => id !== payload.id),
					favorites: {
						...state.favorites
					},
					fetchedSearchQuery: payload.searchQuery,
					filters: mergedFilters,
					initialPreviousPage: !state.initialPreviousPage ? payload.pageQuery : state.initialPreviousPage,
					selectedColumnSize: state.selectedColumnSize,
					total: totalAmountOfArticles,
					viewActive: state.viewActive,
					viewActiveImage: state.viewActiveImage,
					viewActiveImageOnHover: state.viewActiveImageOnHover,
					componentsLoading,
					inView: {
						...state.inView,
						[payload.id]: {
							inView: Object.keys(state.components).length < 1
						}
					}
				};

				saveScrollMemoryData('articlesData', {
					...newState,
					sort: payload.sort
				});

				return newState;
			}

			const newState = {
				...state,
				activeFilters: {
					...state.activeFilters,
					prev: state.activeFilters.curr,
				},
				components: {
					...state.components,
					[payload.id]: {
						...component,
						aggregations: payload.aggregations,
						articles: state.loadPrevious ? payload.articles.concat(component.articles) : (payload.from === 0 || payload.paginationType === 'pagination' ? payload.articles : removeArticlesDuplicates([...component.articles, ...payload.articles])),
						from: payload.from,
						isFiltered: payload.isFiltered,
						total: payload.total,
						meta: payload.meta,
						fetchedCategoryId: payload.categoryId
					}
				},
				currentFilters: payload.from === 0 ? mergedFilters : state.currentFilters,
				initialPreviousPage: !state.initialPreviousPage ? payload.pageQuery : state.initialPreviousPage,
				fetchedSearchQuery: payload.searchQuery,
				total: totalAmountOfArticles,
				componentsLoading,
				clearedComponents: state.clearedComponents.filter(id => id !== payload.id)
			};

			saveScrollMemoryData('articlesData', {
				...newState,
				sort: payload.sort
			});

			return newState;
		}
		case ARTICLES_FAVORITES_ADD_REJECTED:
		case ARTICLES_FAVORITES_FETCH_REJECTED:
		case ARTICLES_FAVORITES_REMOVE_REJECTED:
		case ARTICLES_FETCH_REJECTED: {
			const componentsLoading = state.componentsLoading.filter(id => id !== payload.id);

			return {
				...state,
				componentsLoading
			};
		}
		case ARTICLES_FAVORITES_REMOVE_FULFILLED: {
			const newStateFavorites = {
				articles: state.favorites.articles.filter(favorite => favorite.art_no + String(sanitizeAttr1IdKeyValue(favorite)) !== payload.artNo + String(payload.attr1Id)),
				artNosAndAttr1Ids: state.favorites.artNosAndAttr1Ids.filter(favorite => favorite.artNo + String(favorite.attr1Id) !== payload.artNo + String(payload.attr1Id))
			};

			return {
				...state,
				favorites: {
					...newStateFavorites,
					saved: newStateFavorites.articles.length > 0 && newStateFavorites.artNosAndAttr1Ids.length > 0,
					numberOfSavedFavorites: getNumberOfFavorites(newStateFavorites.artNosAndAttr1Ids, newStateFavorites.articles)
				}
			};
		}
		case ARTICLES_FAVORITES_ADD_FULFILLED: {
			return {
				...state,
				favorites: {
					articles: state.favorites.articles,
					artNosAndAttr1Ids: state.favorites.artNosAndAttr1Ids.some(favorite => favorite.artNo === payload.artNo && favorite.attr1Id === payload.attr1Id) ? state.favorites.artNosAndAttr1Ids : [{...payload}, ...state.favorites.artNosAndAttr1Ids],
					saved: state.favorites.saved
				}
			};
		}
		case ARTICLES_FAVORITES_FETCH_FULFILLED: {
			const newStateFavorites = {
				articles: payload.articles.hits && payload.articles.hits.length > 1 ? sortFavorites(payload.articles, state.favorites.artNosAndAttr1Ids) : payload.articles.hits,
				artNosAndAttr1Ids: state.favorites.artNosAndAttr1Ids
			};

			return {
				...state,
				favorites: {
					...newStateFavorites,
					saved: newStateFavorites.articles.length > 0 && newStateFavorites.artNosAndAttr1Ids.length > 0,
					numberOfSavedFavorites: getNumberOfFavorites(newStateFavorites.artNosAndAttr1Ids, newStateFavorites.articles)
				}
			};
		}
		case ARTICLES_FILTERS_RESET: {
			return {
				...state,
				activeFilters: initialState.activeFilters,
				loadPrevious: false,
				selectedFilters: initialState.selectedFilters
			};
		}
		case ARTICLES_SET_ACTIVE_FILTERS: {
			const isPriceSlider = meta.key === 'lowest_price_sales';
			const isBoxFilter = !isPriceSlider;

			const getActiveFilters = () => {
				if (isPriceSlider) {
					return {
						apiInput: {
							...state.activeFilters.apiInput,
							lowest_price_sales: payload.apiInput.lowest_price_sales
						},
						curr: {
							...state.activeFilters.curr,
							lowest_price_sales: payload.curr.lowest_price_sales
						}
					};
				}

				if (isBoxFilter) {
					return {
						apiInput: payload.apiInput,
						curr: payload.curr,
						prev: state.activeFilters.curr
					};
				}
			};

			const getSelectedFilters = () => {
				if (isPriceSlider) {
					return state.selectedFilters;
				}

				if (isBoxFilter) {
					const boxFilters = {
						apiInput: omit(payload.apiInput, ['lowest_price_sales']),
						curr: omit(payload.curr, ['lowest_price_sales'])
					};

					const boxFilterKeys = Object.keys(boxFilters.curr);

					return boxFilterKeys.reduce((acc, key) => {
						const filter = boxFilters.curr[key];
						acc = acc.concat(filter);
						return acc;
					}, []);
				}
			};

			return {
				...state,
				activeFilters: getActiveFilters(),
				from: 0,
				loadPrevious: initialState.loadPrevious,
				nextPageQuery: initialState.nextPageQuery,
				initialPreviousPage: initialState.initialPreviousPage,
				selectedFilters: getSelectedFilters()
			};
		}
		case ARTICLES_PAGINATION_PREVIOUS: {
			const component = state.components[payload];
			const componentIsLoading = state.componentsLoading.find(id => id === payload);

			if (componentIsLoading || component.articles.length === component.total || state.initialPreviousPage <= 1) {
				return state;
			}

			return {
				...state,
				components: {
					...state.components,
					[payload]: {
						...component,
						from: component.from + payload.size
					}
				},
				loadPrevious: true,
				initialPreviousPage: state.initialPreviousPage - 1
			};
		}
		case ARTICLES_PAGINATION_NEXT: {
			const component = state.components[payload.id];
			const componentIsLoading = state.componentsLoading.find(id => id === payload.id);

			if (!component || componentIsLoading || component.articles.length === component.total) {
				return state;
			}

			return {
				...state,
				components: {
					...state.components,
					[payload.id]: {
						...component,
						from: component.from + payload.size,
						loadPrevious: false
					}
				}
			};
		}
		case ARTICLES_GO_TO_PAGE_FULFILLED: {
			const component = state.components[payload.id];
			const componentIsLoading = state.componentsLoading.find(id => id === payload.id);

			if (componentIsLoading) {
				return state;
			}

			return {
				...state,
				nextPageQuery: payload.newPage,
				components: {
					...state.components,
					[payload.id]: {
						...component
					}
				},
				componentsLoading: state.componentsLoading.filter(id => id !== payload.id),
				loadPrevious: false
			};
		}
		case ARTICLES_SET_COLUMN_SIZE: {
			return {
				...state,
				selectedColumnSize: payload
			};
		}
		case ARTICLES_SET_VIEW: {
			return {
				...state,
				viewActive: payload.viewActive,
				viewActiveImage: payload.viewActiveImage,
				viewActiveImageOnHover: payload.viewActiveImageOnHover
			};
		}
		case ARTICLES_SORT: {
			return {
				...state,
				from: initialState.from,
				loadPrevious: initialState.loadPrevious,
				nextPageQuery: initialState.nextPageQuery,
				initialPreviousPage: initialState.initialPreviousPage,
				sort
			};
		}
		case ARTICLES_INLINE_QUICKBUY_OPEN: {
			return {
				...state,
				inlineQuickbuy: {
					content: action.payload,
					open: true
				}
			};
		}
		case ARTICLES_INLINE_QUICKBUY_CLOSE: {
			return {
				...state,
				inlineQuickbuy: {
					open: false,
					content: {}
				}
			};
		}
		case PATH_CHANGE: {
			return {
				...state,
				loadPrevious: false
			};
		}
		case ARTICLES_IN_VIEW: {
			return {
				...state,
				inView: {
					...state.inView,
					[payload.id]: {
						inView: payload.inView
					}
				},
			};
		}
		default: {
			return state;
		}
	}
};
