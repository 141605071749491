export default node => ({
	type: 'CATEGORY_CONTENT_SEARCH',
	name: 'Content search',
	allowedSections: ['main', 'header'],
	icon: 'fal fa-search',
	category: 'Search',
	disallowedChildren: ['*'],
	styleClasses: {
		Wrapper: '',
		Load_More_Button: 'Preset-Primary-Button',
		No_Search_Results_Wrapper: '',
		No_Search_Results_Heading: '',
		No_Search_Results_Text: '',
		Number_Of_Results: '',
		Progress_Bar_Wrapper: '',
		Progress_Bar_Text: '',
		Progress_Bar: '',
		Progress_Bar_Filler: '',
		Search_Results: '',
		Search_Results_Heading: 'Preset-Heading-Font-Family Preset-Heading-Color',
		Search_Results_Text: '',
		Search_Result: '',
		Search_Result_Category_Name: 'Preset-Heading-Font-Family Preset-Heading-Color',
		Search_Result_Category_Content: '',
		Search_Result_Highlight: '',
		Search_Result_Read_More: 'Preset-Link-Color'
	},
	props: [{
		name: 'showNumberOfResults',
		inputType: 'checkbox',
		label: 'Show total number of results',
		description: 'You can change the text under translations',
		default: true
	}, {
		name: 'showSearchResultsHeading',
		inputType: 'checkbox',
		label: 'Show search results heading',
		default: true
	}, node && node.props.showSearchResultsHeading && {
		name: 'searchResultsHeadingPrefix',
		inputType: 'text',
		label: 'Search results heading prefix',
		default: 'Results for: '
	}, node && node.props.showSearchResultsHeading && {
		name: 'searchResultsHeadingSuffix',
		inputType: 'text',
		label: 'Search results heading suffix',
		default: ''
	}, node && node.props.showSearchResultsHeading && {
		name: 'searchResultsHeadingElement',
		inputType: 'select',
		label: 'Search results heading element type',
		default: 'h1',
		values: ['h1', 'h2', 'h3', 'h4', 'h5']
	}, {
		name: 'noSearchResultsHeading',
		inputType: 'text',
		label: 'Heading for no search results',
		default: 'No search results found for: '
	}, {
		name: 'noSearchResultsText',
		inputType: 'text',
		label: 'Text for no search results',
		default: 'We could not find any results matching your query. Please try rephrasing.'
	}, {
		name: 'showCategoryName',
		inputType: 'checkbox',
		label: 'Show category name',
		default: true
	}, {
		name: 'showCategoryContent',
		inputType: 'checkbox',
		label: 'Show category content',
		description: 'Shows a snippet of the matching text containing the search query',
		default: true
	}, {
		name: 'showReadMoreLink',
		inputType: 'checkbox',
		label: 'Show read more link',
		default: true
	}, node && node.props.showReadMoreLink && {
		name: 'readMoreLinkText',
		inputType: 'text',
		label: 'Read more link text',
		default: 'Read more'
	}, {
		name: 'linkWrap',
		inputType: 'checkbox',
		label: 'Wrap the search result in a link',
		default: false
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Pagination',
		props: [{
			name: 'size',
			inputType: 'slider',
			label: 'Number of results to load',
			default: 20,
			min: 1,
			max: 50
		}, {
			name: 'paginationType',
			inputType: 'select',
			label: 'Pagination type',
			default: 'none',
			values: [{
				label: 'Load more button',
				value: 'loadMore'
			}, {
				label: 'None',
				value: 'none'
			}]
		}, node && node.props.paginationType === 'loadMore' && {
			name: 'showProgressBar',
			inputType: 'checkbox',
			label: 'Show progress bar (for amount of results viewed)',
			description: 'You can change the text under translations',
			default: false
		}, node && node.props.paginationType === 'loadMore' && {
			name: 'loadMoreButtonText',
			inputType: 'text',
			label: 'Load more button text',
			default: 'Load more'
		}].filter(Boolean)
	}].filter(Boolean)
});
