import store from 'store2';
import {SHOPCART_ADD_PENDING, STORE_ARTICLE_FETCH_PENDING, STORE_ARTICLE_FETCH_FULFILLED} from '../action-types';

const initialState = {
	pendingRequests: [],
	articleData: {},
	subscriptionData: store('vs-subscription-data') || {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOPCART_ADD_PENDING: {
			if (action.meta && action.meta.data) {
				return {
					...state,
					articleData: {
						...state.articleData,
						[action.meta.data.art_id]: action.meta.data
					}
				};
			}

			if (action.meta && action.meta.subscription) {
				if (Array.isArray(action.meta.subscription)) {
					action.meta.subscription.forEach(subscription => {
						delete subscription.article;
					});
				}

				const newState = {
					...state,
					subscriptionData: {
						...state.subscriptionData,
						[action.meta.subscription.prentype_id]: action.meta.subscription
					}
				};

				store('vs-subscription-data', newState.subscriptionData);

				return newState;
			}

			return state;
		}
		case STORE_ARTICLE_FETCH_FULFILLED: {
			return {
				...state,
				articleData: {
					...state.articleData,
					[action.payload.art_id]: action.payload
				},
				pendingRequests: state.pendingRequests.filter(request => request !== action.meta.query)
			};
		}
		case STORE_ARTICLE_FETCH_PENDING: {
			return {
				...state,
				pendingRequests: [...state.pendingRequests, action.meta.query]
			};
		}
		default: {
			return state;
		}
	}
};
