import {conditionallyAddConfig} from '~/utils';

const selectIconConfig = [{
	name: 'iconSelectOpen',
	inputType: 'icon',
	label: 'Choose icon when select list is open',
	default: '{"prefix":"fal","x":{"name":"chevron-up"}}'
}, {
	name: 'iconSelectOpenSize',
	inputType: 'text',
	label: 'Choose size on open icon',
	default: '1em'
}, {
	name: 'iconSelectOpenColor',
	inputType: 'color',
	label: 'Choose color on open icon',
	default: '#000'
}, {
	name: 'iconSelectClosed',
	inputType: 'icon',
	label: 'Choose icon when select list is closed',
	default: '{"prefix":"fal","x":{"name":"chevron-down"}}'
}, {
	name: 'iconSelectClosedSize',
	inputType: 'text',
	label: 'Choose size on closed icon',
	default: '1em'
}, {
	name: 'iconSelectClosedColor',
	inputType: 'color',
	label: 'Choose color on closed icon',
	default: '#000'
}];

const selectImageConfig = [{
	name: 'imageSelectOpen',
	inputType: 'media-v2',
	label: 'Choose image when select list is open',
	default: ''
}, {
	name: 'imageSelectClosed',
	inputType: 'media-v2',
	label: 'Choose image when select list is closed',
	default: ''
}];

const quantityIconConfig = [{
	name: 'iconQuantityIncrease',
	inputType: 'icon',
	label: 'Increase quantity icon',
	default: '{"prefix":"fal","x":{"name":"plus"}}'
}, {
	name: 'iconQuantityIncreaseSize',
	inputType: 'text',
	label: 'Increase quantity icon size',
	default: '1em'
}, {
	name: 'iconQuantityIncreaseColor',
	inputType: 'color',
	label: 'Increase quantity icon color',
	default: '#000'
}, {
	name: 'iconQuantityDecrease',
	inputType: 'icon',
	label: 'Decrease quantity icon',
	default: '{"prefix":"fal","x":{"name":"minus"}}'
}, {
	name: 'iconQuantityDecreaseSize',
	inputType: 'text',
	label: 'Decrease quantity icon size',
	default: '1em'
}, {
	name: 'iconQuantityDecreaseColor',
	inputType: 'color',
	label: 'Decrease quantity icon color',
	default: '#000'
}];

const quantityImageConfig = [{
	name: 'imageQuantityIncrease',
	inputType: 'media-v2',
	label: 'Increase quantity image',
	default: ''
}, {
	name: 'imageQuantityDecrease',
	inputType: 'media-v2',
	label: 'Decrease quantity image',
	default: ''
}];

export default node => ({
	type: 'BLURB_OFFER',
	name: 'Blurb offer',
	category: 'Blurbs',
	allowedSections: ['article', 'footer', 'header', 'main'],
	disallowedChildren: ['*'],
	icon: 'fal fa-object-group',
	styleClasses: {
		Wrapper: '',
		Article: '',
		Article_Image: '',
		Article_Link: '',
		Article_Body: '',
		Article_Name: '',
		Article_Number: '',
		Article_Price: '',
		Article_Price_Tier: '',
		Article_Price_Tiers: '',
		Attribute_Select: '',
		Buy_Button: 'Preset-Primary-Button',
		Color_Label: '',
		Color_Select: '',
		Heading: 'Preset-Heading-Font-Family Preset-Heading-Color',
		Shopcart_Actions: '',
		Size_Label: '',
		Size_Select: '',
		Size_Select_Error_Message: '',
		Quantity: '',
		Quantity_Increase: '',
		Quantity_Decrease: '',
		Quantity_Wrapper: ''
	},
	props: [{
		name: 'articleNumbers',
		inputType: 'text',
		label: 'Article numbers',
		description: 'Separate by comma, e.g. 1234, 5678, 91011. If the first article number is already in the shopcart or does not have an offer, the next one is shown.',
		default: ''
	}, {
		name: 'onlyShowFirstOffer',
		inputType: 'checkbox',
		label: 'Only show first offer',
		description: 'If disabled, the next offer is shown after adding the article to cart',
		default: false
	}, {
		name: 'propertyKey',
		inputType: 'text',
		label: 'Internal property key name to show value from',
		default: ''
	}, {
		name: 'heading',
		inputType: 'text',
		label: 'Heading',
		default: ''
	}, {
		name: 'sizeLabel',
		inputType: 'text',
		label: 'Size label',
		default: 'Size'
	}, {
		name: 'sizePlaceholder',
		inputType: 'text',
		label: 'Size select placeholder',
		default: 'Choose size'
	}, {
		name: 'colorLabel',
		inputType: 'text',
		label: 'Color label',
		default: 'Color'
	}, {
		name: 'colorPlaceholder',
		inputType: 'text',
		label: 'Color select placeholder',
		default: 'Choose color'
	}, {
		name: 'buyButtonText',
		inputType: 'text',
		label: 'Buy button text',
		default: 'Add to cart'
	}, {
		name: 'activeBuyButtonText',
		inputType: 'text',
		label: 'Active buy button text',
		default: 'Added to cart'
	}, {
		name: 'showArticleImage',
		inputType: 'checkbox',
		label: 'Show image',
		default: true
	}, node && node.props.showArticleImage && {
		name: 'articleImageScale',
		inputType: 'select',
		label: 'Image scale',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, {
		name: 'showArticleName',
		inputType: 'checkbox',
		label: 'Show article name',
		default: true
	}, {
		name: 'showArticleNumber',
		inputType: 'checkbox',
		label: 'Show article number',
		default: true
	}, {
		name: 'showPrice',
		inputType: 'checkbox',
		label: 'Show price',
		default: true
	}, {
		name: 'showPriceTiers',
		inputType: 'checkbox',
		label: 'Show price tiers',
		description: 'Tiers are added to article properties',
		default: true
	}, {
		name: 'errorMessageNoAttr2Selected',
		inputType: 'text',
		label: 'Error message when no size is selected',
		default: 'Please select a size'
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Icons',
		props: [{
			name: 'iconType',
			inputType: 'select',
			label: 'Choose icon type',
			default: 'icon',
			values: [{
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
		...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', selectIconConfig),
		...conditionallyAddConfig(node, 'iconType', 'image', 'icon', selectImageConfig),
		...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', quantityIconConfig),
		...conditionallyAddConfig(node, 'iconType', 'image', 'icon', quantityImageConfig)]
	}]
});
