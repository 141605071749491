import externalCmsComponents from '~/external-cms-components-reducer';
import app from './app/reducer';
import article from './article/reducer';
import articleAddons from './cms-components/article/addons/reducer';
import articleBackInStock from './cms-components/article/back-in-stock/reducer';
import articleMedia from './cms-components/article/media/reducer';
import articleStoreBalance from './cms-components/article/store-balance/reducer';
import articleStore from './article-store/reducer';
import articles from './cms-components/articles/reducer';
import articlesSlider from './cms-components/articles-slider/reducer';
import breakpoint from './breakpoint/reducer';
import customer from './customer/reducer';
import findStore from './cms-components/find-store/reducer';
import helloRetail from './cms-components/hello-retail/fetcher/reducer';
import popup from './popup-redux/reducer';
import receipt from './receipt/reducer';
import router from './router/reducer';
import shopcart from './shopcart/reducer';
import site from './site/reducer';
import slideout from './slideout/reducer';
import streamlineCms from './streamline-cms/reducer';
import tree from './tree/reducer';
import extendCmsComponents from './reducer-extend-cms-components';
import extend from './reducer-extend';

export default {
	app,
	article,
	articleAddons,
	articleBackInStock,
	articleMedia,
	articleStore,
	articleStoreBalance,
	articles,
	articlesSlider,
	breakpoint,
	cmsComponents: {
		...externalCmsComponents,
		...extendCmsComponents
	},
	customer,
	findStore,
	helloRetail,
	popup,
	shopcart,
	site,
	slideout,
	receipt,
	router,
	tree,
	streamlineCms,
	...extend
};
