import page from 'page';
import {createAction} from 'redux-actions';
import {getLocaleFriendlyPath} from '@viskan/streamline-utilities/lib/url';
import {CATEGORY_FETCH, CONTENT_CLEAR} from '../action-types';

const findRedirects = async (query, options) => {
	try {
		const {redirect} = await v12.redirect.get(query, {query: v12.options.defaults});
		const {targetUrl, status} = redirect;

		if (targetUrl.startsWith('http') && !targetUrl.includes(location.host)) {
			location.href = targetUrl;
		}

		if (targetUrl.startsWith('http')) {
			location.href = targetUrl;
			return;
		}

		const targetUrlWithSlash = targetUrl.startsWith('/') ? targetUrl : `/${targetUrl}`;

		page.redirect(getLocaleFriendlyPath(targetUrlWithSlash, options.localeMode, options.pathPrefix));

		throw parseInt(status, 10);
	} catch (error) {
		console.error('error', error);
		throw (error);
	}
};

export const categoryFetch = createAction(CATEGORY_FETCH, async (query, section, v12Options, options = {}, articleCategory = false) => {
	try {
		const data = await v12.category.get(v12.util.pathToLinkFriendlyName(query), v12Options);

		return {
			data,
			section,
			notFound: false,
			articleCategory
		};
	} catch (error) {
		if (section === 'main' && !options.dontRedirect) {
			return findRedirects(query, options);
		}

		throw (error);
	}
}, (query, section) => ({section}));

export const clearMainContent = createAction(CONTENT_CLEAR, () => ({section: 'main'}));
