export default {
	type: 'YOUTUBE_EMBED',
	name: 'Youtube embed',
	category: 'Article',
	allowedSections: ['main', 'article'],
	disallowedChildren: ['*'],
	icon: 'fab fa-youtube',
	styleClasses: {
		Wrapper: 'Preset-Youtube-Wrapper',
		Embed: 'Preset-Youtube-Embed',
		Iframe: 'Preset-Youtube-Iframe',
		Popup: 'Preset-Youtube-Popup',
		Popup_Embed: 'Preset-Youtube-Popup-Embed',
		Popup_Iframe: '',
		Popup_Play_Icon: 'Preset-Youtube-Play-Icon',
		Popup_Thumbnail: 'Preset-Youtube-Popup-Thumbnail',
		Popup_Thumbnail_Wrapper: 'Preset-Youtube-Popup-Thumbnail-Wrapper'
	},
	props: [{
		name: 'propertyKey',
		inputType: 'text',
		label: 'Property key internal',
		default: ''
	}, {
		name: 'playIcon',
		inputType: 'media',
		label: 'Play icon',
		default: ''
	}, {
		name: 'openInPopup',
		inputType: 'checkbox',
		label: 'Open in popup',
		default: true
	}]
};
