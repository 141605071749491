import {stripHtml} from '@viskan/streamline-utilities';
import {deepEqual} from 'fast-equals';
import NotFound from '../../not-found';
import {setCanonical, setTitle, setDescription, setProductImages, setType} from '../../seo';
import {Section} from '../../streamline-cms';

const getFirstPropertyValue = (props, propertyKey) => {
	const [property] = v12.article.properties(propertyKey, props.article.propertyKeys);

	if (property && property.propertyValues[0]) {
		return property.propertyValues[0].propertyvalue;
	}
};

const getMetaDescription = props => {
	const {article} = props;
	const sources = [getFirstPropertyValue(props, 'seo.meta.desc'), article.art_desc, article.art_desc2];
	const description = sources.map(source => stripHtml(source).trim()).find(Boolean);

	if (!description || description.length <= 0) {
		return '';
	}

	return description;
};

const getTitle = props => {
	const {article} = props;
	const metaTitle = getFirstPropertyValue(props, 'seo.meta.title');

	return metaTitle || article.artname;
};

const beforeMount = ({props}) => {
	const {customerReducedPricePercentage, fetchArticle, query, sku, settings} = props;

	if (props.article.link_friendly_name !== query || customerReducedPricePercentage !== 0) {
		fetchArticle(query, sku, {query: {dcp: props.customerReducedPricePercentage}, settings});
	}

	setCanonical();
	setType({pageType: 'product'});

	if (!props.contentManagementActive && props.loadCategoryOnArticle) {
		props.categoryFetch(props.pathname, {}, props.loadCategoryOnArticle);
	}
};

const afterUpdate = ({props}, prevProps) => {
	const {settings} = props;
	const metaDescription = getMetaDescription(props);
	const title = getTitle(props);

	if (props.query !== prevProps.query || props.customerReducedPricePercentage !== prevProps.customerReducedPricePercentage) {
		props.fetchArticle(props.query, props.sku, {query: {dcp: props.customerReducedPricePercentage}, settings});

		if (!props.contentManagementActive && props.loadCategoryOnArticle) {
			props.categoryFetch(props.pathname, {}, props.loadCategoryOnArticle);
		}

		setCanonical();
		setType({pageType: 'product'});
	}

	if (title !== getTitle(prevProps)) {
		setTitle(title);
	}

	if (metaDescription !== getMetaDescription(prevProps)) {
		setDescription(metaDescription);
	}

	if (props.active.attr1_id !== prevProps.active.attr1_id) {
		if (!props.article.art_id) {
			props.fetchArticle(props.query, props.sku, {query: {dcp: props.customerReducedPricePercentage}, settings});
		}

		if (props.article.articleAttributeMedias) {
			setProductImages(props.article.articleAttributeMedias.filter(item => item.attr1_id === props.active.attr1_id));
		}
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const beforeUnmount = ({props}) => props.resetArticle(false);

const render = ({props}) => {
	const {contentManagementActive, loadCategoryOnArticle, notFound, popup, slideout, inline} = props;

	if (notFound) {
		return contentManagementActive ? <div class='u-textCenter'>404 Page not found</div> : <NotFound/>;
	}

	if (!props.article.art_id) {
		return <noscript/>;
	}

	return (
		<article class='Article'>
			{
				(props.useArticleQuickBuyLayout && (popup || slideout || inline || props.activeCmsSection === 'articleQuickBuy')) ?
					<Section key='articleQuickBuy' sectionId='articleQuickBuy'/> :
					<Section key='article' sectionId='article'/>
			}
			{(!popup && loadCategoryOnArticle) && <Section key='main' sectionId='main'/>}
		</article>
	);
};

export default {afterUpdate, beforeMount, beforeUnmount, render, shouldUpdate};
