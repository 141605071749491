import {SLIDEOUT_CLOSE, SLIDEOUT_OPEN} from '../action-types';

const initialState = {
	content: null,
	open: false,
	options: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SLIDEOUT_CLOSE: {
			return {
				...state,
				open: false,
				content: null
			};
		}
		case SLIDEOUT_OPEN: {
			return {
				...state,
				content: action.payload.content,
				open: true,
				options: action.payload.options
			};
		}
		default: {
			return state;
		}
	}
};
