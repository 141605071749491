import {conditionallyAddConfig} from '~/utils';

const layoutOptionsSearch = [{
	name: 'searchPlaceholder',
	inputType: 'text',
	label: 'Search placeholder',
	default: 'Search...'
}, {
	name: 'searchPinIcon',
	inputType: 'icon',
	label: 'Choose an icon for search pin',
	default: ''
}, {
	name: 'searchPinIconSize',
	inputType: 'text',
	label: 'Size on icon',
	default: '1em'
}, {
	name: 'searchPinIconColor',
	inputType: 'color',
	label: 'Icon color',
	default: '#000'
}, {
	name: 'searchPinImage',
	inputType: 'media-v2',
	label: 'Choose an image for search pin',
	default: ''
}, {
	name: 'showStoreName',
	inputType: 'checkbox',
	label: 'Show store name',
	default: true
}, {
	name: 'showStoreAddress',
	inputType: 'checkbox',
	label: 'Show store address',
	default: true
}, {
	name: 'showStoreZipcode',
	inputType: 'checkbox',
	label: 'Show store zipcode',
	default: true
}, {
	name: 'showStoreCity',
	inputType: 'checkbox',
	label: 'Show store city',
	default: true
}, {
	name: 'showStorePhone',
	inputType: 'checkbox',
	label: 'Show store phone',
	default: true
}, {
	name: 'showStoreOpeningHoursWeekdays',
	inputType: 'checkbox',
	label: 'Show store opening hours for weekdays',
	default: true
}, {
	name: 'showStoreOpeningHoursSaturday',
	inputType: 'checkbox',
	label: 'Show store opening hours for saturday',
	default: true
}, {
	name: 'showStoreOpeningHoursSunday',
	inputType: 'checkbox',
	label: 'Show store opening hours for sunday',
	default: true
}];

const layoutOptionsSelect = [{
	name: 'apiKey',
	inputType: 'text',
	label: 'Google API key',
	default: ''
}, {
	name: 'height',
	inputType: 'text',
	label: 'Height',
	default: '500px'
}, {
	name: 'maxWidth',
	inputType: 'text',
	label: 'Max-width',
	default: '100%'
}, {
	name: 'zoom',
	inputType: 'select',
	label: 'City zoom',
	default: '12',
	values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']
}, {
	name: 'icon',
	inputType: 'media',
	label: 'Marker icon',
	default: ''
}, {
	name: 'theme',
	inputType: 'select',
	label: 'Theme',
	default: 'Standard',
	values: ['Standard', 'Silver', 'Retro', 'Dark', 'Night', 'Aubergine']
}, {
	name: 'note',
	inputType: 'text',
	label: 'Help message',
	default: ''
}, {
	name: 'centerAllStores',
	inputType: 'checkbox',
	label: 'Center all stores',
	default: false
}];

const closestStoreOptions = [{
	label: 'Closest store options',
	props: [{
		name: 'maxClosestStores',
		inputType: 'slider',
		label: 'Max closest stores',
		default: 5,
		min: 1,
		max: 10
	}, {
		name: 'useSlider',
		inputType: 'checkbox',
		label: 'Use slider',
		default: false
	}, {
		name: 'columnsMobile',
		inputType: 'slider',
		label: 'Columns mobile',
		default: 1,
		min: 1,
		max: 5
	}, {
		name: 'columnsTablet',
		inputType: 'slider',
		label: 'Columns tablet',
		default: 2,
		min: 1,
		max: 5
	}, {
		name: 'columnsDesktop',
		inputType: 'slider',
		label: 'Columns desktop',
		default: 3,
		min: 1,
		max: 5
	}]
}];

export default node => ({
	type: 'FIND_STORE',
	name: 'Find store',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Google',
	icon: 'fas fa-map-pin',
	styleClasses: {
		'Search': '',
		'Search Input': '',
		'Search Geolocation': '',
		'Search Results': '',
		'Store': '',
		'Store Name': '',
		'Store Map Icon': '',
		'Store Map Image': '',
		'Store AddressLine1': '',
		'Store Zipcode City': '',
		'Store Zipcode': '',
		'Store City': '',
		'Store Phone': '',
		'Store Hours': '',
		'Store Hours Weekdays': '',
		'Store Hours Saturday': '',
		'Store Hours Sunday': '',
		'Store Hours Label': '',
		'Store Hours Time': ''
	},
	props: [{
		name: 'layout',
		inputType: 'select',
		label: 'Select layout',
		default: 'select',
		values: [{
			label: 'Select list and map',
			value: 'select'
		}, {
			label: 'Search',
			value: 'search'
		}]
	},
	...conditionallyAddConfig(node, 'layout', 'search', 'select', layoutOptionsSearch),
	...conditionallyAddConfig(node, 'layout', 'select', 'select', layoutOptionsSelect)],
	hiddenProps: [
		...conditionallyAddConfig(node, 'layout', 'select', 'select', closestStoreOptions)
	]
});
