import {createAction} from 'redux-actions';
import {deepEqual} from 'fast-equals';
import {trackImpression} from '../../analytics';
import {ARTICLES_CLEAR_COMPONENT, ARTICLES_FETCH, ARTICLES_FAVORITES_FETCH, ARTICLES_GO_TO_PAGE, ARTICLES_INLINE_QUICKBUY_OPEN, ARTICLES_INLINE_QUICKBUY_CLOSE, ARTICLES_PAGINATION_NEXT, ARTICLES_PAGINATION_PREVIOUS, ARTICLES_SET_ACTIVE_FILTERS, ARTICLES_RESET_COMPONENT, ARTICLES_IN_VIEW} from '../../action-types';
import {PAGINATION_TYPE_INFINITE_SCROLL} from '~/utils/constants';

const appendReduced = (apiInput, reduced) => {
	if (!reduced || reduced === 'all') {
		return apiInput;
	}

	return {
		...apiInput,
		lowest_price_sales: {
			...(apiInput.lowest_price_sales || {}),
			reduced: {
				value: reduced === 'onSale'
			}
		}
	};
};

const getFrom = (options, size) => {
	if (options.paginationType === PAGINATION_TYPE_INFINITE_SCROLL) {
		return deepEqual(options.activeFilters.curr, options.activeFilters.prev) && options.from ? options.from : 0;
	}

	if (options.pageQuery > 1 && !options.filterOrSortChanged) {
		return (options.pageQuery - 1) * size;
	}

	return 0;
};

const fetch = fetchFunction => (identifier, options) => {
	const size = parseInt(options.size, 10);
	const from = getFrom(options, size);

	return fetchFunction(identifier, {
		filter: appendReduced(options.activeFilters.apiInput, options.reducedPriceFilter),
		from,
		size,
		sort: options.sort,
		throwOnMissingArticles: options.isSearch,
		key: options.key,
		url: options.url,
		query: {
			dcp: options.dcp,
			filterSplit: options.showColorList === false && options.showSizeList === false,
			helloRetailSearchKey: options.helloRetailSearchKey,
			aggregateAttribute1Group: options.aggregateAttribute1Group,
			aggregateAttribute2Group: options.aggregateAttribute2Group,
			aggregateAttribute3Group: options.aggregateAttribute3Group,
			articleListWithPrentypes: options.articleListWithPrentypes,
			splitByAttribute1: options.splitByAttribute1,
			ignoreAggregations: from > 0 && options.hasFetchedCategoryId,
			mediaRowIds: options.mediaRowIds,
			excludeOutOfStockListArticles: options.excludeOutOfStockListArticles,
			...(options.daysAsBrandNew && {
				daysAsBrandNew: options.daysAsBrandNew
			})
		}
	}).then(data => {
		const resultData = data.articles ? data.articles : data;
		trackImpression(resultData.hits, {from});

		return {
			aggregations: v12.filter.unNestProperties(resultData.aggregations),
			articles: resultData.hits,
			categoryId: options.searchCategoryId || identifier,
			from,
			hasFetchedCategoryId: options.hasFetchedCategoryId,
			id: options.id,
			isFiltered: options.isFiltered,
			meta: resultData.meta,
			pageQuery: options.pageQuery,
			paginationType: options.paginationType,
			searchQuery: options.isSearch ? identifier : '',
			sort: options.sort,
			total: (data.meta && data.meta.total) || resultData.total
		};
	});
};

export const fetchFavorites = (favoritesArtNosAndAttr1Ids, showColorList, mediaRowIds, options) => {
	const currentFavoritesArtNosAndAttr1Ids = favoritesArtNosAndAttr1Ids.map(favorite => favorite.artNo);

	return v12.search(currentFavoritesArtNosAndAttr1Ids.join(','), {
		key: 'article',
		query: {
			filterSplit: !showColorList,
			dcp: options.dcp,
			artNos: true,
			splitByAttribute1: true,
			articleListWithPrentypes: options.articleListWithPrentypes,
			excludeOutOfStockListArticles: options.excludeOutOfStockListArticles,
			mediaRowIds
		},
		size: 25
	});
};

export const nextPagination = createAction(ARTICLES_PAGINATION_NEXT, options => ({
	size: parseInt(options.size, 10),
	id: options.id
}));

export const clearComponent = createAction(ARTICLES_CLEAR_COMPONENT, id => id);
export const goToPage = createAction(ARTICLES_GO_TO_PAGE, async (newPage, id) => ({newPage, id}));
export const previousPagination = createAction(ARTICLES_PAGINATION_PREVIOUS, id => id);
export const articlesFetchFavorites = createAction(ARTICLES_FAVORITES_FETCH, fetchFavorites);
export const articlesFetch = createAction(ARTICLES_FETCH, async (identifier, options) => fetch(v12.articles.get)(identifier, options), (identifier, options) => ({identifier, options}));
export const setInView = createAction(ARTICLES_IN_VIEW);

export const setActiveFiltersFromQuery = createAction(ARTICLES_SET_ACTIVE_FILTERS);
export const inlineQuickbuyOpen = createAction(ARTICLES_INLINE_QUICKBUY_OPEN);
export const inlineQuickbuyClose = createAction(ARTICLES_INLINE_QUICKBUY_CLOSE);
export const resetComponent = createAction(ARTICLES_RESET_COMPONENT);
