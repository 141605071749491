import {deepEqual} from 'fast-equals';
import {handleCancel} from '../utils';
import Countdown from './countdown';

const defaultProps = {
	pollInterval: 5000,
	pollTimeout: 240_000
};

const initialState = props => {
	if (props.fetchReceipt && props.purchaseNumber) {
		return {
			intervalId: setInterval(() => props.fetchReceipt({
				purchaseNumber: props.purchaseNumber
			}), props.pollInterval)
		};
	}

	return {
		intervalId: undefined
	};
};

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);
const beforeUnmount = ({state}) => state.intervalId && clearInterval(state.intervalId);

const render = ({props}) => {
	return (
		<div class={['Purchase', props.class]}>
			<div class='Purchase-label'>
				{props.getTranslation(`purchase.polling.${props.serviceProviderCode}.label`)}
			</div>
			<Countdown pollTimeout={props.pollTimeout} onTimeOut={handleCancel(props.purchaseNumber, props.pathPrefix, props.checkoutUrl)} getTranslation={props.getTranslation}/>
			<button class='Purchase-cancelButton Preset-Primary-Button' type='button' onClick={handleCancel(props.purchaseNumber, props.pathPrefix, props.checkoutUrl)}>
				{props.getTranslation('purchase.polling.cancel')}
			</button>
		</div>
	);
};

export default {beforeUnmount, defaultProps, initialState, render, shouldUpdate};
