import counterpart from '@viskan/counterpart';
import {createSelector} from 'reselect';
import daLocales from '../../locale/da.json';
import deLocales from '../../locale/de.json';
import enLocales from '../../locale/en.json';
import fiLocales from '../../locale/fi.json';
import noLocales from '../../locale/no.json';
import svLocales from '../../locale/sv.json';
import daExtendLocales from '../../locale/extends/da.json';
import deExtendLocales from '../../locale/extends/de.json';
import enExtendLocales from '../../locale/extends/en.json';
import fiExtendLocales from '../../locale/extends/fi.json';
import noExtendLocales from '../../locale/extends/no.json';
import svExtendLocales from '../../locale/extends/sv.json';

const defaultTranslation = 'en';
const translations = {
	da: daLocales,
	de: deLocales,
	en: enLocales,
	fi: fiLocales,
	no: noLocales,
	sv: svLocales
};

const extendedTranslations = {
	da: daExtendLocales,
	de: deExtendLocales,
	en: enExtendLocales,
	fi: fiExtendLocales,
	no: noExtendLocales,
	sv: svExtendLocales
};

const instances = {};
const pluralize = (entry, count) => entry[count === 0 && 'zero' in entry ? 'zero' : (count === 1 ? 'one' : 'other')];

const convertDotNotationToObject = obj => {
	const result = {};

	for (const objectPath in obj) {
		if (objectPath) {
			const parts = objectPath.split('.');

			let target = result;
			while (parts.length > 1) {
				const part = parts.shift();
				/* eslint no-multi-assign: */
				target = target[part] = target[part] || {};
			}

			target[parts[0]] = obj[objectPath];
		}
	}

	return result;
};

const translator = site => {
	const {lang_code_web} = site;
	const storeTranslations = convertDotNotationToObject(window._streamline.translations);

	if (!instances[lang_code_web]) {
		const instance = new counterpart.Instance();
		instance.registerTranslations(lang_code_web, {counterpart: {pluralize}});
		instance.registerTranslations(lang_code_web, translations[lang_code_web]);
		instance.registerTranslations(lang_code_web, extendedTranslations[lang_code_web]);
		instance.registerTranslations(lang_code_web, storeTranslations);
		instance.setLocale(lang_code_web);
		instance.onTranslationNotFound((locale, key) => console.warn(`missing translation: ${locale}.${key}`));

		if (lang_code_web !== 'en') {
			instance.registerTranslations(defaultTranslation, translations[defaultTranslation]);
			instance.registerTranslations(defaultTranslation, extendedTranslations[defaultTranslation]);
			instance.setFallbackLocale(defaultTranslation);
		}

		instances[lang_code_web] = (key, options) => instance.translate(key, options);
	}

	return instances[lang_code_web];
};

export const getTranslator = createSelector(
	[state => state.site],
	site => translator(site)
);

export default translator;
