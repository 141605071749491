export default {
	type: 'CATEGORY_ALPHABETICAL_LIST',
	name: 'Alphabetical Category List',
	allowedSections: ['header', 'main', 'footer'],
	disallowedChildren: ['*'],
	category: 'Nav',
	icon: 'fal fa-sort-alpha-up',
	styleClasses: {
		Wrapper: '',
		Section: 'Preset-AlphabeticalList-Section',
		Letter: 'Preset-Heading2 Preset-Heading-Font-Family Preset-Heading-Color',
		Link: 'Preset-AlphabeticalList-Link Preset-Link-Color Preset-Primary-Text Preset-Primary-Font-Family'
	},
	props: []
};
