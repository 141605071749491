import {deepEqual} from 'fast-equals';
import BadgeList from '~/badge/list';
import {component as FavoriteButton} from '~/cms-components/article/favorite';
import {ColorList, SizeList, SizeRange} from '~/attribute-list';
import Link from '~/link';
import {Image} from '~/media';
import Price from '~/price';
import QuickBuy from '~/quick-buy';
import {isEmpty, mergeDefined, sanitizeAttr1IdKeyValue, lowestPriceVisibility} from '~/utils';
import {type, stripHtml} from '@viskan/streamline-utilities';
import Slide from '@viskan/deku-slide';
import {getIcon} from '../../cms-components/utils/actions';
import clickOutside from 'click-outside';

let unbindOutsideClick;

const afterRender = ({props}, el) => {
	if (unbindOutsideClick) {
		unbindOutsideClick();

		unbindOutsideClick = undefined;
	}

	if (!props.open) {
		return;
	}

	unbindOutsideClick = clickOutside(el, event => {
		if (!event.target.closest('.ListArticle-inlineQuickbuy') && !event.target.className.includes('QuickBuy')) {
			props.inlineQuickbuyClose();
		}
	});
};

const propTypes = {
	article: {
		type: 'object'
	},
	back: {
		type: 'string'
	},
	class: {
		type: 'string'
	},
	front: {
		type: 'string'
	},
	media: {
		type: 'object'
	},
	trackProductClick: {
		source: 'trackProductClick'
	}
};

const defaultProps = {
	showLowestPrice: 'useStoreSetting',
	back: '-1',
	badgeOptions: {
		showCampaigns: false,
		showSaleOrNew: false,
		showLowerPriceBadge: false // CUSTOMER UNIQUE
	},
	front: '1010',
	media: {},
	scale: 'list',
	styleClasses: {},
	styling: {}
};

const getMedia = (props, attr1) => {
	const attr1_id = type.isUndefined(attr1) ? props.attr1_id : attr1;

	return v12.article.media(props.article, {
		size: '',
		include: [parseInt(props.front, 10), parseInt(props.back, 10)],
		sku: {
			attr1_id
		},
		fallback: props.useFallbackImage,
		useFilename: true
	});
};

const initialState = props => {
	const medias = getMedia(props);

	return {
		art_id: props.article.art_id,
		attr1_id: props.attr1_id,
		image: medias.front,
		imageSizes: medias.sizes && medias.sizes.front ? medias.sizes.front : {}
	};
};

const onMouseOver = (medias, setState) => () => medias.back && setState({image: medias.back});
const onMouseOut = (medias, setState) => () => setState({image: medias.front});
const getLink = ({article, site, meta}, attr1_id) => `/${site.articleUrlPrefix}/${article.link_friendly_name}${attr1_id > 0 ? `?attr1_id=${attr1_id}` : ''}${(meta && meta.trackingLink) || ''}`;

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	const {article, front} = props;
	const {attr1_id, image} = state;
	const attr1IdFound = props.article.articleAttributes.some(attribute => attribute.attr1_id === attr1_id);

	if ((attr1_id !== props.attr1_id) && !attr1IdFound) {
		setState({attr1_id: props.attr1_id});
		return;
	}

	const medias = getMedia(props, attr1_id);

	if (front !== prevProps.front || !deepEqual(article.articleAttributeMedias, prevProps.article.articleAttributeMedias)) {
		setState({
			image: medias.front,
			imageSizes: medias.sizes && medias.sizes.front ? medias.sizes.front : {}
		});
	}

	if (medias.front !== image && medias.back !== image) {
		setState({
			image: medias.front,
			imageSizes: medias.sizes && medias.sizes.front ? medias.sizes.front : {}
		});
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const sanitizeMediaFilename = filename => {
	// V12 returns media prefixed with /
	if (filename && filename.startsWith('/')) {
		return filename.slice(1);
	}

	return filename;
};

const getArticleProperties = (propertyNames, articleProperties, styleClasses) => {
	const foundProperties = propertyNames.split(',').map(name => articleProperties.filter(property => property.propertykey_name_internal.toLowerCase() === name.toLowerCase().trim()));

	return foundProperties.map(properties => {
		return properties.map(property => {
			return (
				<div key={property.propertykey_name_internal} class={['CMS-ListArticleProperty', styleClasses.Property]} data-style-attr='Property'>
					<div class={['CMS-ListArticleProperty-key', styleClasses.Property_Key]} data-style-attr='Property_Key'>
						{property.propertykey_name}
					</div>
					<div class={['CMS-ListArticleProperty-value', styleClasses.Property_Value]} data-style-attr='Property_Value'>
						{property.propertyValues.map(value => <div key={value.propertyvalue} innerHTML={value.propertyvalue}/>)}
					</div>
				</div>
			);
		});
	});
};

const getPriceProperties = (propertyNames, articlePropertiesForPrice, styleClasses) => {
	const foundProperties = propertyNames.split(',').map(name => articlePropertiesForPrice.filter(property => property.propertykey_name_internal.toLowerCase() === name.toLowerCase().trim()));

	return foundProperties.map(properties => {
		return properties.map(property => {
			return (
				<div class={['CMS-ListArticlePricePropertyValue', styleClasses.Price_Property_Value]} data-style-attr='Price_Property_Value'>
					{property.propertyValues.map(value => <div key={value.propertyvalue} innerHTML={value.propertyvalue}/>)}
				</div>
			);
		});
	});
};

const handleSetListColor = setState => attr1_id => setState({attr1_id});

const getArrows = (stateString, sliderOptions, props) => {
	switch (sliderOptions.sliderArrowType) {
		case 'icon': {
			return getIcon({icon: sliderOptions[`icon${stateString}`], iconColor: sliderOptions[`icon${stateString}Color`], iconSize: sliderOptions[`icon${stateString}Size`], label: stateString});
		}
		case 'image': {
			return <Image class={props.styleClasses.Image} source={sliderOptions[`image${stateString}`].image.filename} scale={sliderOptions[`image${stateString}`].scale} width={sliderOptions[`image${stateString}`].width} height={sliderOptions[`image${stateString}`].height} data-style-attr='Image'/>;
		}
		default:
	}
};

const getSliderSettings = (props, sliderOptions, sliderImages) => {
	const iconNext = (sliderOptions.iconNext || sliderOptions.imageNext) ? getArrows('Next', sliderOptions, props) : '';
	const iconPrev = (sliderOptions.iconPrev || sliderOptions.imagePrev) ? getArrows('Prev', sliderOptions, props) : '';
	const options = {};

	switch (props.breakpoint) {
		case 'xsm':
		case 'sm':
			options.slidesPerView = parseFloat(sliderOptions.slidesPerViewMobile, 1);
			options.spaceBetween = parseInt(sliderOptions.spaceBetweenMobile, 10);
			options.freeMode = sliderOptions.freeModeMobile;
			options.loop = sliderOptions.loopMobile;
			options.arrows = sliderOptions.showArrowsMobile;
			options.showPagination = sliderOptions.showPaginationMobile;
			break;
		case 'md':
			options.slidesPerView = parseFloat(sliderOptions.slidesPerViewTablet, 1);
			options.spaceBetween = parseInt(sliderOptions.spaceBetweenTablet, 10);
			options.freeMode = sliderOptions.freeModeTablet;
			options.loop = sliderOptions.loopTablet;
			options.arrows = sliderOptions.showArrowsTablet;
			options.showPagination = sliderOptions.showPaginationTablet;
			break;
		default:
			options.slidesPerView = parseFloat(sliderOptions.slidesPerViewDesktop, 1);
			options.spaceBetween = sliderOptions.spaceBetweenDesktop && parseInt(sliderOptions.spaceBetweenDesktop, 10);
			options.freeMode = sliderOptions.freeModeDesktop;
			options.loop = sliderOptions.loopDesktop;
			options.arrows = sliderOptions.showArrowsDesktop;
			options.showPagination = sliderOptions.showPaginationDesktop;
	}

	const settings = {
		arrows: sliderImages && sliderImages.length > options.slidesPerView && options.arrows,
		iconNext,
		iconPrev,
		direction: 'horizontal',
		loop: options.loop,
		options,
		pagination: sliderImages && sliderImages.length > options.slidesPerView && options.showPagination,
		paginationType: sliderOptions.sliderPaginationType
	};

	return settings;
};

const render = ({props, state}, setState) => {
	const {addToCartOnClick, cmsActive, quickbuy, quickbuyInPopup, slideoutSide, showColorList, showSizeList, showOverlay, showArticleDescription, showArticleNumber, showColor, showBrandName, showArticleProperties, showPlunoNumber, showNumberOfVariants, showLipscoreRatingWidget, stripArticleDescriptionHtml, styleClasses, styling, trackProductClick, favorite, favoriteOptions, isArticle, isBig, bigArticleImageScale, isPerson, scale, showSizeRange, regexToRemoveFromSize, sliderOptions, showPropertiesForPrice, index} = props;
	const quickbuyLayout = (props.breakpoint === 'xsm' || props.breakpoint === 'sm') ? props.quickbuyLayoutMobile : (props.breakpoint === 'md' ? props.quickbuyLayoutTablet : props.quickbuyLayout);
	const colorListPlacement = (props.breakpoint === 'xsm' || props.breakpoint === 'sm') ? props?.colorListOptions?.colorListPlacementMobile || 'image' : (props.breakpoint === 'md' ? props?.colorListOptions?.colorListPlacementTablet || 'image' : props?.colorListOptions?.colorListPlacementDesktop || 'image');

	const {attr1_id, image, imageSizes} = state;
	const article = {...props.article, attr1_id};
	const {art_desc, art_no, artname, virtual_flg} = article;
	const sku = article.articleAttributes.find(sku => sku.attr1_id === attr1_id) || article.articleAttributes[0];
	const color = showColor && article.articleAttributes.reduce((acc, attr) => {
		return !acc.includes(attr.attribute1.attr1_code) ? [...acc, attr.attribute1.attr1_code] : acc;
	}, []).toString();

	const hasSubscriptions = (type.isArray(props.article.prentypes) && props.article.prentypes.length > 0) ?? false;
	const properties = props.showArticleProperties && getArticleProperties(props.articleProperties, article.propertyKeys, styleClasses);
	const priceProperties = props.showPropertiesForPrice && getPriceProperties(props.articlePropertiesForPrice, article.propertyKeys, styleClasses);
	const imageElement = <Image source={sanitizeMediaFilename(image)} scale={isBig ? bigArticleImageScale : scale} alt={artname} title={artname} width={imageSizes.width} height={imageSizes.height} style='cursor: pointer;'/>;
	const medias = getMedia(props, attr1_id);
	const url = getLink(props, attr1_id);
	const {articleMargins, articleNameFontColor, articleNameFontSize, articleNameFontStyle, articleNameFontWeight, articleTextPaddings} = styling;
	const badgeOptions = mergeDefined(defaultProps.badgeOptions, props.badgeOptions);
	const quickbuyButtonText = quickbuy && (props.quickbuyButtonText || props.getTranslation('articles.buy'));
	const quickbuyNotifyButtonText = quickbuy && (props.quickbuyNotifyButtonText || props.getTranslation('articles.notify'));
	const showPriceForCurrentSku = props.colorListOptions && props.colorListOptions.colorListShowFromPrice && props.showColorList ? false : props.showPriceForCurrentSku || props.showColorList;
	const lowestPricePrefixText = props.lowestPricePrefixText || props.site.settings.lowestPricePrefixText || '';
	const lowestPriceSuffixText = props.lowestPriceSuffixText || props.site.settings.lowestPriceSuffixText || '';
	const hideLowestPriceForSpecificOfferTypes = props.hideLowestPriceForSpecificOfferTypes || '';
	const price = v12.article.price(article, false);
	const articleStyles = {
		margin: articleMargins
	};

	const articleNameStyles = {
		'color': articleNameFontColor,
		'font-size': articleNameFontSize,
		'font-style': articleNameFontStyle,
		'font-weight': articleNameFontWeight
	};

	const articleTextStyles = {
		'padding': articleTextPaddings
	};

	let allImages = article.articleAttributeMedias.flatMap(image => image.attr1_id === article.attr1_id ? <Image source={sanitizeMediaFilename(image.media.filename)} scale={isBig ? bigArticleImageScale : scale} alt={artname} title={artname} width={imageSizes.width} height={imageSizes.height} style={{cursor: 'pointer'}}/> : []);

	if (allImages.length === 0 && props.useFallbackImage) {
		allImages = article.articleAttributeMedias.flatMap(image => <Image source={sanitizeMediaFilename(image.media.filename)} scale={isBig ? bigArticleImageScale : scale} alt={artname} title={artname} width={imageSizes.width} height={imageSizes.height} style={{cursor: 'pointer'}}/>);
	}

	const sliderImages = sliderOptions && sliderOptions.maximumNumberOfSlides && allImages.length > sliderOptions.maximumNumberOfSlides ? allImages.slice(0, sliderOptions.maximumNumberOfSlides) : allImages;
	const showSlider = sliderOptions ? ((props.breakpoint === 'xsm' || props.breakpoint === 'sm') && sliderOptions.useSliderMobile) || (props.breakpoint === 'md' && sliderOptions.useSliderTablet) || ((props.breakpoint === 'xlg' || props.breakpoint === 'lg') && sliderOptions.useSliderDesktop) : false;
	const sliderSettings = sliderOptions && getSliderSettings(props, sliderOptions, sliderImages);
	const elementToShow = showSlider ? <Slide key={`${sku.attr1_id}-${sku.attr2_id}-${sku.attr3_id}`} {...sliderSettings} styleClasses={props.styleClasses}>{sliderImages}</Slide> : imageElement;
	const activeInlineQuickbuyArticle = props.content && props.content.attributes && props.content.attributes.art_id === article.art_id && props.content.attributes.attr1_id === article.attr1_id;

	const handleOnClick = article => () => {
		props.clickArticle(index);
		trackProductClick(article, index);
	};

	return (
		<article class={['ListArticle', hasSubscriptions && 'ListArticle-HasSubscriptions', props.class, styleClasses.Article]} data-style-attr='Article' style={!styleClasses.Article && articleStyles}>
			<div class='ListArticle-img-wrapper'>
				<figure class={[!isBig ? ['ListArticle-img', styleClasses.Image] : ['ListArticleBig-img', styleClasses.BigArticleImage]]} data-style-attr={!isBig ? 'Image' : 'BigArticleImage'} onMouseOver={onMouseOver(medias, setState)} onMouseOut={onMouseOut(medias, setState)}>
					<BadgeList article={article} sku={sku} {...badgeOptions} isPerson={isPerson}/>
					{!cmsActive || showSlider ?
						<Link href={url} onClick={handleOnClick(article)}>
							{elementToShow}
						</Link> : elementToShow
					}
				</figure>
				{showSizeList && <SizeList article={article} sizeListOptions={props.sizeListOptions} styleClasses={styleClasses}/>}
				{showSizeRange && <SizeRange article={article} regexToRemoveFromSize={regexToRemoveFromSize} styleClasses={styleClasses} getTranslation={props.getTranslation}/>}
				{showColorList && colorListPlacement !== 'articleBody' && <ColorList article={article} breakpoint={props.breakpoint} colorListOptions={props.colorListOptions} handleSetListColor={handleSetListColor(setState)} styleClasses={styleClasses} sanitizeMediaFilename={sanitizeMediaFilename}/>}
				{quickbuy && quickbuyLayout === 'inline' && activeInlineQuickbuyArticle && props.open && <div class={['ListArticle-inlineQuickbuy', styleClasses.Inline_Quickbuy]} data-style-attr='Inline_Quickbuy'>{props.content}</div>}
			</div>
			<div class={['ListArticle-body', styleClasses.Article_Body]} style={!styleClasses.Article_Body && articleTextStyles} data-style-attr='Article_Body'>
				<Link href={!cmsActive && url} onClick={!cmsActive && handleOnClick(article)}>
					<h2 class={['ListArticle-title', styleClasses.Title]} style={!styleClasses.Title && articleNameStyles} data-style-attr='Title'>
						{artname}
					</h2>
					{showBrandName && !isEmpty(article.brand) &&
						<span class={['ListArticle-brand', styleClasses.Brand]} data-style-attr='Brand'>{article.brand.name}</span>
					}
					{showArticleDescription && art_desc && art_desc.length > 0 &&
						<span class={['ListArticle-artdesc', styleClasses.ArtDesc]} data-style-attr='ArtDesc' innerHTML={stripArticleDescriptionHtml ? stripHtml(art_desc) : art_desc}/>
					}
					{showArticleNumber && art_no &&
						<span class={['ListArticle-artno', styleClasses.ArtNo]} data-style-attr='ArtNo'>
							{art_no}
						</span>
					}
					{showPlunoNumber && sku.pluno.length !== 0 &&
						<span class={['ListArticle-pluno', styleClasses.Pluno]} data-style-attr='Pluno'>
							{sku.pluno}
						</span>
					}
					{(!showColorList && showColor && color) &&
						<span class={['ListArticle-color', styleClasses.Color]} data-style-attr='Color' data-color={color}>
							{color}
						</span>
					}
					{(showNumberOfVariants && article.articleAttributes.length > 1) &&
						<span class={['ListArticle-variants', styleClasses.Variants_Qty]} data-style-attr='Variants_Qty'>
							{props.getTranslation('articles.variants', {qty: article.articleAttributes.length})}
						</span>
					}
					{(showArticleProperties && properties) &&
						<span class={['ListArticle-properties', styleClasses.Properties]} data-style-attr='Properties'>
							{properties}
						</span>
					}
					{/* CUSTOMER UNIQUE - Virtual Article Price Label & priceProperties */ virtual_flg ? <div class={['ListArticle-virtual-article-price-label', styleClasses.Virtual_Article_Price_Label]} data-style-attr='Virtual_Article_Price_Label'>{props.getTranslation('article.price_virtual_article_label')}</div> : <Price class='ListArticle-prices' styleClasses={styleClasses} data={article} sku={sku} showDiscountPercent={props.showDiscountPercent} showPriceForCurrentSku={showPriceForCurrentSku || (props.showColorList || props.showSizeList)} showFromLabel={props.showFromLabel} priceProperties={priceProperties} showRecPrice={props.showRecPrice} recPriceLabel={props.recPricePrefixText} hideZeroPrice={props.hideZeroPrice} showLowestPrice={lowestPriceVisibility(props.showLowestPrice, props.showLowestPriceStoreSetting, hideLowestPriceForSpecificOfferTypes, price)} lowestPricePrefixText={lowestPricePrefixText} lowestPriceSuffixText={lowestPriceSuffixText}/>}
				</Link>
				{favorite && <FavoriteButton articleIsLoaded artNo={article.art_no} attr1Id={sanitizeAttr1IdKeyValue(sku)} favoriteOptions={favoriteOptions}/>}
				{isArticle && quickbuy ? <Link href={url} class={['Button Button Button--primary ListArticle-button-buy']} onClick={handleOnClick(article)}>{quickbuyButtonText}</Link> : (quickbuy ? <QuickBuy data={article} sku={sku} url={url} styleClasses={styleClasses} addToCartOnClick={addToCartOnClick} quickbuyInPopup={quickbuyInPopup} quickbuyButtonText={quickbuyButtonText} quickbuyNotifyButtonText={quickbuyNotifyButtonText} quickbuyLayout={quickbuyLayout} slideoutSide={slideoutSide} showOverlay={showOverlay}/> : <noscript/>)}
				{showLipscoreRatingWidget && <span class='lipscore-rating-small' ls-product-name={artname} ls-brand={article.brandName} ls-product-id={article.art_no} ls-product-url={`${window.location.origin}/${props.locale.replaceAll('/', '')}${url}`} ls-product-description={article.art_desc}/>}
			</div>
		</article>
	);
};

export default {afterUpdate, afterRender, defaultProps, initialState, propTypes, render, shouldUpdate};
