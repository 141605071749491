export default node => ({
	type: 'FILTER',
	name: 'Filter',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Articles',
	props: [{
		name: 'filterSource',
		inputType: 'filter-property',
		label: 'Source'
	}, node && node.props.filterSource === 'selectedFilters' && {
		name: 'hideSelectedFiltersIfEmpty',
		inputType: 'checkbox',
		label: 'Hide selected filters if there are none selected.',
		default: false
	}, node && node.props.filterSource === 'category' && {
		name: 'hideHiddenCategories',
		inputType: 'checkbox',
		label: 'Hide categories that are hidden',
		default: false
	}, {
		name: 'filterStyle',
		inputType: 'select',
		label: 'Style',
		values: [{
			label: 'Accordion',
			value: 'accordion'
		}, {
			label: 'Dropdown',
			value: 'dropdownBox'
		}],
		default: 'dropdownBox'
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'startOpen',
		inputType: 'checkbox',
		label: 'Start accordion open',
		default: false
	}, {
		name: 'filterType',
		inputType: 'select',
		label: 'Type',
		values: [{
			label: 'Button',
			value: 'button'
		}, {
			label: 'Checkbox',
			value: 'checkbox'
		}],
		default: 'button'
	}, {
		name: 'interactionType',
		inputType: 'select',
		label: 'Interaction',
		values: [{
			label: 'Hover',
			value: 'hover'
		}, {
			label: 'Click',
			value: 'click'
		}],
		default: 'hover'
	}, {
		name: 'filterHeader',
		inputType: 'text',
		label: 'Filter header',
		default: 'Filter header'
	}, {
		name: 'hideIdZero',
		inputType: 'checkbox',
		label: 'Hide filter options where key has ID 0',
		default: false
	}, {
		name: 'showActiveFilterCount',
		inputType: 'checkbox',
		label: 'Show how many filters are selected for each filter type',
		default: false
	}, {
		name: 'showFilterItemCount',
		inputType: 'checkbox',
		label: 'Show how many items are in each filter',
		default: false
	}, node && node.props.interactionType === 'click' && {
		name: 'iconType',
		inputType: 'select',
		label: 'Choose icon type',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconOpen',
		inputType: 'icon',
		label: 'Choose open icon',
		default: false
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconOpenSize',
		inputType: 'text',
		label: 'Set size on open icon',
		default: '2em'
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconOpenColor',
		inputType: 'color',
		label: 'Set color on open icon',
		default: '#000'
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconClosed',
		inputType: 'icon',
		label: 'Choose closed icon',
		default: false
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconClosedSize',
		inputType: 'text',
		label: 'Set size on closed icon',
		default: '2em'
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconClosedColor',
		inputType: 'color',
		label: 'Set color on closed icon',
		default: '#000'
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconDropdown',
		inputType: 'icon',
		label: 'Choose dropdown open icon',
		default: false
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconDropdownSize',
		inputType: 'text',
		label: 'Set size on dropdown open icon',
		default: '2em'
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconDropdownColor',
		inputType: 'color',
		label: 'Set color on dropdown open icon',
		default: '#000'
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconDropdownClose',
		inputType: 'icon',
		label: 'Choose dropdown close icon',
		default: false
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconDropdownCloseSize',
		inputType: 'text',
		label: 'Set size on dropdown close icon',
		default: '2em'
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType !== 'image' && {
		name: 'iconDropdownCloseColor',
		inputType: 'color',
		label: 'Set color on dropdown close icon',
		default: '#000'
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType === 'image' && {
		name: 'imageAccordionOpen',
		inputType: 'media-v2',
		label: 'Choose open accordion image',
		default: ''
	}, node && node.props.filterStyle === 'accordion' && node.props.interactionType === 'click' && node.props.iconType === 'image' && {
		name: 'imageAccordionClose',
		inputType: 'media-v2',
		label: 'Choose close accordion image',
		default: ''
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType === 'image' && {
		name: 'imageDropdownOpen',
		inputType: 'media-v2',
		label: 'Choose open dropdown image',
		default: ''
	}, node && node.props.filterStyle === 'dropdownBox' && node.props.interactionType === 'click' && node.props.iconType === 'image' && {
		name: 'imageDropdownClose',
		inputType: 'media-v2',
		label: 'Choose close dropdown image',
		default: ''
	}].filter(Boolean)
});
