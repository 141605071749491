export default {
	type: 'SHOPCART_ROWS',
	name: 'Shopcart rows',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: [],
	category: 'Shopcart',
	props: [{
		name: 'filterRows',
		inputType: 'select',
		label: 'Filter',
		default: 'both',
		values: [{
			label: 'Show single purchases',
			value: 'single'
		}, {
			label: 'Show subscriptions',
			value: 'subscriptions'
		}, {
			label: 'Show both',
			value: 'both'
		}]
	}]
};
