import cmsComponentArticlePackage from '~/../../node_modules/@viskan/cms-component-article-package/dist/reducer';
import cmsComponentArticlePackageSimple from '~/../../node_modules/@viskan/cms-component-article-package-simple/dist/reducer';
import cmsComponentBreadcrumbs from '~/../../node_modules/@viskan/cms-component-breadcrumbs/dist/reducer';
import cmsComponentCategoryImport from '~/../../node_modules/@viskan/cms-component-category-import/dist/reducer';
import cmsComponentDropdown from '~/../../node_modules/@viskan/cms-component-dropdown/dist/reducer';
import cmsComponentMyPagesSubscriptions from '~/../../node_modules/@viskan/cms-component-my-pages-subscriptions/dist/reducer';
import cmsComponentMyPagesSubscriptionPayment from '~/../../node_modules/@viskan/cms-component-my-pages-subscription-payment/dist/reducer';
import cmsComponentPopup from '~/../../node_modules/@viskan/cms-component-popup/dist/reducer';
import cmsComponentShopTheLook from '~/../../node_modules/@viskan/cms-component-shop-the-look/dist/reducer';
import cmsComponentSlideout from '~/../../node_modules/@viskan/cms-component-slideout/dist/reducer';
import cmsComponentSubMenu from '~/../../node_modules/@viskan/cms-component-sub-menu/dist/reducer';
import cmsComponentSubMenuDropdown from '~/../../node_modules/@viskan/cms-component-sub-menu-dropdown/dist/reducer';
import cmsComponentCheckout from '~/../../node_modules/@viskan/cms-component-checkout/dist/reducer';
import cmsComponentCheckoutShipping from '~/../../node_modules/@viskan/cms-component-checkout-shipping/dist/reducer';
import cmsComponentCheckoutVoucher from '~/../../node_modules/@viskan/cms-component-checkout-voucher/dist/reducer';
import cmsComponentCheckoutKlarnaPayments from '~/../../node_modules/@viskan/cms-component-checkout-klarna-payments/dist/reducer';
import cmsComponentQuickCart from '~/../../node_modules/@viskan/cms-component-quick-cart/dist/reducer';
import cmsComponentShowOnAction from '~/../../node_modules/@viskan/cms-component-show-on-action/dist/reducer';
import cmsComponentDiggecard from '~/../../node_modules/@viskan/cms-component-diggecard/dist/reducer';

export default {
	articlePackage: cmsComponentArticlePackage,
	articlePackageSimple: cmsComponentArticlePackageSimple,
	breadcrumbs: cmsComponentBreadcrumbs,
	categoryImport: cmsComponentCategoryImport,
	dropdown: cmsComponentDropdown,
	subscriptions: cmsComponentMyPagesSubscriptions,
	subscriptionPayment: cmsComponentMyPagesSubscriptionPayment,
	popup: cmsComponentPopup,
	articleShopTheLook: cmsComponentShopTheLook,
	slideout: cmsComponentSlideout,
	subMenu: cmsComponentSubMenu,
	subMenuDropdown: cmsComponentSubMenuDropdown,
	checkout: cmsComponentCheckout,
	shipping: cmsComponentCheckoutShipping,
	voucher: cmsComponentCheckoutVoucher,
	klarnaPayments: cmsComponentCheckoutKlarnaPayments,
	quickCart: cmsComponentQuickCart,
	showOnAction: cmsComponentShowOnAction,
	diggecard: cmsComponentDiggecard
};