import {generateMediaURL} from '@viskan/streamline-utilities';
import queryString from 'query-string';
import {matchesOnAttribute2, getMatchingImages} from '~/utils';

const getUrl = item => {
	const query = queryString.stringify({
		attr1_id: item.attr1_id !== 0 ? item.attr1_id : undefined,
		attr2_id: item.attr2_id !== 0 ? item.attr2_id : undefined,
		attr3_id: item.attr3_id !== 0 ? item.attr3_id : undefined
	});

	return `${window.location.origin}${window.location.pathname}${query ? '?' : ''}${query}`;
};

// Strips the description of any html tags
const getDescription = description => {
	const tempElement = document.createElement('div');

	tempElement.innerHTML = description;

	return tempElement.textContent;
};

const getVariesBy = articleAttributes => {
	const uniqueAttr1Ids = [...new Set(articleAttributes.map(item => item.attribute1.attr1_id))];
	const uniqueAttr2Ids = [...new Set(articleAttributes.map(item => item.attribute2.attr2_id))];

	const variesBy = [];

	if (uniqueAttr1Ids.length > 1) {
		variesBy.push('https://schema.org/color');
	}

	if (uniqueAttr2Ids.length > 1) {
		variesBy.push('https://schema.org/size');
	}

	return variesBy;
};

const getProductMarkup = (article, site) => {
	if (!article.art_id) {
		return JSON.stringify([]);
	}

	const {currency_code} = site;
	const {articleAttributes} = article;
	let productString;

	const variants = articleAttributes.map(item => {
		const matchOnAttr2 = site.settings.isSizeMatchRequired ? matchesOnAttribute2(article.articleAttributes, article.articleAttributeMedias, item) : false;

		const imageSettings = {...site.settings, matchOnAttr2, fallbackImage: site.settings.useFallbackImage ? site.settings.useFallbackImage : false};

		const media = getMatchingImages(article, item, imageSettings).map(m => generateMediaURL(m.filename, {scale: 'normal'}, site.mediaConfig));

		return {
			'@type': 'Product',
			'@id': `${article.art_id}-${item.attr1_id}-${item.attr2_id}-${item.attr3_id}`,
			'productID': article.art_id,
			'name': article.artname,
			'description': getDescription(article.art_desc || article.art_desc2),
			'image': media,
			'mpn': article.art_no,
			'color': item.attribute1.attr1_id !== 0 ? item.attribute1.attr1_code : '',
			'size': item.attribute2.attr2_id !== 0 ? item.attribute2.attr2_code : '',
			'sku': item.pluno || `${article.art_no}-${item.attr1_id}-${item.attr2_id}-${item.attr3_id}`,
			'offers': {
				'@type': 'Offer',
				'availability': item.inStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock',
				'itemCondition': 'http://schema.org/NewCondition',
				'url': getUrl(item),
				'priceSpecification': {
					'@type': 'PriceSpecification',
					'priceCurrency': currency_code,
					'price': item.articlePrice.price_sales,
					'valueAddedTaxIncluded': true
				}
			}
		};
	});

	const json = [{
		'@type': 'ProductGroup',
		'@id': `${article.art_id}`,
		'productGroupID': article.art_id,
		'name': article.artname,
		'description': getDescription(article.art_desc || article.art_desc2),
		'brand': {
			'@type': 'Brand',
			'name': article.brandName
		},
		'url': `${window.location.origin}${window.location.pathname}`,
		'hasVariant': variants,
		'variesBy': getVariesBy(articleAttributes)
	}];

	try {
		productString = JSON.stringify(json);
	} catch (error) {
		productString = JSON.stringify([]);
	}

	return productString;
};

export default getProductMarkup;
