export default {
	type: 'CHECKOUT_INCOMING_CONSENT',
	name: 'Incoming Consent',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Checkout',
	icon: 'fal fa-check-square',
	styleClasses: {
		Wrapper: '',
		Label: '',
		Checkbox: ''
	},
	props: [{
		name: 'label',
		inputType: 'text',
		label: 'Label text',
		default: 'Lorem ipsum'
	}]
};
