const conditionallyAddConfig = (node, key, conditionStr, configArray) => (node && node.props[key] === conditionStr) ? configArray : [];

const iconConfig = [{
	name: 'favoriteIcon',
	inputType: 'icon',
	label: 'Icon',
	default: '{"prefix":"fal","x":{"name":"heart"}}'
}, {
	name: 'favoriteIconSelected',
	inputType: 'icon',
	label: 'Icon when selected',
	default: '{"prefix":"fas","x":{"name":"heart"}}'
}, {
	name: 'favoriteIconSize',
	inputType: 'text',
	label: 'Set size on icon',
	default: '1em'
}, {
	name: 'favoriteIconColor',
	inputType: 'color',
	label: 'Set color on icon',
	default: '#000'
}];

const imageConfig = [{
	name: 'image',
	inputType: 'media-v2',
	label: 'Image',
	default: ''
}, {
	name: 'imageSelected',
	inputType: 'media-v2',
	label: 'Image when selected',
	default: ''
}];

const textConfig = [{
	name: 'text',
	inputType: 'text',
	label: 'Button text',
	default: 'Add favorite'
}, {
	name: 'textSelected',
	inputType: 'text',
	label: 'Button text when selected',
	default: 'Remove favorite'
}];

export default node => ({
	type: 'ARTICLE_FAVORITE',
	name: 'Favorite',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fas fa-heart',
	props: [{
		name: 'displayType',
		inputType: 'select',
		label: 'How should the button be displayed?',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Icon and Text',
			value: 'iconAndText'
		}, {
			label: 'Image',
			value: 'image'
		}, {
			label: 'Image and Text',
			value: 'imageAndText'
		}, {
			label: 'Text',
			value: 'text'
		}]
	},
	...conditionallyAddConfig(node, 'displayType', 'icon', iconConfig),
	...conditionallyAddConfig(node, 'displayType', 'iconAndText', iconConfig),
	...conditionallyAddConfig(node, 'displayType', 'image', imageConfig),
	...conditionallyAddConfig(node, 'displayType', 'imageAndText', imageConfig),
	...conditionallyAddConfig(node, 'displayType', 'text', textConfig),
	...conditionallyAddConfig(node, 'displayType', 'iconAndText', textConfig),
	...conditionallyAddConfig(node, 'displayType', 'imageAndText', textConfig)]
});
