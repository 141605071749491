const initialState = () => ({
	orders: []
});

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CUSTOMER_ORDERS': {
			return {
				...state,
				orders: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
