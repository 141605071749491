import loadable from '@viskan/deku-loadable';
import ARTICLE_PROPERTIES_CUSTOM_CONFIG from './customer-unique/properties-custom/config';
import ARTICLE_INCOMING_TEXT_CONFIG from './customer-unique/article-incoming-text/config';
import CATEGORY_ALPHABETICAL_LIST_CONFIG from './customer-unique/category-alphabetical-list/config';
import CHECKOUT_SHIPPING_SPORTSHOPEN_CONFIG from './customer-unique/checkout-shipping/config';
import CHECKOUT_INCOMING_CONSENT_CONFIG from './customer-unique/checkout-incoming-consent/config';
import SHOPCART_CAMPAIGN_INFORMATION_CONFIG from './customer-unique/shopcart-campaign-info/config';
import SHOPCART_INCOMING_TEXT_CONFIG from './customer-unique/shopcart-incoming-text/config';
import SHOPCART_ROW_PRICES_CONFIG from './customer-unique/shopcart-row-prices/config';
import SHOW_MORE_CONFIG from './customer-unique/show-more/config';
import STICKY_OUT_OF_VIEWPORT_CONFIG from './customer-unique/sticky-out-of-viewport/config';
import LINDBAK_CONFIG from './customer-unique/lindbak/config';
import ARTICLE_ADDONS_CONFIG from './customer-unique/article-addons/config';
import LIPSCORE_WIDGET_CONFIG from './customer-unique/lipscore-widget/config';
import TABS_CONTAINER_CONFIG from './tabs-container/config';
import MY_PAGES_ORDERS_CONFIG from './customer-unique/my-pages-orders/config';

const ARTICLE_PROPERTIES_CUSTOM = loadable(() => import('./customer-unique/properties-custom'));
const ARTICLE_INCOMING_TEXT = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './customer-unique/article-incoming-text'));
const CATEGORY_ALPHABETICAL_LIST = loadable(() => import('./customer-unique/category-alphabetical-list'));
const CHECKOUT_SHIPPING_SPORTSHOPEN = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ './customer-unique/checkout-shipping'));
const CHECKOUT_INCOMING_CONSENT = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ './customer-unique/checkout-incoming-consent'));
const SHOPCART_CAMPAIGN_INFORMATION = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './customer-unique/shopcart-campaign-info'));
const SHOPCART_INCOMING_TEXT = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './customer-unique/shopcart-incoming-text'));
const SHOPCART_ROW_PRICES = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './customer-unique/shopcart-row-prices'));
const SHOW_MORE = loadable(() => import('./customer-unique/show-more'));
const STICKY_OUT_OF_VIEWPORT = loadable(() => import('./customer-unique/sticky-out-of-viewport'));
const LINDBAK = loadable(() => import('./customer-unique/lindbak'));
const ARTICLE_ADDONS = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './customer-unique/article-addons'));
const LIPSCORE_WIDGET = loadable(() => import('./customer-unique/lipscore-widget'));
const TABS_CONTAINER = loadable(() => import('./tabs-container/'));
const MY_PAGES_ORDERS = loadable(() => import('./customer-unique/my-pages-orders'));

export default {
	ARTICLE_PROPERTIES_CUSTOM: {
		component: ARTICLE_PROPERTIES_CUSTOM,
		config: ARTICLE_PROPERTIES_CUSTOM_CONFIG
	},
	ARTICLE_ADDONS: {
		component: ARTICLE_ADDONS,
		config: ARTICLE_ADDONS_CONFIG
	},
	ARTICLE_INCOMING_TEXT: {
		component: ARTICLE_INCOMING_TEXT,
		config: ARTICLE_INCOMING_TEXT_CONFIG
	},
	CATEGORY_ALPHABETICAL_LIST: {
		component: CATEGORY_ALPHABETICAL_LIST,
		config: CATEGORY_ALPHABETICAL_LIST_CONFIG
	},
	CHECKOUT_SHIPPING_SPORTSHOPEN: {
		component: CHECKOUT_SHIPPING_SPORTSHOPEN,
		config: CHECKOUT_SHIPPING_SPORTSHOPEN_CONFIG
	},
	CHECKOUT_INCOMING_CONSENT: {
		component: CHECKOUT_INCOMING_CONSENT,
		config: CHECKOUT_INCOMING_CONSENT_CONFIG
	},
	SHOPCART_CAMPAIGN_INFORMATION: {
		component: SHOPCART_CAMPAIGN_INFORMATION,
		config: SHOPCART_CAMPAIGN_INFORMATION_CONFIG
	},
	SHOPCART_INCOMING_TEXT: {
		component: SHOPCART_INCOMING_TEXT,
		config: SHOPCART_INCOMING_TEXT_CONFIG
	},
	SHOPCART_ROW_PRICES: {
		component: SHOPCART_ROW_PRICES,
		config: SHOPCART_ROW_PRICES_CONFIG
	},
	SHOW_MORE: {
		component: SHOW_MORE,
		config: SHOW_MORE_CONFIG
	},
	STICKY_OUT_OF_VIEWPORT: {
		component: STICKY_OUT_OF_VIEWPORT,
		config: STICKY_OUT_OF_VIEWPORT_CONFIG
	},
	LINDBAK: {
		component: LINDBAK,
		config: LINDBAK_CONFIG
	},
	LIPSCORE_WIDGET: {
		component: LIPSCORE_WIDGET,
		config: LIPSCORE_WIDGET_CONFIG
	},
	TABS_CONTAINER: {
		component: TABS_CONTAINER,
		config: TABS_CONTAINER_CONFIG
	},
	MY_PAGES_ORDERS: {
		component: MY_PAGES_ORDERS,
		config: MY_PAGES_ORDERS_CONFIG
	}
};
