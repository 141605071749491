import editorLayout from './editor-layout';

export default node => ({
	type: 'TABS_CONTAINER',
	name: 'Tabs container',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*', '!TAB', '!TAB_*'],
	category: 'Layout',
	icon: 'fal fa-folder-open',
	editorLayout: editorLayout(node),
	styleClasses: {
		Tabs_Container: '',
		Heading: '',
		Tab: '',
		Wrapper: '',
		Headings_Wrapper: '',
		Content_Wrapper: '',
		Element: '',
		Content: '',
		Accordion: ''
	},
	props: [{
		name: 'spaceTabsEvenly',
		inputType: 'checkbox',
		label: 'Space tabs evenly',
		default: false
	}, {
		name: 'accordionOnMobile',
		inputType: 'checkbox',
		label: 'Accordion on mobile',
		default: false
	}, {
		name: 'accordionOnTablet',
		inputType: 'checkbox',
		label: 'Accordion on tablet',
		default: false
	}, {
		name: 'accordionOnDesktop',
		inputType: 'checkbox',
		label: 'Accordion on desktop',
		default: false
	}, {
		name: 'keepTabsOpen',
		inputType: 'checkbox',
		label: 'Allow multiple accordion tabs to be open',
		default: false
	}, {
		name: 'renderInactiveTabs',
		inputType: 'checkbox',
		label: 'Render inactive tabs',
		default: true
	}, {
		name: 'renderAsContentScrollIndicator',
		inputType: 'checkbox',
		label: 'Render as "content scroll indicator"',
		description: 'Will show all tabs and change active tab header depending on what\'s in viewport',
		default: false
	}]
});
