import loadable from '@viskan/deku-loadable';
import ARTICLE_ADDONS_CONFIG from './article/addons/config';
import ARTICLE_ADDONS_NEW_CONFIG from './article/addons-new/config';
import ARTICLE_ATTRIBUTE_CONFIG from './article/attribute/config';
import ARTICLE_BUY_BUTTON_CONFIG from './article/buy-button/config';
import ARTICLE_CAMPAIGNS_CONFIG from './article/campaigns/config';
import ARTICLE_FAVORITE_CONFIG from './article/favorite/config';
import ARTICLE_FIELD_CONFIG from './article/field/config';
import ARTICLE_MEDIA_CONFIG from './article/media/config';
import ARTICLE_PRICE_CONFIG from './article/price/config';
import ARTICLE_BACK_IN_STOCK_CONFIG from './article/back-in-stock/config';
import ARTICLE_SUBSCRIPTION_INTERVAL_CONFIG from './article/subscription-interval/config';
import ARTICLE_PROPERTIES_CONFIG from './article/properties/config';
import ARTICLE_QUANTITY_CONFIG from './article/quantity/config';
import ARTICLE_RELATED_CONFIG from './article/related/config';
import ARTICLE_STORE_BALANCE_CONFIG from './article/store-balance/config';
import ARTICLE_STOCK_STATUS_CONFIG from './article/stock-status/config';
import ARTICLE_SUBSCRIPTIONS_CONFIG from './article/subscriptions/config';
import ARTICLE_THUMBNAILS_CONFIG from './article/thumbnails/config';
import ARTICLES_CONFIG from './articles/config';
import ARTICLES_SLIDER_CONFIG from './articles-slider/config';
import CATEGORY_CONTENT_SEARCH_CONFIG from './category-content-search/config';
import BLURB_OFFER_CONFIG from './blurb-offer/config';
import FILTER_CONFIG from './filter/config';
import FILTER_RESET_CONFIG from './filter-reset/config';
import FILTER_SLIDER_CONFIG from './filter-slider/config';
import FIND_STORE_CONFIG from './find-store/config';
import HELLO_RETAIL_FETCHER from './hello-retail/fetcher';
import HELLO_RETAIL_RECOMMENDATIONS from './hello-retail/recommendations';
import MAIN_NAVIGATION_01_CONFIG from './main-navigation/config';
import RECEIPT_FIELD_CONFIG from './receipt-field/config';
import SEARCH_FORM_CONFIG from './search-form/config';
import SHOPCART_ROWS_CONFIG from './shopcart/rows/config';
import SHOPCART_ROWS_ACTION_CONFIG from './shopcart/rows-action/config';
import SHOPCART_ROWS_FIELD_CONFIG from './shopcart/rows-field/config';
import SHOPCART_SUMMARY_FIELD_CONFIG from './shopcart/summary-field/config';
import YOUTUBE_EMBED_CONFIG from './article/youtube-embed/config';
import extend from './index-extend';

const ARTICLE_ADDONS = loadable(() => import('./article/addons'));
const ARTICLE_ADDONS_NEW = loadable(() => import('./article/addons-new'));
const ARTICLE_ATTRIBUTE = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/attribute'));
const ARTICLE_BUY_BUTTON = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/buy-button'));
const ARTICLE_CAMPAIGNS = loadable(() => import('./article/campaigns'));
const ARTICLE_FAVORITE = loadable(() => import('./article/favorite'));
const ARTICLE_FIELD = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/field'));
const ARTICLE_SUBSCRIPTION_INTERVAL = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/subscription-interval'));
const ARTICLE_MEDIA = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/media'));
const ARTICLE_PRICE = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/price'));
const ARTICLE_BACK_IN_STOCK = loadable(() => import('./article/back-in-stock'));
const ARTICLE_PROPERTIES = loadable(() => import('./article/properties'));
const ARTICLE_QUANTITY = loadable(() => import('./article/quantity'));
const ARTICLE_RELATED = loadable(() => import('./article/related'));
const ARTICLE_STORE_BALANCE = loadable(() => import('./article/store-balance'));
const ARTICLE_STOCK_STATUS = loadable(() => import('./article/stock-status'));
const ARTICLE_SUBSCRIPTIONS = loadable(() => import('./article/subscriptions'));
const ARTICLE_THUMBNAILS = loadable(() => import(/* webpackChunkName: "cms-component-article" */ './article/thumbnails'));
const ARTICLES = loadable(() => import('./articles'));
const ARTICLES_SLIDER = loadable(() => import('./articles-slider'));
const BLURB_OFFER = loadable(() => import('./blurb-offer'));
const CATEGORY_CONTENT_SEARCH = loadable(() => import('./category-content-search'));
const FILTER = loadable(() => import(/* webpackChunkName: "cms-component-filter" */ './filter'));
const FILTER_RESET = loadable(() => import(/* webpackChunkName: "cms-component-filter" */ './filter-reset'));
const FILTER_SLIDER = loadable(() => import(/* webpackChunkName: "cms-component-filter" */ './filter-slider'));
const FIND_STORE = loadable(() => import('./find-store'));
const MAIN_NAVIGATION_01 = loadable(() => import('./main-navigation'));
const RECEIPT_FIELD = loadable(() => import('./receipt-field'));
const SEARCH_FORM = loadable(() => import('./search-form'));
const SHOPCART_ROWS = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './shopcart/rows'));
const SHOPCART_ROWS_ACTION = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './shopcart/rows-action'));
const SHOPCART_ROWS_FIELD = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './shopcart/rows-field'));
const SHOPCART_SUMMARY_FIELD = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ './shopcart/summary-field'));
const YOUTUBE_EMBED = loadable(() => import('./article/youtube-embed'));

export default {
	ARTICLE_ADDONS: {
		component: ARTICLE_ADDONS,
		config: ARTICLE_ADDONS_CONFIG
	},
	ARTICLE_ADDONS_NEW: {
		component: ARTICLE_ADDONS_NEW,
		config: ARTICLE_ADDONS_NEW_CONFIG
	},
	ARTICLE_ATTRIBUTE: {
		component: ARTICLE_ATTRIBUTE,
		config: ARTICLE_ATTRIBUTE_CONFIG
	},
	ARTICLE_BUY_BUTTON: {
		component: ARTICLE_BUY_BUTTON,
		config: ARTICLE_BUY_BUTTON_CONFIG
	},
	ARTICLE_CAMPAIGNS: {
		component: ARTICLE_CAMPAIGNS,
		config: ARTICLE_CAMPAIGNS_CONFIG
	},
	ARTICLE_FAVORITE: {
		component: ARTICLE_FAVORITE,
		config: ARTICLE_FAVORITE_CONFIG
	},
	ARTICLE_FIELD: {
		component: ARTICLE_FIELD,
		config: ARTICLE_FIELD_CONFIG
	},
	ARTICLE_SUBSCRIPTION_INTERVAL: {
		component: ARTICLE_SUBSCRIPTION_INTERVAL,
		config: ARTICLE_SUBSCRIPTION_INTERVAL_CONFIG
	},
	ARTICLE_MEDIA: {
		component: ARTICLE_MEDIA,
		config: ARTICLE_MEDIA_CONFIG
	},
	ARTICLE_PRICE: {
		component: ARTICLE_PRICE,
		config: ARTICLE_PRICE_CONFIG
	},
	ARTICLE_BACK_IN_STOCK: {
		component: ARTICLE_BACK_IN_STOCK,
		config: ARTICLE_BACK_IN_STOCK_CONFIG
	},
	ARTICLE_PROPERTIES: {
		component: ARTICLE_PROPERTIES,
		config: ARTICLE_PROPERTIES_CONFIG
	},
	ARTICLE_QUANTITY: {
		component: ARTICLE_QUANTITY,
		config: ARTICLE_QUANTITY_CONFIG
	},
	ARTICLE_RELATED: {
		component: ARTICLE_RELATED,
		config: ARTICLE_RELATED_CONFIG
	},
	ARTICLE_STORE_BALANCE: {
		component: ARTICLE_STORE_BALANCE,
		config: ARTICLE_STORE_BALANCE_CONFIG
	},
	ARTICLE_STOCK_STATUS: {
		component: ARTICLE_STOCK_STATUS,
		config: ARTICLE_STOCK_STATUS_CONFIG
	},
	ARTICLE_SUBSCRIPTIONS: {
		component: ARTICLE_SUBSCRIPTIONS,
		config: ARTICLE_SUBSCRIPTIONS_CONFIG
	},
	ARTICLE_THUMBNAILS: {
		component: ARTICLE_THUMBNAILS,
		config: ARTICLE_THUMBNAILS_CONFIG
	},
	ARTICLES: {
		component: ARTICLES,
		config: ARTICLES_CONFIG
	},
	ARTICLES_SLIDER: {
		component: ARTICLES_SLIDER,
		config: ARTICLES_SLIDER_CONFIG
	},
	BLURB_OFFER: {
		component: BLURB_OFFER,
		config: BLURB_OFFER_CONFIG
	},
	CATEGORY_CONTENT_SEARCH: {
		component: CATEGORY_CONTENT_SEARCH,
		config: CATEGORY_CONTENT_SEARCH_CONFIG
	},
	FILTER: {
		component: FILTER,
		config: FILTER_CONFIG
	},
	FILTER_RESET: {
		component: FILTER_RESET,
		config: FILTER_RESET_CONFIG
	},
	FILTER_SLIDER: {
		component: FILTER_SLIDER,
		config: FILTER_SLIDER_CONFIG
	},
	FIND_STORE: {
		component: FIND_STORE,
		config: FIND_STORE_CONFIG
	},
	HELLO_RETAIL_FETCHER,
	HELLO_RETAIL_RECOMMENDATIONS,
	MAIN_NAVIGATION_01: {
		component: MAIN_NAVIGATION_01,
		config: MAIN_NAVIGATION_01_CONFIG
	},
	RECEIPT_FIELD: {
		component: RECEIPT_FIELD,
		config: RECEIPT_FIELD_CONFIG
	},
	SEARCH_FORM: {
		component: SEARCH_FORM,
		config: SEARCH_FORM_CONFIG
	},
	SHOPCART_ROWS: {
		component: SHOPCART_ROWS,
		config: SHOPCART_ROWS_CONFIG
	},
	SHOPCART_ROWS_ACTION: {
		component: SHOPCART_ROWS_ACTION,
		config: SHOPCART_ROWS_ACTION_CONFIG
	},
	SHOPCART_ROWS_FIELD: {
		component: SHOPCART_ROWS_FIELD,
		config: SHOPCART_ROWS_FIELD_CONFIG
	},
	SHOPCART_SUMMARY_FIELD: {
		component: SHOPCART_SUMMARY_FIELD,
		config: SHOPCART_SUMMARY_FIELD_CONFIG
	},
	YOUTUBE_EMBED: {
		component: YOUTUBE_EMBED,
		config: YOUTUBE_EMBED_CONFIG
	},
	...extend
};
