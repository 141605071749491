import {deepEqual} from 'fast-equals';
import getBreadcrumbsMarkup from '../breadcrumbs';
import getProductMarkup from '../products';

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const products = getProductMarkup(props.article, props.site);
	const breadcrumbs = !props.isLandingPage ? getBreadcrumbsMarkup(props.tree, props.activeCategoryId, props.site) : '';

	const baseString = `{
		"@context": "http://schema.org",
		"@graph":
		[
		  ${products}
		  ${breadcrumbs && ','}
		  ${breadcrumbs}
		]
	  }`;

	return (
		<script type='application/ld+json'>
			{baseString}
		</script>
	);
};

export default {render, shouldUpdate, name: 'StrucuredDataMarkup'};
