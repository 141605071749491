const conditionallyAddConfig = (node, key, conditionStr, configArray) => (node && node.props[key] === conditionStr) ? configArray : [];

const iconConfig = [{
	name: 'iconActive',
	inputType: 'icon',
	label: 'Choose active icon',
	default: false
}, {
	name: 'iconActiveSize',
	inputType: 'text',
	label: 'Set size on active icon',
	default: '2em'
}, {
	name: 'iconActiveColor',
	inputType: 'color',
	label: 'Set color on active icon',
	default: '#000'
}, {
	name: 'iconUnactive',
	inputType: 'icon',
	label: 'Choose unactive icon',
	default: false
}, {
	name: 'iconUnactiveSize',
	inputType: 'text',
	label: 'Set size on unactive icon',
	default: '2em'
}, {
	name: 'iconUnactiveColor',
	inputType: 'color',
	label: 'Set color on unactive icon',
	default: '#000'
}];

export default node => ({
	type: 'ARTICLE_ADDONS',
	name: 'Addons',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	styleClasses: {
		Wrapper: 'Preset-ArticleAddons-Wrapper',
		Select_Wrapper: '',
		Select: '',
		Options: '',
		Option: '',
		Text: '',
		Text_Label: '',
		Text_Input: '',
		Article_Wrapper: '',
		Article_Artname: '',
		Article_Price: '',
		Article_Info: '',
		Image_Wrapper: 'Preset-Article-ImageWrapper',
		Icon: 'Preset-Article-Icon'
	},
	props: [{
		name: 'relationNumber',
		label: 'Relation Number',
		inputType: 'text',
		default: '0'
	}, {
		name: 'layout',
		label: 'Layout',
		inputType: 'select',
		default: 'attributeList',
		values: [{
			label: 'Attribute list',
			value: 'attributeList'
		}, {
			label: 'Text',
			value: 'text'
		}]
	}, node && node.props.layout === 'text' && {
		name: 'textFieldPlaceholder',
		label: 'Placeholder for text input',
		inputType: 'text',
		default: 'Text...'
	}, node && node.props.layout === 'attributeList' && {
		name: 'selectFieldPlaceholder',
		label: 'Placeholder for dropdown',
		inputType: 'text',
		default: 'Select your ...'
	}, ...conditionallyAddConfig(node, 'layout', 'attributeList', iconConfig)].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});

