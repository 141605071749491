import editorLayout from './editor-layout';

export default node => ({
	type: 'SEARCH_FORM',
	name: 'Search',
	allowedSections: ['header', 'main'],
	disallowedChildren: ['*'],
	category: 'Header',
	icon: 'fal fa-search',
	editorLayout,
	styleClasses: {
		Category: '',
		'Category_Title': '',
		SearchForm: '',
		SearchInput: '',
		Icon: '',
		Image: '',
		Dropdown: '',
		Menu: '',
		Article: 'Preset-SearchForm-Article',
		ImageWrapper: 'Preset-SearchForm-ImageWrapper',
		InfoWrapper: 'Preset-SearchForm-InfoWrapper',
		Title: '',
		Price: '',
		ResultLink: '',
		'Lowest_Price_Wrapper': '',
		'Lowest_Price_Prefix': '',
		'Lowest_Price': '',
		'Lowest_Price_Suffix': '',
		'SearchResult_Wrapper': '',
		'SearchResult_Articles': '',
		'SearchResult_Articles_Title': '',
		'SearchResult_Articles_No_Results_Text': '',
		'SearchResult_Categories': '',
		'SearchResult_Categories_Title': '',
		'SearchResult_Categories_No_Results_Text': ''
	},
	props: [{
		name: 'searchEngine',
		inputType: 'select',
		label: 'Search engine',
		default: 'viskanEtm',
		values: [{
			label: 'Viskan ETM',
			value: 'viskanEtm'
		}, {
			label: 'Hello Retail Search',
			value: 'helloRetail'
		}]
	}, {
		name: 'searchType',
		inputType: 'select',
		label: 'What to search for',
		default: 'articles',
		values: [{
			label: 'Articles',
			value: 'articles'
		}, {
			label: 'Categories',
			value: 'categories',
		}, {
			label: 'Articles and categories',
			value: 'articlesAndCategories'
		}]
	}, {
		name: 'goToSearchPageOnSubmit',
		inputType: 'checkbox',
		label: 'Go to search page on submit',
		default: true
	}, node && node.props.searchEngine === 'helloRetail' && {
		name: 'helloRetailSearchKey',
		inputType: 'text',
		label: 'Hello Retail Search Key',
		default: ''
	}, {
		name: 'placeholder',
		inputType: 'text',
		label: 'Search placeholder',
		default: 'Search...'
	}, {
		name: 'maxNumberOfArticles',
		inputType: 'number',
		label: 'Maximum number of articles in result',
		default: 5
	}, {
		name: 'maxNumberOfCategories',
		inputType: 'number',
		label: 'Maximum number of categories in result',
		default: 5
	}, {
		name: 'searchResultArticlesTitle',
		inputType: 'text',
		label: 'Search result articles title',
		default: ''
	}, {
		name: 'searchResultArticlesNoResults',
		inputType: 'text',
		description: 'Only visible when search type is `Articles and categories`',
		label: 'Search result articles not found text',
		default: 'No results'
	}, {
		name: 'searchResultCategoriesTitle',
		inputType: 'text',
		label: 'Search result categories title',
		default: ''
	}, {
		name: 'searchResultCategoriesNoResults',
		inputType: 'text',
		description: 'Only visible when search type is `Articles and categories`',
		label: 'Search result categories not found text',
		default: 'No results'
	}, {
		name: 'scale',
		inputType: 'select',
		label: 'Image scale on search result',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, {
		name: 'icon',
		inputType: 'icon',
		label: 'Choose an icon',
		required: true,
		default: false
	}, {
		name: 'iconSize',
		inputType: 'text',
		label: 'Size on icon',
		default: '1em'
	}, {
		name: 'iconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#009ABF'
	}, {
		name: 'image',
		inputType: 'media-v2',
		label: 'Choose an image',
		default: ''
	}, {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}, {
		name: 'autofocus',
		inputType: 'checkbox',
		label: 'Autofocus search input',
		default: false
	}, {
		name: 'showAllResultsLink',
		inputType: 'checkbox',
		label: 'Show all results link',
		default: false
	}, {
		name: 'showLowestPrice',
		inputType: 'select',
		label: 'Show lowest price (when applicable)',
		description: 'This can override global setting `Show lowest price`',
		default: 'useStoreSetting',
		values: [{
			label: 'Use store setting: Show lowest price',
			value: 'useStoreSetting'
		}, {
			label: 'Show',
			value: 'show'
		}, {
			label: 'Hide',
			value: 'hide'
		}]
	}, {
		name: 'lowestPricePrefixText',
		inputType: 'text',
		label: 'Lowest price prefix text',
		default: ''
	}, {
		name: 'lowestPriceSuffixText',
		inputType: 'text',
		label: 'Lowest price suffix text',
		default: ''
	}].filter(Boolean)
});
