export default node => ({
	type: 'SHOPCART_SUMMARY_FIELD',
	name: 'Summary field',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		default: 'tot_to_pay',
		values: [{
			label: 'Campaign code',
			value: 'camp_code'
		}, {
			label: 'Free carriage left',
			value: 'free_carriage_left'
		}, {
			label: 'Original price',
			value: 'org_price'
		}, {
			label: 'Total quantity',
			value: 'tot_qty_wo_addons'
		}, {
			label: 'Total row summary price inclusive VAT',
			value: 'tot_rowsum_to_pay'
		}, {
			label: 'Total row summary price exclusive VAT',
			value: 'tot_rowsum_to_pay_exvat'
		}, {
			label: 'Price to pay',
			value: 'tot_to_pay'
		}, {
			label: 'Price to pay inclusive VAT',
			value: 'tot_to_pay_incvat'
		}, {
			label: 'Price to pay exclusive VAT',
			value: 'tot_to_pay_exvat'
		}, {
			label: 'Total saved',
			value: 'tot_saved'
		}, {
			label: 'VAT',
			value: 'tot_vat'
		}, {
			label: 'Total discount',
			value: 'total_discount'
		}, {
			label: 'Total discount inclusive sales',
			value: 'total_discount_w_sales'
		}, {
			label: 'Delivery carriage',
			value: 'wdel_carriage'
		}, {
			label: 'Payment carriage',
			value: 'wpay_carriage'
		}, {
			label: 'Offer descriptions',
			value: 'offerDescriptions'
		/* START CUSTOMER UNIQUE - Voucher amount field */
		}, {
			label: 'Vouchers amount',
			value: 'vouchersAmount'
		}]
		/* END CUSTOMER UNIQUE - Voucher amount field */
	}, {
		name: 'elementType',
		inputType: 'select',
		label: 'Select element type',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Paragraph',
			value: 'p'
		}, {
			label: 'Span',
			value: 'span'
		}]
	}, {
		name: 'label',
		inputType: 'text',
		label: 'Label',
		default: ''
	}, {
		name: 'hideEmpty',
		inputType: 'checkbox',
		label: 'Hide zero values',
		default: true
	}, node && node.props.hideEmpty === false && {
		name: 'zeroValueReplacement',
		inputType: 'text',
		label: 'Zero value replacement',
		default: ''
	}, {
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before',
		default: ''
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after',
		default: ''
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
