export default {
	type: 'ARTICLE_INCOMING_TEXT',
	name: 'Incoming Text',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-clock',
	styleClasses: {
		Wrapper: '',
		Prefix: '',
		Date: '',
		Suffix: ''
	},
	props: [{
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before date',
		default: 'Inkommande: '
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after date',
		default: ''
	}]
};
