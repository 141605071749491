import {connect} from '@viskan/deku-redux';
import * as shopcart from '../shopcart/actions';
import {open as openPopup} from '../popup-redux/actions';
import {open as openSlideout} from '../slideout/actions';
import {inlineQuickbuyOpen} from '../cms-components/articles/actions';
import QuickBuy from './components';

export default connect(
	state => ({
		isArticle: state.router.path.startsWith(`/${state.site.articleUrlPrefix}/`),
		pathPrefix: state.router.pathPrefix
	}),
	dispatch => ({
		openPopup: (content, options) => dispatch(openPopup(content, options)),
		openSlideout: (content, options) => dispatch(openSlideout(content, options)),
		inlineQuickbuyOpen: content => dispatch(inlineQuickbuyOpen(content)),
		addToCart: (data, sku) => dispatch(shopcart.addItem(data, sku, [], 1))
	})
)(QuickBuy);
