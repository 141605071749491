import Button from '@viskan/deku-button';
import debounce from 'debounce';
import page from 'page';
import ArticleData from '../../article';
import {type} from '@viskan/streamline-utilities';
import {QUICKBUY_ALWAYS, QUICKBUY_MULTIPLEVARIANTS} from '~/utils/constants';

const name = 'QuickBuy';

const addOrPromptSku = ({props}) => {
	const {addToCart, data, inlineQuickbuyOpen, isArticle, openPopup, openSlideout, pathPrefix, url, quickbuyInPopup, quickbuyLayout, slideoutSide, showOverlay} = props;

	if (isArticle && data.articleAttributes.length > 1) {
		const pageUrl = pathPrefix.endsWith('/') && url.startsWith('/') ? url.slice(1) : url;

		page(pathPrefix + pageUrl);
		return;
	}

	if (!isArticle && quickbuyLayout === 'inline' && ((type.isBoolean(quickbuyInPopup) && quickbuyInPopup) || quickbuyInPopup === QUICKBUY_ALWAYS || (quickbuyInPopup === QUICKBUY_MULTIPLEVARIANTS && data.articleAttributes.length > 1))) {
		inlineQuickbuyOpen(<ArticleData inline query={data.link_friendly_name} sku={{attr1_id: data.attr1_id}} attr1_id={data.attr1_id} art_id={data.art_id}/>);
		return;
	}

	if (!isArticle && quickbuyLayout === 'slideout' && ((type.isBoolean(quickbuyInPopup) && quickbuyInPopup) || quickbuyInPopup === QUICKBUY_ALWAYS || (quickbuyInPopup === QUICKBUY_MULTIPLEVARIANTS && data.articleAttributes.length > 1))) {
		openSlideout(<ArticleData slideout query={data.link_friendly_name} sku={{attr1_id: data.attr1_id}}/>, {side: slideoutSide, showOverlay});
		return;
	}

	// (type.isBoolean(quickbuyInPopup) && quickbuyInPopup) is for backwards-compability because quickbuyInPopup was a boolean
	if (!isArticle && ((type.isBoolean(quickbuyInPopup) && quickbuyInPopup) || quickbuyInPopup === QUICKBUY_ALWAYS || (quickbuyInPopup === QUICKBUY_MULTIPLEVARIANTS && data.articleAttributes.length > 1))) {
		openPopup(<ArticleData popup query={data.link_friendly_name} sku={{attr1_id: data.attr1_id}}/>, {class: 'QuickBuyPopup'});
		return;
	}

	const sku = props.sku ? props.sku : data.articleAttributes[0];
	debounce(() => addToCart(data, sku), 1000, true)();
};

const render = ({props}) => {
	const {data, quickbuyButtonText, quickbuyNotifyButtonText, styleClasses = {}} = props;
	const onClick = () => addOrPromptSku({props});
	const colorIsOutOfStock = props.data.articleAttributes.filter(sku => sku.attr1_id === props.data.attr1_id).every(sku => !sku.inStock);
	const isDisabled = props.addToCartOnClick ? false : !data.inStock;

	return <Button class={[name, styleClasses.QuickBuy]} disabled={isDisabled} onClick={onClick} name={name}>{colorIsOutOfStock ? quickbuyNotifyButtonText : quickbuyButtonText}</Button>;
};

export default {name, render};
