import editorLayout from './editor-layout';

const stockIconConfig = [{
	name: 'inStockIcon',
	inputType: 'icon',
	label: 'In stock icon',
	default: false
}, {
	name: 'inStockIconSize',
	inputType: 'text',
	label: 'Set size on in stock icon',
	default: '1em'
}, {
	name: 'inStockIconColor',
	inputType: 'color',
	label: 'Set color on in stock icon',
	default: '#000'
}, {
	name: 'fewInStockIcon',
	inputType: 'icon',
	label: 'few in stock icon',
	default: false
}, {
	name: 'fewInStockIconSize',
	inputType: 'text',
	label: 'Set size on few in stock icon',
	default: '1em'
}, {
	name: 'fewInStockIconColor',
	inputType: 'color',
	label: 'Set color on few in stock icon',
	default: '#000'
}, {
	name: 'outOfStockIcon',
	inputType: 'icon',
	label: 'Out of stock icon',
	default: false
}, {
	name: 'outOfStockIconSize',
	inputType: 'text',
	label: 'Set size on out of stock icon',
	default: '1em'
}, {
	name: 'outOfStockIconColor',
	inputType: 'color',
	label: 'Set color on out of stock icon',
	default: '#000'
}];

const stockImageConfig = [{
	name: 'inStockImage',
	inputType: 'media-v2',
	label: 'In stock image',
	default: ''
}, {
	name: 'fewInStockImage',
	inputType: 'media-v2',
	label: 'Few in stock image',
	default: ''
}, {
	name: 'outOfStockImage',
	inputType: 'media-v2',
	label: 'Out of stock image',
	default: ''
}];

const pinIconConfig = [{
	name: 'pinIcon',
	inputType: 'icon',
	label: 'Map pin icon',
	default: false
}, {
	name: 'pinIconSize',
	inputType: 'text',
	label: 'Set size on icon',
	default: '1em'
}, {
	name: 'pinIconColor',
	inputType: 'color',
	label: 'Set color on icon',
	default: '#000'
}];

const pinImageConfig = [{
	name: 'pinImage',
	inputType: 'media-v2',
	label: 'Map pin image',
	default: ''
}];

export default node => ({
	type: 'ARTICLE_STORE_BALANCE',
	name: 'Store Balance',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	editorLayout: editorLayout(node),
	styleClasses: {
		'Store Balance': '',
		'Balance List': '',
		'Balance List Item': '',
		'Balance List Item Media': '',
		'Balance List Item Media Image': '',
		'Balance List Item Label': '',
		'Balance List Item Size': '',
		'Balance List Item Sizes': '',
		'Search': '',
		'Search Input': '',
		'Search Geolocation': '',
		'Stores': '',
		'Store': '',
		'Store Wrapper': '',
		'Store Choose': '',
		'Store Choose Button': '',
		'Store Change Button': '',
		'Store Selected': '',
		'Store Name': '',
		'Store Map': '',
		'Store Map Image': '',
		'Store Address': '',
		'Store Zipcode': '',
		'Store Phone': '',
		'Store Hours': '',
		'Store Hours Weekdays': '',
		'Store Hours Saturday': '',
		'Store Hours Sunday': '',
		'Store Hours Label': '',
		'Store Hours Time': ''
	},
	icon: 'fal fa-warehouse-alt',
	props: [{
		name: 'renderAs',
		inputType: 'select',
		label: 'Show as',
		default: 'searchAndSetStore',
		values: [{
			label: 'Search and set store',
			value: 'searchAndSetStore'
		}, {
			label: 'Selected store',
			value: 'selectedStore'
		}]
	}, {
		name: 'slideoutIdentifier',
		inputType: 'text',
		label: 'Unique slideout identifier',
		description: 'must match the slideout identifier in the slideout component',
		default: ''
	}, {
		name: 'chooseStoreButtonText',
		inputType: 'text',
		label: 'Choose store button text',
		default: 'Choose store'
	}, {
		name: 'changeStoreButtonText',
		inputType: 'text',
		label: 'Change store button text',
		default: 'Change store'
	}, {
		name: 'selectedStoreText',
		inputType: 'text',
		label: 'Selected store text',
		default: 'Selected'
	}, {
		name: 'showStoreName',
		inputType: 'checkbox',
		label: 'Show store name',
		default: true
	}, {
		name: 'showStoreAddress',
		inputType: 'checkbox',
		label: 'Show store address',
		default: true
	}, {
		name: 'showStoreZipcode',
		inputType: 'checkbox',
		label: 'Show store zipcode',
		default: true
	}, {
		name: 'showStorePhone',
		inputType: 'checkbox',
		label: 'Show store phone',
		default: true
	}, {
		name: 'showStoreOpeningHours',
		inputType: 'checkbox',
		label: 'Show store opening hours',
		default: true
	}, {
		name: 'showStoreOpeningHoursWeekdays',
		inputType: 'checkbox',
		label: 'Show store opening hours for weekdays',
		default: true
	}, {
		name: 'showStoreOpeningHoursSaturday',
		inputType: 'checkbox',
		label: 'Show store opening hours for saturday',
		default: true
	}, {
		name: 'showStoreOpeningHoursSunday',
		inputType: 'checkbox',
		label: 'Show store opening hours for sunday',
		default: true
	}, {
		name: 'stockIconType',
		inputType: 'select',
		label: 'Choose icon type for stock balance',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	},
	...stockIconConfig,
	...stockImageConfig,
	{
		name: 'pinIconType',
		inputType: 'select',
		label: 'Choose icon type for store map pin',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	},
	...pinIconConfig,
	...pinImageConfig,
	{
		name: 'balanceDelimiter',
		inputType: 'text',
		description: 'This will be used to separate the sizes in the balance list.',
		label: 'Balance delimiter',
		default: ', '
	}, {
		name: 'inStockText',
		inputType: 'text',
		label: 'In stock text',
		default: 'In stock'
	}, {
		name: 'outOfStockText',
		inputType: 'text',
		label: 'Out of stock text',
		default: 'Out of stock'
	}, {
		name: 'fewInStockText',
		inputType: 'text',
		label: 'Few in stock text',
		default: 'Few in stock'
	}, {
		name: 'fewInStockThreshold',
		description: 'This will be the few in stock if the stock is equal or less than this number. If set to 0, the few in stock text will never be shown.',
		inputType: 'number',
		label: 'Few in stock threshold',
		default: 10
	}, {
		name: 'searchPlaceholder',
		inputType: 'text',
		label: 'Placeholder',
		default: 'Search for a store'
	}, {
		name: 'searchPinIcon',
		inputType: 'icon',
		label: 'Map pin icon',
		default: false
	}, {
		name: 'searchPinIconSize',
		inputType: 'text',
		label: 'Set size on icon',
		default: '1em'
	}, {
		name: 'searchPinIconColor',
		inputType: 'color',
		label: 'Set color on icon',
		default: '#000'
	}, {
		name: 'class',
		inputType: 'text',
		label: 'CSS-class',
		default: ''
	}]
});
