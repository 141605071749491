const stockImageOrIcon = node => {
	switch (node && node.props.stockIconType) {
		case 'icon':
			return `
            <Field name="inStockIcon"/>
            <Field name="inStockIconSize"/>
            <Field name="inStockIconColor"/>
            <Field name="fewInStockIcon"/>
            <Field name="fewInStockIconSize"/>
            <Field name="fewInStockIconColor"/>
            <Field name="outOfStockIcon"/>
            <Field name="outOfStockIconSize"/>
            <Field name="outOfStockIconColor"/>
            `;
		case 'image':
		default:
			return `
            <Field name="inStockImage"/>
            <Field name="fewInStockImage"/>
            <Field name="outOfStockImage"/>
            `;
	}
};

const getPinImageOrIcon = node => {
	switch (node && node.props.pinIconType) {
		case 'icon':
			return `
            <Field name="pinIcon"/>
            <Field name="pinIconSize"/>
            <Field name="pinIconColor"/>
            `;
		case 'image':
		default:
			return `
            <Field name="pinImage"/>
            `;
	}
};

const storeFields = node => {
	switch (node && node.props.renderAs) {
		case 'selectedStore':
			return '';
		case 'searchAndSetStore':
		default:
			return `
			<Field name="chooseStoreButtonText"/>
			<Field name="changeStoreButtonText"/>
			<Field name="selectedStoreText"/>
            `;
	}
};

export default node => `
	<Tabs>
		<Tab heading="General">
			<Grid>
				<Cell size="full" description="Store settings">
					<Field name="renderAs"/>
					<Field name="slideoutIdentifier"/>
					${storeFields(node)}
					<Field name="showStoreName"/>
					<Field name="showStoreAddress"/>
					<Field name="showStoreZipcode"/>
					<Field name="showStorePhone"/>
					<Field name="showStoreOpeningHours"/>
					<Field name="showStoreOpeningHoursWeekdays" hidden="${node && !node.props.showStoreOpeningHours}"/>
					<Field name="showStoreOpeningHoursSaturday" hidden="${node && !node.props.showStoreOpeningHours}"/>
					<Field name="showStoreOpeningHoursSunday" hidden="${node && !node.props.showStoreOpeningHours}"/>
				</Cell>
				<Cell size="full" description="Pin settings">
					<Field name="pinIconType"/>
					${getPinImageOrIcon(node)}
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Stock Settings">
			<Grid>
				<Cell size="full">
                    <Field name="stockIconType"/>
                    ${stockImageOrIcon(node)}
					<Field name="balanceDelimiter"/>
					<Field name="inStockText"/>
					<Field name="outOfStockText"/>
					<Field name="fewInStockText"/>
					<Field name="fewInStockThreshold"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Search Settings" hidden="${node && node.props.renderAs !== 'searchAndSetStore'}">
			<Grid>
				<Cell size="full">
					<Field name="searchPlaceholder"/>
					<Field name="searchPinIcon"/>
					<Field name="searchPinIconSize"/>
					<Field name="searchPinIconColor"/>
				</Cell>
			</Grid>
		</Tab>
        <Tab heading="Advanced settings">
            <Grid>
                <Cell size="full">
                    <Field name="class"/>
                </Cell>
            </Grid>
     </Tab>
	</Tabs>
`;
