import editorLayout from './editor-layout';

export default {
	type: 'MY_PAGES_ORDERS',
	name: 'Orders',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'My Pages',
	icon: 'fal fa-receipt',
	editorLayout,
	styleClasses: {
		Wrapper: '',
		'No Orders Text': '',
		Order: 'Preset-MyPagesOrders-Order',
		'Order Header': 'Preset-MyPagesOrders-Order-Header',
		'Order Header Column': '',
		'Order Number Label': '',
		'Order Number Value': '',
		'Order Body': 'Preset-MyPagesOrders-Order-Body',
		'Order Store': '',
		'Order Rows Header': 'Preset-MyPagesOrders-Order-Rows-Header',
		'Order Rows Header Column': 'Preset-MyPagesOrders-Order-Rows-Header-Column',
		'Order Rows': '',
		'Order Rows Summarize': 'Preset-MyPagesOrders-Order-Rows-Summarize',
		'Order Row': 'Preset-MyPagesOrders-Order-Row',
		'Order Row Column': '',
		'Return Button': 'Preset-Button Preset-Primary-Button Preset-Primary-Font-Family',
		'Return Success Text': 'Preset-Primary-Font-Family Preset-Primary-Text Preset-Primary-Color',
		Parcel: 'Preset-MyPagesOrders-Parcel',
		'Parcel Number': 'Preset-MyPagesOrders-Parcel-Number',
		'Parcel Tracking': 'Preset-MyPagesOrders-Parcel-Tracking',
		'Parcel Steps': 'Preset-MyPagesOrders-Parcel-Steps',
		'Parcel Step': 'Preset-MyPagesOrders-Parcel-Step',
		'Parcel Step Dot': 'Preset-MyPagesOrders-Parcel-Step-Dot',
		'Parcel Step Label': '',
		'Parcel Step Description': 'Preset-MyPagesOrders-Parcel-Step-Description'
	},
	props: [{
		name: 'maxOrderAge',
		inputType: 'number',
		label: 'Only show orders younger than X days (0 means no max age)',
		default: 0
	}, {
		name: 'noOrdersText',
		inputType: 'text',
		label: 'Text to display when no orders are found',
		default: 'You have no orders to display'
	}, {
		name: 'returnOrderButtonText',
		inputType: 'text',
		label: 'Return order button text',
		default: 'Return Order'
	}, {
		name: 'returnOrderSuccessText',
		inputType: 'text',
		label: 'Return order success text',
		default: 'A return slip will soon be sent to your e-mail'
	}, {
		name: 'showSearchForm',
		inputType: 'checkbox',
		label: 'Show search form',
		default: false
	}, {
		name: 'searchFormLabel',
		inputType: 'text',
		label: 'Search form label',
		default: 'Find your order:'
	}, {
		name: 'showDeliviredOrders',
		inputType: 'checkbox',
		label: 'Show orders delivered',
		default: true
	}, {
		name: 'showNonDeliviredOrders',
		inputType: 'checkbox',
		label: 'Show orders not delivered',
		default: true
	}, {
		name: 'showCancelledOrders',
		inputType: 'checkbox',
		label: 'Show cancelled orders',
		default: true
	}, {
		name: 'showReturnableOrders',
		inputType: 'checkbox',
		label: 'Show returnable orders',
		default: true
	}, {
		name: 'showNonReturnableOrders',
		inputType: 'checkbox',
		label: 'Show non returnable orders',
		default: true
	}, {
		name: 'orderHeaderOrderDateSize',
		inputType: 'select',
		label: 'Desktop size for order header date',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderDateSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order header date',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderDateSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order header date',
		default: '6of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderNumberSize',
		inputType: 'select',
		label: 'Desktop size for order header number',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderNumberSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order header number',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderNumberSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order header number',
		default: '6of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderStoreNameSize',
		inputType: 'select',
		label: 'Desktop size for order header store name',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderStoreNameSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order header store name',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderStoreNameSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order header store name',
		default: '6of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderStatusSize',
		inputType: 'select',
		label: 'Desktop size for order header status',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderStatusSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order header status',
		default: '3of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderHeaderOrderStatusSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order header status',
		default: '6of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowProductNumberSize',
		inputType: 'select',
		label: 'Desktop size for order row product number',
		default: '2of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowProductNumberSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row product number',
		default: '2of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowProductNumberSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row product number',
		default: '2of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowProductSize',
		inputType: 'select',
		label: 'Desktop size for order row product',
		default: '4of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowProductSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row product',
		default: '4of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowProductSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row product',
		default: '4of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowQuantitySize',
		inputType: 'select',
		label: 'Desktop size for order row quantity',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowQuantitySizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row quantity',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowQuantitySizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row quantity',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowQuantityDeliveredSize',
		inputType: 'select',
		label: 'Desktop size for order row quantity delivered',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowQuantityDeliveredSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row quantity delivered',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowQuantityDeliveredSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row quantity delivered',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowUnitPriceSize',
		inputType: 'select',
		label: 'Desktop size for order row unit price',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowUnitPriceSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row unit price',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowUnitPriceSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row unit price',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowDiscountSize',
		inputType: 'select',
		label: 'Desktop size for order row discount',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowDiscountSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row discount',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowDiscountSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row discount',
		default: '1of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowPriceSize',
		inputType: 'select',
		label: 'Desktop size for order row price',
		default: '2of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowPriceSizeTablet',
		inputType: 'select',
		label: 'Tablet size for order row price',
		default: '2of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}, {
		name: 'orderRowPriceSizeMobile',
		inputType: 'select',
		label: 'Mobile size for order row price',
		default: '2of12',
		values: ['1of12', '2of12', '3of12', '4of12', '5of12', '6of12', '7of12', '8of12', '9of12', '10of12', '11of12', 'full', 'hidden']
	}]
};
