import {deepEqual} from 'fast-equals';
import uniqueBy from 'unique-by';
import {getPriceDiscountInPercent} from '~/utils';
import Badge from '.';
import Box from './box';
import Link from '~/link'; // CUSTOMER OVERRIDE

const propTypes = {
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	site: {
		source: 'site'
	}
};

const defaultProps = {
	showCampaigns: true,
	showClubBadge: false,
	showInStoreStockBadge: false,
	showOutOfStockBadge: false,
	showPropertyIcon: false,
	showSaleOrNew: true,
	propertyIconName: 'article_icon',
	showLowerPriceBadge: false
};

const getIconFilename = (campaign, badgeCompany) => {
	const firstProperty = v12.article.properties('camp.icon', campaign.propertyKeys)[0];
	const firstValue = firstProperty && firstProperty.propertyValues[0];

	return (firstValue ? firstValue.propertyvalue : `default_campaign_icon${badgeCompany}.png`).replace(/^\/media\//, '');
};

const getLabel = campaign => {
	const firstProperty = v12.article.properties('camp.label', campaign.propertyKeys)[0];
	const firstValue = firstProperty && firstProperty.propertyValues[0];

	return firstValue ? firstValue.propertyvalue : '';
};

const getBackgroundColor = campaign => {
	const firstProperty = v12.article.properties('camp.backgroundcolor', campaign.propertyKeys)[0];
	const firstValue = firstProperty && firstProperty.propertyValues[0];

	return firstValue ? firstValue.propertyvalue : '';
};

const getTextColor = campaign => {
	const firstProperty = v12.article.properties('camp.textcolor', campaign.propertyKeys)[0];
	const firstValue = firstProperty && firstProperty.propertyValues[0];

	return firstValue ? firstValue.propertyvalue : '';
};

const getLink = campaign => {
	const firstProperty = v12.article.properties('camp.link', campaign.propertyKeys)[0];
	const firstValue = firstProperty && firstProperty.propertyValues[0];

	return firstValue ? firstValue.propertyvalue : '';
};

const filterCampaigns = (isPerson, skuId) => campaign => {
	if ((isPerson === true && (campaign.customerType === -1 || campaign.customerType === 1)) && (typeof skuId === 'undefined' || campaign.skuIds.includes(skuId))) {
		return true;
	}

	if ((isPerson === false && (campaign.customerType === -1 || campaign.customerType === 0)) && (typeof skuId === 'undefined' || campaign.skuIds.includes(skuId))) {
		return true;
	}

	return false;
};

const renderCampaigns = ({article, campaignHeader, sku = {}, campaignPopup, isPerson}, badgeCompany) => { // CUSTOMER OVERRIDE
	const heading = campaignHeader.length > 0 && campaignHeader;
	const campaigns = uniqueBy(article.articleCampaigns, 'camp_id');

	return campaigns.filter(filterCampaigns(isPerson, sku.sku_id)).map(campaign => {
		const label = getLabel(campaign);
		const image = getIconFilename(campaign, badgeCompany);
		const checkDefault = label.length === 0 && image.includes('default_campaign_icon') ? ' BadgeList-item--campaign-default' : '';
		const newSkuCheck = campaign.camp_name2 === 'NEW' && sku.brandnew === true; // CUSTOMER OVERRIDE

		if (campaign.camp_name2 === 'NEW' && !newSkuCheck) { // CUSTOMER OVERRIDE
			return <noscript/>;
		}

		return (
			<Box
				key={campaign.camp_id}
				class={newSkuCheck ? 'BadgeList-item BadgeList-item--new' : 'BadgeList-item BadgeList-item--campaign' + checkDefault}
				isClickable={campaignPopup}
				heading={heading}
				subHeading={campaign.camp_name}
				description={campaign.camp_desc}
				label={label}
				backgroundcolor={getBackgroundColor(campaign)}
				textcolor={getTextColor(campaign)}
				link={getLink(campaign)}
				image={image}
			/>
		);
	});
};

const renderPropertyIcon = props => {
	const iconName = (props.propertyIconName && props.propertyIconName.length !== 0 && props.propertyIconName) || defaultProps.propertyIconName;
	if (props.article.propertyKeys.length > 0) {
		const matchingProperties = iconName.split(',').map(icon => v12.article.properties(icon.trim(), props.article.propertyKeys)).filter(arr => arr.length > 0);
		const images = matchingProperties.flatMap(property => property.map(value => value.propertyValues.map(prop => ({
			image: prop.propertyvalue,
			alt: prop.propertyvalue_desc || value.propertykey_name_internal || value.propertykey_name || value.propertykey_desc
		})))).flat().filter(image => image.image.toLowerCase() !== 'false');

		if (images && images.length > 0) {
			return images.map((imageObject, index) => {
				const {image, alt} = imageObject;

				return <Badge key={`articleProperty-${image}-${index}`} class={`BadgeList-item BadgeList-item--articleProperty BadgeList-item--articleProperty-${image.toLowerCase().replace(/\s+|\.[^/.]+$/g, '')}`} image={image} loading={props.loading} alt={alt}/>;
			});
		}
	}

	return undefined;
};

const getOnSalePrice = ({article, sku}) => {
	const price = v12.article.price(article, sku);

	return price && price.price_sales < price.price_org ? price : false;
};

const renderSale = (props, badgeCompany) => { // CUSTOMER OVERRIDE
	const salePrice = getOnSalePrice(props);

	if (salePrice && props.onSaleImage) {
		return <Badge key='on-sale-badge' class='BadgeList-item BadgeList-item--on-sale' heading='On sale' image={`onsale${badgeCompany}.png`} loading={props.loading}/>;
	}

	if (salePrice) {
		const salesString = props.getPrice(salePrice.price_sales, salePrice.price_sales_exvat);

		return <Badge key='on-sale' class='BadgeList-item BadgeList-item--onSale' heading={props.getTranslation('article.sale')} subHeading={salesString}/>;
	}
};

const renderDiscount = props => {
	const price = v12.article.price(props.article, props.sku);
	const sale = price.price_sales < price.price_org;
	const priceDiff = window._streamline.siteContext.currency_code === 'SEK' ? 49 : 0; // CUSTOMER UNIQUE
	const saleRed = price.price_sales < (price.price_org - priceDiff); // CUSTOMER UNIQUE - For Sweden, it should be a sale (red) price only if price_org is more than 49 SEK higher than price_sales

	const discountPercent = sale && getPriceDiscountInPercent({
		sales: price.price_sales,
		salesExvat: price.price_sales_exvat,
		org: price.price_org,
		orgExvat: price.price_org_exvat,
		lowestPrice: price.lowestPrice
	});

	if (!discountPercent || !saleRed) { // CUSTOMER UNIQUE
		return <noscript/>;
	}

	return <Badge key='discount-percent' class='BadgeList-item BadgeList-item--discount' heading={`${discountPercent}%`} subHeading={props.getTranslation('article.discountPercent')} loading={props.loading}/>;
};

const renderLowerPrice = (props, badgeCompany) => { // CUSTOMER UNIQUE
	const price = v12.article.price(props.article, props.sku);
	const isSale = price.price_sales < price.price_org;
	const lowerPrice = price.price_sales < price.price_rec;

	if (!isSale && lowerPrice) {
		return <Badge key='lower-price' class='BadgeList-item BadgeList-item--lowerPrice' heading='Lower price' image={`lower_price${badgeCompany}_${props.site.lang_code_web}.svg`} loading={props.loading}/>; // CUSTOMER UNIQUE
	}

	return <noscript/>;
};

const renderInStoreStock = props => {
	if (!props.article.inStock && props.article.inStoreStock) {
		return <Badge key='in-store-stock-badge' class='BadgeList-item BadgeList-item--inStoreStock' heading={props.getTranslation('articles.instorestock_badge_text')} loading={props.loading}/>;
	}
};

const renderOutofStock = props => {
	const outOfStock = props.article.articleAttributes.filter(attribute => attribute.attr1_id === props.article.attr1_id).every(attribute => !attribute.inStock && !attribute.inStoreStock);

	if (outOfStock) {
		return <Badge key='out-of-stock-badge' class='BadgeList-item BadgeList-item--outOfStock' heading={props.getTranslation('articles.outofstock_badge_text')} loading={props.loading}/>;
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => { // CUSTOMER OVERRIDE
	let badgeCompany = '';

	switch (props.site.companyName) {
		case 'Sportshopen': {
			badgeCompany = '_sportshopen';
			break;
		}
		case 'Sportshopen Outlet': {
			badgeCompany = '_outlet';
			break;
		}
		case 'swedemount': {
			badgeCompany = '_swedemount';
			break;
		}
		default: {
			break;
		}
	}

	return (
		<div class={['BadgeList', props.class]}>
			{props.showLowerPriceBadge && renderLowerPrice(props, badgeCompany)}
			{props.showClubBadge && <Badge key='club-badge' class='BadgeList-item BadgeList-item--club' image='icon_club_price.png' heading='Club Price' loading={props.loading}/>}
			{props.showCampaigns && renderCampaigns(props, badgeCompany)}
			{props.showPropertyIcon && renderPropertyIcon(props)}
			{props.showSaleOrNew && renderSale(props, badgeCompany)}
			{props.showDiscountPercent && renderDiscount(props)}
			{props.showInStoreStockBadge && renderInStoreStock(props)}
			{props.showOutOfStockBadge && renderOutofStock(props)}
		</div>
	);
};

export default {defaultProps, propTypes, render, shouldUpdate};
