export default {
	type: 'SHOPCART_CAMPAIGN_INFORMATION',
	name: 'Campaign Information',
	allowedSections: ['header', 'main'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	icon: 'fad fa-info',
	styleClasses: {
		Wrapper: '',
		Prefix: '',
		Campaign_Information: '',
		Suffix: ''
	},
	props: [{
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before info',
		default: ''
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after info',
		default: ''
	}]
};
