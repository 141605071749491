import {deepEqual} from 'fast-equals';
import {getPriceDiscountInPercent} from '~/utils';

const propTypes = {
	class: {
		type: 'string'
	},
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	sku: {
		type: 'object'
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, getPrice, getTranslation, showPriceForCurrentSku, showFromLabel, sku, priceProperties} = props; // CUSTOMER UNIQUE
	const campaignPrice = sku.articlePrice.camp_id > 0;
	const styleClasses = props.styleClasses || {};
	const price = showPriceForCurrentSku ? v12.article.price(data, sku) : v12.article.price(data, null, {...(sku && sku.attr1_id && {attr1_id: sku.attr1_id})});
	const priceDiff = window._streamline.siteContext.currency_code === 'SEK' ? 49 : 0; // CUSTOMER UNIQUE
	const isSale = price.price_sales < price.price_org;
	const isSaleRed = price.price_sales < (price.price_org - priceDiff); // CUSTOMER UNIQUE - For Sweden, it should be a sale (red) price only if price_org is more than 49 SEK higher than price_sales
	const showRecPrice = props.showRecPrice && price.price_rec !== 0;
	const lowestPrice = price.lowestPrice > 0 ? price.lowestPrice : isSaleRed ? price.price_sales : 0; // CUSTOMER UNIQUE
	const showUnitPrice = props.showUnitPrice && price.price_unit !== 0;
	const discountPercent = isSale && getPriceDiscountInPercent({
		sales: price.price_sales,
		salesExvat: price.price_sales_exvat,
		org: price.price_org,
		orgExvat: price.price_org_exvat,
		lowestPrice: price.lowestPrice
	});

	const priceAttributes = {
		class: {
			Price: true,
			'Price--sale': isSale && isSaleRed,
			'Price--campaign': campaignPrice,
			[styleClasses.Price]: !isSale && styleClasses.Price,
			[styleClasses.Price_Sale]: isSale && isSaleRed && styleClasses.Price_Sale,
		},
		'data-style-attr': isSale && isSaleRed ? 'Price_Sale' : 'Price'
	};

	return props.hideZeroPrice && price.price_sales === 0 ? <noscript/> : (
		<div class={['Prices', props.class, styleClasses.Prices]} data-style-attr='Prices'>
			{showFromLabel && !showPriceForCurrentSku && price.more && <span class={['Price-fromLabel', styleClasses.Price_From_Label]} data-style-attr='Price_From_Label'>{getTranslation('article.price_from_label')}</span>}
			{showUnitPrice &&
				<div class={['CMS-ArticlePrice-unitPrice-Wrapper', styleClasses.Unit_Price_Wrapper]} data-style-attr='Unit_Price_Wrapper'>
					<span class={['CMS-ArticlePrice-unitPrice-Prefix', styleClasses.Unit_Price_Prefix]} data-style-attr='Unit_Price_Prefix'>{props.unitPricePrefixText}</span>
					<span class={['CMS-ArticlePrice-unitPrice', styleClasses.Unit]} data-style-attr='Unit'>{getPrice(price.price_unit, price.price_unit_exvat)}</span>
					<span class={['CMS-ArticlePrice-unitPrice-Suffix', styleClasses.Unit_Price_Suffix]} data-style-attr='Unit_Price_Suffix'>{props.unitPriceSuffixText}</span>
				</div>
			}
			{!showUnitPrice &&
				<span class={['Price--salesOrgWrapper', styleClasses.Price_Sales_Org_Wrapper]} data-style-attr='Price_Sales_Org_Wrapper'> {/* CUSTOMER UNIQUE - salesOrgWrapper */}
					<span {...priceAttributes}>{getPrice(price.price_sales, price.price_sales_exvat)}</span>
					{isSale && <span class={['Price Price--org', styleClasses.Price_Original]} data-style-attr='Price_Original'>{getPrice(price.price_org, price.price_org_exvat)}</span>}
				</span>
			}
			{showRecPrice && (
				<span class={['Price--recWrapper', styleClasses.Price_Rec_Wrapper]} data-style-attr='Price_Rec_Wrapper'>
					{/* CUSTOMER UNIQUE - priceProperties */ priceProperties && <span class={['ListArticle-Price-Properties', styleClasses.Properties]} data-style-attr='Price_Properties'>{priceProperties}</span>}
					<span class={['CMS-ArticlePrice-price--recLabel', props.styleClasses.Price_Rec_Label]} data-style-attr='Price_Rec_Label'>{props.recPriceLabel}</span>
					<span class={['Price--rec', styleClasses.Price_Rec]} data-style-attr='Price_Rec'> {getPrice(price.price_rec, price.price_rec_exvat)}</span>
				</span>
			)}
			{isSale && props.showDiscountPercent && <span class={['Price Price--discountPercent', styleClasses.Price_Discount_Percent]} data-style-attr='Price_Discount_Percent'>{discountPercent}%</span>}
			{(lowestPrice > 0 || isSaleRed) && props.showLowestPrice &&
				<div class={['CMS-ArticlePrice-lowestPrice-Wrapper', props.styleClasses.Lowest_Price_Wrapper]} data-style-attr='Lowest_Price_Wrapper'>
					<span class={['CMS-ArticlePrice-lowestPrice-Prefix', props.styleClasses.Lowest_Price_Prefix]} data-style-attr='Lowest_Price_Prefix'>{props.lowestPricePrefixText}</span>
					<span class={['CMS-ArticlePrice-lowestPrice', props.styleClasses.Lowest_Price]} data-style-attr='Lowest_Price'>{props.getPrice(lowestPrice)}</span>
					<span class={['CMS-ArticlePrice-lowestPrice-Suffix', props.styleClasses.Lowest_Price_Suffix]} data-style-attr='Lowest_Price_Suffix'>{props.lowestPriceSuffixText}</span>
				</div>
			}
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
