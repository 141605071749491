export default node => ({
	type: 'ARTICLE_PROPERTIES_CUSTOM',
	name: 'Properties Custom',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [node && node.props.displayAs !== 'imageList' && {
		name: 'disableName',
		inputType: 'checkbox',
		label: 'Disable property name',
		default: false
	}, node && node.props.disableName && {
		name: 'customDisplayName',
		inputType: 'text',
		label: 'Display name',
		default: ''
	}, {
		name: 'propertyKey',
		inputType: 'text',
		label: 'Property key internal',
		default: ''
	}, {
		name: 'displayAs',
		inputType: 'select',
		label: 'Display as',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Image',
			value: 'img'
		}, {
			label: 'Download Link',
			value: 'link'
		}, {
			label: 'Image List',
			value: 'imageList'
		}]
	}, node && node.props.displayAs === 'imageList' && {
		name: 'maxImageHeight',
		inputType: 'number',
		label: 'Image Height (px)',
		default: 48
	}, node && node.props.displayAs === 'img' && {
		name: 'toolTip',
		inputType: 'checkbox',
		label: 'Show large tooltip',
		default: false
	}, node && node.props.displayAs === 'img' && {
		name: 'scale',
		inputType: 'select',
		label: 'Image size',
		default: 'thumbnail',
		values: [{
			label: 'Thumbnail',
			value: 'thumbnail'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Original',
			value: 'original'
		}]
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
