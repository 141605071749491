import queryString from 'query-string';
import store from 'store2';
import {isPerson} from '../actions';

export const omit = (o, ...keys) => keys.reduce((memo, key) => {
	const {[key]: ignored, ...rest} = memo;
	return rest;
}, o);

export const valueFilter = filter => object => Object.keys(object).reduce((passed, key) => !filter(object[key]) ? passed : {
	...passed,
	[key]: object[key]
}, {});

export const isDefined = x => x !== undefined;
export const mergeDefined = (...objects) => Object.assign({}, ...objects.map(valueFilter(isDefined)));
export const isEmpty = obj => Object.entries(obj).length === 0 && obj.constructor === Object;
export const isVideo = src => (src && src.length > 0) && src.includes('.mp4');
export const isMediaTypeVideo = (mediaType = '') => mediaType === 'VIDEO/VIMEO' || mediaType === 'VIDEO/YOUTUBE';

export const getMediaTypeVideoUrl = (src, mediaType) => {
	const mediaTypeMap = {
		'VIDEO/VIMEO': 'https://player.vimeo.com/video/',
		'VIDEO/YOUTUBE': 'https://www.youtube.com/watch?v='
	};

	if (mediaTypeMap[mediaType]) {
		return `${mediaTypeMap[mediaType]}${src}`;
	}

	return src;
};

export const sanitizeAttr1IdKeyValue = article => {
	if ('attr1_id' in article && article.attr1_id !== -1) {
		return article.attr1_id;
	}

	if ('_attr1_id' in article && article._attr1_id !== -1) {
		return article._attr1_id;
	}

	if ('attribute1Id' in article && article.attribute1Id !== -1) {
		return article.attribute1Id;
	}

	if ('attr1_id_display' in article && article.attr1_id_display !== -1) {
		return article.attr1_id_display;
	}

	return null;
};

export const getSkuBasedOnAttribute1Id = article => {
	let result; // eslint-disable-line fp/no-let

	if ('attr1_id' in article && article.attr1_id !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article.attr1_id);
	}

	if (!result && '_attr1_id' in article && article._attr1_id !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article._attr1_id);
	}

	if (!result && 'attribute1Id' in article && article.attribute1Id !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article.attribute1Id);
	}

	if (!result && 'attr1_id_display' in article && article.attr1_id_display !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article.attr1_id_display);
	}

	if (!result) {
		return article.articleAttributes[0];
	}

	return result;
};

export const stringToNumberArray = str => str ? str.replace(/\s/g, '').split(',').map(Number) : [];

export const matchesOnAttribute2 = (articleAttributes, articleAttributeMedias, item) => {
	const attr2Attributes = articleAttributes.filter(attribute => attribute.attr2_id === item.attr2_id && attribute.attr1_id === item.attr1_id);
	const attr2Medias = articleAttributeMedias.filter(media => media.attr2_id === item.attr2_id && media.attr1_id === item.attr1_id);

	return (attr2Attributes.length > 0 && attr2Medias.length > 0);
};

export const getMatchingImages = (article, item, settings) => {
	const images = v12.article.medias(article, {
		sku: {
			attr1_id: item.attr1_id,
			attr2_id: (settings.isSizeMatchRequired && !settings.matchOnAttr2 && settings.useFallbackImageForSize) ? 0 : settings.isSizeMatchRequired ? item.attr2_id : undefined,
			attr3_id: settings.isAttr3MatchRequired ? item.attr3_id : undefined
		},
		size: '',
		exclude: settings.excludeImages ? stringToNumberArray(settings.excludeImages) : '',
		fallback: settings.fallbackImage
	});

	return images;
};

const persistedStateIdentifier = window._streamline && window._streamline.siteContext.companyName.length > 0 ? `${window._streamline.siteContext.companyName.replace(/\s/g, '-').toLowerCase()}-state` : 'vs-state';

export const saveState = state => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(persistedStateIdentifier, serializedState);
	} catch (error) {
		v12.util.error(error);
		console.error('Could not persist state', error);
	}
};

export const logoutIfLoggedIn = customer => {
	if (customer.logged_in_level > 0) {
		return v12.customer.logout({copyCart: true});
	}

	// eslint-disable-next-line no-promise-executor-return
	return new Promise(resolve => resolve());
};

const getPersistedState = () => store(persistedStateIdentifier) || [];

export const persistedState = getPersistedState();
export const getQueryString = query => queryString.parseUrl(query).query;
export const parseNumber = (num, defaultValue) => Number(num) ? parseInt(num, 10) : (num === 0 || num === '0' ? 0 : defaultValue);

export const getPriceDiscountInPercent = prices => {
	const person = isPerson();
	const {disableExVATForB2B} = window._streamline.siteContext.settings;
	const useLowestPriceAsOrgPrice = window._streamline.siteContext.settings.calculateDiscountPercentFromLowestPrice;
	const salesPrice = disableExVATForB2B || person ? prices.sales : prices.salesExvat;
	const orgPrice = disableExVATForB2B || person ? (prices.lowestPrice && useLowestPriceAsOrgPrice ? prices.lowestPrice : prices.org) : prices.orgExvat;
	const discount = orgPrice - salesPrice; // CUSTOMER UNIQUE
	const rounded = Math.round(discount * 100) / 100; // CUSTOMER UNIQUE round to 1 decimal

	if (orgPrice < salesPrice) {
		return;
	}

	return parseInt(Math.floor((100 * rounded).toFixed(0) / orgPrice), 10); // CUSTOMER UNIQUE Math.floor
};

export const lowestPriceVisibility = (showLowestPriceComponent, showLowestPriceStoreSetting, hideLowestPriceForSpecificOfferTypes, price) => {
	// Don't show lowest price if offer type matches the ones in the store setting
	if (hideLowestPriceForSpecificOfferTypes && hideLowestPriceForSpecificOfferTypes.trim() !== '' && price && price.is_price_campaign) {
		const offerTypesToHide = new Set(hideLowestPriceForSpecificOfferTypes
			.split(',')
			.map(offerType => Number(offerType.trim()))
			.filter(offerType => !isNaN(offerType) && offerType !== -1) // Ignore invalid offer types
		);

		if (offerTypesToHide.has(price.offertype_id)) {
			return false;
		}
	}

	if (showLowestPriceComponent === 'useStoreSetting') {
		return showLowestPriceStoreSetting;
	}

	return showLowestPriceComponent === 'show';
};

export const formatAddonsIntoRowsPerQty = addons => {
	if (!addons || addons.length === 0) {
		return addons;
	}

	return addons.flatMap(addon =>
		Array.from({length: addon.qty}, () => ({
			...addon,
			qty: 1
		}))
	);
};

export const conditionallyAddConfig = (node, key, conditionStr, defaultValue, configArray) => (node && ((node.props[key] === undefined && defaultValue === conditionStr) || node.props[key] === conditionStr)) ? configArray : []; // eslint-disable-line max-params
