import {connectWrap as connect} from '@viskan/deku-redux';
import BaseComponent from './components';

export default connect(
	({app, article, router, site, tree}) => ({
		article: article.article,
		activeCategoryId: app.category.main.categoryId,
		isLandingPage: router.pathname === site.localePickerPath,
		site,
		tree
	})
)(BaseComponent);
