import {deepEqual} from 'fast-equals';
import components from '../cms-components';

const name = 'StreamlineCmsSection';

const getComponent = component => component.component ? component.component : component;

export const renderChildren = (children, props, isEditable = true) => children.filter(Boolean).map(child => {
	const Component = components[child.type] && getComponent(components[child.type].component);
	const classes = !isEditable ? child.props.class : [
		child.props.class,
		'StreamlineCmsSection-node'
	];

	if (!Component) {
		console.warn(`Component ${child.type} does not exist`);
		return <noscript/>;
	}

	return (
		<Component id={child.id} {...child.props} components={components} class={classes} styleClasses={child.props.styleClasses || {}}>
			{child.children && renderChildren(child.children, props)}
		</Component>
	);
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const classes = {
		[name]: true,
		[`${name}--${props.sectionId}`]: true,
		[props.class]: props.class,
		isEmpty: props.content.length === 0
	};

	const Element = props.sectionId === 'header' ? 'header' : (props.sectionId === 'footer' ? 'footer' : 'div');

	return (
		<Element class={classes} data-section={props.sectionId}>
			<div class='StreamlineCmsSection-node' data-id={0} data-topnode='true'>
				{props.content.length !== 0 ? renderChildren(props.content, props) : props.emptySectionComponent}
			</div>
		</Element>
	);
};

export default {name, render, shouldUpdate};
