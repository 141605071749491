import {conditionallyAddConfig} from '~/utils';

const inStockIconConfig = [{
	name: 'inStockIcon',
	inputType: 'icon',
	label: 'Choose icon when in stock',
	default: false
}, {
	name: 'inStockIconSize',
	inputType: 'text',
	label: 'Icon size',
	default: '1em'
}, {
	name: 'inStockIconColor',
	inputType: 'color',
	label: 'Icon color',
	default: '#009ABF'
}];

const outOfStockIconConfig = [{
	name: 'outOfStockIcon',
	inputType: 'icon',
	label: 'Choose icon when out of stock',
	default: false
}, {
	name: 'outOfStockIconSize',
	inputType: 'text',
	label: 'Icon size',
	default: '1em'
}, {
	name: 'outOfStockIconColor',
	inputType: 'color',
	label: 'Icon color',
	default: '#009ABF'
}];

const incomingInStockIconConfig = [{
	name: 'incomingInStockIcon',
	inputType: 'icon',
	label: 'Choose icon when incoming in stock',
	default: false
}, {
	name: 'incomingInStockIconSize',
	inputType: 'text',
	label: 'Icon size',
	default: '1em'
}, {
	name: 'incomingInStockIconColor',
	inputType: 'color',
	label: 'Icon color',
	default: '#009ABF'
}];

const inStockImageConfig = [{
	name: 'inStockImage',
	inputType: 'media-v2',
	label: 'In stock image',
	default: ''
}];

const outOfStockImageConfig = [{
	name: 'outOfStockImage',
	inputType: 'media-v2',
	label: 'Out of stock image',
	default: ''
}];

const incomingInStockImageConfig = [{
	name: 'incomingInStockImage',
	inputType: 'media-v2',
	label: 'Incoming in stock image',
	default: ''
}];

export default node => ({
	type: 'ARTICLE_STOCK_STATUS',
	name: 'Stock status',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-container-storage',
	styleClasses: {
		Wrapper: ''
	},
	props: [{
		name: 'showStockBasedOn',
		inputType: 'select',
		label: 'Choose parameter to base stock on',
		default: 'stockStatus',
		values: [{
			label: 'Stock status',
			value: 'stockStatus'
		}, {
			label: 'Stock balance',
			value: 'stockBalance'
		}]
	}, node && node.props.showStockBasedOn === 'stockBalance' && {
		name: 'stockBalanceSuffix',
		inputType: 'text',
		label: 'Stock balance suffix',
		default: ''
	}, {
		name: 'iconType',
		inputType: 'select',
		label: 'Choose icon type for stock status',
		default: 'icon',
		values: [{
			label: 'Icon',
			value: 'icon'
		}, {
			label: 'Image',
			value: 'image'
		}]
	}, {
		name: 'inStockSuffix',
		inputType: 'text',
		label: 'In stock label',
		default: 'In stock'
	},
	...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', inStockIconConfig),
	...conditionallyAddConfig(node, 'iconType', 'image', 'icon', inStockImageConfig), {
		name: 'outOfStockSuffix',
		inputType: 'text',
		label: 'Out of stock label',
		default: 'Out of stock'
	},
	...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', outOfStockIconConfig),
	...conditionallyAddConfig(node, 'iconType', 'image', 'icon', outOfStockImageConfig), {
		name: 'incomingInStockSuffix',
		inputType: 'text',
		label: 'Incoming in stock label',
		default: 'Coming soon'
	},
	...conditionallyAddConfig(node, 'iconType', 'icon', 'icon', incomingInStockIconConfig),
	...conditionallyAddConfig(node, 'iconType', 'image', 'icon', incomingInStockImageConfig)].filter(Boolean),
	hiddenProps: []
});
