import * as cmsUtils from '@viskan/cms-component-utilities';
import {type} from '@viskan/streamline-utilities';
import {getIcon as getDekuIcon} from '../../../utils/actions';
import {mergeDefined} from '~/utils';
import {Image as DekuImage} from '~/media';
import {articleIsFavorite} from '../actions';

const name = 'ArticleFavorite';

const defaultProps = {
	displayType: 'icon',
	favoriteOptions: {
		favoriteIcon: '{"prefix":"fal","x":{"name":"heart"}}',
		favoriteIconSelected: '{"prefix":"fas","x":{"name":"heart"}}',
		favoriteIconColor: '#000',
		favoriteIconSize: '1em'
	}
};

const getIcon = (props, isFavorite) => {
	const displayType = props.favoriteOptions.favoriteType || props.displayType;
	const show = ['iconAndText', 'icon'].includes(displayType);

	if (!show) {
		return <noscript/>;
	}

	const favoriteOptions = props.componentName === 'Favorite' && props.favoriteIcon ? {
		favoriteIcon: props.favoriteIcon,
		favoriteIconSelected: props.favoriteIconSelected,
		favoriteIconColor: props.favoriteIconColor,
		favoriteIconSize: props.favoriteIconSize
	} : mergeDefined(defaultProps.favoriteOptions, props.favoriteOptions);

	return isFavorite ?
		getDekuIcon({icon: favoriteOptions.favoriteIconSelected, iconColor: favoriteOptions.favoriteIconColor, iconSize: favoriteOptions.favoriteIconSize}) :
		getDekuIcon({icon: favoriteOptions.favoriteIcon, iconColor: favoriteOptions.favoriteIconColor, iconSize: favoriteOptions.favoriteIconSize});
};

const getImage = (props, isFavorite) => {
	const displayType = props.favoriteOptions.favoriteType || props.displayType;
	const show = ['imageAndText', 'image'].includes(displayType);

	if (!show) {
		return <noscript/>;
	}

	const image = isFavorite ? (props.imageSelected || props.favoriteOptions.favoriteImageSelected) : (props.image || props.favoriteOptions.favoriteImage);

	if (!image) {
		return <noscript/>;
	}

	return <DekuImage class={props.styleClasses && props.styleClasses.Image} data-style-attr='Image' source={image.image.filename} scale={image.scale} width={image.width} height={image.height}/>;
};

const getText = (props, isFavorite) => {
	const displayType = props.favoriteOptions.favoriteType || props.displayType;
	const show = ['iconAndText', 'imageAndText', 'text'].includes(displayType);

	if (!show) {
		return <noscript/>;
	}

	return <span class={props.styleClasses && props.styleClasses.Text} data-style-attr='Text'>{isFavorite ? props.textSelected : props.text}</span>;
};

const render = ({props}) => {
	if (!props.articleIsLoaded) {
		return cmsUtils.createCmsComponent(<noscript/>, props);
	}

	const {favoritesArtNosAndAttr1Ids, artNo, remove, add, styleClasses} = props;
	const attr1Id = type.isUndefined(props.attr1Id) ? props.activeArticleAttr1Id : props.attr1Id;
	const isFavorite = articleIsFavorite(favoritesArtNosAndAttr1Ids, artNo, attr1Id);

	const onClick = () => isFavorite ? remove(artNo, attr1Id) : add(artNo, attr1Id).then(() => {
		const intervalId = setInterval(() => {
			if (window.lipscore) {
				window.lipscore.initWidgets();
			}
		}, 300);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 5000);
	});

	return cmsUtils.createCmsComponent((
		<a class={['CMS-ArticleFavorite-icon', styleClasses && styleClasses['Favorite Icon']]} data-style-attr='Favorite Icon' onClick={onClick}>
			{getIcon(props, isFavorite)}
			{getImage(props, isFavorite)}
			{getText(props, isFavorite)}
		</a>
	), props);
};

export default {defaultProps, name, render};
