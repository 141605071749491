import editorLayout from './editor-layout';

export default node => ({
	type: 'ARTICLE_SUBSCRIPTION_INTERVAL',
	name: 'Subscription Interval',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	editorLayout: editorLayout(node),
	icon: 'fal fa-calendar',
	styleClasses: {
		Wrapper: '',
		Label: '',
		Input: ''
	},
	props: [{
		name: 'interactionType',
		inputType: 'select',
		label: 'Choose interaction for user',
		default: 'select',
		values: [{
			label: 'Select list',
			value: 'select'
		}, {
			label: 'Number input',
			value: 'input'
		}]
	}, {
		name: 'label',
		inputType: 'text',
		label: 'Label',
		default: 'Subscription Interval'
	}, {
		name: 'useDefaultValue',
		inputType: 'checkbox',
		label: 'Use a default value',
		description: 'If enabled it will not be possible to buy products as single purchase, if not default will be 0 (single purchase)',
		default: false
	}, {
		name: 'inputDefaultValue',
		inputType: 'number',
		label: 'Choose default (starting) value',
		description: 'If not set higher then 0 it will work as if "Use a default value" was not checked',
		default: 14
	}, {
		name: 'inputStepValue',
		inputType: 'number',
		label: 'Choose step value for input element',
		description: 'Will also be the lowest possible interval',
		default: 7
	}, {
		name: 'selectDefaultValue',
		inputType: 'select',
		label: 'Choose default (starting) value',
		description: 'If choosen one is not set to a valid (checked in select list values) it will default to first checked.',
		default: 14,
		values: [{
			label: 'No interval',
			value: 0
		}, {
			label: '2 weeks (14 days)',
			value: 14
		}, {
			label: '1 month (30 days)',
			value: 30
		}, {
			label: '6 weeks (42 days)',
			value: 42
		}, {
			label: '2 months (61 days)',
			value: 61
		}, {
			label: '10 weeks (70 days)',
			value: 70
		}, {
			label: '3 months (91 days)',
			value: 91
		}, {
			label: '4 months (122 days)',
			value: 122
		}, {
			label: '5 months (152 days)',
			value: 152
		}, {
			label: '6 months (183 days)',
			value: 183
		}, {
			label: '7 months (213 days)',
			value: 213
		}, {
			label: '8 months (244 days)',
			value: 244
		}, {
			label: '9 months (274 days)',
			value: 274
		}, {
			label: '10 months (304 days)',
			value: 304
		}, {
			label: '11 months (335 days)',
			value: 335
		}, {
			label: '12 months (365 days)',
			value: 365
		}]
	}, {
		name: 'days0',
		inputType: 'checkbox',
		label: 'No interval',
		default: true
	}, {
		name: 'days14',
		inputType: 'checkbox',
		label: '2 weeks (14 days)',
		default: true
	}, {
		name: 'days30',
		inputType: 'checkbox',
		label: '1 month (30 days)',
		default: true
	}, {
		name: 'days42',
		inputType: 'checkbox',
		label: '6 weeks (42 days)',
		default: true
	}, {
		name: 'days61',
		inputType: 'checkbox',
		label: '2 months (61 days)',
		default: true
	}, {
		name: 'days70',
		inputType: 'checkbox',
		label: '10 weeks (70 days)',
		default: true
	}, {
		name: 'days91',
		inputType: 'checkbox',
		label: '3 months (91 days)',
		default: true
	}, {
		name: 'days122',
		inputType: 'checkbox',
		label: '4 months (122 days)',
		default: true
	}, {
		name: 'days152',
		inputType: 'checkbox',
		label: '5 months (152 days)',
		default: true
	}, {
		name: 'days183',
		inputType: 'checkbox',
		label: '6 months (183 days)',
		default: true
	}, {
		name: 'days213',
		inputType: 'checkbox',
		label: '7 months (213 days)',
		default: true
	}, {
		name: 'days244',
		inputType: 'checkbox',
		label: '8 months (244 days)',
		default: true
	}, {
		name: 'days274',
		inputType: 'checkbox',
		label: '9 months (274 days)',
		default: true
	}, {
		name: 'days304',
		inputType: 'checkbox',
		label: '10 months (304 days)',
		default: true
	}, {
		name: 'days335',
		inputType: 'checkbox',
		label: '11 months (335 days)',
		default: true
	}, {
		name: 'days365',
		inputType: 'checkbox',
		label: '12 months (365 days)',
		default: true
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
