import mergeOptions from 'merge-options';

import daLocales from '../../locale/da.json';
import deLocales from '../../locale/de.json';
import enLocales from '../../locale/en.json';
import fiLocales from '../../locale/fi.json';
import noLocales from '../../locale/no.json';
import svLocales from '../../locale/sv.json';
import daExtendLocales from '../../locale/extends/da.json';
import deExtendLocales from '../../locale/extends/de.json';
import enExtendLocales from '../../locale/extends/en.json';
import fiExtendLocales from '../../locale/extends/fi.json';
import noExtendLocales from '../../locale/extends/no.json';
import svExtendLocales from '../../locale/extends/sv.json';

const defaultTranslations = {
	da: daLocales,
	de: deLocales,
	en: enLocales,
	fi: fiLocales,
	no: noLocales,
	sv: svLocales
};

const extendedTranslations = {
	da: daExtendLocales,
	de: deExtendLocales,
	en: enExtendLocales,
	fi: fiExtendLocales,
	no: noExtendLocales,
	sv: svExtendLocales
};

const convertObjectToDotNotation = (obj, key, result) => {
	if (typeof obj !== 'object') {
		result[key] = obj;
		return result;
	}
	const keys = Object.keys(obj);

	for (let i = 0; i < keys.length; i++) {
		const newKey = key ? (key + '.' + keys[i]) : keys[i];
		convertObjectToDotNotation(obj[keys[i]], newKey, result);
	}

	return result;
};

const getDefaultTranslation = translation => {
	if (translation[1] === '') {
		const defaultLang = window._streamline.siteContext.lang_code_web === 'sv' ? window._streamline.siteContext.lang_code_web : 'en';
		const translations = convertObjectToDotNotation(mergeOptions(extendedTranslations[defaultLang], defaultTranslations[defaultLang]), '', {});

		return translations[translation[0]];
	}

	return translation[1];
};

const editableTranslations = () => {
	try {
		const translations = convertObjectToDotNotation(mergeOptions(extendedTranslations[window._streamline.siteContext.lang_code_web], defaultTranslations[window._streamline.siteContext.lang_code_web]), '', {});

		const settings = Object.entries(translations).map(translation => ({
			description: `Default translation: ${getDefaultTranslation(translation)}`,
			label: translation[0].replace(/^([^.]+\.)/, ''),
			settingKey: translation[0],
			removeOnEmpty: true,
			type: 'text',
			defaultValue: translation[1],
			languageSpecific: true
		}));

		return Array.from(new Set(Object.keys(translations).map(key => key.split('.')[0]))).map(label => {
			return {
				label: label.split(new RegExp(/[\W_]+/g)).join(' '),
				settings: [...settings.filter(setting => setting.settingKey.split('.')[0] === label)]
			};
		}).sort((a, b) => a.label.localeCompare(b.label));
	} catch (error) {
		v12.util.error(error);
	}
};

export default window._streamline.siteContext.cmsTokenSet ? editableTranslations() : {};
