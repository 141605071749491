import editorLayout from './editor-layout';

export default {
	type: 'CHECKOUT_SHIPPING_SPORTSHOPEN',
	name: 'Shipping (Sportshopen)',
	allowedSections: ['footer', 'header', 'main', 'article'],
	disallowedChildren: ['*'],
	category: 'Checkout',
	icon: 'fal fa-shipping-fast',
	editorLayout,
	styleClasses: {
		Wrapper: '',
		Delivery_Method: 'Preset-CheckoutShipping-deliveryMethod',
		Delivery_Method_Active: 'Preset-CheckoutShipping-deliveryMethodActive',
		Delivery_Method_Information: '',
		Delivery_Method_Name: 'Preset-CheckoutShipping-deliveryMethodName',
		Delivery_Method_Fee: 'Preset-CheckoutShipping-deliveryMethodFee',
		Delivery_Method_Description: 'Preset-CheckoutShipping-deliveryMethodDescription',
		Delivery_Method_Image: 'Preset-CheckoutShipping-deliveryMethodImage',
		Delivery_Points: 'Preset-CheckoutShipping-deliveryPoints',
		Delivery_Points_Title: 'Preset-CheckoutShipping-deliveryPointsTitle',
		Delivery_Point_Select_Wrapper: 'Preset-CheckoutShipping-deliveryPointSelectWrapper',
		Delivery_Point_Select_Label: 'Preset-CheckoutShipping-deliveryPointSelectLabel',
		Delivery_Point_Select: 'Preset-CheckoutShipping-deliveryPointSelect'
	},
	props: [{
		name: 'showDeliveryMethodName',
		inputType: 'checkbox',
		label: 'Show name',
		default: true
	}, {
		name: 'showDeliveryMethodFee',
		inputType: 'checkbox',
		label: 'Show fee',
		default: true
	}, {
		name: 'showDeliveryMethodDescription',
		inputType: 'checkbox',
		label: 'Show description',
		default: true
	}, {
		name: 'showDeliveryMethodImage',
		inputType: 'checkbox',
		label: 'Show image',
		default: true
	}, {
		name: 'deliveryPointsTitle',
		inputType: 'text',
		label: 'Title',
		default: 'Choose Delivery Point'
	}, {
		name: 'chooseCityLabel',
		inputType: 'text',
		label: 'Label for city dropdown',
		default: 'Select a city:'
	}, {
		name: 'chooseDeliveryPointLabel',
		inputType: 'text',
		label: 'Label for delivery point dropdown',
		default: 'Select a delivery point:'
	}, {
		name: 'deliveryPointSelectPlaceholder',
		inputType: 'text',
		label: 'Placeholder for delivery point selects',
		default: '...'
	}]
};
