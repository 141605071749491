
export const initialState = {
	activeAddon: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'ARTICLE_ADDON_SELECT_TOGGLE': {
			const {art_id} = action.payload;
			const activeAddon = state.activeAddon.art_id === art_id ? {} : action.payload;

			return {
				...state,
				activeAddon
			};
		}
		default: {
			return state;
		}
	}
};
