export default node => `
	<Tabs>
		<Tab heading="General">
			<Grid>
				<Cell size="1of2" description="Appearance">
					<Field name="spaceTabsEvenly"/>
					<Field name="renderAsContentScrollIndicator"/>
				</Cell>
				<Cell size="1of2" description="Device Layout">
					<Field name="accordionOnMobile"/>
					<Field name="accordionOnTablet"/>
					<Field name="accordionOnDesktop"/>
					<Field name="keepTabsOpen"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Advanced" hidden="${node && node.props.renderAsContentScrollIndicator === true}">
			<Grid>
				<Cell size="full">
					<Field name="renderInactiveTabs"/>
				</Cell>
			</Grid>
		</Tab>
	</Tabs>
`;
