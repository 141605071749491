import {connectWrap as connect} from '@viskan/deku-redux';
import {recommendationsFetch, setRecommendationIds} from './actions';
import Component from './components';
import config from './config';

const getCategoryId = (categoryId, latestCategoryId, article) => {
	if (!article.art_id) {
		return categoryId;
	}

	if (latestCategoryId > 0) {
		const articleCategory = article.articleCategories.find(category => category.category.cat_id === latestCategoryId);

		if (articleCategory) {
			return articleCategory.category.cat_id;
		}
	}

	const articleCategory = article.articleCategories.find(category => category.default_flg) || article.articleCategories[0];

	return articleCategory ? articleCategory.category.cat_id : -1;
};

const component = connect(
	state => ({
		articleUrlPrefix: state.site.articleUrlPrefix,
		breadcrumbs: state.cmsComponents.breadcrumbs,
		categoryId: getCategoryId(state.app.category.main.categoryId, state.app.latestCategoryId, state.article.article),
		recommendationIds: state.helloRetail.recommendationIds,
		router: state.router,
		shopcartRows: state.shopcart ? state.shopcart.rows : []
	}),
	() => ({
		recommendationsFetch,
		setRecommendationIds
	})
)(Component);

export default {component, config};
