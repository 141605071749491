export const initialState = {
	recommendationIds: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HELLO_RETAIL_RECOMMENDATION_FETCH_FULFILLED': {
			const recommendationIds = action.payload.reduce((map, obj) => {
				map[obj.id] = {
					...map[obj.id],
					articles: obj.articles,
					meta: {
						trackingLink: obj.trackingLink
					}
				};

				return map;
			}, Object.assign({}, state.recommendationIds));

			return {
				...state,
				recommendationIds
			};
		}
		case 'HELLO_RETAIL_SET_RECOMMENDATION_IDS': {
			const recommendationIds = {};

			action.payload.forEach(id => {
				recommendationIds[id] = {
					...state.recommendationIds[id],
					recommendationId: id
				};
			});

			return {
				...state,
				recommendationIds
			};
		}
		case 'PATH_CHANGE': {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};
