const iconConfig = [{
	name: 'iconNext',
	inputType: 'icon',
	label: 'Choose next icon',
	default: false
}, {
	name: 'iconNextSize',
	inputType: 'text',
	label: 'Set size on next icon',
	default: '2em'
}, {
	name: 'iconNextColor',
	inputType: 'color',
	label: 'Set color on next icon',
	default: '#000'
}, {
	name: 'iconPrev',
	inputType: 'icon',
	label: 'Choose previous icon',
	default: false
}, {
	name: 'iconPrevSize',
	inputType: 'text',
	label: 'Set size on previous icon',
	default: '2em'
}, {
	name: 'iconPrevColor',
	inputType: 'color',
	label: 'Set color on previous icon',
	default: '#000'
}];

const imageConfig = [{
	name: 'imageNext',
	inputType: 'media',
	label: 'Choose next image',
	default: ''
}, {
	name: 'imagePrev',
	inputType: 'media',
	label: 'Choose previous image',
	default: ''
}];

const mobileSettings = [{
	name: 'slidesPerViewMobile',
	inputType: 'text',
	label: 'Slides per view',
	description: 'If you want part of the next image to show, set the number of slides to e.g. 2.2 instead of 2. If you want automatic adjustments, set it to auto.',
	default: '1'
}, {
	name: 'slidesPerColumnMobile',
	inputType: 'select',
	label: 'Slides per column',
	default: '1',
	values: ['1', '2', '3', '4']
}, {
	name: 'slidesPerGroupMobile',
	inputType: 'select',
	label: 'Slides per group',
	default: '1',
	values: ['1', '2', '3', '4']
}, {
	name: 'spaceBetweenMobile',
	inputType: 'select',
	label: 'Space between',
	default: '0',
	values: ['0', '10', '20', '30', '40', '50']
}, {
	name: 'freeModeMobile',
	inputType: 'checkbox',
	label: 'Use free mode on mobile',
	description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
	default: false
}, {
	name: 'centerSlidesMobile',
	inputType: 'checkbox',
	label: 'Center slides',
	default: false
}];

const tabletSettings = [{
	name: 'slidesPerViewTablet',
	inputType: 'text',
	label: 'Slides per view',
	description: 'If you want part of the next image to show, set the number of slides to e.g. 2.2 instead of 2. If you want automatic adjustments, set it to auto.',
	default: '1'
}, {
	name: 'slidesPerColumnTablet',
	inputType: 'select',
	label: 'Slides per column',
	default: '1',
	values: ['1', '2', '3', '4']
}, {
	name: 'slidesPerGroupTablet',
	inputType: 'select',
	label: 'Slides per group',
	default: '1',
	values: ['1', '2', '3', '4']
}, {
	name: 'spaceBetweenTablet',
	inputType: 'select',
	label: 'Space between',
	default: '0',
	values: ['0', '10', '20', '30', '40', '50']
}, {
	name: 'freeModeTablet',
	inputType: 'checkbox',
	label: 'Use free mode on tablet',
	description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
	default: false
}, {
	name: 'centerSlidesTablet',
	inputType: 'checkbox',
	label: 'Center slides',
	default: false
}];

const desktopSettings = [{
	name: 'slidesPerViewDesktop',
	inputType: 'text',
	label: 'Slides per view',
	description: 'If you want part of the next image to show, set the number of slides to e.g. 2.2 instead of 2. If you want automatic adjustments, set it to auto.',
	default: '1'
}, {
	name: 'slidesPerColumnDesktop',
	inputType: 'select',
	label: 'Slides per column',
	default: '1',
	values: ['1', '2', '3', '4']
}, {
	name: 'slidesPerGroupDesktop',
	inputType: 'select',
	label: 'Slides per group',
	default: '1',
	values: ['1', '2', '3', '4']
}, {
	name: 'spaceBetweenDesktop',
	inputType: 'select',
	label: 'Space between',
	default: '0',
	values: ['0', '10', '20', '30', '40', '50']
}, {
	name: 'freeModeDesktop',
	inputType: 'checkbox',
	label: 'Use free mode on desktop',
	description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
	default: false
}, {
	name: 'centerSlidesDesktop',
	inputType: 'checkbox',
	label: 'Center slides',
	default: false
}];

const videoSettings = [{
	name: 'videoAspectRatio',
	inputType: 'text',
	label: 'Video aspect ratio',
	description: 'To correctly size the video player, enter the aspect ratio of your videos.',
	default: '3:4'
}, {
	name: 'videoAutoPlay',
	inputType: 'checkbox',
	label: 'Autoplay',
	default: false
}, {
	name: 'videoControls',
	inputType: 'checkbox',
	label: 'Show Controls',
	default: false
}, {
	name: 'videoLoop',
	inputType: 'checkbox',
	label: 'Loop video',
	default: false
}, {
	name: 'videoMuted',
	inputType: 'checkbox',
	label: 'Muted video',
	default: false
}];

const gridSelectValues = [{
	label: '1 / 1',
	value: 'full'
}, {
	label: '1 / 2',
	value: '1of2'
}, {
	label: '1 / 3',
	value: '1of3'
}, {
	label: '1 / 4',
	value: '1of4'
}, {
	label: '1 / 5',
	value: '1of5'
}, {
	label: '1 / 6',
	value: '1of6'
}, {
	label: '1 / 8',
	value: '1of8'
}, {
	label: '1 / 10',
	value: '1of10'
}, {
	label: '1 / 12',
	value: '1of12'
}];

const conditionallyAddConfig = (node, conditionStr, configArray) => (node && node.props.sliderArrowsType === conditionStr) ? configArray : [];

export default node => ({
	type: 'ARTICLE_MEDIA',
	name: 'Media',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'mobileLayout',
		inputType: 'select',
		label: 'Layout on mobile',
		default: 'Slider',
		values: [{
			label: 'Zoom on hover',
			value: 'Zoom'
		}, {
			label: 'Slider',
			value: 'Slider'
		}, {
			label: 'Slider with zoom on hover',
			value: 'SliderZoom'
		}, {
			label: 'Grid',
			value: 'grid'
		}]
	}, {
		name: 'tabletLayout',
		inputType: 'select',
		label: 'Layout on tablet',
		default: 'Slider',
		values: [{
			label: 'Zoom on hover',
			value: 'Zoom'
		}, {
			label: 'Slider',
			value: 'Slider'
		}, {
			label: 'Slider with zoom on hover',
			value: 'SliderZoom'
		}, {
			label: 'Grid',
			value: 'grid'
		}]
	}, {
		name: 'desktopLayout',
		inputType: 'select',
		label: 'Layout on desktop',
		default: 'Slider',
		values: [{
			label: 'Zoom on hover',
			value: 'Zoom'
		}, {
			label: 'Zoom on scroll and click',
			value: 'TwoStepZoom'
		}, {
			label: 'Slider',
			value: 'Slider'
		}, {
			label: 'Slider with zoom on hover',
			value: 'SliderZoom'
		}, {
			label: 'Slider with zoom on scroll and click',
			value: 'TwoStepSliderZoom'
		}, {
			label: 'Grid',
			value: 'grid'
		}]
	}, {
		name: 'mobileScale',
		inputType: 'select',
		label: 'Image scale on mobile',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'normal',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, {
		name: 'tabletScale',
		inputType: 'select',
		label: 'Image scale on tablet',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'normal',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, {
		name: 'desktopScale',
		inputType: 'select',
		label: 'Image scale on desktop',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'normal',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, {
		name: 'zoomPopup',
		inputType: 'checkbox',
		label: 'Popup on click',
		default: true
	}, node && node.props.zoomPopup === true && {
		name: 'popupSlider',
		inputType: 'checkbox',
		label: 'Show popup as slider',
		default: false
	}, {
		name: 'disableLazyLoadOnMobile',
		inputType: 'checkbox',
		label: 'Disable lazy load on mobile',
		description: 'Will turn off lazy load on mobile. Recommended for media that are visible on the page without scrolling (i.e. above the fold) for better pagespeed.',
		default: false
	}, {
		name: 'disableLazyLoadOnTablet',
		inputType: 'checkbox',
		label: 'Disable lazy load on tablet',
		description: 'Will turn off lazy load on tablet. Recommended for media that are visible on the page without scrolling (i.e. above the fold) for better pagespeed.',
		default: false
	}, {
		name: 'disableLazyLoadOnDesktop',
		inputType: 'checkbox',
		label: 'Disable lazy load on desktop',
		description: 'Will turn off lazy load on desktop. Recommended for media that are visible on the page without scrolling (i.e. above the fold) for better pagespeed.',
		default: false
	}].filter(Boolean),
	hiddenProps: [node && (node.props.mobileLayout === 'grid' || node.props.tabletLayout === 'grid' || node.props.desktopLayout === 'grid') && {
		label: 'Grid settings',
		props: [{
			name: 'mobileCellSize',
			inputType: 'select',
			label: 'Cell size in mobile',
			values: gridSelectValues,
			default: '1of2'
		}, {
			name: 'mobileGridGutter',
			inputType: 'checkbox',
			label: 'Grid gutter in mobile',
			default: true
		}, {
			name: 'useFullSizeForMobile',
			inputType: 'checkbox',
			label: 'Use full size for first image in mobile',
			default: false
		}, {
			name: 'tabletCellSize',
			inputType: 'select',
			label: 'Cell size in tablet',
			values: gridSelectValues,
			default: '1of2'
		}, {
			name: 'tabletGridGutter',
			inputType: 'checkbox',
			label: 'Grid gutter in tablet',
			default: true
		}, {
			name: 'useFullSizeForTablet',
			inputType: 'checkbox',
			label: 'Use full size for first image in tablet',
			default: false
		}, {
			name: 'desktopCellSize',
			inputType: 'select',
			label: 'Cell size in desktop',
			values: gridSelectValues,
			default: '1of2'
		}, {
			name: 'desktopGridGutter',
			inputType: 'checkbox',
			label: 'Grid gutter in desktop',
			default: true
		}, {
			name: 'useFullSizeForDesktop',
			inputType: 'checkbox',
			label: 'Use full size for first image in desktop',
			default: false
		}]
	}, {
		label: 'Slider settings',
		props: [{
			name: 'sliderDirection',
			inputType: 'select',
			label: 'Direction (Needs fixed height)',
			default: 'horizontal',
			values: [{
				label: 'Horizontal',
				value: 'horizontal'
			}, {
				label: 'Vertical',
				value: 'vertical'
			}]
		}, {
			name: 'sliderAutoplay',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'sliderDisableOnInteraction',
			inputType: 'checkbox',
			label: 'Disable autoplay after user interaction',
			default: false
		}, {
			name: 'sliderMousewheel',
			inputType: 'checkbox',
			label: 'Use Mousewheel to navigate between slides',
			default: false
		}, node && node.props.sliderMousewheel === true && {
			name: 'mouseWheelTrigger',
			inputType: 'text',
			label: 'Element to listen for mousewheel events',
			description: 'Element to listen for mousewheel events, (must include . or #). Leave empty for slider container',
			default: ''
		}, {
			name: 'sliderMaintenance',
			inputType: 'checkbox',
			label: 'Set slideshow in maintenance mode',
			default: false
		}, {
			name: 'sliderLoop',
			inputType: 'checkbox',
			label: 'Loop',
			default: true
		}, {
			name: 'sliderSpeed',
			inputType: 'text',
			label: 'Animation speed',
			default: '300'
		}, {
			name: 'sliderDuration',
			inputType: 'text',
			label: 'Animation duration',
			default: '3000'
		}, {
			name: 'sliderEffect',
			inputType: 'select',
			label: 'Effect',
			default: 'slide',
			values: [{
				label: 'Slide',
				value: 'slide'
			}, {
				label: 'Fade',
				value: 'fade'
			}, {
				label: 'Cube',
				value: 'cube'
			}, {
				label: 'Coverflow (Needs fixed height)',
				value: 'coverflow'
			}, {
				label: 'Flip',
				value: 'flip'
			}]
		}, {
			name: 'timing',
			inputType: 'select',
			label: 'Transition',
			default: 'ease',
			values: [{
				label: 'Linear',
				value: 'linear'
			}, {
				label: 'Ease',
				value: 'ease'
			}, {
				label: 'Ease-in',
				value: 'ease-in'
			}, {
				label: 'Ease-out',
				value: 'ease-out'
			}, {
				label: 'Ease-in-out',
				value: 'ease-in-out'
			}]
		}, {
			name: 'sliderArrows',
			inputType: 'checkbox',
			label: 'Arrows',
			default: false
		}, {
			name: 'sliderArrowsType',
			inputType: 'select',
			label: 'Choose arrow type',
			default: 'none',
			values: [{
				label: 'Native',
				value: 'none'
			}, {
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
		...conditionallyAddConfig(node, 'icon', iconConfig),
		...conditionallyAddConfig(node, 'image', imageConfig),
		{
			name: 'sliderPagination',
			inputType: 'checkbox',
			label: 'Pagination',
			default: true
		}, {
			name: 'sliderPaginationType',
			inputType: 'select',
			label: 'Choose pagination type',
			default: 'bullets',
			values: [{
				label: 'Bullets',
				value: 'bullets'
			}, {
				label: 'Fraction',
				value: 'fraction'
			}]
		}].filter(Boolean)
	}, {
		label: 'Slider layout mobile',
		props: [
			...mobileSettings
		]
	}, {
		label: 'Slider layout tablet',
		props: [
			...tabletSettings
		]
	}, {
		label: 'Slider layout desktop',
		props: [
			...desktopSettings
		]
	}, {
		label: 'Zoom layout settings',
		props: [{
			name: 'zoomDelay',
			inputType: 'number',
			label: 'Delay before zooming',
			default: '500'
		}, {
			name: 'zoomThumbnails',
			inputType: 'checkbox',
			label: 'Show thumbnails',
			default: true
		}, {
			name: 'showZoomIcon',
			inputType: 'checkbox',
			label: 'Show toggle zoom icon on touch devices',
			description: 'Note: This icon will only be visible on touch devices and when logged in to CMS.',
			default: false
		}, {
			name: 'zoomIcon',
			inputType: 'icon',
			label: 'Choose toggle zoom icon',
			default: false
		}, {
			name: 'zoomIconSize',
			inputType: 'text',
			label: 'Set size on toggle zoom icon',
			default: '2em'
		}, {
			name: 'zoomIconColor',
			inputType: 'color',
			label: 'Set color on toggle zoom icon',
			default: '#000'
		}]
	}, {
		label: 'Badge settings',
		props: [{
			name: 'showSaleOrNew',
			inputType: 'checkbox',
			label: 'Show sale or new icon',
			default: false
		}, node && node.props.showSaleOrNew === true && {
			name: 'onSaleImage',
			inputType: 'checkbox',
			label: 'Show image to indicate on sale item',
			default: false
		}, {
			name: 'showDiscountPercent',
			inputType: 'checkbox',
			label: 'Show discount percent',
			default: false
		}, {
			name: 'showPropertyIcon',
			inputType: 'checkbox',
			label: 'Show article icons from property keys',
			default: false
		}, node && node.props.showPropertyIcon === true && {
			name: 'propertyIconName',
			inputType: 'text',
			label: 'Property Key Names',
			description: 'Use the internal property key names. Separate with commas.',
			default: 'article_icon'
		}, {
			name: 'showCampaigns',
			inputType: 'checkbox',
			label: 'Show campaign icon',
			default: true
		}, {
			name: 'campaignPopup',
			inputType: 'checkbox',
			label: 'Popup on campaign click',
			default: false
		}, {
			name: 'campaignHeader',
			inputType: 'text',
			label: 'Campaign popup header',
			default: ''
		}, {
			name: 'wrapWithLink', // CUSTOMER UNIQUE
			inputType: 'checkbox', // CUSTOMER UNIQUE
			label: 'Badge-icon wrapped with link', // CUSTOMER UNIQUE
			default: false // CUSTOMER UNIQUE
		}, {
			name: 'showLowerPriceBadge', // CUSTOMER UNIQUE
			inputType: 'checkbox', // CUSTOMER UNIQUE
			label: 'Show lower price badge', // CUSTOMER UNIQUE
			default: false // CUSTOMER UNIQUE
		}].filter(Boolean)
	}, {
		label: 'Video settings',
		props: [
			...videoSettings
		]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'useFallbackImage',
			inputType: 'checkbox',
			label: 'Use fallback images if article variant has no images',
			default: false
		}, {
			name: 'useGlobalSettings',
			inputType: 'checkbox',
			label: 'Use global settings for size and attribute 3 match',
			description: 'Change global settings under the site settings menu',
			default: false
		}, node && !node.props.useGlobalSettings && {
			name: 'isSizeMatchRequired',
			inputType: 'checkbox',
			label: 'Show images for selected size only',
			default: ''
		}, node && !node.props.useGlobalSettings && {
			name: 'isAttr3MatchRequired',
			inputType: 'checkbox',
			label: 'Show images for selected attribute 3 only',
			default: ''
		}, {
			name: 'show360ImageIfPossible',
			inputType: 'checkbox',
			label: 'Override with 360 degree image when possible',
			default: false
		}, node && node.props.show360ImageIfPossible === true && {
			name: 'initiate360WithClick',
			inputType: 'checkbox',
			label: 'Initiate 360 image with click (will save bandwidth)',
			default: false
		}, {
			name: 'excludeImages',
			inputType: 'text',
			label: 'Exclude images (eg. 1011, 1040)',
			default: ''
		}, {
			name: 'imagesOrderList',
			inputType: 'text',
			label: 'Order images by media row id',
			description: 'Comma separated list of media row ids to order images by (eg. 1011, 1040)',
			default: ''
		}, {
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}].filter(Boolean)
	}].filter(Boolean)
});
