export default node => ({
	type: 'ARTICLE_PRICE',
	name: 'Price',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'showDiscountPercent',
		inputType: 'checkbox',
		label: 'Show price discount percent',
		default: false
	}, {
		name: 'showRecPrice',
		inputType: 'checkbox',
		label: 'Show recommended price',
		default: false
	}, {
		name: 'showUnitPrice',
		inputType: 'checkbox',
		label: 'Show unit price',
		default: false
	}, node && node.props.showUnitPrice && {
		name: 'unitPricePrefixText',
		inputType: 'text',
		label: 'Unit price prefix',
		default: 'Unit price'
	}, node && node.props.showUnitPrice && {
		name: 'unitPriceSuffixText',
		inputType: 'text',
		label: 'Unit price suffix',
		default: ''
	}, node && node.props.showRecPrice && {
		name: 'recPricePrefixText',
		inputType: 'text',
		label: 'Recommended price label',
		default: 'Rec price'
	}, {
		name: 'includeAddonsPrice',
		inputType: 'checkbox',
		label: 'Include active addons price',
		default: false
	}, {
		name: 'showSubscriptionPrice',
		inputType: 'checkbox',
		label: 'Show price for selected subscription',
		default: false
	}, {
		name: 'showOnlyOriginalPrice',
		inputType: 'checkbox',
		description: 'Hides Sales, Unit and Lowest price.',
		label: 'Show only original price',
		default: false
	}, {
		name: 'showOnlySalesPrice',
		inputType: 'checkbox',
		description: 'Hides Original, Unit and Lowest price.',
		label: 'Show only sales price',
		default: false
	}, {
		name: 'showOnlyUnitPrice',
		inputType: 'checkbox',
		description: 'Hides Original, Sales and Lowest price.',
		label: 'Show only unit price',
		default: false
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Lowest Price',
		props: [{
			name: 'showLowestPrice',
			inputType: 'select',
			label: 'Show lowest price (when applicable)',
			description: 'This can override global setting `Show lowest price`',
			default: 'useStoreSetting',
			values: [{
				label: 'Use store setting: Show lowest price',
				value: 'useStoreSetting'
			}, {
				label: 'Show',
				value: 'show'
			}, {
				label: 'Hide',
				value: 'hide'
			}]
		}, {
			name: 'showOnlyLowestPrice',
			inputType: 'checkbox',
			description: 'Hides Original and Sales price.',
			label: 'Show only lowest price',
			default: false
		}, {
			name: 'lowestPricePrefixText',
			inputType: 'text',
			label: 'Lowest price prefix text',
			default: ''
		}, {
			name: 'lowestPriceSuffixText',
			inputType: 'text',
			label: 'Lowest price suffix text',
			default: ''
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
