const colorListPresentation = (node, type) => node.props.colorListDisplayType === type || node.props.colorListDisplayTypeTablet === type || node.props.colorListDisplayTypeMobile === type;

const iconConfig = [{
	name: 'iconNext',
	inputType: 'icon',
	label: 'Choose next icon',
	default: false
}, {
	name: 'iconNextSize',
	inputType: 'text',
	label: 'Set size on next icon',
	default: '2em'
}, {
	name: 'iconNextColor',
	inputType: 'color',
	label: 'Set color on next icon',
	default: '#000'
}, {
	name: 'iconPrev',
	inputType: 'icon',
	label: 'Choose previous icon',
	default: false
}, {
	name: 'iconPrevSize',
	inputType: 'text',
	label: 'Set size on previous icon',
	default: '2em'
}, {
	name: 'iconPrevColor',
	inputType: 'color',
	label: 'Set color on previous icon',
	default: '#000'
}];

const imageConfig = [{
	name: 'imageNext',
	inputType: 'media',
	label: 'Choose next image',
	default: ''
}, {
	name: 'imagePrev',
	inputType: 'media',
	label: 'Choose previous image',
	default: ''
}];

const conditionallyAddConfig = (node, conditionStr, configArray) => (node && node.props.type === conditionStr) ? configArray : [];

export default node => ({
	type: 'ARTICLES_SLIDER',
	name: 'Articles slider',
	allowedSections: ['main', 'footer', 'header'],
	disallowedChildren: ['*'],
	category: 'Articles',
	icon: 'fal fa-columns',
	props: [{
		label: 'Source',
		name: 'source',
		inputType: 'select',
		description: 'Active article default category only works on article detail',
		default: 'recent',
		values: [{
			label: 'Recent views',
			value: 'recent'
		}, {
			label: 'Selection',
			value: 'selection'
		}, {
			label: 'Category',
			value: 'category'
		}, {
			label: 'Active article default category',
			value: 'activeArticleDefaultCategory'
		}, {
			label: 'Hello Retail Recommendations',
			value: 'helloRetailRecommendations'
		}],
	}, node && node.props.source === 'helloRetailRecommendations' && {
		name: 'recommendationId',
		inputType: 'text',
		label: 'Hello Retail Recommendations ID',
		default: ''
	}, node && node.props.source === 'category' && {
		name: 'categoryId',
		inputType: 'link',
		label: 'Source category',
		default: '',
		requestKey: 'categoryId'
	}, node && node.props.source === 'selection' && {
		name: 'articleNumbers',
		inputType: 'text',
		label: 'Article numbers (eg. 1234, 5678, 91011)',
		default: ''
	}, node && node.props.source === 'selection' && {
		name: 'splitByAttribute1',
		label: 'Show all colors for articles',
		inputType: 'checkbox',
		description: 'When enabled all available colors are listed. A page reload might be required to get all colors',
		default: false
	}, {
		name: 'showArticleProperties',
		inputType: 'checkbox',
		label: 'Show selected article properties',
		default: false
	}, node && node.props.showArticleProperties && {
		name: 'articleProperties',
		inputType: 'text',
		label: 'Article properties to show',
		description: 'Define internal name of properties to show, split each property with ,',
		default: ''
	}, {
		name: 'size',
		inputType: 'slider',
		label: 'Number of articles to load',
		default: 10,
		min: 1,
		max: 20
	}, {
		name: 'image',
		inputType: 'select',
		label: 'Image',
		default: '1010',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}]
	}, {
		name: 'imageOnHover',
		inputType: 'select',
		label: 'Image on hover',
		default: '-1',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}, {
			label: 'None',
			value: '-1'
		}]
	}, {
		name: 'favorite',
		inputType: 'checkbox',
		label: 'Toggle favorite',
		default: false
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Price settings',
		props: [{
			name: 'showPriceForCurrentSku',
			inputType: 'checkbox',
			label: 'Show price for current sku',
			description: 'When disabled it will display lowest price for variant',
			default: false
		}, node && !node.props.showPriceForCurrentSku && {
			name: 'showFromLabel',
			inputType: 'checkbox',
			label: 'Show from label in price (if possible)',
			description: 'This setting requires showPriceForCurrentSku to be false.'
		}, {
			name: 'showUnitPrice',
			inputType: 'checkbox',
			label: 'Show unit price',
			default: false
		}, node && node.props.showUnitPrice && {
			name: 'unitPricePrefixText',
			inputType: 'text',
			label: 'Unit price prefix',
			default: 'Unit price'
		}, node && node.props.showUnitPrice && {
			name: 'unitPriceSuffixText',
			inputType: 'text',
			label: 'Unit price suffix',
			default: ''
		}, {
			name: 'showDiscountPercent',
			inputType: 'checkbox',
			label: 'Show price discount percent',
			default: false
		}, {
			name: 'showRecPrice',
			inputType: 'checkbox',
			label: 'Show recommended price',
			default: false
		}, node && node.props.showRecPrice && {
			name: 'recPricePrefixText',
			inputType: 'text',
			label: 'Recommended price label',
			default: 'Rec price'
		}, node && node.props.showRecPrice && {
			name: 'showPropertiesForPrice',
			inputType: 'checkbox',
			label: 'Add selected article properties to be shown with rec price',
			default: false
		}, node && node.props.showPropertiesForPrice && node.props.showRecPrice && {
			name: 'articlePropertiesForPrice',
			inputType: 'text',
			label: 'Article properties to show',
			description: 'Define internal name of properties to show, split each property with ,',
			default: ''
		}, {
			name: 'showLowestPrice',
			inputType: 'select',
			label: 'Show lowest price (when applicable)',
			description: 'This can override global setting `Show lowest price`',
			default: 'useStoreSetting',
			values: [{
				label: 'Use store setting: Show lowest price',
				value: 'useStoreSetting'
			}, {
				label: 'Show',
				value: 'show'
			}, {
				label: 'Hide',
				value: 'hide'
			}]
		}, {
			name: 'lowestPricePrefixText',
			inputType: 'text',
			label: 'Lowest price prefix text',
			default: ''
		}, {
			name: 'lowestPriceSuffixText',
			inputType: 'text',
			label: 'Lowest price suffix text',
			default: ''
		}].filter(Boolean)
	}, {
		label: 'Quickbuy settings',
		props: [{
			name: 'quickbuy',
			inputType: 'checkbox',
			label: 'Quick buy',
			default: false
		}, node && node.props.quickbuy && {
			name: 'quickbuyLayout',
			inputType: 'select',
			label: 'Quick buy layout desktop',
			default: 'popup',
			values: [{
				label: 'Popup',
				value: 'popup'
			}, {
				label: 'Slideout',
				value: 'slideout'
			}, {
				label: 'Inline',
				value: 'inline'
			}]
		}, node && node.props.quickbuy && {
			name: 'quickbuyLayoutTablet',
			inputType: 'select',
			label: 'Quick buy layout tablet',
			default: 'popup',
			values: [{
				label: 'Popup',
				value: 'popup'
			}, {
				label: 'Slideout',
				value: 'slideout'
			}, {
				label: 'Inline',
				value: 'inline'
			}]
		}, node && node.props.quickbuy && {
			name: 'quickbuyLayoutMobile',
			inputType: 'select',
			label: 'Quick buy layout mobile',
			default: 'popup',
			values: [{
				label: 'Popup',
				value: 'popup'
			}, {
				label: 'Slideout',
				value: 'slideout'
			}, {
				label: 'Inline',
				value: 'inline'
			}]
		}, node && node.props.quickbuy && (node.props.quickbuyLayout === 'slideout' || node.props.quickbuyLayoutTablet === 'slideout' || node.props.quickbuyLayoutMobile === 'slideout') && {
			name: 'slideoutSide',
			inputType: 'select',
			label: 'Side to slide out from',
			default: 'Bottom',
			values: ['Left', 'Right', 'Top', 'Bottom']
		}, node && node.props.quickbuy && (node.props.quickbuyLayout === 'slideout' || node.props.quickbuyLayoutTablet === 'slideout' || node.props.quickbuyLayoutMobile === 'slideout') && {
			name: 'showOverlay',
			inputType: 'checkbox',
			label: 'Show overlay when slideout is open',
			default: false
		}, node && node.props.quickbuy && {
			name: 'quickbuyButtonText',
			inputType: 'text',
			label: 'Quickbuy button text (Leave empty for default text)',
			default: ''
		}, node && node.props.quickbuy && {
			name: 'quickbuyInPopup',
			inputType: 'select',
			label: 'Show quickbuy layout',
			default: 'multipleVariants',
			values: [{
				label: 'Never',
				value: 'never'
			}, {
				label: 'Always',
				value: 'always'
			}, {
				label: 'If there are more than 1 variant',
				value: 'multipleVariants'
			}]
		}, node && node.props.quickbuy && {
			name: 'addToCartOnClick',
			inputType: 'checkbox',
			label: 'Add to cart on click',
			description: 'When enabled, the article will be added to cart when you click on an attribute. If out of stock, a quickbuy popup will be shown instead.',
			default: false
		}].filter(Boolean)
	}, {
		label: 'Slider settings',
		props: [{
			name: 'play',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'direction',
			inputType: 'select',
			label: 'Direction (Needs fixed height)',
			default: 'horizontal',
			values: [{
				label: 'Horizontal',
				value: 'horizontal'
			}, {
				label: 'Vertical',
				value: 'vertical'
			}]
		}, {
			name: 'sliderDisableOnInteraction',
			inputType: 'checkbox',
			label: 'Disable autoplay after user interaction',
			default: false
		}, {
			name: 'mousewheel',
			inputType: 'checkbox',
			label: 'Use Mousewheel to navigate between slides',
			default: false
		}, {
			name: 'maintenance',
			inputType: 'checkbox',
			label: 'Set slideshow in maintenance mode',
			default: false
		}, {
			name: 'loop',
			inputType: 'checkbox',
			label: 'Loop',
			default: false
		}, {
			name: 'speed',
			inputType: 'text',
			label: 'Animation speed',
			default: '300'
		}, {
			name: 'duration',
			inputType: 'text',
			label: 'Animation duration',
			default: '3000'
		}, {
			name: 'effect',
			inputType: 'select',
			label: 'Effect',
			default: 'slide',
			values: [{
				label: 'Slide',
				value: 'slide'
			}, {
				label: 'Fade',
				value: 'fade'
			}, {
				label: 'Cube',
				value: 'cube'
			}, {
				label: 'Coverflow (Needs fixed height)',
				value: 'coverflow'
			}, {
				label: 'Flip',
				value: 'flip'
			}]
		}, {
			name: 'timing',
			inputType: 'select',
			label: 'Transition',
			default: 'ease',
			values: [{
				label: 'Linear',
				value: 'linear'
			}, {
				label: 'Ease',
				value: 'ease'
			}, {
				label: 'Ease-in',
				value: 'ease-in'
			}, {
				label: 'Ease-out',
				value: 'ease-out'
			}, {
				label: 'Ease-in-out',
				value: 'ease-in-out'
			}]
		}]
	}, {
		label: 'Navigation',
		props: [{
			name: 'arrows',
			inputType: 'checkbox',
			label: 'Show Arrows',
			default: true
		}, {
			name: 'type',
			inputType: 'select',
			label: 'Choose arrow type',
			default: 'none',
			values: [{
				label: 'Native',
				value: 'none'
			}, {
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
		...conditionallyAddConfig(node, 'icon', iconConfig),
		...conditionallyAddConfig(node, 'image', imageConfig),
		{
			name: 'pagination',
			inputType: 'checkbox',
			label: 'Show Pagination',
			default: true
		}, {
			name: 'paginationType',
			inputType: 'select',
			label: 'Choose pagination type',
			default: 'bullets',
			values: [{
				label: 'Bullets',
				value: 'bullets'
			}, {
				label: 'Fraction',
				value: 'fraction'
			}]
		}]
	}, {
		label: 'Slide options mobile',
		props: [{
			name: 'slidesPerViewMobile',
			inputType: 'text',
			label: 'Slides per view',
			description: 'If you want part of the next image to show, set the number of slides to e.g. 5.3',
			default: '5'
		}, {
			name: 'freeModeMobile',
			inputType: 'checkbox',
			label: 'Use free mode on mobile',
			description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
			default: false
		}, {
			name: 'slidesPerColumnMobile',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenMobile',
			inputType: 'select',
			label: 'Space between',
			default: '0',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesMobile',
			inputType: 'checkbox',
			label: 'Center slides',
			default: false
		}]
	}, {
		label: 'Slide options tablet',
		props: [{
			name: 'slidesPerViewTablet',
			inputType: 'text',
			label: 'Slides per view',
			description: 'If you want part of the next image to show, set the number of slides to e.g. 5.3',
			default: '5'
		}, {
			name: 'freeModeTablet',
			inputType: 'checkbox',
			label: 'Use free mode on tablet',
			description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
			default: false
		}, {
			name: 'slidesPerColumnTablet',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenTablet',
			inputType: 'select',
			label: 'Space between',
			default: '0',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesTablet',
			inputType: 'checkbox',
			label: 'Center slides',
			default: false
		}]
	}, {
		label: 'Slide options desktop',
		props: [{
			name: 'slidesPerViewDesktop',
			inputType: 'text',
			label: 'Slides per view',
			description: 'If you want part of the next image to show, set the number of slides to e.g. 5.3',
			default: '5'
		}, {
			name: 'freeModeDesktop',
			inputType: 'checkbox',
			label: 'Use free mode on desktop',
			description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
			default: false
		}, {
			name: 'slidesPerColumnDesktop',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenDesktop',
			inputType: 'select',
			label: 'Space between',
			default: '0',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesDesktop',
			inputType: 'checkbox',
			label: 'Center slides',
			default: false
		}]
	}, {
		label: 'Article settings',
		props: [node && !node.props.showColorList && {
			name: 'showColor',
			inputType: 'checkbox',
			label: 'Show article color',
			description: 'This setting is not compatible with Colorlist.',
			default: false
		}, {
			name: 'showArticleNumber',
			inputType: 'checkbox',
			label: 'Show Article number',
			default: false
		}, {
			name: 'showPlunoNumber',
			inputType: 'checkbox',
			label: 'Show pluno number',
			description: 'If articlelist is unsplitted, it shows the first variant',
			default: false
		}, {
			name: 'showBrandName',
			inputType: 'checkbox',
			label: 'Show brand name',
			default: false
		}, {
			name: 'scale',
			inputType: 'select',
			label: 'Image scale',
			description: 'Select the smallest scale possible to increase page speed',
			default: 'list',
			values: [{
				label: 'Original',
				value: 'original'
			}, {
				label: 'Large',
				value: 'large'
			}, {
				label: 'Normal',
				value: 'normal'
			}, {
				label: 'List',
				value: 'list'
			}, {
				label: 'Thumbnail',
				value: 'thumbnail'
			}]
		}, {
			name: 'showArticleDescription',
			inputType: 'checkbox',
			label: 'Show article description',
			default: false
		}, node && node.props.showArticleDescription && {
			name: 'stripArticleDescriptionHtml',
			inputType: 'checkbox',
			label: 'Remove HTML from article description',
			default: true
		}].filter(Boolean)
	}, {
		label: 'Badge settings',
		props: [{
			name: 'showSaleOrNew',
			inputType: 'checkbox',
			label: 'Show sale or new icon',
			default: false
		}, node && node.props.showSaleOrNew === true && {
			name: 'onSaleImage',
			inputType: 'checkbox',
			label: 'Show image to indicate on sale item',
			default: false
		}, {
			name: 'showPropertyIcon',
			inputType: 'checkbox',
			label: 'Show article icons from property keys',
			default: false
		}, node && node.props.showPropertyIcon === true && {
			name: 'propertyIconName',
			inputType: 'text',
			label: 'Property Key Names',
			description: 'Use the internal property key names. Separate with commas.',
			default: 'article_icon'
		}, {
			name: 'showDiscountPercent',
			inputType: 'checkbox',
			label: 'Show discount percent',
			default: false
		}, {
			name: 'showCampaigns',
			inputType: 'checkbox',
			label: 'Show campaign icon',
			default: true
		}, {
			name: 'campaignPopup',
			inputType: 'checkbox',
			label: 'Popup on campaign click',
			default: false
		}, {
			name: 'campaignHeader',
			inputType: 'text',
			label: 'Campaign popup header',
			default: ''
		}, {
			name: 'showLowerPriceBadge', // CUSTOMER UNIQUE
			inputType: 'checkbox', // CUSTOMER UNIQUE
			label: 'Show lower price badge', // CUSTOMER UNIQUE
			default: false // CUSTOMER UNIQUE
		}].filter(Boolean)
	}, {
		label: 'Colorlist',
		props: [{
			name: 'showColorList',
			inputType: 'checkbox',
			label: 'Show colorlist (attribute1)',
			description: 'Show all colors available in either a slider or select. Not compatible with Favorites and showPriceForCurrentSku setting. Requires you to refresh page.',
			default: false
		}, {
			name: 'colorListShowFromPrice',
			inputType: 'checkbox',
			label: 'Show lowest available price regardless of chosen colour',
			description: 'Will show a "from" label before the price if there is different prices per SKU.',
			default: false
		}, {
			name: 'colorListOnlyShowInStock',
			inputType: 'checkbox',
			label: 'Only show colors that are in stock',
			default: true
		}, {
			name: 'colorListChangeColorOnHover',
			inputType: 'checkbox',
			label: 'Change color on hover',
			default: false
		}, {
			name: 'colorListDisplayType',
			inputType: 'select',
			label: 'Display Colorlist as (Desktop)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Fixed Number of Colors',
				value: 'fixedNumberOfColors'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListPlacementDesktop',
			inputType: 'select',
			label: 'Colorlist placement',
			default: '',
			values: [{
				label: 'Below Image',
				value: 'image'
			}, {
				label: 'Article body',
				value: 'articleBody'
			}]
		}, node && node.props.colorListDisplayType === 'fixedNumberOfColors' && {
			name: 'colorListNumberOfColorsDesktop',
			inputType: 'number',
			label: 'Number of colors to show (desktop)',
			description: 'If there are more available colors than the specified number, they will be displayed as e.g. +3',
			default: '3'
		}, {
			name: 'colorListDisplayTypeTablet',
			inputType: 'select',
			label: 'Display Colorlist as (Tablet)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Fixed Number of Colors',
				value: 'fixedNumberOfColors'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListPlacementTablet',
			inputType: 'select',
			label: 'Colorlist placement',
			default: '',
			values: [{
				label: 'Below Image',
				value: 'image'
			}, {
				label: 'Article body',
				value: 'articleBody'
			}]
		}, node && node.props.colorListDisplayTypeTablet === 'fixedNumberOfColors' && {
			name: 'colorListNumberOfColorsTablet',
			inputType: 'number',
			label: 'Number of colors to show (tablet)',
			description: 'If there are more available colors than the specified number, they will be displayed as e.g. +3',
			default: '3'
		}, {
			name: 'colorListDisplayTypeMobile',
			inputType: 'select',
			label: 'Display Colorlist as (Mobile)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Fixed Number of Colors',
				value: 'fixedNumberOfColors'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListPlacementMobile',
			inputType: 'select',
			label: 'Colorlist placement',
			default: '',
			values: [{
				label: 'Below Image',
				value: 'image'
			}, {
				label: 'Article body',
				value: 'articleBody'
			}]
		}, node && node.props.colorListDisplayTypeMobile === 'fixedNumberOfColors' && {
			name: 'colorListNumberOfColorsMobile',
			inputType: 'number',
			label: 'Number of colors to show (mobile)',
			description: 'If there are more available colors than the specified number, they will be displayed as e.g. +3',
			default: '3'
		}, node && colorListPresentation(node, 'select') && {
			name: 'colorListSelectPlaceholder',
			inputType: 'text',
			label: 'Select placeholder',
			default: 'Color'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderSpeed',
			inputType: 'text',
			label: 'Slider Animation speed',
			default: '500'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListDuration',
			inputType: 'text',
			label: 'Slider Animation duration',
			default: '3000'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerView',
			'inputType': 'select',
			'label': 'Slides per view (Desktop)',
			'default': '5',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerViewTablet',
			'inputType': 'select',
			'label': 'Slides per view (Tablet)',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerViewMobile',
			'inputType': 'select',
			'label': 'Slides per view (Mobile)',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSpaceBetween',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListCenterSlides',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIcon',
			inputType: 'icon',
			label: 'Choose icon for next arrow',
			default: '{"prefix":"fal","x":{"name":"chevron-right"}}'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIconSize',
			inputType: 'text',
			label: 'Set size on next arrow icon',
			default: '1em'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIconColor',
			inputType: 'color',
			label: 'Set color on next arrow icon',
			default: '#000'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIcon',
			inputType: 'icon',
			label: 'Choose icon for previous arrow',
			default: '{"prefix":"fal","x":{"name":"chevron-left"}}'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIconSize',
			inputType: 'text',
			label: 'Set size on previous arrow icon',
			default: '1em'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIconColor',
			inputType: 'color',
			label: 'Set color on previous arrow icon',
			default: '#000'
		}].filter(Boolean)
	}, {
		label: 'Sizelist',
		props: [{
			name: 'showSizeList',
			inputType: 'checkbox',
			label: 'Show Sizelist (attribute2)',
			description: 'Show all sizes available under an article. Not compatible with Favorites. Requires you to refresh page.',
			default: false
		}, {
			name: 'sizeListMaxNumberOfSizes',
			inputType: 'number',
			label: 'How many sizes to display (if available)',
			default: 5
		}, {
			name: 'sizeListOnlyShowInStock',
			inputType: 'checkbox',
			label: 'Only show sizes that are in stock',
			default: true
		}, {
			name: 'showSizeRange',
			inputType: 'checkbox',
			label: 'Show size range',
			default: false
		}, {
			name: 'regexToRemoveFromSize',
			inputType: 'text',
			label: 'Regex pattern which will be removed from size',
			default: ''
		}]
	}, {
		label: 'Favorite button',
		props: [{
			name: 'favoriteType',
			inputType: 'select',
			label: 'Choose favorite button type',
			default: 'icon',
			values: [{
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIcon',
			inputType: 'icon',
			label: 'Choose favorite icon',
			default: '{"prefix":"fal","x":{"name":"heart"}}'
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIconSelected',
			inputType: 'icon',
			label: 'Choose favorite icon for selected',
			default: '{"prefix":"fas","x":{"name":"heart"}}'
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIconSize',
			inputType: 'text',
			label: 'Set size on favorite icon',
			default: '1em'
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIconColor',
			inputType: 'color',
			label: 'Set color on favorite icon',
			default: '#000'
		}, node && node.props.favoriteType === 'image' && {
			name: 'favoriteImage',
			inputType: 'media-v2',
			label: 'Choose favorite image',
			default: ''
		}, node && node.props.favoriteType === 'image' && {
			name: 'favoriteImageSelected',
			inputType: 'media-v2',
			label: 'Choose favorite image when selected',
			default: ''
		}].filter(Boolean)
	}, node && (node.props.source === 'category' || node.props.source === 'activeArticleDefaultCategory') && {
		label: 'Sort options',
		props: [{
			name: 'sort',
			inputType: 'select',
			label: 'Sort articles in category',
			default: '[{"artcatsort": "asc"}]',
			description: 'Sorting is only possible if the source is Category or Active Article Default Category',
			values: [{
				label: 'Activation date (ASCENDING)',
				value: '[{"activation_date": "asc"}]'
			}, {
				label: 'Activation date (DESCENDING)',
				value: '[{"activation_date": "desc"}]'
			}, {
				label: 'Lowest price sales (ASCENDING)',
				value: '[{"lowest_price_sales": "asc"}]'
			}, {
				label: 'Lowest price sales (DESCENDING)',
				value: '[{"lowest_price_sales": "desc"}]'
			}, {
				label: 'Article name (ASCENDING)',
				value: '[{"artname": "asc"}]'
			}, {
				label: 'Article name (DESCENDING)',
				value: '[{"artname": "desc"}]'
			}, {
				label: 'Article number (ASCENDING)',
				value: '[{"art_no": "asc"}]'
			}, {
				label: 'Article number (DESCENDING)',
				value: '[{"art_no": "desc"}]'
			}, {
				label: 'Article category (ASCENDING)',
				value: '[{"artcatsort": "asc"}]'
			}, {
				label: 'Article category (DESCENDING)',
				value: '[{"artcatsort": "desc"}]'
			}, {
				label: 'Article created (ASCENDING)',
				value: '[{"stamp_dat_cr": "asc"}]'
			}, {
				label: 'Article created (DESCENDING)',
				value: '[{"stamp_dat_cr": "desc"}]'
			}]
		}]
	}].filter(Boolean)
});
