export const initialState = {
	hasIncomingArticles: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SHOPCART_BALANCE_FETCH_FULFILLED': {
			const balance = action.payload;
			const hasIncomingArticles = Object.values(balance).some(val => val.balance_inc_disp > 0 && (val.balance_inc_disp === val.balance_disp));

			return {
				...state,
				balance,
				hasIncomingArticles
			};
		}
		default: {
			return state;
		}
	}
};
