const colorListPresentation = (node, type) => node.props.colorListDisplayType === type || node.props.colorListDisplayTypeTablet === type || node.props.colorListDisplayTypeMobile === type;

const iconConfig = [{
	name: 'iconNext',
	inputType: 'icon',
	label: 'Choose next icon',
	default: false
}, {
	name: 'iconNextSize',
	inputType: 'text',
	label: 'Set size on next icon',
	default: '2em'
}, {
	name: 'iconNextColor',
	inputType: 'color',
	label: 'Set color on next icon',
	default: '#000'
}, {
	name: 'iconPrev',
	inputType: 'icon',
	label: 'Choose previous icon',
	default: false
}, {
	name: 'iconPrevSize',
	inputType: 'text',
	label: 'Set size on previous icon',
	default: '2em'
}, {
	name: 'iconPrevColor',
	inputType: 'color',
	label: 'Set color on previous icon',
	default: '#000'
}];

const imageConfig = [{
	name: 'imageNext',
	inputType: 'media',
	label: 'Choose next image',
	default: ''
}, {
	name: 'imagePrev',
	inputType: 'media',
	label: 'Choose previous image',
	default: ''
}];

const conditionallyAddConfig = (node, conditionStr, configArray) => (node && node.props.arrowType === conditionStr) ? configArray : [];

export default node => ({
	type: 'ARTICLE_RELATED',
	name: 'Related',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-gem',
	props: [{
		name: 'title',
		inputType: 'text',
		label: 'Title',
		default: 'Matching products'
	}, {
		name: 'relationType',
		inputType: 'select',
		label: 'Relation type',
		default: '0',
		values: [{
			label: 'Matching',
			value: '0'
		}, {
			label: 'Also bought',
			value: '4'
		}, {
			label: 'Add-on',
			value: '5'
		}, {
			label: 'Purchase options',
			value: '8'
		}]
	}, node && node.props.relationType === '0' && {
		name: 'articleType',
		inputType: 'select',
		label: 'Article type',
		default: 'all',
		values: [{
			label: 'All',
			value: 'all'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'Lookbook',
			value: 'lookbook'
		}, {
			label: 'Mix & Match',
			value: 'mix_match'
		}]
	}, {
		name: 'matchColor',
		inputType: 'checkbox',
		label: 'Match related to selected color',
		default: false
	}, {
		name: 'quickbuy',
		inputType: 'checkbox',
		label: 'Quick buy',
		default: false
	}, node && node.props.quickbuy && {
		name: 'quickbuyInPopup',
		inputType: 'select',
		label: 'Show quickbuy popup',
		default: 'multipleVariants',
		values: [{
			label: 'Never',
			value: 'never'
		}, {
			label: 'Always',
			value: 'always'
		}, {
			label: 'If there are more than 1 variant',
			value: 'multipleVariants'
		}]
	}, {
		name: 'image',
		inputType: 'select',
		label: 'Image',
		default: '1010',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}]
	}, {
		name: 'imageOnHover',
		inputType: 'select',
		label: 'Image on hover',
		default: '-1',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}, {
			label: 'None',
			value: '-1'
		}]
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Price settings',
		props: [{
			name: 'showPriceForCurrentSku',
			inputType: 'checkbox',
			label: 'Show price for current sku',
			description: 'When disabled it will display lowest price for variant',
			default: false
		}, node && !node.props.showPriceForCurrentSku && {
			name: 'showFromLabel',
			inputType: 'checkbox',
			label: 'Show from label in price (if possible)',
			description: 'This setting requires showPriceForCurrentSku to be false.'
		}, {
			name: 'showUnitPrice',
			inputType: 'checkbox',
			label: 'Show unit price',
			default: false
		}, node && node.props.showUnitPrice && {
			name: 'unitPricePrefixText',
			inputType: 'text',
			label: 'Unit price prefix',
			default: 'Unit price'
		}, node && node.props.showUnitPrice && {
			name: 'unitPriceSuffixText',
			inputType: 'text',
			label: 'Unit price suffix',
			default: ''
		}, {
			name: 'showDiscountPercent',
			inputType: 'checkbox',
			label: 'Show price discount percent',
			default: false
		}, {
			name: 'showRecPrice',
			inputType: 'checkbox',
			label: 'Show recommended price',
			default: false
		}, node && node.props.showRecPrice && {
			name: 'recPricePrefixText',
			inputType: 'text',
			label: 'Recommended price label',
			default: 'Rec price'
		}, node && node.props.showRecPrice && {
			name: 'showPropertiesForPrice',
			inputType: 'checkbox',
			label: 'Add selected article properties to be shown with rec price',
			default: false
		}, node && node.props.showPropertiesForPrice && node.props.showRecPrice && {
			name: 'articlePropertiesForPrice',
			inputType: 'text',
			label: 'Article properties to show',
			description: 'Define internal name of properties to show, split each property with ,',
			default: ''
		}, {
			name: 'showLowestPrice',
			inputType: 'select',
			label: 'Show lowest price (when applicable)',
			description: 'This can override global setting `Show lowest price`',
			default: 'useStoreSetting',
			values: [{
				label: 'Use store setting: Show lowest price',
				value: 'useStoreSetting'
			}, {
				label: 'Show',
				value: 'show'
			}, {
				label: 'Hide',
				value: 'hide'
			}]
		}, {
			name: 'lowestPricePrefixText',
			inputType: 'text',
			label: 'Lowest price prefix text',
			default: ''
		}, {
			name: 'lowestPriceSuffixText',
			inputType: 'text',
			label: 'Lowest price suffix text',
			default: ''
		}].filter(Boolean)
	}, {
		label: 'Layout per device',
		props: [{
			name: 'mobileLayout',
			inputType: 'select',
			label: 'Layout on mobile',
			default: 'Slider',
			values: ['Grid', 'Slider']
		}, {
			name: 'tabletLayout',
			inputType: 'select',
			label: 'Layout on tablet',
			default: 'Slider',
			values: ['Grid', 'Slider']
		}, {
			name: 'desktopLayout',
			inputType: 'select',
			label: 'Layout on desktop',
			default: 'Grid',
			values: ['Grid', 'Slider']
		}]
	}, {
		label: 'Grid settings',
		props: [{
			name: 'maxArticles',
			inputType: 'slider',
			label: 'Maximum number of articles',
			min: 1,
			max: 20,
			step: 1,
			default: 4
		}, {
			name: 'columnsSm',
			inputType: 'slider',
			label: 'Columns mobile',
			default: 2
		}, {
			name: 'columnsMd',
			inputType: 'slider',
			label: 'Columns tablet',
			default: 3
		}, {
			name: 'columnsLg',
			inputType: 'slider',
			label: 'Columns desktop',
			default: 4
		}, {
			name: 'gutter',
			inputType: 'checkbox',
			label: 'Gutter',
			default: true
		}]
	}, {
		label: 'Slider settings',
		props: [{
			name: 'sliderPagination',
			inputType: 'checkbox',
			label: 'Pagination',
			default: false
		}, {
			name: 'sliderArrows',
			inputType: 'checkbox',
			label: 'Arrows',
			default: true
		}, {
			name: 'arrowType',
			inputType: 'select',
			label: 'Choose arrow type',
			default: 'none',
			values: [{
				label: 'Native',
				value: 'none'
			}, {
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
		...conditionallyAddConfig(node, 'icon', iconConfig),
		...conditionallyAddConfig(node, 'image', imageConfig),
		{
			name: 'sliderAutoplay',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'sliderCenter',
			inputType: 'checkbox',
			label: 'Center active slide',
			default: true
		}, {
			name: 'sliderMargin',
			inputType: 'slider',
			label: 'Margin between slides',
			min: 1,
			max: 50,
			step: 1,
			default: 20
		}, {
			name: 'sliderColor',
			inputType: 'color',
			label: 'Navigation color',
			default: '#000000'
		}, {
			name: 'direction',
			inputType: 'select',
			label: 'Direction (Needs fixed height)',
			default: 'horizontal',
			values: [{
				label: 'Horizontal',
				value: 'horizontal'
			}, {
				label: 'Vertical',
				value: 'vertical'
			}]
		}, {
			name: 'sliderDisableOnInteraction',
			inputType: 'checkbox',
			label: 'Disable autoplay after user interaction',
			default: false
		}, {
			name: 'mousewheel',
			inputType: 'checkbox',
			label: 'Use Mousewheel to navigate between slides',
			default: false
		}, {
			name: 'maintenance',
			inputType: 'checkbox',
			label: 'Set slideshow in maintenance mode',
			default: false
		}, {
			name: 'loop',
			inputType: 'checkbox',
			label: 'Loop',
			default: false
		}, {
			name: 'speed',
			inputType: 'text',
			label: 'Animation speed',
			default: '300'
		}, {
			name: 'duration',
			inputType: 'text',
			label: 'Animation duration',
			default: '3000'
		}, {
			name: 'effect',
			inputType: 'select',
			label: 'Effect',
			default: 'slide',
			values: [{
				label: 'Slide',
				value: 'slide'
			}, {
				label: 'Fade',
				value: 'fade'
			}, {
				label: 'Cube',
				value: 'cube'
			}, {
				label: 'Coverflow (Needs fixed height)',
				value: 'coverflow'
			}, {
				label: 'Flip',
				value: 'flip'
			}]
		}, {
			name: 'timing',
			inputType: 'select',
			label: 'Transition',
			default: 'ease',
			values: [{
				label: 'Linear',
				value: 'linear'
			}, {
				label: 'Ease',
				value: 'ease'
			}, {
				label: 'Ease-in',
				value: 'ease-in'
			}, {
				label: 'Ease-out',
				value: 'ease-out'
			}, {
				label: 'Ease-in-out',
				value: 'ease-in-out'
			}]
		}]
	}, {
		label: 'Slider settings mobile',
		props: [{
			name: 'slidesPerViewSm',
			inputType: 'text',
			label: 'Slides per view',
			description: 'If you want part of the next image to show, set the number of slides to e.g. 5.3',
			default: '2'
		}, {
			name: 'freeModeMobile',
			inputType: 'checkbox',
			label: 'Use free mode on mobile',
			description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
			default: false
		}, {
			name: 'slidesPerColumnMobile',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenMobile',
			inputType: 'select',
			label: 'Space between',
			default: '20',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesMobile',
			inputType: 'checkbox',
			label: 'Center slides',
			default: true
		}]
	}, {
		label: 'Slider settings tablet',
		props: [{
			name: 'slidesPerViewMd',
			inputType: 'text',
			label: 'Slides per view',
			description: 'If you want part of the next image to show, set the number of slides to e.g. 5.3',
			default: '3'
		}, {
			name: 'freeModeTablet',
			inputType: 'checkbox',
			label: 'Use free mode on tablet',
			description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
			default: false
		}, {
			name: 'slidesPerColumnTablet',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenTablet',
			inputType: 'select',
			label: 'Space between',
			default: '20',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesTablet',
			inputType: 'checkbox',
			label: 'Center slides',
			default: true
		}]
	}, {
		label: 'Slider settings desktop',
		props: [{
			name: 'slidesPerViewLg',
			inputType: 'text',
			label: 'Slides per view',
			description: 'If you want part of the next image to show, set the number of slides to e.g. 5.3',
			default: '4'
		}, {
			name: 'freeModeDesktop',
			inputType: 'checkbox',
			label: 'Use free mode on desktop',
			description: 'Enables a smooth slider instead of sliding one fixed frame at a time',
			default: false
		}, {
			name: 'slidesPerColumnDesktop',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenDesktop',
			inputType: 'select',
			label: 'Space between',
			default: '20',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesDesktop',
			inputType: 'checkbox',
			label: 'Center slides',
			default: true
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}, {
		label: 'Badge settings',
		props: [{
			name: 'showSaleOrNew',
			inputType: 'checkbox',
			label: 'Show sale or new icon',
			default: false
		}, node && node.props.showSaleOrNew === true && {
			name: 'onSaleImage',
			inputType: 'checkbox',
			label: 'Show image to indicate on sale item',
			default: false
		}, {
			name: 'showPropertyIcon',
			inputType: 'checkbox',
			label: 'Show article icons from property keys',
			default: false
		}, node && node.props.showPropertyIcon === true && {
			name: 'propertyIconName',
			inputType: 'text',
			label: 'Property Key Names',
			description: 'Use the internal property key names. Separate with commas.',
			default: 'article_icon'
		}, {
			name: 'showCampaigns',
			inputType: 'checkbox',
			label: 'Show campaign icon',
			default: true
		}, {
			name: 'campaignPopup',
			inputType: 'checkbox',
			label: 'Popup on campaign click',
			default: false
		}, {
			name: 'campaignHeader',
			inputType: 'text',
			label: 'Campaign popup header',
			default: ''
		}, {
			name: 'showLowerPriceBadge', // CUSTOMER UNIQUE
			inputType: 'checkbox', // CUSTOMER UNIQUE
			label: 'Show lower price badge', // CUSTOMER UNIQUE
			default: false // CUSTOMER UNIQUE
		}].filter(Boolean)
	}, {
		label: 'Colorlist',
		props: [{
			name: 'showColorList',
			inputType: 'checkbox',
			label: 'Show Colorlist (attribute1)',
			description: 'Show all colors available in either a slider or select. Not compatible with Favorites and showPriceForCurrentSku setting. Requires you to refresh page.',
			default: false
		}, {
			name: 'colorListShowFromPrice',
			inputType: 'checkbox',
			label: 'Show lowest available price regardless of chosen colour',
			description: 'Will show a "from" label before the price if there is different prices per SKU.',
			default: false
		}, {
			name: 'colorListOnlyShowInStock',
			inputType: 'checkbox',
			label: 'Only show colors that are in stock',
			default: true
		}, {
			name: 'colorListChangeColorOnHover',
			inputType: 'checkbox',
			label: 'Change color on hover',
			default: false
		}, {
			name: 'colorListDisplayType',
			inputType: 'select',
			label: 'Display Colorlist as (Desktop)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Fixed Number of Colors',
				value: 'fixedNumberOfColors'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListPlacementDesktop',
			inputType: 'select',
			label: 'Colorlist placement',
			default: '',
			values: [{
				label: 'Below Image',
				value: 'image'
			}, {
				label: 'Article body',
				value: 'articleBody'
			}]
		}, node && node.props.colorListDisplayType === 'fixedNumberOfColors' && {
			name: 'colorListNumberOfColorsDesktop',
			inputType: 'number',
			label: 'Number of colors to show (desktop)',
			description: 'If there are more available colors than specified above, they will be displayed as e.g. +3',
			default: '3'
		}, {
			name: 'colorListDisplayTypeTablet',
			inputType: 'select',
			label: 'Display Colorlist as (Tablet)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Fixed Number of Colors',
				value: 'fixedNumberOfColors'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListPlacementTablet',
			inputType: 'select',
			label: 'Colorlist placement',
			default: '',
			values: [{
				label: 'Below Image',
				value: 'image'
			}, {
				label: 'Article body',
				value: 'articleBody'
			}]
		}, node && node.props.colorListDisplayTypeTablet === 'fixedNumberOfColors' && {
			name: 'colorListNumberOfColorsTablet',
			inputType: 'number',
			label: 'Number of colors to show (tablet)',
			description: 'If there are more available colors than specified above, they will be displayed as e.g. +3',
			default: '3'
		}, {
			name: 'colorListDisplayTypeMobile',
			inputType: 'select',
			label: 'Display Colorlist as (Mobile)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Fixed Number of Colors',
				value: 'fixedNumberOfColors'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListPlacementMobile',
			inputType: 'select',
			label: 'Colorlist placement',
			default: '',
			values: [{
				label: 'Below Image',
				value: 'image'
			}, {
				label: 'Article body',
				value: 'articleBody'
			}]
		}, node && node.props.colorListDisplayTypeMobile === 'fixedNumberOfColors' && {
			name: 'colorListNumberOfColorsMobile',
			inputType: 'number',
			label: 'Number of colors to show (mobile)',
			description: 'If there are more available colors than specified above, they will be displayed as e.g. +3',
			default: '3'
		}, node && colorListPresentation(node, 'select') && {
			name: 'colorListSelectPlaceholder',
			inputType: 'text',
			label: 'Select placeholder',
			default: 'Color'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderSpeed',
			inputType: 'text',
			label: 'Slider Animation speed',
			default: '500'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListDuration',
			inputType: 'text',
			label: 'Slider Animation duration',
			default: '3000'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerView',
			'inputType': 'select',
			'label': 'Slides per view (Desktop)',
			'default': '5',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerViewTablet',
			'inputType': 'select',
			'label': 'Slides per view (Tablet)',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerViewMobile',
			'inputType': 'select',
			'label': 'Slides per view (Mobile)',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSpaceBetween',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListCenterSlides',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIcon',
			inputType: 'icon',
			label: 'Choose icon for next arrow',
			default: '{"prefix":"fal","x":{"name":"chevron-right"}}'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIconSize',
			inputType: 'text',
			label: 'Set size on next arrow icon',
			default: '1em'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIconColor',
			inputType: 'color',
			label: 'Set color on next arrow icon',
			default: '#000'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIcon',
			inputType: 'icon',
			label: 'Choose icon for previous arrow',
			default: '{"prefix":"fal","x":{"name":"chevron-left"}}'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIconSize',
			inputType: 'text',
			label: 'Set size on previous arrow icon',
			default: '1em'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIconColor',
			inputType: 'color',
			label: 'Set color on previous arrow icon',
			default: '#000'
		}].filter(Boolean)
	}, {
		label: 'Sizelist',
		props: [{
			name: 'showSizeList',
			inputType: 'checkbox',
			label: 'Show Sizelist (attribute2)',
			description: 'Show all sizes available under an article. Not compatible with Favorites. Requires you to refresh page.',
			default: false
		}, {
			name: 'sizeListMaxNumberOfSizes',
			inputType: 'number',
			label: 'How many sizes to display (if available)',
			default: 5
		}, {
			name: 'sizeListOnlyShowInStock',
			inputType: 'checkbox',
			label: 'Only show sizes that are in stock',
			default: true
		}, {
			name: 'showSizeRange',
			inputType: 'checkbox',
			label: 'Show size range',
			default: false
		}, {
			name: 'regexToRemoveFromSize',
			inputType: 'text',
			label: 'Regex pattern which will be removed from size',
			default: ''
		}]
	}]
});
