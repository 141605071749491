import {SHOPCART_ADD_FULFILLED, SHOPCART_EDIT_FULFILLED, SHOPCART_FETCH_FULFILLED, SHOPCART_REMOVE_FULFILLED, SHOPCART_UPDATE_FULFILLED, SHOPCART_SET, SHOPCART_SET_TEMP_QTY, SHOPCART_STORE_KLARNA_SHIPPING_DATA} from '../action-types';

const initialState = {
	rows: [],
	head: {},
	outorder: {},
	offers: {},
	tempRowQty: {},
	storedKlarnaShippingData: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOPCART_ADD_FULFILLED:
		case SHOPCART_EDIT_FULFILLED:
		case SHOPCART_FETCH_FULFILLED:
		case SHOPCART_REMOVE_FULFILLED:
		case SHOPCART_UPDATE_FULFILLED:
		case SHOPCART_SET: {
			return {
				...state,
				...action.payload.data,
				tempRowQty: {}
			};
		}
		case SHOPCART_SET_TEMP_QTY: {
			return {
				...state,
				tempRowQty: action.payload
			};
		}
		case SHOPCART_STORE_KLARNA_SHIPPING_DATA: {
			return {
				...state,
				storedKlarnaShippingData: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
