import {createAction} from 'redux-actions';

const articlesSearch = (articleNumbers, {id, trackingLink}) => {
	const artIds = true;
	return v12.search(encodeURI(articleNumbers), {query: {artIds, filterSplit: false}})
		.then(({articles}) => {
			if (!articles) {
				return {
					articles: [],
					id
				};
			}

			const {hits} = articles;

			return {
				articles: hits,
				id,
				trackingLink
			};
		});
};

export const fetch = (query, options) => {
	const ids = Object.values(query).map(id => id.recommendationId).join(',');
	const hierarchies = options.hierarchies || {};

	/**
	 * Extract each categoryName from breadcrumbs and return array
	 */
	const hierarchy = Object.values(hierarchies).reduce((accumulator, crumb) => {
		if (crumb.level >= 3) {
			accumulator.push(crumb.categoryName);
		}

		return accumulator;
	}, []);

	/**
	 * Map each recommendationId and apply filter
	 */
	const filterData = Object.values(query).map(id => {
		const hierarchiesFilter = hierarchy.map(category => `crawledData[${id.recommendationId}][hierarchies][0][]=${encodeURI(category)}`).join('&');
		const urlsFilter = options.shopcartUrls.map(url => `crawledData[${id.recommendationId}][urls][]=${encodeURI(url)}`).join('&');

		return {
			hierarchiesFilter,
			urlsFilter
		};
	});

	/**
	 * Create string from filter array above
	 */
	const filters = filterData.map(filter => {
		const values = Object.values(filter).join('&');
		return values;
	}).join('&');

	return v12.util.http('/api/hello-retail', {query: {
		recommendationId: ids,
		url: encodeURI(options.url),
		userId: options.userId,
		filters,
		...v12.options.defaults
	}})
		.then(({body}) => {
			const result = body.result || {};
			/**
			 * Loop each result object and rewrite to array with objects
			 * contain artId's & attr1Ids
			 */
			const response = Object.entries(result).reduce((res, [key, value]) => {
				const {result} = value;

				/**
				 * If result is empty, return empty values
				 */
				if (!result || result.length === 0) {
					res[key] = {
						articles: [],
						trackingLink: ''
					};

					return res;
				}

				const trackingHash = result[0].url.match(/#.*/ig);
				/**
				 * Loop eact result object, return articles artId's & attr1Ids
				 */
				const articles = result.reduce((accumulator, article) => {
					const attr1Id = article.url.match((/attr1_id=(\d+)/i));

					const product = {
						'art_id': article.extraData && article.extraData.artId ? parseInt(article.extraData.artId, 10) : article.productNumber,
						'attr1_id': attr1Id && attr1Id[1]
					};

					accumulator.push(product);

					return accumulator;
				}, []);

				/**
				 * Assign result from above to object with recommendationId as key
				 */
				res[key] = {
					articles,
					trackingLink: trackingHash[0]
				};

				return res;
			}, {});

			return response;
		})
		.then(result => {
			const response = Object.keys(result)
				.filter(key => result[key].articles && result[key].articles.length !== 0)
				.map(key => {
					const id = Object.keys(query).find(qkey => query[qkey].recommendationId === key);
					const articlesQuery = JSON.stringify(result[key].articles);
					const trackingLink = result[key].trackingLink;

					return articlesSearch(articlesQuery, {id, trackingLink});
				});

			return Promise.all(response).then(values => values);
		});
};

export const recommendationsFetch = createAction('HELLO_RETAIL_RECOMMENDATION_FETCH', async (query, options) => {
	const response = new Promise(resolve => {
		resolve(fetch(query, options));
	});

	return response;
});

export const setRecommendationIds = createAction('HELLO_RETAIL_SET_RECOMMENDATION_IDS');
