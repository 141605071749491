// CUSTOMER OVERRIDE
import {Image} from '~/media';
import Link from '~/link';

const propTypes = {
	openPopup: {
		source: 'openPopup'
	}
};

const renderDetails = (props, isPopup) => {
	const HeadingElement = isPopup ? 'h1' : 'div';
	const SubHeadingElement = isPopup ? 'h1' : 'div';

	return (
		<div class='Badge-details'>
			{props.heading && <HeadingElement class='Badge-heading'>{props.heading}</HeadingElement>}
			{props.subHeading && <SubHeadingElement class='Badge-subHeading'>{props.subHeading}</SubHeadingElement>}
			{props.description && <div class='Badge-description'>{props.description}</div>}
		</div>
	);
};

const render = ({props}) => {
	if (!props.label && !props.image) {
		return <noscript/>;
	}

	const onClick = props.isClickable && (() => props.openPopup(<div class='Badge-popup' height='500px' width='360px'>{renderDetails(props, true)}</div>));
	const backgroundColor = props.backgroundcolor || null;
	const color = props.textcolor || null;
	const border = backgroundColor ? `1px solid ${backgroundColor}` : null;

	const classes = [
		'Badge',
		props.class,
		props.isClickable && 'Badge--clickable'
	];

	const styles = {
		backgroundColor,
		border,
		color
	};

	const label = props.label && props.label.length > 0 ? <div class='Badge-details' style={styles}>{props.label}</div> : <Image class='Badge-image' alt={props.subHeading} title={props.subHeading} source={props.image}/>;
	const badge = props.link && props.link.length > 0 ? <Link class='Badge-link' href={`/${props.link}`}>{label}</Link> : props.label && props.label.length > 0 ? <div class='Badge-text--wrapper'>{label}</div> : <div class='Badge-image--wrapper'>{label}</div>;

	return (
		<div class={classes} onClick={onClick}>
			{badge}
		</div>
	);
};

export default {propTypes, render};
