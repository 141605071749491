const initialState = {
	chosenCity: 0,
	chosenDeliveryPoint: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SHIPPING_SET_CHOSEN_CITY': {
			return {
				...state,
				chosenCity: action.payload
			};
		}

		case 'SHIPPING_SET_CHOSEN_DELIVERY_POINT': {
			return {
				...state,
				chosenDeliveryPoint: action.payload
			};
		}

		case 'SHIPPING_GET_DELIVERY_POINTS_FULFILLED': {
			return {
				...state,
				cities: action.payload.cities,
				deliveryPoints: action.payload.deliveryPoints
			};
		}

		default: {
			return state;
		}
	}
};
