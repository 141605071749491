import bus from '@viskan/bus';
import cookie from 'component-cookie';
import store from 'store2';
import {type} from '@viskan/streamline-utilities';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import isBot from '~/utils/is-bot';
import {deepEqual} from 'fast-equals'; /* CUSTOMER UNIQUE */
import {trackShopcartOnDemand, trackShopcart, trackImBoxInsertCard} from '../../analytics'; /* CUSTOMER UNIQUE */
import {setCanonical, setTitle, setDescription, setContentImages, setType} from '../../seo';
import {BreakpointHandler} from '../../breakpoint';
import Footer from '../../footer';
import Header from '../../header';
import LeaveWindow from '../../leave-window';
import Popup from '../../popup';
import PopupRedux from '../../popup-redux';
import {View} from '../../router';
import ScrollMemory from '../../scroll-memory';
import Slideout from '../../slideout';
import Spinner from '../../spinner';
import StreamlineCms from '../../streamline-cms';
import StructuredDataMarkup from '../../structured-data-markup';
import ArticleStore from '../../article-store';

const propTypes = {
	view: {
		source: 'view'
	},
	getEmail: {
		source: 'getEmail'
	},
	getTranslation: {
		source: 'getTranslation'
	}
};

const TEN_YEARS_IN_MILLISECONDS = 315_360_000;
const GRAY = '#686868';

const setBreakpointOverrideBackground = props => {
	const html = document.querySelector('html');

	html.style.background = props.breakpointOverride ? GRAY : '';
};

const removeScrollBarGap = () => {
	const elementsToHandle = document.querySelectorAll('body, .CMS-StickyHeader--isSticky .CMS-StickyHeader-content');
	elementsToHandle.forEach(section => {
		section.style.paddingRight = null;
	});
};

const addScrollBarGap = () => {
	const paddingToAdd = `${window.innerWidth - document.documentElement.clientWidth}px`;

	if (paddingToAdd === '0px') {
		return;
	}

	const elementsToHandle = document.querySelectorAll('body, .CMS-StickyHeader--isSticky .CMS-StickyHeader-content');
	elementsToHandle.forEach(section => {
		section.style.paddingRight = paddingToAdd;
	});
};

const afterMount = ({props}) => trackShopcart(props.shopcart, props.site);

const afterUpdate = ({props}, prevProps) => {
	if (prevProps.site.club_id !== props.site.club_id || prevProps.site.country_id !== props.site.country_id || prevProps.site.currency_id !== props.site.currency_id || prevProps.site.lang_id !== props.site.lang_id) {
		v12.options.defaults.club_id = props.site.club_id;
		v12.options.defaults.country_id = props.site.country_id;
		v12.options.defaults.currency_id = props.site.currency_id;
		v12.options.defaults.lang_id = props.site.lang_id;
	}

	if (prevProps.site.country_id !== props.site.country_id || prevProps.site.currency_id !== props.site.currency_id || prevProps.site.lang_id !== props.site.lang_id) {
		store('vs-person_flg', true);
		bus.emit('site:get');
	}

	if (props.customer.person_flg !== prevProps.customer.person_flg) {
		store('vs-person_flg', props.customer.person_flg);
		bus.emit('site:get');
	}

	if (!deepEqual(props.customer, prevProps.customer) || !deepEqual(props.myPagesOrders, prevProps.myPagesOrders)) {
		trackImBoxInsertCard(props.customer, props.site, props.myPagesOrders);
	}

	if (!deepEqual(props.slideoutsAndPopups, prevProps.slideoutsAndPopups)) {
		const hasOpenSlideoutOrPopup = Object.values(props.slideoutsAndPopups).some(value => type.isBoolean(value) && value === true);
		const hadPreviousOpenSlideoutOrPopup = Object.values(prevProps.slideoutsAndPopups).some(value => type.isBoolean(value) && value === true);

		if (hadPreviousOpenSlideoutOrPopup) {
			clearAllBodyScrollLocks();
			removeScrollBarGap();
		}

		if (hasOpenSlideoutOrPopup) {
			addScrollBarGap();

			const openSlideoutsAndPopups = document.querySelectorAll('.CMS-Slideout--open .Slideout, .Popup.is-open .CMS-Popup-content, .Popup.is-open .Popup-content');
			openSlideoutsAndPopups.forEach(element => disableBodyScroll(element));
		}
	}

	if (props.mainSection.categoryId !== undefined) {
		const currentTitle = props.mainSection.title || props.mainSection.categoryName;

		if (currentTitle !== (prevProps.mainSection.title || prevProps.mainSection.categoryName) || props.router.query.page !== prevProps.router.query.page) {
			setTitle(currentTitle, props.site.companyName, !props.mainSection.title, props);
		}

		if (props.mainSection.linkFriendlyName !== prevProps.mainSection.linkFriendlyName) {
			setDescription(props.mainSection.metaDescription);
			setContentImages(JSON.stringify(props.mainSection.content));
			setType({pageType: 'website'});
		}

		if (props.mainSection.linkFriendlyName !== prevProps.mainSection.linkFriendlyName || props.router.query.page !== prevProps.router.query.page) {
			setCanonical();
		}
	}

	if (!deepEqual(props.shopcart.rows, prevProps.shopcart.rows)) {
		trackShopcart(props.shopcart, props.site);
	}

	setBreakpointOverrideBackground(props);

	/* CUSTOMER UNIQUE - Shopcart tracking on checkout */
	if (props.router.pathname.includes('checkout') && !deepEqual(props.shopcart, prevProps.shopcart)) {
		trackShopcartOnDemand(props.shopcart);
	}
};

const customerGet = data => data ? Promise.resolve(data) : v12.customer.get();
const beforeMount = ({props}) => {
	const {customer, fetchShopcart, getEmail, customerPatch, recieveSite, router, site} = props;
	const locale = site.locale.toLowerCase();

	if (!props.isLandingPage && locale !== cookie('locale')) {
		cookie('locale', locale, {
			maxage: TEN_YEARS_IN_MILLISECONDS,
			path: '/'
		});
	}

	fetchShopcart();

	bus.on('shopcart:get', fetchShopcart);
	bus.on('site:recieve', recieveSite);
	bus.on('customer:update', data => customerPatch(data, false));
	bus.on('logout', () => {
		customerGet().then(() => props.customerFetch());
	});
	bus.on('login', data => {
		customerGet(data)
			.then(data => {
				const {person_flg} = data;

				store('vs-person_flg', person_flg);
				bus.emit('customer:update', data);
				bus.emit('shopcart:get');
				bus.emit('site:get');
			})
			.catch(v12.util.error);
	});

	if (site.settings.remarketing && getEmail() && customer.logged_in_level === 0 && site.selectedPurchaseCountry) {
		v12.customer.match({
			'del_country.id': site.selectedPurchaseCountry.id,
			'inv_country.id': site.selectedPurchaseCountry.id,
			'vis_country.id': site.selectedPurchaseCountry.id,
			email_1: getEmail()
		})
			.then(data => bus.emit('login', data))
			.catch(v12.util.error);
	}

	if (!site.countrySelectable && !site.selectedPurchaseCountry.id && !props.isLandingPage && !isBot()) {
		const fullLocationUrl = `${window.location.pathname}${window.location.search}`.replace(router.pathPrefix, '/');

		window.location.href = `/?no-redirect&${site.country_code}=${encodeURIComponent(fullLocationUrl)}`;
	}

	setBreakpointOverrideBackground(props);
};

const render = ({props}) => {
	const classes = {
		'App': true,
		'SCMS-DevicePreview--mobile': props.breakpointOverride === 'xsm',
		'SCMS-DevicePreview--tablet': props.breakpointOverride === 'md',
		'SCMS-EditMode': props.isEditing,
		[`SCMS-EditMode--${props.activeCmsSection}`]: props.isEditing && props.activeCmsSection
	};

	const contentStyle = props.breakpointOverride && {
		background: '#fff'
	};

	const hideFooterAndHeader = props.isLandingPage || props.isRedirectPage;

	return (
		<div>
			<div class={classes}>
				<StructuredDataMarkup/>
				<BreakpointHandler progressive>
					<div class={props.cssNamespace.content} style={contentStyle}>
						{!hideFooterAndHeader && <Header/>}
						<main>
							<View/>
						</main>
						{!hideFooterAndHeader && <Footer/>}
						<Popup universal/>
						<PopupRedux/>
						<Slideout/>
						<Spinner/>
						<LeaveWindow/>
					</div>
					<StreamlineCms/>
				</BreakpointHandler>
				<ScrollMemory/>
				<ArticleStore disabled={false /* CUSTOMER UNIQUE SET TO FALSE */}/>
			</div>
		</div>
	);
};

export default {afterMount, afterUpdate, beforeMount, propTypes, render};
