export default {
	type: 'SHOPCART_ROW_PRICES',
	name: 'Row Prices',
	allowedSections: ['header', 'main'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	icon: 'fad fa-tags',
	styleClasses: {
		Wrapper: '',
		Price: '',
		Sale_Price: '',
		Original_Price: ''
	},
	props: []
};
